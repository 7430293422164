import { HStack, Text, VStack } from '@chakra-ui/react';
import { Currency } from '@jurnee/common/src/entities/Currency';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { UserBudgetBreakdownJSON } from '@jurnee/common/src/entities/UserBudgetBreakdown';
import { getUserBudgetRemaining } from '@jurnee/common/src/utils/budgets';
import { useTranslation } from 'react-i18next';
import PriceInUserCurrency from '../../../PriceInUserCurrency';
import { BudgetStatus } from './BudgetStatus';
import { UsersOutBudget } from './UsersOutBudget';

interface Props {
  bookingTotal: number;
  users: Pick<UserJSON, 'id' | 'firstName' | 'lastName' | 'email'>[];
  userBudgetBreakdowns: UserBudgetBreakdownJSON[];
  currency: Currency;
}

export function BudgetIndividualCard(props: Props) {
  const { t } = useTranslation('booking');

  const individualBudget = props.bookingTotal / props.users.length;

  const userBudgetBreakdownsOut = props.userBudgetBreakdowns.filter(userBudgetBreakdown =>
    (getUserBudgetRemaining(userBudgetBreakdown) - individualBudget) < 0
  );

  return (
    <VStack w="100%" alignItems="flex-start" spacing={4}>
      <HStack w="100%" justifyContent="space-between">
        <Text fontWeight={700} lineHeight="14px">
          { t('budget.budgetIndividualCard.distributedBetween') }
        </Text>

        <Text color="gray.400" lineHeight="14px">
          { t('participants', { count: props.users.length }) }
        </Text>
      </HStack>

      <HStack w="100%" justifyContent="space-between">
        <Text fontWeight={700} lineHeight="14px">
          { t('budget.budgetIndividualCard.individualBudget') }
        </Text>

        <Text color="gray.400" lineHeight="14px">
          <PriceInUserCurrency value={individualBudget} currency={props.currency} />
        </Text>
      </HStack>

      <HStack w="100%" justifyContent="space-between">
        <Text fontWeight={700} lineHeight="14px">
          { t('budget.budgetIndividualCard.status') }
        </Text>

        <BudgetStatus outBudgetCount={userBudgetBreakdownsOut.length} />
      </HStack>

      {
        userBudgetBreakdownsOut.length > 0 &&
          <UsersOutBudget
            individualBudget={individualBudget}
            userBudgetBreakdowns={userBudgetBreakdownsOut}
            users={props.users}
            currency={props.currency}
          />
      }
    </VStack>
  );
}