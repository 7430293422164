import { Loader } from '@jurnee/common/src/components/Loader';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BudgetBreakdownJSON } from '@jurnee/common/src/entities/BudgetBreakdown';
import { Currency } from '@jurnee/common/src/entities/Currency';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { UserBudgetBreakdownJSON } from '@jurnee/common/src/entities/UserBudgetBreakdown';
import { getBudgetRemaining } from '@jurnee/common/src/utils/budgets';
import { BudgetGlobalCard } from 'src/components/Budget/BookingBudgetCard/BudgetGlobalCard';
import { BudgetIndividualCard } from 'src/components/Budget/BookingBudgetCard/BudgetIndividualCard';

interface Props {
  bookingTotal: number;
  budget: BookingJSON['budget'] | null;
  budgetBreakdown: BudgetBreakdownJSON | null;
  users: Pick<UserJSON, 'id' | 'firstName' | 'lastName' | 'email'>[];
  userBudgetBreakdowns: UserBudgetBreakdownJSON[];
  currency: Currency;
  isLoading?: boolean;
}

export function BookingBudgetCard(props: Props) {
  if(props.isLoading) {
    return <Loader h={150} />;
  }

  if (!props.budget && props.users.length > 0) {
    return <BudgetIndividualCard
      bookingTotal={props.bookingTotal}
      users={props.users}
      userBudgetBreakdowns={props.userBudgetBreakdowns}
      currency={props.currency}
    />;
  }

  const remaining = getBudgetRemaining(props.budgetBreakdown);
  const pending = props.budgetBreakdown.pending - props.bookingTotal;

  return <BudgetGlobalCard
    budgetName={props.budget.name}
    booking={props.bookingTotal}
    remaining={remaining}
    pending={pending}
    spent={props.budgetBreakdown.spent}
    total={props.budgetBreakdown.total}
    currency={props.currency}
  />;
}