import { VStack } from '@chakra-ui/react';
import { QuestionUpsertBody } from '@jurnee/common/src/dtos/surveys';
import { DragEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from 'src/components/buttons';
import { QuestionForm } from './QuestionForm';

interface Props {
  questions: QuestionUpsertBody[];
  onChange(questions: QuestionUpsertBody[]): void;
  onAdd(): void;
}

function getStyleProps(index: number, draggedIndex: number, dropTargetIndex: number) {
  if (draggedIndex === null) {
    return {};
  }

  if (index === draggedIndex) {
    return {
      opacity: '30%'
    };
  }

  if (index === dropTargetIndex && index < draggedIndex) {
    return {
      boxShadow: 'inset 0 2px 0 var(--chakra-colors-gray-400)'
    };
  }

  if (index === dropTargetIndex && index > draggedIndex) {
    return {
      boxShadow: 'inset 0 -2px 0 var(--chakra-colors-gray-400)'
    };
  }

  return {};
}

export function Questions(props: Props) {
  const { t } = useTranslation('surveys', { keyPrefix: 'drawers.survey' });

  const [draggedIndex, setDraggedIndex] = useState(null);
  const [dropTargetIndex, setDropTargetIndex] = useState(null);

  function handleDragOver(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
  }

  function handleDragEnd() {
    const newQuestions = [...props.questions];
    const draggedQuestion = newQuestions[draggedIndex];

    newQuestions.splice(draggedIndex, 1);
    newQuestions.splice(dropTargetIndex, 0, draggedQuestion);

    setDraggedIndex(null);
    setDropTargetIndex(null);

    props.onChange(newQuestions.map((question, rank) => ({ ...question, rank })));
  }

  function handleChange(newQuestion: QuestionUpsertBody) {
    props.onChange(props.questions.map(question => question.rank === newQuestion.rank ? newQuestion : question));
  }

  function handleDelete(rank: QuestionUpsertBody['rank']) {
    const newQuestions = props.questions.filter(question => question.rank !== rank);
    props.onChange(newQuestions.map((question, rank) => ({ ...question, rank })));
  }

  return (
    <VStack w="100%" spacing={0} alignItems="flex-start">
      {
        props.questions.map((question, i) => {
          const styleProps = getStyleProps(i, draggedIndex, dropTargetIndex);

          return <QuestionForm
            key={question.id}
            question={question}
            onQuestionChange={handleChange}
            onQuestionDelete={() => handleDelete(question.rank)}
            onDragStart={() => setDraggedIndex(i)}
            onDragEnter={() => setDropTargetIndex(i)}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            py={2}
            {...styleProps}
          />;
        })
      }

      <SecondaryButton size="sm" mt={2} colorScheme="gray" color="blue.400" cursor="pointer" onClick={props.onAdd}>
        { t('addQuestion') }
      </SecondaryButton>
    </VStack>
  );
}