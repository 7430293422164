import { Box, HStack } from '@chakra-ui/react';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { useSelector } from 'react-redux';
import { getEmployeesByBudgetId } from 'src/store/employees/employees.selectors';
import { BudgetBreakdown } from '../../../../../../components/Budget/BudgetBreakdown';
import { BudgetCard } from '../../BudgetCard';

interface Props {
  budget: BudgetJSON;
}

export function BudgetRow({ budget }: Props) {
  const users = useSelector(getEmployeesByBudgetId(budget.id));

  return (
    <HStack spacing={5} w="100%" alignItems="stretch" key={budget.id}>
      <BudgetCard budget={budget} users={users} />
      <Box display="flex" bg="white" borderRadius={4} border="1px solid" borderColor="blue.50" p={4} minW={280} maxW={280}>
        <BudgetBreakdown budget={budget} />
      </Box>
    </HStack>
  );
}