export const canvasStyles: React.CSSProperties = {
  position: 'absolute',
  zIndex: 10,
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0
};

export function fireConfetti(confetti: confetti.CreateTypes, origin = { y: 0.95 }) {
  confetti({
    origin,
    particleCount: 100,
    scalar: 0.7,
    spread: 25,
    startVelocity: 60
  });

  confetti({
    origin,
    particleCount: 100,
    scalar: 0.8,
    spread: 75,
    startVelocity: 40
  });

  confetti({
    origin,
    particleCount: 100,
    scalar: 0.9,
    spread: 125,
    startVelocity: 20
  });
}