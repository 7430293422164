import { Box, BoxProps, HStack, Tooltip } from '@chakra-ui/react';
import { useMemo } from 'react';

export interface Segment {
  label: string;
  value: number;
  color: BoxProps['bgColor']
}

interface Props {
  segments: Segment[];
}

export default function ProgressBar({ segments }: Props) {
  const total = useMemo(() => segments.reduce((total, { value }) => total + value, 0), [segments]);

  if (!total) {
    return <Box w="100%" h="4px" minW="4px" bgColor="gray.400" borderRadius="3px" />;
  }

  return (
    <HStack w="100%" spacing={1}>
      {
        segments.map(({ label, value, color }, index) => {
          if (typeof value !== 'number' || value <= 0) {
            return null;
          }

          return (
            <Tooltip key={index} hasArrow label={label} fontSize='12px' bg={color} mt={1} borderRadius={4}>
              <Box w={`${value * 100 / total}%`} h="4px" minW="4px" bgColor={color} borderRadius="3px" />
            </Tooltip>
          );
        })
      }
    </HStack>
  );
}