import countriesList from '@jurnee/common/src/components/CountrySelector/list';
import { EntityUpdateBody } from '@jurnee/common/src/dtos/entities';
import { EntityJSON } from '@jurnee/common/src/entities/Entity';
import { TaxIdNumberType } from '@jurnee/common/src/entities/TaxIdNumber';
import { isEmpty } from '@jurnee/common/src/utils/strings';

export const taxIdNumberTypes: { label: string, value: TaxIdNumberType }[] = [
  { label: 'EU VAT', value: 'eu_vat' },
  { label: 'GB VAT', value: 'gb_vat' },
];

export function formatEntityAddress(address: EntityJSON['address']) {
  let formattedAddress = address.address;

  if (!isEmpty(address.additionalInformation)) {
    formattedAddress += `, ${address.additionalInformation}`;
  }

  formattedAddress += `, ${address.postalCode} ${address.city}`;

  if (!isEmpty(address.state)) {
    formattedAddress += `, ${address.state}`;
  }

  formattedAddress += `, ${countriesList.find(country => country.value === address.countryCode)?.label || address.countryCode}`;

  return formattedAddress;
}

export function isEmptyMandatoryField(data: EntityUpdateBody) {
  return isEmpty(data.name) ||
    isEmpty(data.address) ||
    isEmpty(data.postalCode) ||
    isEmpty(data.city) ||
    isEmpty(data.country) ||
    isEmpty(data.countryCode) ||
    isEmpty(data.taxIdNumber.type) ||
    isEmpty(data.taxIdNumber.value);
}

export function getEntityUpdateBody(entity: EntityJSON): EntityUpdateBody {
  return {
    name: entity.name,
    additionalInformation: entity.address?.additionalInformation,
    address: entity.address?.address,
    city: entity.address?.city,
    postalCode: entity.address?.postalCode,
    state: entity.address?.state,
    country: entity.address?.country,
    countryCode: entity.address?.countryCode,
    taxIdNumber: {
      type: entity.taxIdNumber?.type || taxIdNumberTypes[0].value,
      value: entity.taxIdNumber?.value,
    },
  };
}