import { Box, Divider, VStack } from '@chakra-ui/react';
import { BookingParticipantJSON } from '@jurnee/common/src/entities/BookingParticipant';
import { ParticipantsBudgetsChart } from './ParticipantsBudgetsChart';
import { ParticipantsStatusesChart } from './ParticipantsStatusesChart';

interface Props {
  bookingParticipants: BookingParticipantJSON[];
  shouldDisplayBudget: boolean;
}

export default function ParticipantsChart({ bookingParticipants, shouldDisplayBudget }: Props) {
  return (
    <VStack
      w="100%"
      spacing={0}
      alignItems="stretch"
      bgColor="white"
      border="1px solid"
      borderColor="gray.200"
      borderRadius={4}
    >
      <ParticipantsStatusesChart
        p={5}
        bookingParticipants={bookingParticipants}
      />

      {
        shouldDisplayBudget && (
          <>
            <Box px={5}>
              <Divider />
            </Box>

            <ParticipantsBudgetsChart
              p="12px 20px 12px 12px"
              bookingParticipants={bookingParticipants}
            />
          </>
        )
      }
    </VStack>
  );
}