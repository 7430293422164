import { Heading, HStack, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { getIntervalToDuration } from '@jurnee/common/src/utils/dates';
import * as React from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { useTranslation } from 'react-i18next';
import { canvasStyles, fireConfetti } from '../utils/confetti';

interface Props {
  date: string;
}

interface TimeLeft {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

export default function EventConfirmedModal(props: Props) {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const [duration, setDuration] = React.useState(getDuration());
  const { t } = useTranslation();

  function onInit({ confetti }: { confetti: confetti.CreateTypes | null }) {
    confetti ? fireConfetti(confetti) : null;
  }

  function getDuration() {
    return getIntervalToDuration(new Date(), new Date(props.date));
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      setDuration(getDuration());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [duration]);

  function getTimeElement(label: string, value: string) {
    return (
      <VStack spacing={4} minWidth="70px">
        <Text fontSize={14} color="gray.400">{label}</Text>
        <Text fontSize={48} fontWeight={700} lineHeight="36px">{value}</Text>
      </VStack>
    );
  }

  function getTimeSeparator() {
    return <Text fontSize={48} fontWeight={700} lineHeight="36px" alignSelf="flex-end">:</Text>;
  }

  function getTimeLeft() {
    if (new Date() > new Date(props.date)) {
      return null;
    }

    const { months, days, hours, minutes, seconds } = duration;

    const timeLeft = Object.keys({ months, days, hours, minutes, seconds }).reduce((result, key: keyof TimeLeft) => {
      result[key] = (duration[key] || 0).toString().padStart(2, '0');
      return result;
    }, { months: null, days: null, hours: null, minutes: null, seconds: null });

    return (
      <HStack spacing={3} justifyContent="center">
        { months > 0 && getTimeElement('Months', timeLeft.months) }
        { months > 0 && getTimeSeparator() }
        { getTimeElement('Days', timeLeft.days) }
        { getTimeSeparator() }
        { getTimeElement('Hours', timeLeft.hours) }
        { getTimeSeparator() }
        { getTimeElement('Minutes', timeLeft.minutes) }
        { getTimeSeparator() }
        { getTimeElement('Seconds', timeLeft.seconds) }
      </HStack>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="roundedBody">
      <ModalOverlay />
      <ModalContent maxWidth="600px">
        <ModalBody position="relative">
          <ReactCanvasConfetti onInit={onInit} style={canvasStyles} />

          <VStack minH="350px" justifyContent="center" spacing={5}>
            <HStack spacing={3}>
              <Heading size="xl">{t('booking:modals.eventConfirmed.heading')}</Heading>

              <Image src='/assets/illustrations/event_confirmed.svg' w="40px" h="40px" />;
            </HStack>

            { getTimeLeft() }
          </VStack>

          <ModalCloseButton />
        </ModalBody>
      </ModalContent>
    </Modal>
  );

}