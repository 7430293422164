import { Heading, VStack } from '@chakra-ui/react';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { CurrencyExchangeRates } from '@jurnee/common/src/entities/Currency';
import { useTranslation } from 'react-i18next';
import { BookingsTabs as Tabs } from 'src/utils/booking';
import BookingCard from './BookingCard';

interface Props {
  bookings: BookingJSON[];
  relationships: Pick<BookingRelationshipsJSON, 'approvalRequests' | 'bookingsInvoices' | 'bookingsReviews' | 'externalCosts' | 'quotes' | 'propositions' | 'propositionsGroups' | 'users'>;
  currencies: CurrencyExchangeRates;
  tab: Tabs;
  children: React.ReactNode;
}

export default function BookingsList(props: Props) {
  const { t } = useTranslation('bookings');

  function getBookingCard(booking: BookingJSON) {
    return <BookingCard
      key={booking.id}
      booking={booking}
      relationships={props.relationships}
      currencies={props.currencies}
    />;
  }

  return (
    <VStack w="100%" spacing={3} alignItems="flex-start">
      <Heading size="md" lineHeight="32px">{t(`list.${props.tab}`)}</Heading>

      { props.children }

      <VStack w="100%" spacing={8}>
        { props.bookings.map(getBookingCard) }
      </VStack>
    </VStack>
  );

}