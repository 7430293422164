{
  "createTask": "Create task",
  "drawer": {
    "createTitle": "Create task",
    "form": {
      "assignedTo": {
        "jurneeTeam": "Jurnee team",
        "label": "Assigned to",
        "placeholder": "Select a user"
      },
      "attachments": {
        "label": "Attachments"
      },
      "description": {
        "placeholder": "Add description…"
      },
      "dueDate": {
        "label": "Due date",
        "placeholder": "Select a date"
      },
      "name": {
        "placeholder": "Task name"
      },
      "priority": {
        "label": "Priority"
      },
      "status": {
        "label": "Status"
      }
    },
    "toasts": {
      "create": {
        "error": "An error occurred while creating task",
        "success": "Task created"
      },
      "createComment": {
        "error": "An error occurred while creating comment"
      },
      "deleteFile": {
        "error": "An error occurred while deleting the attachment"
      },
      "downloadFile": {
        "error": "An error occurred while downloading the attachment"
      },
      "update": {
        "error": "An error occurred while updating task",
        "success": "Task updated"
      },
      "uploadFile": {
        "error": "An error occurred while uploading the attachment"
      }
    },
    "updateTitle": "Edit task"
  },
  "filters": {
    "assignee": {
      "all": "All owners"
    },
    "booking": {
      "all": "All events"
    },
    "name": {
      "placeholder": "Search a task"
    },
    "priority": {
      "all": "All priorities"
    },
    "status": {
      "all": "All status"
    }
  },
  "heading": "My tasks",
  "priority": {
    "HIGH": "High",
    "LOW": "Low",
    "MEDIUM": "Medium",
    "URGENT": "Urgent"
  },
  "status": {
    "CANCELED": "Canceled",
    "DONE": "Done",
    "IN_PROGRESS": "In progress",
    "TODO": "Todo"
  },
  "table": {
    "dotMenu": {
      "delete": "Delete task",
      "deleteConfirmation": {
        "message": "Are you sure you want to delete <strong>{{taskName}}</strong>?",
        "title": "Delete task"
      },
      "edit": "Edit task"
    },
    "dueDate": "Due date",
    "emptyState": {
      "description": "Tasks allow you to create a to-do list, collaborate, discuss a topic and make decisions",
      "description_filters": "We can't find any tasks matching these filters",
      "heading": "No tasks created",
      "heading_filters": "No tasks found"
    },
    "event": "Event",
    "name": "Name",
    "owner": "Owner",
    "priority": "Priority",
    "status": "Status"
  },
  "toasts": {
    "deleteTask": {
      "error": "An error occurred while deleting the task",
      "success": "Task deleted"
    },
    "fetchTasks": {
      "error": "An error occurred while retrieving your tasks"
    }
  }
}