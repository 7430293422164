import { Link, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { EmptyState } from '@jurnee/common/src/components/EmptyState';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { TeamJSON } from '@jurnee/common/src/entities/Team';
import { getInvoicesTotal } from '@jurnee/common/src/utils/bookingInvoices';
import { formatPrice } from '@jurnee/common/src/utils/prices';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import { BookingInvoiceStatus } from 'src/components/Status/BookingInvoiceStatus';
import { InvoiceDate } from './InvoiceDate';
import { InvoiceDotMenu } from './InvoiceDotMenu';

interface Props {
  bookingsInvoices: BookingInvoiceJSON[];
  budgets: BudgetJSON[];
  teams: TeamJSON[];
}

export function BookingsInvoicesTable({ bookingsInvoices, budgets, teams }: Props) {
  const { t } = useTranslation('billing', { keyPrefix: 'invoices' });
  const location = useLocation();

  const rows = bookingsInvoices.length === 0 ? (
    <Tr>
      <Td p={16} colSpan={7}>
        <EmptyState
          icon="listSearch"
          heading={t('emptyState.heading')}
          description={t('emptyState.description')}
        />
      </Td>
    </Tr>
  ) : (
    bookingsInvoices.map((bookingInvoice, idx) => {
      return (
        <Tr key={idx}>
          <Td>{bookingInvoice.booking.budget?.name || '-'}</Td>
          <Td>{bookingInvoice.booking.team?.name || '-'}</Td>
          <Td>
            <Link color="blue.400" to={`/bookings/${bookingInvoice.bookingId}`} as={ReactRouterLink} state={{ prevPath: location.pathname }}>
              { bookingInvoice.booking.name }
            </Link>
          </Td>
          <Td><InvoiceDate bookingInvoice={bookingInvoice} /></Td>
          <Td>{formatPrice(getInvoicesTotal([bookingInvoice]), bookingInvoice.currency)}</Td>
          <Td textAlign="right"><BookingInvoiceStatus status={bookingInvoice.status} /></Td>
          <Td pl={0} pr={3}><InvoiceDotMenu bookingInvoice={bookingInvoice} budgets={budgets} teams={teams} /></Td>
        </Tr>
      );
    })
  );

  return (
    <TableContainer w="100%">
      <Table>
        <Thead>
          <Tr>
            <Th w="15%">{t('table.budget')}</Th>
            <Th w="15%">{t('table.team')}</Th>
            <Th w="30%">{t('table.booking')}</Th>
            <Th w="10%">{t('table.date')}</Th>
            <Th w="10%">{t('table.amount')}</Th>
            <Th w="10%" textAlign="right">{t('table.status')}</Th>
            <Th w="32px"></Th>
          </Tr>
        </Thead>
        <Tbody>{rows}</Tbody>
      </Table>
    </TableContainer>
  );
}