import { FormControl, FormLabel } from '@chakra-ui/react';
import { DatePicker } from '@jurnee/common/src/components/DatePicker';
import { useTranslation } from 'react-i18next';

interface Props {
  selected: Date;
  isDisabled: boolean;
  onChange(date: Date): void;
}

export function DueDateDatePicker({ selected, isDisabled, onChange }: Props) {
  const { t } = useTranslation('tasks', { keyPrefix: 'drawer.form.dueDate' });

  return (
    <FormControl id="dueDate">
      <FormLabel>{t('label')}</FormLabel>

      <DatePicker
        popperPlacement="top"
        dateFormat="dd MMM yyyy"
        selected={selected}
        placeholderText={t('placeholder')}
        onChange={onChange}
        minDate={new Date()}
        inputProps={{ size: 'sm' }}
        disabled={isDisabled}
      />
    </FormControl>
  );
}