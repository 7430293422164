import { Select } from '@chakra-ui/react';
import { QuestionUpsertBody } from '@jurnee/common/src/dtos/surveys';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  type: QuestionUpsertBody['type'];
  onSelect(e: ChangeEvent<HTMLSelectElement>): void;
}

export function TypeSelect(props: Props) {
  const { t } = useTranslation('surveys', { keyPrefix: 'drawers.survey.form.question.type' });

  const types = ['TEXT', 'CHECKBOX', 'SELECT'];

  return (
    <Select
      size="sm"
      placeholder={t('placeholder')}
      value={props.type || ''}
      onChange={props.onSelect}
    >
      {
        types.map((type, index) => (
          <option key={index} value={type}>
            { t(`label.${type}`) }
          </option>
        ))
      }
    </Select>
  );
}