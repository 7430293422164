import { TimeSlot } from '@jurnee/common/src/components/TimeSlotSelector';
import { getZonedDate } from '@jurnee/common/src/utils/dates';

export function mapFrequencyToPeriodicity(frequency: string) {
  switch (frequency) {
  case 'MONTHLY':
    return 'current_month';
  case 'QUARTERLY':
    return 'current_quarter';
  case 'SEMIANNUALLY':
    return 'current_semester';
  case 'YEARLY':
    return 'current_year';
  default:
    return 'current_month';
  }
}

export function setHoursFromTime(input: Date, time: string) {
  const date = new Date(input);
  const hour = time.split(':').map(i => Number(i));
  date.setHours(hour[0], hour[1], 0);

  return date;
}

export function getZonedTimeSlot(date: Date, timeSlot: TimeSlot, timezone: string) {
  const from = setHoursFromTime(date, timeSlot.from);
  const to = setHoursFromTime(date, timeSlot.to);

  return {
    from: getZonedDate(from, timezone),
    to: getZonedDate(to, timezone)
  };
}