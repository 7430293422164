import { VStack } from '@chakra-ui/react';
import { QuestionOptionJSON } from '@jurnee/common/src/entities/Survey';
import { QuestionAnswerJSON } from '@jurnee/common/src/entities/SurveySubmission';
import { groupBy } from '@jurnee/common/src/utils/arrays';
import { useMemo } from 'react';
import { AnswerChart } from './AnswerChart';

interface Props {
  questionAnswers: QuestionAnswerJSON[];
  questionOptions: QuestionOptionJSON[];
}

export function AnswersMultiple(props: Props) {
  const groups = useMemo(() => groupBy(props.questionAnswers, 'questionOptionId'), [props.questionAnswers]);

  const sortedOptions = useMemo(
    () => props.questionOptions.sort((a,b) => (groups[b.id] || []).length - (groups[a.id] || []).length),
    [props.questionOptions, groups]
  );

  return (
    <VStack
      w="100%"
      bgColor="white"
      border="1px solid"
      borderColor="gray.200"
      borderRadius={4}
      p={5}
      spacing={4}
    >
      {
        sortedOptions.map(({ id, value }) =>
          <AnswerChart
            key={id}
            label={value}
            value={(groups[id] || []).length}
            total={props.questionAnswers.length}
          />
        )
      }
    </VStack>
  );
}