import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { createBudget, deleteBudget, updateBudget } from '../budgets/budgets.thunks';
import { initialState } from '../state';
import { deleteEmployee, getEmployees, updateEmployee } from './employees.thunks';

const slice = createSlice({
  name: 'employees',
  initialState: initialState.employees,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getEmployees.pending, () => {
      return { list: [], error: null, status: FETCH_STATUS.FETCHING };
    });

    builder.addCase(getEmployees.fulfilled, (state, action) => {
      return { list: action.payload, error: null, status: FETCH_STATUS.FETCHED };
    });

    builder.addCase(getEmployees.rejected, () => {
      return { list: [], error: true, status: FETCH_STATUS.INITIAL };
    });

    builder.addCase(deleteEmployee.pending, (state, action) => {
      return { ...state, list: state.list.filter(employee => employee.id !== action.meta.arg.id) };
    });

    builder.addCase(deleteEmployee.rejected, (state, action) => {
      return { ...state, list: [ ...state.list, action.meta.arg ]};
    });

    builder.addCase(updateEmployee.fulfilled, (state, action) => {
      return {
        ...state,
        list: state.list.map(employee => employee.id === action.payload.id ? action.payload : employee)
      };
    });

    builder.addCase(createBudget.fulfilled, (state, { payload: { relationships: { users } } }) => {
      return {
        ...state,
        list: state.list.map(employee => {
          const user = users.find(user => user.id === employee.id);

          return user ? { ...employee, budgetId: user.budgetId } : employee;
        })
      };
    });

    builder.addCase(updateBudget.fulfilled, (state, { payload }) => {
      const { data, relationships } = payload;

      return {
        ...state,
        list: state.list.map(employee => {
          const user = relationships.users.find(user => user.id === employee.id);

          if (user) {
            return { ...employee, budgetId: user.budgetId };
          }

          if (employee.budgetId === data.id) {
            return { ...employee, budgetId: null };
          }

          return employee;
        })
      };
    });

    builder.addCase(deleteBudget.fulfilled, (state, { payload }) => {
      return {
        ...state,
        list: state.list.map(employee =>
          employee.budgetId === payload.id ? { ...employee, budgetId: null } : employee
        )
      };
    });

  }
});

export default slice.reducer;