import { FormControl, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast } from '@chakra-ui/react';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import InputCopy from 'src/components/InputCopy';
import { PrimaryButton, SecondaryButton } from 'src/components/buttons';
import { useAppDispatch } from 'src/store';
import { getCompanySelector } from 'src/store/company/company.selector';
import { createScimConfiguration } from 'src/store/scimConfigurations/scimConfigurations.thunks';

export default function ScimConfigurationModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [configuration, setConfiguration] = useState(null);
  const dispatch = useAppDispatch();
  const company = useSelector(getCompanySelector);
  const toast = useToast();

  async function open() {
    setIsLoading(true);

    try {
      const data = await dispatch(createScimConfiguration()).unwrap();
      setConfiguration(data);
      onOpen();
    } catch(err) {
      toast(getErrorToast(t('people:userProvisioning.toasts.create.error')));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('people:userProvisioning.heading')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex" flexDirection="column" gap={5}>
            <FormControl>
              <FormLabel>{t('people:userProvisioning.clientId')}</FormLabel>
              <InputCopy value={configuration ? configuration.clientId : ''} />
            </FormControl>
            <FormControl>
              <FormLabel>{t('people:userProvisioning.clientSecret')}</FormLabel>
              <InputCopy value={configuration ? configuration.clientSecret : ''} />
            </FormControl>
          </ModalBody>
          <ModalFooter display="flex" justifyContent="flex-end">
            <SecondaryButton colorScheme="pink" size="sm" onClick={onClose}>
              {t('common:buttons.close')}
            </SecondaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {!company.scimConfiguration && (
        <PrimaryButton size="sm" onClick={open} isLoading={isLoading}>
          {t('people:userProvisioning.generateCredentials')}
        </PrimaryButton>
      )}
    </>
  );

}