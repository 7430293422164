import { FormControl, FormLabel, HStack } from '@chakra-ui/react';
import { DatePicker } from '@jurnee/common/src/components/DatePicker';
import { TimeSlotSelector } from '@jurnee/common/src/components/TimeSlotSelector';
import { useTranslation } from 'react-i18next';

interface Props {
  date: Date | null;
  onDateChange(date: Date): void;
  onTimeChange(time: string): void;
}

export function DateTimeSelector(props: Props) {
  const { t } = useTranslation('common', { keyPrefix: 'fields' });

  function onDateChange(date: Date) {
    if (!date) {
      return;
    }

    props.onDateChange(date);
  }

  return (
    <HStack w="100%" spacing={5}>
      <FormControl id="date" isRequired>
        <FormLabel>{t('date.label')}</FormLabel>
        <DatePicker
          popperPlacement="bottom-start"
          dateFormat="dd MMM yyyy"
          selected={props.date}
          placeholderText={t('date.placeholder')}
          onChange={onDateChange}
          inputProps={{ size: 'sm' }}
        />
      </FormControl>

      <FormControl id="time" isRequired>
        <FormLabel>{t('time.label')}</FormLabel>
        <TimeSlotSelector
          size="sm"
          date={props.date || new Date()}
          duration={30}
          onPick={({ from }) => props.onTimeChange(from ? from : '')}
          placeholder={t('time.placeholder')}
        />
      </FormControl>
    </HStack>
  );
}