import { ApprovalProcessJSON } from '@jurnee/common/src/entities/ApprovalProcess';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { useTranslation } from 'react-i18next';
import { EditEmployeeDrawerLink } from './EditEmployeeDrawerLink';

interface Props {
  approvalProcess: ApprovalProcessJSON | null;
  user: UserDetails;
  budgets: BudgetJSON[];
}

export function UserApprovalProcess({ approvalProcess, user, budgets }: Props) {
  const { t } = useTranslation('people', { keyPrefix: 'approvalProcess' });

  if (!approvalProcess) {
    return <EditEmployeeDrawerLink user={user} label={t('add')} budgets={budgets} />;
  }

  return approvalProcess.name;
}
