import { StatusBadge } from '@jurnee/common/src/components/StatusBadge';
import { useTranslation } from 'react-i18next';

function getStatusBadgeColor(status: string) {
  switch (status) {
  case 'ACCEPTED': return 'teal';
  case 'ATTENDED': return 'black';
  case 'DECLINED': return 'pink';
  case 'INVITED': return 'blue';
  default: return 'gray';
  }
}

export function ParticipantStatus({ status }: { status: string }) {
  const { t } = useTranslation('registration', { keyPrefix: 'status' });

  return <StatusBadge
    label={t(status)}
    color={getStatusBadgeColor(status)}
  />;
}