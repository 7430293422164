import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
  useToast
} from '@chakra-ui/react';
import { BookingParticipantsImportBody } from '@jurnee/common/src/dtos/bookingsParticipants';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { isValidEmail } from '@jurnee/common/src/utils/strings';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/store';
import { trackEvent } from 'src/store/analytics/analytics.thunks';
import { importBookingParticipantsThunk } from 'src/store/bookingParticipants/bookingParticipants.thunks';
import { PrimaryButton, SecondaryButton } from '../components/buttons';

interface Props {
  booking: BookingJSON;
  onImportParticipants(): void
}

export default function ImportParticipantsModal(props: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['registration', 'common']);
  const toast = useToast();

  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [participants, setParticipants] = useState<BookingParticipantsImportBody['participants']>([]);

  function onClose() {
    setIsOpen(false);
  }

  function onOpen(event: React.MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    setIsOpen(true);

    dispatch(trackEvent({
      name: 'opened_import_participants_modal'
    }));
  }

  function importParticipants() {
    return dispatch(
      importBookingParticipantsThunk({
        bookingId: props.booking.id,
        data: { participants }
      })
    );
  }

  async function onImport() {
    setIsSaving(true);

    try {
      await importParticipants().unwrap();
      props.onImportParticipants();
      toast(getSuccessToast(t('importModal.toasts.success')));
      onClose();
    } catch(error) {
      toast(getErrorToast(t('importModal.toasts.error'), error.message));
    }

    setIsSaving(false);
  }

  function handleParticipantsChange({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) {
    const emails = value.split(/\s|,/g).filter(isValidEmail);
    const participants = [...new Set(emails)].map(email => ({ email }));

    setParticipants(participants);
  }

  return (
    <>
      <PrimaryButton size="sm" colorScheme="black" flexShrink={0} onClick={onOpen}>
        { t('buttons.import') }
      </PrimaryButton>

      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            { t('importModal.title') }

            <ModalCloseButton />
          </ModalHeader>

          <ModalBody>
            <VStack w="100%" spacing={5}>
              <Text>{t('importModal.description')}</Text>

              <Textarea
                size="sm"
                height="140px"
                focusBorderColor="gray.300"
                resize="none"
                placeholder={t('importModal.textArea.placeholder')}
                onChange={handleParticipantsChange}
              />
            </VStack>
          </ModalBody>

          <ModalFooter>
            <HStack w="100%" justifyContent="space-between">
              <SecondaryButton colorScheme="pink" size="sm" onClick={onClose}>
                { t('common:buttons.close') }
              </SecondaryButton>

              <PrimaryButton colorScheme="teal" size="sm" onClick={onImport} isLoading={isSaving}>
                { t('buttons.import') }
              </PrimaryButton>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}