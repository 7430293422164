import { SignupBody, SignupResponseJSON } from '@jurnee/common/src/dtos/auth';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/auth';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';

export const signup = createAsyncThunk<SignupResponseJSON, SignupBody, { state: RootState }>('SIGNUP', async (args, thunkAPI) => {
  try {
    const response = await api.signup(args);
    return response;
  } catch (error) {
    thunkAPI.dispatch(showToast({ title: error.message || 'An error occurred', status: 'error' }));
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});
