import { Input } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultValue: string;
  isDisabled: boolean;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
}

export function NameInput({ defaultValue, isDisabled, onChange }: Props) {
  const { t } = useTranslation('tasks', { keyPrefix: 'drawer.form.name' });

  return (
    <Input
      name="name"
      variant="unstyled"
      borderRadius="none"
      fontSize={16}
      fontWeight={700}
      defaultValue={defaultValue}
      placeholder={t('placeholder')}
      onChange={onChange}
      px={5}
      py={2}
      isDisabled={isDisabled}
      _disabled={{ color: 'black' }}
      autoFocus
    />
  );
}