import { api } from '@jurnee/common/src/browser/Api';
import {
  ForgotPasswordBody,
  ForgotPasswordResponse,
  LoginBody,
  LoginResponseJSON,
  LoginSsoBody,
  ResendVerificationEmailBody,
  ResendVerificationEmailResponse,
  ResetPasswordBody,
  ResetPasswordResponse,
  SignupBody,
  SignupResponseJSON,
  VerifyBody,
  VerifyEmailBody,
  VerifyEmailResponse,
  VerifyResponse
} from '@jurnee/common/src/dtos/auth';
import gsi from 'src/utils/gsi';
import msalInstance from '../utils/msal';

export default {
  verify(payload: VerifyBody): Promise<VerifyResponse> {
    return api.post('auth/verify', payload);
  },
  signup(payload: SignupBody): Promise<SignupResponseJSON> {
    return api.post('auth/signup', payload);
  },
  login(payload: LoginBody): Promise<LoginResponseJSON> {
    return api.post('auth/login', payload);
  },
  loginSSO(payload: LoginSsoBody): Promise<LoginResponseJSON> {
    return api.post('auth/sso', payload);
  },
  async getGoogleToken(): Promise<string> {
    const { access_token } = await gsi.getToken();

    return access_token;
  },
  async getMsToken(): Promise<string> {
    const loginResponse = await msalInstance.loginPopup({
      scopes: ['User.Read'],
      redirectUri: `${window.location.origin}/login`
    });

    return loginResponse.accessToken;
  },
  forgotPassword(payload: ForgotPasswordBody): Promise<ForgotPasswordResponse> {
    return api.post('auth/forgot-password', payload);
  },
  resetPassword(payload: ResetPasswordBody): Promise<ResetPasswordResponse> {
    return api.post('auth/reset-password', payload);
  },
  verifyEmail(payload: VerifyEmailBody): Promise<VerifyEmailResponse> {
    return api.post('auth/verify-email', payload);
  },
  resendVerificationEmail(payload: ResendVerificationEmailBody): Promise<ResendVerificationEmailResponse> {
    return api.post('auth/resend-verification-email', payload);
  }
};