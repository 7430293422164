import { Box, HStack, Text } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import * as React from 'react';

interface Props {
  lowRatingLabel: string;
  highRatingLabel: string;
  onClick(rating: number): void;
}

interface State {
  rating: number;
}

export default class StarsRating extends React.PureComponent<Props> {

  static readonly ACTIVE_COLOR = 'orange.300';
  static readonly INATIVE_COLOR = 'gray.200';

  state: State = {
    rating: 0
  };

  onClick = (rating: number) => {
    this.props.onClick(rating);

    this.setState({ ...this.state, rating });
  };

  getStarColor = (value: number) => {
    return value <= this.state.rating ? StarsRating.ACTIVE_COLOR : StarsRating.INATIVE_COLOR;
  };

  get stars() {
    return [...Array(5).keys()].map(value => {
      const rating = value + 1;

      return (
        <Box key={rating} cursor="pointer" onClick={() => this.onClick(rating)}>
          <Icon icon="star" color={this.getStarColor(rating)} _hover={{ color: StarsRating.ACTIVE_COLOR }} />
        </Box>
      );
    });
  }

  render() {
    return (
      <HStack
        h="40px"
        p={4}
        border="1px solid"
        borderColor="blue.50"
        borderRadius={4}
        bgColor="white"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text color="gray.400">{this.props.lowRatingLabel}</Text>

        <HStack spacing={4}>
          {this.stars}
        </HStack>

        <Text color="gray.400">{this.props.highRatingLabel}</Text>
      </HStack>
    );
  }

}