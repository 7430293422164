import { Box, Heading, HStack, Text, UseToastOptions, VStack } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { ApprovalRequestCreateBody } from '@jurnee/common/src/dtos/approvalRequests';
import { QuoteCreateBody } from '@jurnee/common/src/dtos/quotes';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { BookingItemJSON } from '@jurnee/common/src/entities/BookingItem';
import { EntityJSON } from '@jurnee/common/src/entities/Entity';
import { QuoteJSON } from '@jurnee/common/src/entities/Quote';
import { sortByDate } from '@jurnee/common/src/utils/arrays';
import { isSelfApprovalEnabled } from '@jurnee/common/src/utils/bookings';
import { isValidEntity } from '@jurnee/common/src/utils/entities';
import { hasInitialStatus } from '@jurnee/common/src/utils/quotes';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import api from 'src/api/bookings';
import EventSchedule from 'src/components/BookingDetails/EventSchedule';
import { PrimaryButton } from 'src/components/buttons';
import EntityCard from 'src/components/EntityCard';
import EditEntityDrawer from 'src/drawers/EditEntityDrawer';
import { createQuote, CreditCardCommitPayload, getBooking, QuoteCreatePayload } from 'src/store/bookingDetails/bookingDetails.thunks';
import { RootState } from 'src/store/state';
import { showToast } from 'src/store/toasts/toasts.thunks';
import { getCommitmentType, isBookingEditDisabled } from 'src/utils/booking';
import { BookingDetailsStepper } from '../../BookingDetailsStepper';
import ApprovalRequestCard from './ApprovalRequestCard';
import CreditCardCard from './CreditCardCard';
import QuoteCard from './QuoteCard';

interface OwnProps {
  booking: BookingJSON;
  relationships: BookingRelationshipsJSON;
  bookingInvoice: BookingInvoiceJSON;
  entity: EntityJSON;
  onApprovalRequestSubmit(data: ApprovalRequestCreateBody): Promise<void>;
  onSetupIntentCreated(data: CreditCardCommitPayload['data']): void;
  onRemoveBookingItem(bookingItemId: BookingItemJSON['id']): void;
}

interface DispatchProps {
  createQuote(data: QuoteCreatePayload): void;
  showToast(payload: UseToastOptions): void;
  getBooking(bookingId: BookingJSON['id']): void;
}

interface StateProps {
  company: RootState['company'];
  user: RootState['user'];
}

interface State {
  processNumber: string;
}

type Props = OwnProps & DispatchProps & StateProps & WithTranslation;

class CommitmentStep extends React.Component<Props, State> {

  state: State = {
    processNumber: this.props.booking.processNumber
  };

  onApprovalRequestSubmit = async (data: ApprovalRequestCreateBody) => {
    this.setState({ ...this.state, processNumber: data.processNumber });

    await this.props.onApprovalRequestSubmit(data);
  };

  openQuote = async (quote: QuoteJSON) => {
    const { url } = await api.getQuoteLink(this.props.booking.id, quote.id);

    if (url) {
      window.open(url, '_blank');

      const callback = () => {
        window.removeEventListener('focus', callback);
        this.props.getBooking(this.props.booking.id);
      };

      window.addEventListener('focus', callback);
    } else {
      throw new Error('URL not found');
    }
  };

  onQuoteSubmit = async (data: QuoteCreateBody) => {
    this.setState({ ...this.state, processNumber: data.processNumber });

    await this.props.createQuote({
      bookingId: this.props.booking.id,
      data,
      onError: (err) => {
        const title = err instanceof Error ? err.message : this.props.t('steps.commitment.quote.toasts.error');
        this.props.showToast({ title, status: 'error' });
      },
      onSuccess: async (quote: QuoteJSON) => {
        if (quote.recipient.id === this.props.user.id && quote.providerMemberId) {
          try {
            this.openQuote(quote);
          } catch (error) {
            const title = this.props.t('steps.commitment.quote.toasts.error');
            this.props.showToast({ title, status: 'error' });
          }
        }

        this.props.showToast({ title: this.props.t('steps.commitment.quote.toasts.success'), status: 'success' });
      }
    });
  };

  get loader() {
    return (
      <Box bg="white" w="100%" h="100%" border="1px solid" borderColor="blue.50" borderRadius="lg" position="relative">
        <Loader overlay />
      </Box>
    );
  }

  get commitmentType() {
    return getCommitmentType(this.props.company.data, this.props.user);
  }

  get approvalRequest() {
    const [approvalRequest] = sortByDate(this.props.relationships.approvalRequests, 'createdAt', 'desc');

    return approvalRequest;
  }

  get quote() {
    const quotes = sortByDate(this.props.relationships.quotes, 'createdAt', 'desc');

    return quotes.find(hasInitialStatus);
  }

  get formCard() {
    if (this.commitmentType === 'APPROVAL_REQUEST') {
      const [approvalProcess] = this.props.relationships.approvalProcesses;

      return <ApprovalRequestCard
        approvalRequest={this.approvalRequest}
        approvalProcess={approvalProcess}
        bookingsInvoices={this.props.relationships.bookingsInvoices}
        isSelfApprovalEnabled={isSelfApprovalEnabled(this.props.booking, approvalProcess, this.props.user.id)}
        processNumber={this.state.processNumber}
        users={this.props.relationships.users}
        onSubmit={this.onApprovalRequestSubmit}
        disabled={!this.isValidEntity}
      />;
    }

    if (this.commitmentType === 'CREDIT_CARD') {
      return <CreditCardCard
        bookingInvoice={this.props.bookingInvoice}
        entity={this.props.entity}
        processNumber={this.state.processNumber}
        onSetupIntentCreated={this.props.onSetupIntentCreated}
        disabled={!this.isValidEntity}
      />;
    }

    return <QuoteCard
      quote={this.quote}
      processNumber={this.state.processNumber}
      bookingsInvoices={this.props.relationships.bookingsInvoices}
      onSubmit={this.onQuoteSubmit}
      disabled={!this.isValidEntity}
    />;
  }

  get isValidEntity() {
    return isValidEntity(this.props.entity);
  }

  get entityCard() {
    if (this.isValidEntity) {
      return <EntityCard entity={this.props.entity} />;
    }

    return (
      <VStack p={5} spacing={2} justifyContent="center" bg="orange.50" w="100%" borderRadius={8}>
        <Text fontSize={14} color="orange.500" textAlign="center" maxW={250}>
          {this.props.t('warnings.noEntities.message')}
        </Text>
        <EditEntityDrawer entity={this.props.entity}>
          <PrimaryButton size="sm" colorScheme="orange">
            {this.props.t('warnings.noEntities.button')}
          </PrimaryButton>
        </EditEntityDrawer>
      </VStack>
    );
  }

  render() {
    return (
      <HStack width="100%" spacing={5} alignItems="flex-start">
        <Box w="100%">
          <EventSchedule
            heading={this.props.t('schedule.heading')}
            bookingsItems={this.props.booking.bookingsItems}
            experiences={this.props.relationships.experiences}
            products={this.props.relationships.products}
            currency={this.props.bookingInvoice.currency}
            editDisabled={isBookingEditDisabled(this.props.booking, this.props.relationships)}
            onRemove={this.props.onRemoveBookingItem}
          />
        </Box>

        <VStack w="100%" minW={380} maxW={380} alignItems="stretch" spacing={3}>
          <Heading size="md">{this.props.t('billingInfo.heading')}</Heading>

          <Box bg="white" border="1px solid" borderColor="gray.200" borderRadius={4}>
            <BookingDetailsStepper step="COMMITMENT"/>

            <VStack p={5} spacing={5}>
              {this.entityCard}
              {this.formCard}
            </VStack>
          </Box>
        </VStack>
      </HStack>
    );
  }

}

function mapStateToProps(state: RootState): StateProps {
  return {
    company: state.company,
    user: state.user
  };
}

const mapDispatchToProps: DispatchProps = {
  createQuote,
  getBooking,
  showToast
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('booking')(CommitmentStep));