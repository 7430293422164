import { Skeleton, VStack } from '@chakra-ui/react';
import * as React from 'react';
import SkeletonText from '../Skeleton/SkeletonText';

export default class ExperienceCardSkeleton extends React.PureComponent {

  getText = (width: string) => {
    return <SkeletonText w={width} h="16px" pt="3px" skeletonHeight="10px" />;
  };

  render() {
    return (
      <VStack w="100%" spacing={4} alignItems="flex-start">
        <Skeleton
          w="100%"
          style={{ aspectRatio: '1' }}
          borderRadius={8}
          variant="lightGray"
        />

        <VStack w="100%" spacing={2} alignItems="flex-start">
          { this.getText('90%') }
          { this.getText('60%') }
        </VStack>

        <VStack w="100%" spacing={2} alignItems="flex-start">
          { this.getText('70%') }
          { this.getText('40%') }
        </VStack>

        { this.getText('35%') }
      </VStack>
    );
  }

}