import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  HStack,
  Text
} from '@chakra-ui/react';
import { CollectionExperience } from '@jurnee/common/src/entities/CollectionExperience';
import * as React from 'react';
import { connect } from 'react-redux';
import { PrimaryButton, SecondaryButton, TrashButton } from '../components/buttons';
import { trackEvent, TrackEventOptions } from '../store/analytics/analytics.thunks';

interface OwnProps {
  collectionExperience: CollectionExperience;
  onRemove(collectionExperience: CollectionExperience): void;
}

interface State {
  isOpen: boolean;
}

interface DispatchProps {
  trackEvent(opts: TrackEventOptions): void;
}

class RemoveFromCollectionModal extends React.PureComponent<OwnProps & DispatchProps, State> {

  state: State = {
    isOpen: false
  };

  onRemove = () => {
    this.props.onRemove(this.props.collectionExperience);

    this.onClose();
  };

  onClose = () => {
    this.setState({ isOpen: false });
  };

  onOpen = () => {
    this.setState({ isOpen: true });

    this.props.trackEvent({
      name: 'opened_remove_from_collection_modal'
    });
  };

  render() {
    return (
      <>
        <TrashButton aria-label="Remove" onClick={this.onOpen} />

        <AlertDialog leastDestructiveRef={null} isOpen={this.state.isOpen} onClose={this.onClose} size="md">
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogHeader>
              Remove experience
              <AlertDialogCloseButton />
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>
                Are you sure you want to remove <b>{this.props.collectionExperience.experience.name}</b> from this list?
              </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <HStack spacing={4} justifyContent="flex-end" w="100%">
                <SecondaryButton colorScheme="pink" size="sm" onClick={this.onClose}>Cancel</SecondaryButton>
                <PrimaryButton colorScheme="pink" size="sm" onClick={this.onRemove}>Remove</PrimaryButton>
              </HStack>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
    );
  }

}

const mapDispatchToProps: DispatchProps = {
  trackEvent
};

export default connect<null, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps
)(RemoveFromCollectionModal);