import { CompanyJSON } from '@jurnee/common/src/entities/Company';
import { EntityJSON } from '@jurnee/common/src/entities/Entity';
import { ProviderSetupIntent } from '@jurnee/common/src/entities/ProviderEntity';
import { loadStripe } from '@stripe/stripe-js';
import api from '../api/company';

export async function createSetupIntent(company: CompanyJSON, entity: EntityJSON, token: string): Promise<ProviderSetupIntent['id']> {
  const setupIntent = await api.createProviderSetupIntent(company.id, entity.id);

  const stripe = await loadStripe(process.env.STRIPE_PUBLIC_KEY);

  const result = await stripe.confirmCardSetup(setupIntent.client_secret, {
    payment_method: { card: { token } }
  });

  return result.setupIntent.id;
}