import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { CollectionExperienceJSON } from '@jurnee/common/src/entities/CollectionExperience';
import { Experience } from '@jurnee/common/src/entities/Experience';
import { createSlice } from '@reduxjs/toolkit';
import { getLocalCollectionUpvotes, setLocalCollectionUpvotes } from 'src/utils/collectionExperience';
import { RootState, initialState } from '../state';
import { downvoteExperience, getCollection, removeFromCollection, updateCollection, upvoteExperience } from './collectionDetails.thunks';

function onVote(state: RootState['collectionDetails'], experienceId: Experience['id'], value: number): RootState['collectionDetails'] {
  const list = getLocalCollectionUpvotes(state.data.id);

  setLocalCollectionUpvotes(
    state.data.id,
    value > 0 ? [...list, experienceId] : list.filter((id) => id !== experienceId)
  );

  const collectionsExperiences = state.data.collectionsExperiences.map((collectionExperience) => {
    if (collectionExperience.experienceId === experienceId) {
      const upvotes = collectionExperience.upvotes + value;

      return { ...(collectionExperience as CollectionExperienceJSON), upvotes };
    }

    return collectionExperience as CollectionExperienceJSON;
  });

  const data = { ...state.data, collectionsExperiences };

  return { ...state, data };
}

const slice = createSlice({
  name: 'collectionDetails',
  initialState: initialState.collectionDetails,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getCollection.pending, (state) => {
      return { ...state, data: null, error: null, status: FETCH_STATUS.FETCHING };
    });

    builder.addCase(getCollection.fulfilled, (state, action) => {
      return { ...state, data: action.payload, error: null, status: FETCH_STATUS.FETCHED };
    });

    builder.addCase(getCollection.rejected, (state) => {
      return { ...state, data: null, error: true, status: FETCH_STATUS.INITIAL };
    });

    builder.addCase(updateCollection.pending, (state) => {
      return { ...state, updating: true };
    });

    builder.addCase(updateCollection.fulfilled, (state, action) => {
      return {
        ...state,
        data: action.payload,
        updating: false,
      };
    });

    builder.addCase(updateCollection.rejected, (state) => {
      return { ...state, updating: false };
    });

    builder.addCase(removeFromCollection.pending, (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          collectionsExperiences: state.data.collectionsExperiences.filter((collectionExperience): collectionExperience is CollectionExperienceJSON => {
            return collectionExperience.experienceId !== action.meta.arg.experienceId;
          })
        }
      };
    });

    builder.addCase(removeFromCollection.rejected, (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          collectionsExperiences: [...state.data.collectionsExperiences, action.meta.arg as CollectionExperienceJSON]
        }
      };
    });

    builder.addCase(downvoteExperience.pending, (state, { meta: { arg: { experienceId } } }) => {
      return onVote(state, experienceId, -1);
    });

    builder.addCase(downvoteExperience.rejected, (state, { meta: { arg: { experienceId } } }) => {
      return onVote(state, experienceId, 1);
    });

    builder.addCase(upvoteExperience.pending, (state, { meta: { arg: { experienceId } } }) => {
      return onVote(state, experienceId, 1);
    });

    builder.addCase(upvoteExperience.rejected, (state, { meta: { arg: { experienceId } } }) => {
      return onVote(state, experienceId, -1);
    });

  }
});

export default slice.reducer;