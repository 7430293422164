import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { teamsAdapter } from './teams.adapter';
import { createTeam, deleteTeam, getTeams, getUserTeams, updateTeam } from './teams.thunks';

const slice = createSlice({
  name: 'teams',
  initialState: initialState.teams,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getTeams.pending, (state) => {
      state.status = FETCH_STATUS.FETCHING;
    });

    builder.addCase(getTeams.fulfilled, (state, { payload: { list } }) => {
      teamsAdapter.setMany(state, list);
      state.status = FETCH_STATUS.FETCHED;
    });

    builder.addCase(getTeams.rejected, (state) => {
      state.status = FETCH_STATUS.INITIAL;
    });

    builder.addCase(getUserTeams.fulfilled, (state, { payload: { list } }) => {
      teamsAdapter.setMany(state, list);
    });

    builder.addCase(createTeam.fulfilled, (state, { payload }) => {
      teamsAdapter.addOne(state, payload);
    });

    builder.addCase(updateTeam.fulfilled, (state, { payload }) => {
      teamsAdapter.updateOne(state, { id: payload.id, changes: payload });
    });

    builder.addCase(deleteTeam.fulfilled, (state, { payload: { id } }) => {
      teamsAdapter.removeOne(state, id);
    });

  }
});

export default slice.reducer;