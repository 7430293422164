import { api } from '@jurnee/common/src/browser/Api';
import { BookingParticipantCreateBody, BookingParticipantUpdateBody, BookingParticipantsImportBody, BookingParticipantsInviteBody } from '@jurnee/common/src/dtos/bookingsParticipants';
import { RegistrationUpsertBody } from '@jurnee/common/src/dtos/registrations';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BookingParticipantJSON } from '@jurnee/common/src/entities/BookingParticipant';
import { ImageJSON } from '@jurnee/common/src/entities/Image';
import { RegistrationJSON } from '@jurnee/common/src/entities/Registration';
import { List } from '@jurnee/common/src/serializers';

interface GetRegistrationParams {
  bookingId: BookingJSON['id' | 'cuid'];
  registrationId: RegistrationJSON['id'];
}

interface BookingParticipantsParams {
  bookingId: BookingJSON['id' | 'cuid'];
  bookingParticipantId: BookingParticipantJSON['id'];
}

interface UpsertRegistrationParams {
  bookingId: BookingJSON['id'];
  registrationId: RegistrationJSON['id'];
}

export function getRegistration({ bookingId, registrationId }: GetRegistrationParams): Promise<RegistrationJSON> {
  return api.get(`bookings/${bookingId}/registrations/${registrationId}`);
}

export function getBookingParticipant({ bookingId, bookingParticipantId }: BookingParticipantsParams): Promise<BookingParticipantJSON> {
  return api.get(`bookings/${bookingId}/bookings-participants/${bookingParticipantId}`);
}

export function createBookingParticipant({ bookingId }: Pick<BookingParticipantsParams, 'bookingId'>, body: BookingParticipantCreateBody): Promise<BookingParticipantJSON> {
  return api.post(`bookings/${bookingId}/bookings-participants`, body);
}

export function updateBookingParticipant({ bookingId, bookingParticipantId }: BookingParticipantsParams, body: BookingParticipantUpdateBody): Promise<BookingParticipantJSON> {
  return api.put(`bookings/${bookingId}/bookings-participants/${bookingParticipantId}`, body);
}

export function deleteBookingParticipant({ bookingId, bookingParticipantId }: BookingParticipantsParams): Promise<BookingParticipantJSON> {
  return api.delete(`bookings/${bookingId}/bookings-participants/${bookingParticipantId}`);
}

export function getBookingParticipants({ bookingId }: Pick<BookingParticipantsParams, 'bookingId'>): Promise<List<BookingParticipantJSON, never>> {
  return api.get(`bookings/${bookingId}/bookings-participants`);
}

export function importBookingParticipants({ bookingId }: Pick<BookingParticipantsParams, 'bookingId'>, data: BookingParticipantsImportBody): Promise<List<BookingParticipantJSON, never>> {
  return api.post(`bookings/${bookingId}/bookings-participants/import`, data);
}

export function inviteBookingParticipants({ bookingId }: Pick<BookingParticipantsParams, 'bookingId'>, data: BookingParticipantsInviteBody): Promise<List<BookingParticipantJSON, never>> {
  return api.post(`bookings/${bookingId}/bookings-participants/invite`, data);
}

export function createRegistration({ bookingId }: Pick<UpsertRegistrationParams, 'bookingId'>, body: RegistrationUpsertBody): Promise<RegistrationJSON> {
  return api.post(`bookings/${bookingId}/registrations`, body);
}

export function updateRegistration({ bookingId, registrationId }: UpsertRegistrationParams, body: RegistrationUpsertBody): Promise<RegistrationJSON> {
  return api.put(`bookings/${bookingId}/registrations/${registrationId}`, body);
}

export function uploadRegistrationImage({ bookingId, registrationId }: UpsertRegistrationParams, image: File): Promise<ImageJSON> {
  return api.uploadFile(`bookings/${bookingId}/registrations/${registrationId}/image`, image);
}