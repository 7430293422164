import { Text, VStack } from '@chakra-ui/react';
import { EntityJSON } from '@jurnee/common/src/entities/Entity';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { formatEntityAddress } from 'src/utils/entities';

interface Props extends WithTranslation {
  entity: EntityJSON;
}

class EntityCard extends React.PureComponent<Props> {

  render() {
    return (
      <VStack w="100%" spacing={3} alignItems="flex-start">
        <Text lineHeight="16px" fontWeight={700}>{this.props.t('billedEntity')}</Text>

        <VStack w="100%" alignItems="flex-start" py={2} pl={3} spacing={1} borderLeft="4px solid" borderColor="gray.200">
          <Text lineHeight="16px" fontWeight={700} fontSize="sm">{this.props.entity.name}</Text>
          { this.props.entity.address && <Text lineHeight="16px" color="gray.400" fontSize="sm">{formatEntityAddress(this.props.entity.address)}</Text> }
        </VStack>
      </VStack>
    );
  }

}

export default withTranslation('entity')(EntityCard);