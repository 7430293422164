import { Button, ButtonProps, MenuButton as ChakraMenuButton, Menu, MenuList } from '@chakra-ui/react';
import { Icon } from '../Icon';

interface Props extends ButtonProps {
  label: string;
  children: React.ReactNode;
}

export function MenuButton({ label, children, ...inheritedProps }: Props) {
  return (
    <Menu>
      <ChakraMenuButton
        as={Button}
        bg="black"
        color="white"
        _hover={{ background: 'blackAlpha.700' }}
        _active={{ background: 'blackAlpha.800' }}
        rightIcon={<Icon icon="chevronDown" size={4} color="white" />}
        {...inheritedProps}
      >
        { label }
      </ChakraMenuButton>
      <MenuList>
        { children }
      </MenuList>
    </Menu>
  );
}