import { ExperienceJSON, ExperienceRelationshipsJSON } from '@jurnee/common/src/entities/Experience';
import { List } from '@jurnee/common/src/serializers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/experiences';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';

export interface ExperiencesFiltersLocationParams {
  lat: number;
  long: number;
  radius: number;
  description?: string;
}

export interface ExperiencesFiltersParams {
  price?: number;
  participants?: number;
  languageId?: number;
  tagIds?: number[];
  type?: ExperienceJSON['type'];
  query?: string;
  location?: ExperiencesFiltersLocationParams;
  isCompanyRecommended?: boolean;
  currency?: string;
}

export const getExperiences = createAsyncThunk<List<ExperienceJSON, ExperienceRelationshipsJSON>, URLSearchParams, { state: RootState }>('EXPERIENCES_GET', async (args, thunkAPI) => {
  try {
    const { company } = thunkAPI.getState();

    const experiences = await api.getExperiences(company.data.id, args);

    return experiences;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while retrieving experiences`, status: 'error' }));
    return thunkAPI.rejectWithValue(e.message);
  }
});