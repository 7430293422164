import { Tag, TagCloseButton, TagLabel, TagProps } from '@chakra-ui/react';
import * as React from 'react';

interface Props extends TagProps {
  id: string;
  label: string;
  onRemove(e: React.MouseEvent): void;
}

export default class TagClosable extends React.PureComponent<Props> {

  render() {
    return (
      <Tag variant="gray">
        <TagLabel>
          { this.props.label }
        </TagLabel>
        <TagCloseButton id={this.props.id} onClick={this.props.onRemove} />
      </Tag>
    );
  }

}