import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { EmptyState } from '@jurnee/common/src/components/EmptyState';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { SurveyJSON } from '@jurnee/common/src/entities/Survey';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SurveyRow } from './SurveyRow';

interface Props {
  surveys: SurveyJSON[];
  bookingCuid: BookingJSON['cuid'];
  onDelete(survey: SurveyJSON): void;
  onUpdate(survey: SurveyJSON): void;
}

export function SurveysTable({ surveys, bookingCuid, onDelete, onUpdate }: Props) {
  const { t } = useTranslation(['surveys', 'common']);

  const sortedSurveys = useMemo(() => sortAlphabeticallyBy(surveys, 'name'), [surveys]);

  const rows = surveys.length === 0 ? (
    <Tr>
      <Td p={16} colSpan={3}>
        <EmptyState
          icon="listSearch"
          heading={t('table.emptyState.heading')}
          description={t('table.emptyState.description')}
        />
      </Td>
    </Tr>
  ) : (
    sortedSurveys.map(survey =>
      <SurveyRow
        key={survey.id}
        survey={survey}
        bookingCuid={bookingCuid}
        onDelete={onDelete}
        onUpdate={onUpdate}
      />
    )
  );

  return (
    <TableContainer w="100%">
      <Table>
        <Thead>
          <Tr>
            <Th w="80%">{t('table.name')}</Th>
            <Th w="20%">{t('table.questions')}</Th>
            <Th w="32px"></Th>
          </Tr>
        </Thead>
        <Tbody>{rows}</Tbody>
      </Table>
    </TableContainer>
  );
}