import { Heading, Image, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';

export default class Thanks extends React.Component {

  render() {
    return (
      <VStack spacing={10} color="white">
        <Image src="/assets/illustrations/thanks.svg" />;

        <VStack spacing={-2}>
          <Heading fontSize={80} >Thank you</Heading>
          <Text fontSize={30}>so much for your feedback :)</Text>
        </VStack>
      </VStack>
    );
  }

}