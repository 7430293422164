import { TagJSON } from '@jurnee/common/src/entities/Tag';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/tags';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';

export const getTags = createAsyncThunk<TagJSON[], null, { state: RootState }>('TAGS_GET', async (_args, thunkAPI) => {
  try {
    const searchParams = new URLSearchParams({ forTeams: 'true' });
    const { list } = await api.getTags(searchParams);

    return list;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: 'An error occurred while retrieving tags', status: 'error' }));

    return thunkAPI.rejectWithValue([]);
  }
});

export const getCompanyTags = createAsyncThunk<TagJSON[], null, { state: RootState }>('TAGS_COMPANY_GET', async (_args, thunkAPI) => {
  try {
    const searchParams = new URLSearchParams({ forTeams: 'true' });

    const { company } = thunkAPI.getState();
    const { list } = await api.getCompanyTags(company.data.id, searchParams);

    return list;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: 'An error occurred while retrieving tags', status: 'error' }));

    return thunkAPI.rejectWithValue([]);
  }
});