import { HStack, VStack, useToast } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { Currency, CurrencyExchangeRates } from '@jurnee/common/src/entities/Currency';
import { ExternalCostJSON } from '@jurnee/common/src/entities/ExternalCost';
import { sortByDate } from '@jurnee/common/src/utils/arrays';
import { getExternalCostsTotal } from '@jurnee/common/src/utils/externalCosts';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getExternalCostDocumentUrl } from 'src/api/externalCosts';
import TotalAmountCount from 'src/components/TotalAmountCount';
import { PrimaryButton } from 'src/components/buttons';
import { ExternalCostDrawer } from 'src/drawers/ExternalCostDrawer';
import { useAppDispatch } from 'src/store';
import { getBudgetBreakdownByBookingIdAndBudgetId } from 'src/store/budgetBreakdowns/budgetBreakdown.thunks';
import { getCurrenciesFetchStatusSelector, getCurrenciesSelector } from 'src/store/currencies/currencies.selectors';
import { getCurrenciesThunk } from 'src/store/currencies/currencies.thunk';
import { getEmployeesFetchStatusSelector } from 'src/store/employees/employees.selectors';
import { getExternalCostsFetchStatusSelector, getExternalCostsSelector } from 'src/store/externalCosts/externalCosts.selectors';
import { deleteExternalCostThunk, getExternalCostsThunk } from 'src/store/externalCosts/externalCosts.thunks';
import { getUserSelector } from 'src/store/user/user.selectors';
import { getUserBudgetBreakdownsByBookingId } from 'src/store/userBudgetBreakdowns/userBudgetBreakdowns.thunks';
import ExternalCostsTable from './ExternalCostsTable';

interface Props {
  booking: BookingJSON;
}

interface ExternalCostsProps {
  externalCosts: ExternalCostJSON[];
  currencies: CurrencyExchangeRates;
}

function TotalAmount(props: ExternalCostsProps) {
  const user = useSelector(getUserSelector);

  const currency = user.currency.id;
  const totalAmount = getExternalCostsTotal(props.externalCosts, props.currencies, currency);

  return <TotalAmountCount
    totalAmount={totalAmount}
    currency={currency as Currency}
    itemsCount={props.externalCosts.length}
    i18nKey="externalCosts:totalAmount"
  />;
}

export function ExternalCosts(props: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation('externalCosts');

  const externalCosts = useSelector(getExternalCostsSelector);
  const currencies = useSelector(getCurrenciesSelector);

  const externalCostsFetchStatus = useSelector(getExternalCostsFetchStatusSelector);
  const employeesFetchStatus = useSelector(getEmployeesFetchStatusSelector);
  const currenciesFetchStatus = useSelector(getCurrenciesFetchStatusSelector);

  const isLoading = [
    externalCostsFetchStatus,
    employeesFetchStatus,
    currenciesFetchStatus
  ].some(fetchStatus => fetchStatus !== 'FETCHED');

  useEffect(() => {
    dispatch(getExternalCostsThunk({ bookingId: props.booking.id }));
    dispatch(getCurrenciesThunk());
  }, []);

  async function onOpenDocument({ id }: ExternalCostJSON) {
    try {
      const { url } = await getExternalCostDocumentUrl({
        bookingId: props.booking.id,
        externalCostId: id
      });

      window.open(url, '_blank');
    } catch(error) {
      toast(getErrorToast(t('toasts.delete.error'), error.message));
    }
  }

  async function onDelete(externalCost: ExternalCostJSON) {
    try {
      await dispatch(
        deleteExternalCostThunk({
          bookingId: props.booking.id,
          externalCostId: externalCost.id
        })
      ).unwrap();

      toast(getSuccessToast(t('toasts.delete.success')));
      onChange();
    } catch(error) {
      toast(getErrorToast(t('toasts.delete.error'), error.message));
    }
  }

  function onChange() {
    if (props.booking.budgetId) {
      dispatch(getBudgetBreakdownByBookingIdAndBudgetId({
        bookingId: props.booking.id,
        budgetId: props.booking.budgetId
      }));
    } else {
      dispatch(getUserBudgetBreakdownsByBookingId({
        bookingId: props.booking.id
      }));
    }
  }

  const sortedExternalCosts = useMemo(
    () => sortByDate(externalCosts, 'paymentDate'),
    [externalCosts]
  );

  if (isLoading) {
    return <Loader h={400} />;
  }

  return (
    <VStack w="100%" alignItems="flex-start" spacing={5}>
      {
        externalCosts.length > 0 &&
          <HStack w="100%" justifyContent="space-between">
            <TotalAmount externalCosts={externalCosts} currencies={currencies} />

            <ExternalCostDrawer key={new Date().getTime()} booking={props.booking} onChange={onChange}>
              <PrimaryButton size="sm" colorScheme="black" flexShrink={0}>
                { t('buttons.addCost') }
              </PrimaryButton>
            </ExternalCostDrawer>
          </HStack>
      }

      <ExternalCostsTable
        booking={props.booking}
        externalCosts={sortedExternalCosts}
        onChange={onChange}
        onDelete={onDelete}
        onOpenDocument={onOpenDocument}
      />
    </VStack>
  );
}