import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { getLanguagesThunk } from './languages.thunks';

const slice = createSlice({
  name: 'languages',
  initialState: initialState.languages,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getLanguagesThunk.pending, () => {
      return { list: [], error: null, status: FETCH_STATUS.FETCHING };
    });

    builder.addCase(getLanguagesThunk.fulfilled, (state, action) => {
      return { list: action.payload.list, error: null, status: FETCH_STATUS.FETCHED };
    });

    builder.addCase(getLanguagesThunk.rejected, () => {
      return { list: [], error: true, status: FETCH_STATUS.INITIAL };
    });

  }
});

export default slice.reducer;