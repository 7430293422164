import { Text, VStack } from '@chakra-ui/react';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { useTranslation } from 'react-i18next';
import { CustomItemModal } from 'src/modals/CustomItemModal';
import { PayViaJurneeModal } from 'src/modals/PayViaJurneeModal';
import { ExperienceCategory } from 'src/utils/experiences';
import { IconTitle } from '../../IconTitle';
import { Button } from './Button';

interface Props {
  category: ExperienceCategory;
  colorScheme: string;
  booking: BookingJSON;
}

export function CustomCategoryCard({ category, colorScheme, booking }: Props) {
  const { t } = useTranslation('experiences', { keyPrefix: 'categories' });

  return (
    <VStack w="100%" p={5} spacing="10px" alignItems="flex-start" bg="white" border="1px solid" borderColor="gray.200" borderRadius={4}>
      <IconTitle
        colorScheme={colorScheme}
        icon={category.icon}
        label={ t(`${category.type}.title`) }
      />

      <Text color="gray.400">
        { t(`${category.type}.description`) }
      </Text>

      {
        category.type === 'PAY_VIA_JURNEE' ? (
          <PayViaJurneeModal booking={booking}>
            <Button type={category.type} />
          </PayViaJurneeModal>
        ) : (
          <CustomItemModal type={category.type} booking={booking}>
            <Button type={category.type} />
          </CustomItemModal>
        )
      }
    </VStack>
  );
}