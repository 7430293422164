import { createSlice } from '@reduxjs/toolkit';
import { getExperience } from '../experienceDetails/experienceDetails.thunks';
import { getExperiences } from '../experiences/experiences.thunks';
import { initialState } from '../state';
import { getUserFavoriteExperiences } from '../userFavoriteExperiences/userFavoriteExperiences.thunks';
import { companyRecommendedPartnersAdapter } from './companyRecommendedPartners.adapter';

const slice = createSlice({
  name: 'companyRecommendedPartners',
  initialState: initialState.companyRecommendedPartners,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getExperiences.fulfilled, (state, { payload: { relationships: { companyRecommendedPartners } } }) => {
      companyRecommendedPartnersAdapter.setMany(state, companyRecommendedPartners);
    });

    builder.addCase(getExperience.fulfilled, (state, { payload: { relationships: { companyRecommendedPartners } } }) => {
      companyRecommendedPartnersAdapter.setMany(state, companyRecommendedPartners);
    });

    builder.addCase(getUserFavoriteExperiences.fulfilled, (state, { payload: { relationships: { companyRecommendedPartners } } }) => {
      companyRecommendedPartnersAdapter.setMany(state, companyRecommendedPartners);
    });

  }
});

export default slice.reducer;