import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getBookingsSelector } from 'src/store/bookings/bookings.selectors';

export default function Default() {
  const bookings = useSelector(getBookingsSelector);

  if (bookings.status !== 'FETCHED') {
    return null;
  }

  return <Navigate to={bookings.list.length > 0 ? '/bookings' : '/experiences'} />;

}