import { api } from '@jurnee/common/src/browser/Api';
import { PartnerDocumentCreateBody } from '@jurnee/common/src/dtos/bookings';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { PartnerDocumentJSON } from '@jurnee/common/src/entities/PartnerDocument';
import { FileStorageFile } from '@jurnee/common/src/services/FileStorageService';

interface PartnerDocumentParams {
  bookingId: BookingJSON['id'];
}

export function uploadPartnerDocument({ bookingId }: PartnerDocumentParams, file: File): Promise<FileStorageFile> {
  return api.uploadFile(`bookings/${bookingId}/partners-documents/upload`, file);
}

export function createPartnerDocument({ bookingId }: PartnerDocumentParams, body: PartnerDocumentCreateBody): Promise<PartnerDocumentJSON> {
  return api.post(`bookings/${bookingId}/partners-documents`, body);
}