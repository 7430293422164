import { HStack, useToast, VStack } from '@chakra-ui/react';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { PropositionJSON } from '@jurnee/common/src/entities/Proposition';
import { PropositionsGroupJSON } from '@jurnee/common/src/entities/PropositionsGroup';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'src/store';
import { declinePropositionThunk, selectPropositionThunk } from 'src/store/propositions/propositions.thunks';
import { PropositionsGroups } from '../../../components/BookingDetails/PropositionsGroups';
import { Propositions } from './Propositions';

interface Props {
  bookingId: BookingJSON['id'];
  relationships: Pick<BookingRelationshipsJSON, 'propositionsGroups' | 'propositions' | 'products' | 'experiences'>;
}

export function Requests({ bookingId, relationships }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation('requests');
  const [searchParams, setSearchParams] = useSearchParams();
  const propositionsGroupId = Number(searchParams.get('propositionsGroupId')) || relationships.propositionsGroups[0].id;

  const propositionsGroup = useMemo(
    () => relationships.propositionsGroups.find(group => group.id === propositionsGroupId),
    [relationships.propositionsGroups, propositionsGroupId]
  );

  function onClick(id: PropositionsGroupJSON['id']) {
    searchParams.set('propositionsGroupId', id.toString());
    setSearchParams(searchParams);
  }

  async function onDecline(proposition: PropositionJSON, declinedReason: string) {
    try {
      await dispatch(declinePropositionThunk({
        bookingId,
        propositionsGroupId: proposition.propositionsGroupId,
        propositionId: proposition.id,
        declinedReason
      })).unwrap();
    } catch (err) {
      toast(getErrorToast(t('toasts.declineProposition.error')));
    }
  }

  async function onSelect(proposition: PropositionJSON) {
    try {
      await dispatch(selectPropositionThunk({
        bookingId,
        propositionsGroupId: proposition.propositionsGroupId,
        propositionId: proposition.id
      })).unwrap();

      const search = new URLSearchParams({ tab: 'overview' });

      navigate({
        pathname: `/bookings/${bookingId}`,
        search: search.toString()
      });
    } catch (err) {
      toast(getErrorToast(t('toasts.selectProposition.error')));
    }
  }

  return (
    <VStack w="100%">
      <HStack w="100%" alignItems="stretch" spacing={8}>
        <PropositionsGroups
          minW={450}
          maxW={450}
          relationships={relationships}
          heading={t('requests.heading')}
          selected={propositionsGroupId}
          onClick={onClick}
        />

        {
          propositionsGroup &&
            <Propositions
              propositionsGroup={propositionsGroup}
              relationships={relationships}
              onDecline={onDecline}
              onSelect={onSelect}
            />
        }
      </HStack>
    </VStack>
  );
}