import { Text, TextProps } from '@chakra-ui/react';
import { BookingStatus as BookingStatusType } from '@jurnee/common/src/entities/Booking';
import { useTranslation } from 'react-i18next';

interface Props {
  status: BookingStatusType;
}

export default function StatusLabel(props: Props & TextProps) {
  const { t } = useTranslation('booking');
  const { status, ...textProps } = props;

  function getColor() {
    switch (props.status) {
    case 'AWAITING_APPROVAL':
    case 'AWAITING_QUOTE':
      return 'orange.400';
    case 'CANCELED':
    case 'DRAFT':
      return 'gray.400';
    case 'EXTERNAL_COSTS':
    case 'CONFIRMED':
      return 'teal.400';
    case 'CONFIRMING_AVAILABILITY':
    case 'PROCESSING':
      return 'blue.400';
    case 'FINISHED':
      return 'black';
    case 'PROPOSITION_READY':
      return 'pink.400';
    default:
      return 'gray.400';
    }
  }

  return (
    <Text fontSize={12} color={getColor()} textTransform="uppercase" {...textProps}>
      { t(`status.${props.status}`) }
    </Text>
  );

}