import { FormControl, FormLabel, HStack, Heading, Input, Text, VStack, useToast } from '@chakra-ui/react';
import { InputFormControl } from '@jurnee/common/src/components/InputFormControl';
import { TextareaFormControl } from '@jurnee/common/src/components/TextareaFormControl';
import { BookingParticipantJSON } from '@jurnee/common/src/entities/BookingParticipant';
import { RegistrationJSON } from '@jurnee/common/src/entities/Registration';
import { formatAddress } from '@jurnee/common/src/utils/addresses';
import { formatDate } from '@jurnee/common/src/utils/dates';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { useRef, useState } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { useTranslation } from 'react-i18next';
import { createBookingParticipant, updateBookingParticipant } from 'src/api/registrations';
import { canvasStyles, fireConfetti } from '../../../utils/confetti';
import { RegistrationFormButtons } from './RegistrationFormButtons';
import { RegistrationFormResponse } from './RegistrationFormResponse';

interface Props {
  registration: RegistrationJSON;
  bookingParticipant: BookingParticipantJSON;
}

function RegistrationEndDate({ registration }: Pick<Props, 'registration'>) {
  if (!registration.endDate) {
    return null;
  }

  const { t } = useTranslation('registration');

  const endDate = formatDate(new Date(registration.endDate), {
    displayTime: true,
    displayTimeZone: true,
    timeZone: registration.address.timezone
  });

  const registrationClosed = new Date(registration.endDate) < new Date();

  return (
    <Text color={registrationClosed ? 'red.400' : 'gray.400'}>
      {t(registrationClosed ? 'registrationClosed' : 'registrationCloses', { endDate })}
    </Text>
  );
}

export function RegistrationForm(props: Props) {
  const { registration, bookingParticipant } = props;

  const [firstName, setFirstName] = useState(props.bookingParticipant?.participant.firstName);
  const [lastName, setLastName] = useState(props.bookingParticipant?.participant.lastName);
  const [email, setEmail] = useState(null);
  const [comment, setComment] = useState(props.bookingParticipant?.comment);
  const [status, setStatus] = useState(props.bookingParticipant?.status || null);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const instance = useRef(null);

  function onInit({ confetti }: { confetti: confetti.CreateTypes | null }) {
    instance.current = confetti;
  }

  const { t } = useTranslation(['registration', 'common']);
  const toast = useToast();

  async function create(newStatus: BookingParticipantJSON['status']) {
    try {
      const data = await createBookingParticipant({
        bookingId: registration.booking.cuid
      }, {
        status: newStatus,
        comment,
        participant: { firstName, lastName, email }
      });

      setStatus(newStatus);
      setIsFormDisabled(true);

      return data;
    } catch (err) {
      setStatus(status);
      toast(getErrorToast(err.message ?? t('toats.errors.update')));
    }
  }

  async function update(newStatus: BookingParticipantJSON['status']) {
    try {
      const data = await updateBookingParticipant({
        bookingId: registration.booking.cuid,
        bookingParticipantId: bookingParticipant.id
      }, {
        status: newStatus,
        comment,
        participant: { firstName, lastName }
      });

      setStatus(newStatus);

      return data;
    } catch (err) {
      setStatus(status);
      toast(getErrorToast(err.message ?? t('toats.errors.update')));
    }
  }

  async function submit(status: BookingParticipantJSON['status']) {
    return bookingParticipant ? await update(status) : await create(status);
  }

  async function onAccept() {
    const success = await submit('ACCEPTED');

    if (success) {
      fireConfetti(instance.current);
    }
  }

  function onDecline() {
    submit('DECLINED');
  }

  const eventDate = formatDate(new Date(registration.eventDate), {
    displayTime: true,
    displayTimeZone: true,
    timeZone: registration.address.timezone
  });

  const address = formatAddress(registration.address);

  return (
    <VStack w={['100%', '50%']} p={8} spacing={4} alignItems="flex-start" pos="relative">
      <ReactCanvasConfetti onInit={onInit} style={canvasStyles}/>

      <Heading size="md">{registration.booking.name}</Heading>

      <VStack spacing={1} alignItems="flex-start">
        <Text>{eventDate}</Text>
        <Text>{address}</Text>
      </VStack>

      <RegistrationEndDate registration={registration}/>

      <Text color="gray.400" whiteSpace="pre-line">
        {registration.comment}
      </Text>

      <HStack w="100%" spacing={5}>
        <InputFormControl size="sm" name="firstName" value={firstName} onChange={setFirstName} isDisabled={isFormDisabled} />
        <InputFormControl size="sm" name="lastName" value={lastName} onChange={setLastName} isDisabled={isFormDisabled} />
      </HStack>

      <FormControl>
        <FormLabel>{t('common:fields.email.label')}</FormLabel>
        <Input
          size="sm"
          name="email"
          value={props.bookingParticipant?.participant.email}
          onChange={e => setEmail(e.target.value)}
          isDisabled={!!props.bookingParticipant || isFormDisabled}
        />
      </FormControl>

      <TextareaFormControl name="comment" value={comment} onChange={setComment} h={100} isDisabled={isFormDisabled} />

      <RegistrationFormButtons onDecline={onDecline} onAccept={onAccept} status={status}/>

      <RegistrationFormResponse onDecline={onDecline} onAccept={onAccept} status={status} isUpdatable={!!bookingParticipant} />
    </VStack>
  );
}