import { OidcConfigurationCreate, OidcConfigurationJSON, OidcConfigurationUpdate } from '@jurnee/common/src/entities/OidcConfiguration';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { create, update } from '../../api/oidcConfigurations';
import { RootState } from '../state';

export const createOidcConfiguration = createAsyncThunk<OidcConfigurationJSON, OidcConfigurationCreate, { state: RootState }>('CREATE_OIDC_CONFIGURATION', async (body, thunkAPI) => {
  try {
    const configuration = await create(body);

    return configuration;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const updateOidcConfiguration = createAsyncThunk<OidcConfigurationJSON, { id: number } & OidcConfigurationUpdate, { state: RootState }>('UPDATE_OIDC_CONFIGURATION', async (args, thunkAPI) => {
  try {
    const { id, ...body } = args;
    const configuration = await update(id, body);

    return configuration;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});