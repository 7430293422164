import { Box, Flex, Select, Text, VStack } from '@chakra-ui/react';
import { CompanyUpdateBody } from '@jurnee/common/src/dtos/companies';
import { Company, CompanyJSON } from '@jurnee/common/src/entities/Company';
import * as React from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PrimaryButton } from 'src/components/buttons';
import { EmailCreatableSelector } from 'src/components/EmailCreatableSelector';

interface Props extends WithTranslation {
  company: CompanyJSON;
  loading: boolean;
  onSave(payload: CompanyUpdateBody): void;
}

const paymentMethods = ['CREDIT_CARD', 'WIRE_TRANSFER'] as const;
type PaymentMethod = typeof paymentMethods[number];

type State = {
  billingEmails: Company['billingEmails'];
  creditCardEnabled: Company['creditCardEnabled'];
};

class CompanyPaymentSettings extends React.Component<Props, State> {

  state: State = {
    billingEmails: this.props.company.billingEmails,
    creditCardEnabled: this.props.company.creditCardEnabled
  };

  onSave = () => {
    this.setState({ ...this.state });

    this.props.onSave({
      billingEmails: this.state.billingEmails,
      creditCardEnabled: this.state.creditCardEnabled
    });
  };

  onPaymentMethodChange = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      ...this.state,
      creditCardEnabled: target.value === 'CREDIT_CARD'
    });
  };

  get currentPaymentMethod(): PaymentMethod {
    return this.state.creditCardEnabled ? 'CREDIT_CARD' : 'WIRE_TRANSFER';
  }

  get creditCardLabel() {
    const approvalTab = <Link style={{ textDecoration: 'underline', color: 'black' }} to={`/company/settings?tab=approval`} reloadDocument />;

    return (
      <Box w="50%" textAlign="right" borderRight="1px solid" borderColor="blue.50" pr={5} py={2}>
        <Text fontSize={16} fontWeight={700} lineHeight="12px">{this.props.t('payment.paymentMethod.title')}</Text>
        <Text fontSize={14} color="gray.400" mt={2}>
          <Trans
            i18nKey="settings:payment.paymentMethod.description"
            components={{ approvalTab }}
          />
        </Text>
      </Box>
    );
  }

  get creditCardSwitch() {
    return (
      <Box w={320} pl={5} py={2}>
        <Select bg="white" onChange={this.onPaymentMethodChange} value={this.currentPaymentMethod}>
          {paymentMethods.map((method) => {
            return (
              <option value={method} key={method}>
                {this.props.t(`payment.paymentMethod.options.${method}`)}
              </option>
            );
          })}
        </Select>
      </Box>
    );
  }

  onUpdateBillingEmails = (billingEmails: string[]) => {
    this.setState({ ...this.state, billingEmails });
  };

  get billingEmailsLabel() {
    return (
      <Box w="50%" textAlign="right" borderRight="1px solid" borderColor="blue.50" pr={5} py={2}>
        <Text fontSize={16} fontWeight={700} lineHeight="12px">{this.props.t('payment.billingEmails.title')}</Text>
        <Text fontSize={14} color="gray.400" mt={2}>
          <Trans i18nKey="settings:payment.billingEmails.description"/>
        </Text>
      </Box>
    );
  }

  get billingEmailsForm() {
    return (
      <Box w={320} pl={5} py={2}>
        <EmailCreatableSelector
          emails={this.state.billingEmails}
          onChange={this.onUpdateBillingEmails}
        />
      </Box>
    );
  }

  render() {
    return (
      <VStack w="100%" alignItems="center" spacing={8}>
        <Flex w="100%" alignItems="flex-start">
          {this.creditCardLabel}
          {this.creditCardSwitch}
        </Flex>

        <Flex w="100%" alignItems="flex-start">
          {this.billingEmailsLabel}
          {this.billingEmailsForm}
        </Flex>

        <PrimaryButton size="sm" colorScheme="teal" isLoading={this.props.loading} onClick={this.onSave}>
          {this.props.t('buttons.save', { ns: 'common' })}
        </PrimaryButton>
      </VStack>
    );
  }

}

export default withTranslation(['settings', 'common'])(CompanyPaymentSettings);