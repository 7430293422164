import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { BookingJSON, BookingStatus } from '@jurnee/common/src/entities/Booking';
import { createSelector } from '@reduxjs/toolkit';
import { filterByStatuses } from 'src/utils/booking';
import { RootState } from '../state';

export function getBookingsSelector(state: RootState) {
  return state.bookings;
}

export const getBookingsByStatusesSelector = createSelector(
  getBookingsSelector,
  (_: RootState, statuses: BookingStatus[]) => statuses,
  (bookings: RootState['bookings'], statuses: BookingStatus[]): BookingJSON[] => {
    if (bookings.status !== FETCH_STATUS['FETCHED']) {
      return [];
    }

    return filterByStatuses(bookings.list, bookings.relationships, statuses);
  }
);

export function getBookingsFetchStatusSelector({ bookings }: RootState) {
  return bookings.status;
}

export const getConfirmedBookingsSelector = createSelector(
  (state: RootState) => state,
  (state: RootState) => getBookingsByStatusesSelector(state, ['CONFIRMED'])
);

export const getPendingBookingsSelector = createSelector(
  (state: RootState) => state,
  (state: RootState) => getBookingsByStatusesSelector(state, ['PROCESSING', 'CONFIRMING_AVAILABILITY'])
);

export const getActionRequiredBookingsSelector = createSelector(
  (state: RootState) => state,
  (state: RootState) => getBookingsByStatusesSelector(state, ['AWAITING_QUOTE', 'AWAITING_APPROVAL', 'PROPOSITION_READY'])
);

export const getDraftBookingsSelector = createSelector(
  (state: RootState) => state,
  (state: RootState) => getBookingsByStatusesSelector(state, ['DRAFT'])
);

export const getExternalCostsBookingsSelector = createSelector(
  (state: RootState) => state,
  (state: RootState) => getBookingsByStatusesSelector(state, ['EXTERNAL_COSTS'])
);