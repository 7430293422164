import { BoxProps, HStack, StackProps, Text, VStack } from '@chakra-ui/react';
import { Currency } from '@jurnee/common/src/entities/Currency';
import { useTranslation } from 'react-i18next';
import ProgressBar from '../Chart/ProgressBar';
import ProgressBarLegend from '../Chart/ProgressBarLegend';
import { LegendRow } from '../Chart/ProgressBarLegend/LegendRow';
import PriceInUserCurrency from '../PriceInUserCurrency';

interface Props extends StackProps {
  remaining: number;
  pending: number;
  spent: number;
  total: number;
  currency: Currency;
}

export const colors: { [key: string]: BoxProps['bgColor'] } = {
  remaining: 'teal.400',
  booking: 'orange.300',
  pending: 'blue.400',
  spent: 'pink.400',
  noBudget: 'gray.400'
};

function BudgetCardLine({ remaining, total, currency }: Pick<Props, 'remaining' | 'total' | 'currency'>) {
  return (
    <HStack justifyContent="space-between" alignItems="flex-end">
      <Text fontSize={16} fontWeight={700} lineHeight="12px" color={remaining < 0 ? 'red.500' : 'black'}>
        <PriceInUserCurrency value={remaining} currency={currency} fractionDigits={0} />
      </Text>
      <Text fontSize={14} fontWeight={500} color="gray.400" lineHeight="12px">
        <PriceInUserCurrency value={total} currency={currency} fractionDigits={0} />
      </Text>
    </HStack>
  );
}

export default function BudgetChart({ total, currency, remaining, pending, spent, ...inheritedProps }: Props) {
  const { t } = useTranslation('budgets');

  const segments = [
    { label: t('remaining'), value: remaining, color: colors.remaining },
    { label: t('pending'), value: pending, color: colors.pending },
    { label: t('spent'), value: spent, color: colors.spent }
  ];

  return (
    <VStack w="100%" spacing="10px" alignItems="stretch" {...inheritedProps} >
      <BudgetCardLine remaining={remaining} total={total} currency={currency} />
      <ProgressBar segments={segments} />
      {
        !total ?
          <LegendRow label={t('noBudget')} color={colors.noBudget} /> :
          <ProgressBarLegend segments={segments} currency={currency} />
      }
    </VStack>
  );
}