import { api } from '@jurnee/common/src/browser/Api';
import { AUTH_STATUS } from '@jurnee/common/src/browser/State';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { createSlice } from '@reduxjs/toolkit';
import { login, loginSso, verifyEmail } from '../auth/auth.thunks';
import { signup } from '../signup/signup.thunks';
import { RootState, initialState } from '../state';

const reducers = {
  logout: () => {
    localStorage.removeItem('token');

    window.location.href = window.location.origin;
  },
  pending: (): RootState['auth'] => {
    return { status: AUTH_STATUS.AUTHENTICATING, token: null };
  },
  fulfilled: (state: RootState['auth'], action: { payload: { token: string, user: UserDetails } }): RootState['auth'] => {
    const { user, token } = action.payload;

    if (user.verified) {
      localStorage.setItem('token', token);

      api.setToken(token);

      return { status: AUTH_STATUS.AUTHENTICATED, token: token };
    } else {
      return { status: AUTH_STATUS.UNVERIFIED, token: null };
    }
  }
};

const slice = createSlice({
  name: 'auth',
  initialState: initialState.auth,
  reducers,
  extraReducers: (builder) => {

    builder.addCase(login.pending, reducers.pending);
    builder.addCase(login.fulfilled, reducers.fulfilled);
    builder.addCase(login.rejected, reducers.logout);

    builder.addCase(loginSso.pending, reducers.pending);
    builder.addCase(loginSso.fulfilled, reducers.fulfilled);
    builder.addCase(loginSso.rejected, reducers.logout);

    builder.addCase(verifyEmail.pending, reducers.pending);
    builder.addCase(verifyEmail.fulfilled, reducers.fulfilled);
    builder.addCase(verifyEmail.rejected, reducers.logout);

    builder.addCase(signup.fulfilled, reducers.fulfilled);
  }
});

export const { logout } = slice.actions;

export default slice.reducer;