import { Box, FormControl, FormErrorMessage, HStack, Switch, Text, VStack } from '@chakra-ui/react';
import { NumberInput } from '@jurnee/common/src/components/NumberInput';
import { CompanyUpdateBody } from '@jurnee/common/src/dtos/companies';
import { CatalogType, Company, CompanyJSON } from '@jurnee/common/src/entities/Company';
import { Tag } from '@jurnee/common/src/entities/Tag';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { PrimaryButton } from 'src/components/buttons';
import CatalogSelector from 'src/components/CatalogSelector';
import TagSelector from 'src/components/TagSelector';
import { RootState } from 'src/store/state';
import { getTags } from 'src/store/tags/tags.thunks';

interface OwnProps {
  loading: boolean;
  company: CompanyJSON;
  onSave(payload: CompanyUpdateBody): void;
}

interface StateProps {
  tags: RootState['tags'];
}

interface DispatchProps {
  getTags(): void;
}

type State = {
  excludedCatalogs: Company['excludedCatalogs'];
  excludedCatalogsEnabled: boolean;
  excludedTagIds: Tag['id'][];
  excludedTagsEnabled: boolean;
  maxExperiencePrice: Company['maxExperiencePrice'];
  maxExperiencePriceEnabled: boolean;
  submitted: boolean;
};

type Props = OwnProps & StateProps & DispatchProps & WithTranslation;

class CompanyCatalogSettings extends React.Component<Props, State> {

  state: State = {
    excludedCatalogs: this.props.company.excludedCatalogs,
    excludedCatalogsEnabled: this.props.company.excludedCatalogs.length > 0,
    excludedTagIds: this.props.company.excludedTags.map(({ tag }) => tag.id),
    excludedTagsEnabled: this.props.company.excludedTags.length > 0,
    maxExperiencePrice: this.props.company.maxExperiencePrice,
    maxExperiencePriceEnabled: this.props.company.maxExperiencePrice > 0,
    submitted: false,
  };

  componentDidMount() {
    this.props.getTags();
  }

  onSave = () => {
    this.setState({ ...this.state, submitted: true });

    if (this.isMaxExperiencePriceFormInvalid) {
      return;
    }

    if (this.isExcludedCatalogsFormInvalid) {
      return;
    }

    if (this.isExcludedTagsFormInvalid) {
      return;
    }

    this.props.onSave({
      excludedCatalogs: this.state.excludedCatalogs,
      excludedTagIds: this.state.excludedTagIds,
      maxExperiencePrice: this.state.maxExperiencePrice
    });
  };

  onToggleMaxExperiencePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const maxExperiencePrice = event.target.checked ? this.state.maxExperiencePrice : null;

    this.setState({
      ...this.state,
      maxExperiencePrice,
      maxExperiencePriceEnabled: event.target.checked,
    });
  };

  onUpdateMaxExperiencePrice = (price: string) => {
    this.setState({ ...this.state, maxExperiencePrice: Number(price) });
  };

  get isMaxExperiencePriceFormInvalid() {
    return this.state.maxExperiencePriceEnabled && this.state.maxExperiencePrice <= 0;
  }

  get maxActivityPriceLabel() {
    return (
      <Box w="50%" pr={5} py={2} textAlign="right" borderRight="1px solid" borderColor="blue.50">
        Set a maximum activity price per person
        <Text mt={2} color="gray.400">
          The activities with a greater price will be hidden, in €
        </Text>
      </Box>
    );
  }

  get maxActivityPriceNumberInput() {
    if (!this.state.maxExperiencePriceEnabled) {
      return;
    }

    return (
      <FormControl isInvalid={this.state.submitted && this.isMaxExperiencePriceFormInvalid}>
        <NumberInput
          defaultValue={this.state.maxExperiencePrice}
          precision={2}
          step={50}
          width={300}
          onChange={this.onUpdateMaxExperiencePrice}
        />
        <FormErrorMessage>Maximum activity price is required</FormErrorMessage>
      </FormControl>
    );
  }

  get maxActivityPriceForm() {
    return (
      <VStack maxW={320} pl={5} py={2} alignItems="flex-start" spacing={3}>
        <Switch
          id='maxActivityPriceEnabled'
          defaultChecked={this.state.maxExperiencePriceEnabled}
          onChange={this.onToggleMaxExperiencePrice}
        />

        {this.maxActivityPriceNumberInput}
      </VStack>
    );
  }

  onToggleExcludedCatalogs = (event: React.ChangeEvent<HTMLInputElement>) => {
    const excludedCatalogs = event.target.checked ? this.state.excludedCatalogs : [];

    this.setState({
      ...this.state,
      excludedCatalogs,
      excludedCatalogsEnabled: event.target.checked,
    });
  };

  onUpdateExcludedCatalogs = (list: { value: CatalogType, label: string }[]) => {
    this.setState({ ...this.state, excludedCatalogs: list.map(({ value }) => value) });
  };

  get catalogs(): { value: CatalogType, label: string }[] {
    return [
      { value: 'BOX_SHIPPING', label: 'Box Shipping' },
      { value: 'IN_OFFICE', label: 'Office animations' },
      { value: 'IN_PERSON', label: 'Team activities' },
      { value: 'VIRTUAL', label: 'Virtual activities' },
    ];
  }

  get isExcludedCatalogsFormInvalid() {
    return this.state.excludedCatalogsEnabled && this.state.excludedCatalogs.length === 0;
  }

  get excludedCatalogsLabel() {
    return (
      <Box w="50%" pr={5} py={2} textAlign="right" borderRight="1px solid" borderColor="blue.50">
        Disable catalog sections
        <Text mt={2} color="gray.400">
          These catalog sections will be hidden in the sidebar
        </Text>
      </Box>
    );
  }

  get excludedCatalogsSelector() {
    if (!this.state.excludedCatalogsEnabled) {
      return;
    }

    return (
      <FormControl isInvalid={this.state.submitted && this.isExcludedCatalogsFormInvalid}>
        <CatalogSelector
          onChange={this.onUpdateExcludedCatalogs}
          selectedCatalogs={this.state.excludedCatalogs}
          catalogs={this.catalogs}
        />
        <FormErrorMessage>A least one catalog section is required</FormErrorMessage>
      </FormControl>
    );
  }

  get excludedCatalogsForm() {
    return (
      <VStack w={320} pl={5} py={2} alignItems="flex-start" spacing={3}>
        <Switch
          id='excludedCatalogsEnabled'
          defaultChecked={this.state.excludedCatalogsEnabled}
          onChange={this.onToggleExcludedCatalogs}
        />

        {this.excludedCatalogsSelector}
      </VStack>
    );
  }

  onToggleExcludedTags = (event: React.ChangeEvent<HTMLInputElement>) => {
    const excludedTagIds = event.target.checked ? this.state.excludedTagIds : [];

    this.setState({
      ...this.state,
      excludedTagIds,
      excludedTagsEnabled: event.target.checked,
    });
  };

  onUpdateExcludedTags = (list: { value: Tag['id'], label: string }[]) => {
    this.setState({ ...this.state, excludedTagIds: list.map(({ value }) => value) });
  };

  get isExcludedTagsFormInvalid() {
    return this.state.excludedTagsEnabled && this.state.excludedTagIds.length === 0;
  }

  get excludedTagsLabel() {
    return (
      <Box w="50%" pr={5} py={2} textAlign="right" borderRight="1px solid" borderColor="blue.50">
        Disable catalog categories
        <Text mt={2} color="gray.400">
          These activities categories will be hidden
        </Text>
      </Box>
    );
  }

  get excludedTagsSelector() {
    if (!this.state.excludedTagsEnabled) {
      return;
    }

    return (
      <FormControl isInvalid={this.state.submitted && this.isExcludedTagsFormInvalid}>
        <TagSelector
          onChange={this.onUpdateExcludedTags}
          selectedTagIds={this.state.excludedTagIds}
          tags={this.props.tags.list}
          isClearable={false}
          placeholder="Select catalog categories"
        />
        <FormErrorMessage>A least one catalog category is required</FormErrorMessage>
      </FormControl>
    );
  }


  get excludedTagsForm() {
    return (
      <VStack w={320} pl={5} py={2} alignItems="flex-start" spacing={3}>
        <Switch
          id='excludedTagsEnabled'
          defaultChecked={this.state.excludedTagsEnabled}
          onChange={this.onToggleExcludedTags}
        />

        {this.excludedTagsSelector}
      </VStack>
    );
  }

  render() {
    return (
      <VStack alignItems="center" spacing={5}>
        <HStack w="100%" spacing={0} alignItems="flex-start">
          {this.maxActivityPriceLabel}
          {this.maxActivityPriceForm}
        </HStack>

        <HStack w="100%" spacing={0} alignItems="flex-start">
          {this.excludedCatalogsLabel}
          {this.excludedCatalogsForm}
        </HStack>

        <HStack w="100%" spacing={0} alignItems="flex-start">
          {this.excludedTagsLabel}
          {this.excludedTagsForm}
        </HStack>

        <PrimaryButton size="sm" colorScheme="teal" isLoading={this.props.loading} onClick={this.onSave}>
          {this.props.t('buttons.save', { ns: 'common' })}
        </PrimaryButton>
      </VStack>
    );
  }

}

function mapStateToProps(state: RootState): StateProps {
  return {
    tags: state.tags,
  };
}

const mapDispatchToProps: DispatchProps = {
  getTags,
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('common')(CompanyCatalogSettings));