type TokenClient = google.accounts.oauth2.TokenClient;
type TokenClientConfig = google.accounts.oauth2.TokenClientConfig;
type TokenResponse = google.accounts.oauth2.TokenResponse;

class Gsi {

  private initialized = false;

  async injectScript(): Promise<void> {
    const promise: Promise<void> = new Promise((res, rej) => {
      const script = document.createElement('script');

      script.setAttribute('src', 'https://accounts.google.com/gsi/client');

      script.addEventListener('load', () => {
        this.initialized = true;
        res();
      });

      script.addEventListener('error', () => {
        rej();
      });

      document.head.appendChild(script);
    });

    return promise;
  }

  initialize(): Promise<void> {
    if (this.initialized) {
      return Promise.resolve();
    }

    return this.injectScript();
  }

  getClient(onSuccess: TokenClientConfig['callback'], onError: TokenClientConfig['error_callback']): TokenClient {
    return google.accounts.oauth2.initTokenClient({
      client_id: '991554378589-f7kn6j3qdslv9vabu5qtksh7got3nbh5.apps.googleusercontent.com',
      scope: 'profile email openid',
      prompt: 'select_account',
      error_callback: onError,
      callback: onSuccess
    });
  }

  async getToken(): Promise<TokenResponse> {
    await this.initialize();

    const promise: Promise<TokenResponse> = new Promise((res, rej) => {
      const client = this.getClient(res, rej);

      client.requestAccessToken();
    });

    return promise;
  }

}

const gsi = new Gsi();

export default gsi;