import { Heading, VStack } from '@chakra-ui/react';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { useTranslation } from 'react-i18next';
import { ExploreCard } from 'src/components/ExploreCard';

interface Props {
  booking: BookingJSON;
}

export function ExploreSection({ booking }: Props) {
  const { t } = useTranslation('booking', { keyPrefix: 'steps.planning.addSomething' });

  return (
    <VStack w="100%" spacing={3}>
      <Heading w="100%" size="md">{t('heading')}</Heading>

      <ExploreCard booking={booking} />
    </VStack>
  );
}