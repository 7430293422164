import { Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { DotMenu } from '@jurnee/common/src/components/DotMenu';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { EntityJSON } from '@jurnee/common/src/entities/Entity';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import EditEntityDrawer from 'src/drawers/EditEntityDrawer';
import { formatEntityAddress } from 'src/utils/entities';

interface Props extends WithTranslation {
  entities: EntityJSON[];
}

class EntitiesTable extends React.Component<Props> {

  get header() {
    return (
      <Tr>
        <Th minW={200}>{this.props.t('entities.table.name')}</Th>
        <Th>{this.props.t('entities.table.address')}</Th>
        <Th>{this.props.t('entities.table.taxIdNumber')}</Th>
        <Th w={8}></Th>
      </Tr>
    );
  }

  getAddress({ address }: EntityJSON) {
    if (!address) {
      return null;
    }

    return <Text>{formatEntityAddress(address)}</Text>;
  }

  get rows() {
    return this.props.entities.map(entity => {
      return (
        <Tr key={entity.id}>
          <Td>{entity.name}</Td>
          <Td>{this.getAddress(entity)}</Td>
          <Td>{entity.taxIdNumber && entity.taxIdNumber.value}</Td>
          <Td pl={0} pr={3}>
            <DotMenu size="sm" color="black" placement="bottom-end">
              <EditEntityDrawer entity={entity}>
                <MenuItemWrapper icon="edit" label={this.props.t('entities.table.edit')} />
              </EditEntityDrawer>
            </DotMenu>
          </Td>
        </Tr>
      );
    });
  }

  render() {
    return (
      <TableContainer w="100%">
        <Table>
          <Thead>{this.header}</Thead>
          <Tbody>{this.rows}</Tbody>
        </Table>
      </TableContainer>
    );
  }

}

export default withTranslation('billing')(EntitiesTable);