{
  "remaining": "Remaining",
  "booking": "This booking",
  "pending": "Pending",
  "spent": "Spent",
  "noBudget": "No budget defined yet",
  "totalBudgetZero": "The total budget is zero",
  "menu": {
    "edit": "Edit budget",
    "statement": "Spending statement",
    "delete": "Delete budget"
  },
  "fields": {
    "frequency": "Frequency",
    "amount": "Amount",
    "organizers": "Organizers",
    "people": "People"
  },
  "frequencies": {
    "MONTHLY": "Monthly",
    "QUARTERLY": "Quarterly",
    "SEMIANNUALLY": "Semiannually",
    "YEARLY": "Yearly"
  },
  "modals": {
    "delete": {
      "title": "Delete budget",
      "text": "Are you sure you want to delete <strong>{{name}}</strong> budget?"
    }
  },
  "toasts": {
    "delete": {
      "error": "An error occurred while deleting your budget",
      "success": "Budget deleted"
    },
    "fetchSpendingStatement": {
      "error": "Failed to get the spending statement"
    }
  },
  "zeroBudgetReason": {
    "GLOBAL": "It occurs when the filled amount is zero",
    "INDIVIDUAL": "It occurs when no people are assigned to this budget or the filled amount is zero"
  }
}