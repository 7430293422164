import { BookingsInvoicesSearchParams } from '@jurnee/common/src/entities/BookingInvoice';

export function getBookingsInvoicesParams(filters: BookingsInvoicesSearchParams) {
  const params = new URLSearchParams();

  if (Array.isArray(filters.budgetIds) && filters.budgetIds.length > 0) {
    for (const budgetId of filters.budgetIds) {
      params.append('budgetIds[]', budgetId.toString());
    }
  }

  if (Array.isArray(filters.entityIds) && filters.entityIds.length > 0) {
    for (const entityId of filters.entityIds) {
      params.append('entityIds[]', entityId.toString());
    }
  }

  if (Array.isArray(filters.teamIds) && filters.teamIds.length > 0) {
    for (const teamId of filters.teamIds) {
      params.append('teamIds[]', teamId.toString());
    }
  }

  if (filters.from) {
    params.set('from', filters.from.toString());
  }

  if (filters.to) {
    params.set('to', filters.to.toString());
  }

  return params;
}