import { VStack } from '@chakra-ui/react';
import { QuestionAnswerUpdateBody } from '@jurnee/common/src/dtos/surveySubmissions';
import { QuestionJSON } from '@jurnee/common/src/entities/Survey';
import { QuestionCheckbox } from './QuestionCheckbox';
import { QuestionProps } from './QuestionProps';
import { QuestionSelect } from './QuestionSelect';
import { QuestionText } from './QuestionText';

interface Props {
  questions: QuestionJSON[];
  questionAnswers: QuestionAnswerUpdateBody[];
  onChange(questionAnswers: QuestionAnswerUpdateBody[]): void;
}

export function Questions(props: Props) {

  function onChange(answers: QuestionAnswerUpdateBody[], question: QuestionJSON) {
    const filteredAnswers = props.questionAnswers.filter(({ questionId }) => questionId !== question.id);

    props.onChange(filteredAnswers.concat(answers));
  }

  return (
    <VStack w="100%" spacing={5} alignItems="flex-start">
      {
        props.questions.map(question => {
          const questionAnswers = props.questionAnswers.filter(({ questionId }) => question.id === questionId);
          const questionProps: QuestionProps = { question, questionAnswers, onChange };

          switch (question.type) {
          case 'CHECKBOX': return <QuestionCheckbox key={question.id} {...questionProps} />;
          case 'SELECT': return <QuestionSelect key={question.id} {...questionProps} />;
          default: return <QuestionText key={question.id} {...questionProps} />;
          }
        })
      }
    </VStack>
  );
}