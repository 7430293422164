import { Box, Divider, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { ReviewCard } from '@jurnee/common/src/components/reviews/ReviewCard';
import { ReviewsSummary } from '@jurnee/common/src/components/reviews/ReviewsSummary';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { EntityJSON } from '@jurnee/common/src/entities/Entity';
import { sortByDate } from '@jurnee/common/src/utils/arrays';
import { getBookingInvoicesTotalDetails } from '@jurnee/common/src/utils/bookingInvoices';
import { getCommitmentType } from '@jurnee/common/src/utils/bookings';
import { getReviewAuthor } from '@jurnee/common/src/utils/reviews';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import ProcessNumber from 'src/components/Booking/ProcessNumber';
import EventSchedule from 'src/components/BookingDetails/EventSchedule';
import BookingPriceDetailsCard from 'src/components/BookingPriceDetailsCard';
import EmptyState from 'src/components/EmptyState';
import EntityCard from 'src/components/EntityCard';
import ShareReviewFormModal from 'src/modals/ShareReviewFormModal';
import { getAvgBookingReviewsRating } from 'src/utils/booking';
import ApprovalResponses from '../ApprovalResponses';
import { BookingDetailsStepper } from '../BookingDetailsStepper';
import Invoices from '../Invoices';
import Quotes from '../Quotes';

interface OwnProps {
  booking: BookingJSON;
  relationships: BookingRelationshipsJSON;
  entity: EntityJSON;
}

type Props = OwnProps & WithTranslation;

class FinishedStep extends React.PureComponent<Props> {

  get processNumber() {
    if (!this.props.booking.processNumber) {
      return null;
    }

    return <ProcessNumber
      label={this.props.t('processNumber')}
      processNumber={this.props.booking.processNumber}
    />;
  }

  get emptyState() {
    return (
      <EmptyState
        imagePath="/assets/illustrations/reviews.svg"
        heading={this.props.t('emptyStates.reviews.heading')}
        description={this.props.t('emptyStates.reviews.description')}
      >
        <ShareReviewFormModal bookingCuid={this.props.booking.cuid} />
      </EmptyState>
    );
  }

  get reviewsSummary() {
    const avgRating = getAvgBookingReviewsRating(this.props.relationships.bookingsReviews);

    const reviewsCountGroupByRating = this.props.relationships.bookingsReviews.reduce<Record<number, number>>((reviewsCount, { rating }) => {
      reviewsCount[rating] >= 0 ? reviewsCount[rating] += 1 : reviewsCount[rating] = 1;

      return reviewsCount;
    }, {});

    return <ReviewsSummary
      key='reviewsSummary'
      avgRating={avgRating}
      totalReviews={this.props.relationships.bookingsReviews.length}
      reviewsCountGroupByRating={reviewsCountGroupByRating}
    />;
  }

  get reviews() {
    const reviews = this.props.relationships.bookingsReviews.map((bookingReview, idx) => {
      return <ReviewCard key={idx} author={getReviewAuthor(bookingReview.review)} rating={bookingReview.rating} comment={bookingReview.comment} createdAt={bookingReview.createdAt} />;
    });

    return (
      <VStack w="100%" alignItems="flex-start" spacing={3}>
        <Heading size="md">{this.props.t('steps.finished.reviews.heading')}</Heading>

        <VStack w="100%" alignItems="flex-start" spacing={5}>
          {
            this.props.relationships.bookingsReviews.length === 0 ?
              this.emptyState : [this.reviewsSummary, reviews]
          }
        </VStack>
      </VStack>
    );
  }

  get approvalResponses() {
    const [approvalRequest] = sortByDate(this.props.relationships.approvalRequests, 'createdAt', 'desc');

    return (
      <VStack w="100%" spacing={3} alignItems="flex-start">
        <Text lineHeight="16px" fontWeight={700}>{this.props.t('approvalResponses.label')}</Text>

        <ApprovalResponses approvalRequest={approvalRequest} users={this.props.relationships.users} />
      </VStack>
    );
  }

  get quotes() {
    return (
      <VStack w="100%" spacing={3} alignItems="flex-start">
        <Text lineHeight="16px" fontWeight={700}>{this.props.t('quotes.label')}</Text>

        <Quotes quotes={this.props.relationships.quotes} bookingsInvoices={this.props.relationships.bookingsInvoices} />
      </VStack>
    );
  }

  get commitment() {
    const commitmentType = getCommitmentType(this.props.relationships);

    switch(commitmentType) {
    case 'APPROVAL_REQUEST':
      return this.approvalResponses;
    case 'QUOTE':
      return this.quotes;
    default:
      return null;
    }
  }

  get invoices() {
    return (
      <VStack w="100%" spacing={3} alignItems="flex-start">
        <Text lineHeight="16px" fontWeight={700}>{this.props.t('invoices.label')}</Text>

        <Invoices bookingsInvoices={this.props.relationships.bookingsInvoices} />
      </VStack>
    );
  }

  get priceDetails() {
    return getBookingInvoicesTotalDetails(this.props.relationships.bookingsInvoices);
  }

  render () {
    return (
      <HStack w="100%" spacing={5} alignItems="flex-start">
        <VStack w="100%" alignItems="flex-start" spacing={8}>
          <EventSchedule
            heading={this.props.t('schedule.heading')}
            bookingsItems={this.props.booking.bookingsItems}
            experiences={this.props.relationships.experiences}
            products={this.props.relationships.products}
            currency={this.props.relationships.bookingsInvoices[0].currency}
            editDisabled={true}
          />

          { this.reviews }
        </VStack>

        <VStack w="100%" minW={380} maxW={380} alignItems="stretch" spacing={3}>
          <Heading size="md">{this.props.t('invoicing.heading')}</Heading>

          <Box bg="white" border="1px solid" borderColor="gray.200" borderRadius={4}>
            <BookingDetailsStepper step="FINISHED" />

            <VStack p={5} spacing={5}>
              <EntityCard entity={this.props.entity} />

              { this.processNumber }

              { this.commitment }

              { this.invoices }

              <Divider />

              <BookingPriceDetailsCard
                subtotal={this.priceDetails.subtotal}
                totalTax={this.priceDetails.totalTax}
                totalDiscount={this.priceDetails.totalDiscount}
                total={this.priceDetails.total}
                currency={this.priceDetails.currency}
                showCurrencyWarning={false}
              />
            </VStack>
          </Box>
        </VStack>
      </HStack>
    );
  }

}

export default withTranslation('booking')(FinishedStep);