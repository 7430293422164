import { HStack, Text, VStack } from '@chakra-ui/react';
import { formatDate, getCurrentTimeZone } from '@jurnee/common/src/utils/dates';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import { useTranslation } from 'react-i18next';
import { ApprovalRequestData } from 'src/store/approvalRequests/approvalRequests.selectors';

interface Props {
  approvalRequest: ApprovalRequestData;
}

const dateOptions = {
  displayTime: true,
  displayTimeZone: true,
  timeZone: getCurrentTimeZone()
};

export function ApprovalRequestDetails({ approvalRequest }: Props) {
  const { t } = useTranslation('approvalRequests', { keyPrefix: 'details' });

  const requestedAt = formatDate(new Date(approvalRequest.createdAt), dateOptions);
  const requestedBy = getUserLabel(approvalRequest.users.find(user => user.id === approvalRequest.userId));

  return (
    <VStack w="100%" p={5} borderRight="1px solid" borderColor="blue.50">
      <VStack w="100%" spacing={3}>
        <HStack w="100%" justifyContent="space-between">
          <Text fontWeight={700}>{t('billedTo')}</Text>
          <Text color="gray.400">{approvalRequest.entity.name}</Text>
        </HStack>

        {
          approvalRequest.booking.team &&
            <HStack w="100%" justifyContent="space-between">
              <Text fontWeight={700}>{t('team')}</Text>
              <Text color="gray.400">{approvalRequest.booking.team.name}</Text>
            </HStack>
        }

        <HStack w="100%" justifyContent="space-between">
          <Text fontWeight={700}>{t('requestedAt')}</Text>
          <Text color="gray.400">{requestedAt}</Text>
        </HStack>

        <HStack w="100%" justifyContent="space-between">
          <Text fontWeight={700}>{t('requestedBy')}</Text>
          <Text color="gray.400">{requestedBy}</Text>
        </HStack>

        {
          approvalRequest.comment &&
            <VStack w="100%" spacing={3}>
              <Text w="100%" fontWeight={700}>
                { t('requestComment') }
              </Text>

              <Text w="100%" color="gray.400">
                { approvalRequest.comment }
              </Text>
            </VStack>
        }
      </VStack>
    </VStack>
  );
}