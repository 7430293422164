import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  HStack,
  Input,
  VStack,
  useToast
} from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { TeamUpsertBody } from '@jurnee/common/src/dtos/teams';
import { TeamJSON } from '@jurnee/common/src/entities/Team';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { haveSameContent } from '@jurnee/common/src/utils/arrays';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { cloneElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import EmptyStateList from 'src/components/RemovableItemsList/EmptyStateList';
import { RemovableUsersList } from 'src/components/RemovableUsersList';
import { UsersSearchSelect } from 'src/components/UsersSearchSelect';
import WorkModelSelector from 'src/components/WorkModelSelector';
import { useAppDispatch } from 'src/store';
import { trackEvent } from 'src/store/analytics/analytics.thunks';
import { getEmployeesFetchStatusSelector, getEmployeesSelector } from 'src/store/employees/employees.selectors';
import { getEmployees } from 'src/store/employees/employees.thunks';
import { createTeam, updateTeam } from 'src/store/teams/teams.thunks';
import { PrimaryButton, SecondaryButton } from '../components/buttons';

interface Props {
  team?: TeamJSON;
  userIds?: number[];
  children: React.ReactElement;
  isOpen?: boolean;
}

export default function TeamDrawer(props: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation(['teams', 'common']);

  const employees = useSelector(getEmployeesSelector);
  const employeesFetchStatus = useSelector(getEmployeesFetchStatusSelector);

  const [isOpen, setIsOpen] = useState(!!props.isOpen);
  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState(props.team?.name ?? '');
  const [estimatedSize, setEstimatedSize] = useState(props.team?.estimatedSize ?? 0);
  const [workModel, setWorkModel] = useState(props.team?.workModel ?? 'HYBRID');
  const [userIds, setUserIds] = useState(props.team ? props.team.usersTeams.map(({ user }) => user.id) : (props.userIds || []));

  const isLoading = employeesFetchStatus !== 'FETCHED';

  const teamBody: TeamUpsertBody ={
    name,
    estimatedSize,
    workModel,
    userIds
  };

  function onClose() {
    setIsOpen(false);
    setIsSaving(false);
  }

  useEffect(() => {
    if (employeesFetchStatus === 'INITIAL') {
      dispatch(getEmployees());
    }
  }, []);

  function onOpen(event?: React.MouseEvent) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    setIsOpen(true);

    dispatch(trackEvent({
      name: 'opened_teams_drawer'
    }));
  }

  function onCreate() {
    return dispatch(
      createTeam({ team: teamBody })
    );
  }

  function onUpdate() {
    return dispatch(
      updateTeam({
        teamId: props.team.id,
        team: teamBody
      })
    );
  }

  async function onSave() {
    setIsSaving(true);

    if (props.team) {
      try {
        await onUpdate().unwrap();
        toast(getSuccessToast(t('drawer.toasts.update.success')));
        onClose();

        const userIdsChanged = !haveSameContent(userIds, props.team.usersTeams.map(({ userId }) => userId));

        if (userIdsChanged) {
          dispatch(getEmployees());
        }

        return;
      } catch(error) {
        setIsSaving(false);
        return toast(getErrorToast(t('drawer.toasts.update.error'), error.message));
      }
    }

    try {
      await onCreate().unwrap();
      toast(getSuccessToast(t('drawer.toasts.create.success')));
      onClose();

      if (userIds.length > 0) {
        dispatch(getEmployees());
      }

      return;
    } catch(error) {
      setIsSaving(false);
      return toast(getErrorToast(t('drawer.toasts.create.error'), error.message));
    }
  }

  function onRemoveUser({ id }: UserDetails) {
    setUserIds(userIds.filter(userId => userId !== id));
  }

  function onAddUser({ value: { id } }: { value: UserDetails }) {
    setUserIds([...userIds, id]);
  }

  return (
    <>
      { cloneElement(props.children, { onClick: onOpen }) }

      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            { t(`drawer.title.${props.team ? 'edit' : 'create'}`) }
          </DrawerHeader>
          <DrawerBody>
            {
              isLoading ?
                <Loader /> :
                (
                  <VStack spacing={5}>
                    <FormControl isRequired>
                      <FormLabel>{t('drawer.form.name.label')}</FormLabel>
                      <Input
                        size="sm"
                        autoFocus
                        placeholder={t('drawer.form.name.placeholder')}
                        onChange={({ target }) => setName(target.value)}
                        defaultValue={name}
                      />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>{t('drawer.form.estimatedSize.label')}</FormLabel>
                      <Input
                        size="sm"
                        type="number"
                        onChange={({ target }) => setEstimatedSize(Number(target.value))}
                        defaultValue={estimatedSize}
                      />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>{t('drawer.form.workModel.label')}</FormLabel>
                      <WorkModelSelector
                        onChange={({ target }) => setWorkModel(target.value as TeamJSON['workModel'])}
                        defaultValue={workModel}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>{t('drawer.form.usersTeams.label')}</FormLabel>

                      <VStack w="100%" spacing="10px">
                        <UsersSearchSelect users={employees} userIds={userIds} placeholder={t('drawer.form.usersTeams.placeholder')} onAddUser={onAddUser} />
                        {
                          userIds.length === 0 ? (
                            <EmptyStateList description={t('drawer.form.usersTeams.noMembers')} />
                          ) : (
                            <RemovableUsersList users={employees} userIds={userIds} onRemoveUser={onRemoveUser} />
                          )
                        }
                      </VStack>
                    </FormControl>
                  </VStack>
                )
            }
          </DrawerBody>
          <DrawerFooter>
            <HStack justifyContent="space-between" w="100%">
              <SecondaryButton colorScheme="pink" size="sm" onClick={onClose}>
                { t('common:buttons.close') }
              </SecondaryButton>

              <PrimaryButton colorScheme="teal" size="sm" isLoading={isSaving} onClick={onSave}>
                { t('common:buttons.apply') }
              </PrimaryButton>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}