import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { getUserBudgetBreakdownsByBookingId, getUserBudgetBreakdownsByBudgetId } from 'src/store/userBudgetBreakdowns/userBudgetBreakdowns.thunks';
import { initialState } from '../state';
import { userBudgetBreakdownsAdapter } from './userBudgetBreakdowns.adapter';

const slice = createSlice({
  name: 'userBudgetBreakdowns',
  initialState: initialState.userBudgetBreakdowns,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getUserBudgetBreakdownsByBudgetId.pending, (state) => {
      state.status = FETCH_STATUS.FETCHING;
    });

    builder.addCase(getUserBudgetBreakdownsByBudgetId.fulfilled, (state, { payload: { list } }) => {
      userBudgetBreakdownsAdapter.setMany(state, list);
      state.status = FETCH_STATUS.FETCHED;
    });

    builder.addCase(getUserBudgetBreakdownsByBudgetId.rejected, (state) => {
      state.status = FETCH_STATUS.INITIAL;
    });

    builder.addCase(getUserBudgetBreakdownsByBookingId.pending, (state) => {
      state.status = FETCH_STATUS.FETCHING;
    });

    builder.addCase(getUserBudgetBreakdownsByBookingId.fulfilled, (state, { payload: { list } }) => {
      userBudgetBreakdownsAdapter.setMany(state, list);
      state.status = FETCH_STATUS.FETCHED;
    });

    builder.addCase(getUserBudgetBreakdownsByBookingId.rejected, (state) => {
      state.status = FETCH_STATUS.INITIAL;
    });

  }
});

export default slice.reducer;