import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultValue: BudgetJSON['name'];
  onChange(e: ChangeEvent<HTMLInputElement>): void;
}

export function NameInput({ defaultValue, onChange }: Props) {
  const { t } = useTranslation('settings', { keyPrefix: 'budgets.drawer' });

  return (
    <FormControl isRequired>
      <FormLabel>{t('form.name.label')}</FormLabel>
      <Input size="sm" name="name" defaultValue={defaultValue} onChange={onChange} autoFocus />
    </FormControl>
  );
}