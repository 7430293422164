import BookingEntity from '@jurnee/common/src/entities/Booking';
import QuoteEntity, { QuoteJSON } from '@jurnee/common/src/entities/Quote';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'src/api/bookings';
import { openBase64File } from 'src/utils/files';
import { RootState } from '../state';

export interface QuoteDownloadPayload {
  bookingId: BookingEntity['id'];
  quoteId: QuoteEntity['id'];
  onError(error: unknown): void;
}

export interface QuoteCancelPayload {
  bookingId: BookingEntity['id'];
  quoteId: QuoteEntity['id'];
  onError: (error: Error) => void;
  onSuccess: (quote: QuoteJSON) => void;
}

export const downloadQuote = createAsyncThunk<null, QuoteDownloadPayload, { state: RootState }>('QUOTE_DOWNLOAD', async ({ bookingId, quoteId, onError }, thunkAPI) => {
  try {
    const file = await api.downloadQuote(bookingId, quoteId);

    openBase64File(file);
  } catch (e) {
    onError(e);
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const cancelQuote = createAsyncThunk<QuoteJSON, QuoteCancelPayload, { state: RootState }>('QUOTE_CANCEL', async ({ bookingId, quoteId, onError, onSuccess }, thunkAPI) => {
  try {
    const quote = await api.cancelQuote(bookingId, quoteId);
    onSuccess(quote);
    return quote;
  } catch (e) {
    onError(e);
    return thunkAPI.rejectWithValue(e.message);
  }
});