import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { getCurrenciesThunk } from './currencies.thunk';

const slice = createSlice({
  name: 'currencies',
  initialState: initialState.currencies,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getCurrenciesThunk.pending, () => {
      return { data: null, error: null, status: FETCH_STATUS.FETCHING };
    });

    builder.addCase(getCurrenciesThunk.fulfilled, (state, action) => {
      return { data: action.payload, error: null, status: FETCH_STATUS.FETCHED };
    });

    builder.addCase(getCurrenciesThunk.rejected, () => {
      return { data: null, error: true, status: FETCH_STATUS.INITIAL };
    });

  }
});

export default slice.reducer;