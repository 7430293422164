import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { createCollection, getCollections } from './collections.thunks';

const slice = createSlice({
  name: 'collections',
  initialState: initialState.collections,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getCollections.pending, () => {
      return { list: [], error: null, status: FETCH_STATUS.FETCHING };
    });

    builder.addCase(getCollections.fulfilled, (state, action) => {
      return { list: action.payload, error: null, status: FETCH_STATUS.FETCHED };
    });

    builder.addCase(getCollections.rejected, () => {
      return { list: [], error: true, status: FETCH_STATUS.INITIAL };
    });

    builder.addCase(createCollection.fulfilled, (state, action) => {
      return {
        ...state,
        list: [action.payload, ...state.list]
      };
    });

  }
});

export default slice.reducer;