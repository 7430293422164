import { HStack, LinkBox, LinkOverlay, Text, VStack } from '@chakra-ui/react';
import { StatusBadge } from '@jurnee/common/src/components/StatusBadge';
import { BudgetSpendingStatementItemJSON } from '@jurnee/common/src/entities/BudgetSpendingStatement';
import { formatDate } from '@jurnee/common/src/utils/dates';
import { useTranslation } from 'react-i18next';
import PriceInUserCurrency from 'src/components/PriceInUserCurrency';

interface Props {
  item: BudgetSpendingStatementItemJSON;
  type: 'pending' | 'spent';
}

function getPeople(participants: number) {
  const { t } = useTranslation('common');

  if (participants > 0) {
    return <span> ⋅ {participants} {t('people')}</span>;
  }

  return null;
}

export function BudgetSpendingStatementItem({ item, type }: Props) {
  const { name, spent, pending, currency, participants, date, id } = item;

  return (
    <LinkBox bg="white" p={4} border="1px solid" borderColor="blue.50" borderRadius={4} _hover={{ borderColor: 'gray.300' }}>
      <VStack spacing={3}>
        <HStack justifyContent="space-between" w="100%" h="10px">
          <LinkOverlay href={`/bookings/${id}`} target="_blank" fontWeight={700} noOfLines={1}>
            {name}
          </LinkOverlay>
          <Text fontWeight={700}>
            <PriceInUserCurrency value={type === 'spent' ? spent : pending} currency={currency} />
          </Text>
        </HStack>
        <HStack justifyContent="space-between" w="100%" h="10px">
          <Text color="gray.400">
            <span>{formatDate(new Date(date))}</span>
            {getPeople(participants)}
          </Text>
          <StatusBadge color={type === 'pending' ? 'blue' : 'pink'} label={type}/>
        </HStack>
      </VStack>
    </LinkBox>
  );
}