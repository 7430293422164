import { Box, Center, HStack, VStack } from '@chakra-ui/react';
import { AUTH_STATUS, FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { Loader } from '@jurnee/common/src/components/Loader';
import { ItemReviewCreateBody, NetPromoterScoreCreateBody, ReviewCreateBody } from '@jurnee/common/src/dtos/reviews';
import { Booking } from '@jurnee/common/src/entities/Booking';
import { User } from '@jurnee/common/src/entities/User';
import { DashboardJwt, parseJwt } from '@jurnee/common/src/utils/auth';
import { getMainBookingRelation } from '@jurnee/common/src/utils/bookings';
import * as React from 'react';
import { connect } from 'react-redux';
import { createBookingReview, createExperienceReview, createNps, createPartnerReview, createReview, getBookingByCuid } from 'src/store/reviewDetails/reviewDetails.thunks';
import { RootState } from 'src/store/state';
import { RouteProps } from '../Route';
import Author from './Author';
import BookingReview from './BookingReview';
import ExperienceReview from './ExperienceReview';
import Nps from './Nps';
import PartnerReview from './PartnerReview';
import Summary from './Summary';
import Thanks from './Thanks';

interface StateProps {
  currentUserId: User['id'];
  isAuthenticated: boolean;
  reviewDetails: RootState['reviewDetails'];
}

interface DispatchProps {
  getBookingByCuid(cuid: Booking['cuid']): void;
  createReview(data: { bookingCuid: Booking['cuid'] } & ReviewCreateBody): void;
  createExperienceReview(data: { bookingCuid: Booking['cuid'] } & ItemReviewCreateBody): void;
  createPartnerReview(data: { bookingCuid: Booking['cuid'] } & ItemReviewCreateBody): void;
  createBookingReview(data: { bookingCuid: Booking['cuid'] } & ItemReviewCreateBody): void;
  createNps(data: { bookingCuid: Booking['cuid'] } & NetPromoterScoreCreateBody): void;
}

interface State {
  bookingCuid: Booking['cuid'];
  currentStep: number;
}

enum Steps {
  Author,
  ExperienceReview,
  PartnerReview,
  BookingReview,
  Nps,
  Thanks
}

type Props = StateProps & DispatchProps & RouteProps;

class ReviewDetails extends React.Component<Props> {

  state: State = {
    bookingCuid: null,
    currentStep: Steps.Author
  };

  componentDidMount() {
    this.props.getBookingByCuid(this.props.match.params.cuid);

    this.setState({ ...this.state, bookingCuid: this.props.match.params.cuid });
  }

  handleNext = (nextStep: Steps) => {
    this.setState({ ...this.state, currentStep: nextStep });
  };

  handleAuthor = async (data: ReviewCreateBody) => {
    await this.props.createReview({
      bookingCuid: this.state.bookingCuid,
      ...data
    });

    if (!this.props.reviewDetails.error) {
      if (this.steps.includes(Steps.ExperienceReview)) {
        return this.handleNext(Steps.ExperienceReview);
      }

      if (this.steps.includes(Steps.PartnerReview)) {
        return this.handleNext(Steps.PartnerReview);
      }

      this.handleNext(this.lastStep);
    }
  };

  handleExperienceReview = async (data: Omit<ItemReviewCreateBody, 'reviewId'>) => {
    await this.props.createExperienceReview({
      bookingCuid: this.state.bookingCuid,
      reviewId: this.props.reviewDetails.data.id,
      ...data
    });

    if (!this.props.reviewDetails.experienceReview.error) {
      if (this.steps.includes(Steps.PartnerReview)) {
        return this.handleNext(Steps.PartnerReview);
      }

      this.handleNext(this.lastStep);
    }
  };

  handlePartnerReview = async (data: Omit<ItemReviewCreateBody, 'reviewId'>) => {
    await this.props.createPartnerReview({
      bookingCuid: this.state.bookingCuid,
      reviewId: this.props.reviewDetails.data.id,
      ...data
    });

    if (!this.props.reviewDetails.partnerReview.error) {
      this.handleNext(this.lastStep);
    }
  };

  handleBookingReview = async (data: Omit<ItemReviewCreateBody, 'reviewId'>) => {
    await this.props.createBookingReview({
      bookingCuid: this.state.bookingCuid,
      reviewId: this.props.reviewDetails.data.id,
      ...data
    });

    if (!this.props.reviewDetails.bookingReview.error) {
      this.handleNext(Steps.Thanks);
    }
  };

  handleNps = async (data: NetPromoterScoreCreateBody) => {
    await this.props.createNps({
      bookingCuid: this.state.bookingCuid,
      ...data
    });

    if (!this.props.reviewDetails.nps.error) {
      this.handleNext(Steps.Thanks);
    }
  };

  get isBookingOrganizer() {
    if (this.props.reviewDetails.booking.status !== FETCH_STATUS.FETCHED) {
      return false;
    }

    if (!this.props.isAuthenticated) {
      return false;
    }

    return this.props.reviewDetails.booking.data.bookingsOrganizers.some(({ organizerId }) => organizerId === this.props.currentUserId);
  }

  get step() {
    switch(this.state.currentStep) {
    case Steps.Author:
      return <Author onNext={this.handleAuthor} />;
    case Steps.ExperienceReview:
      return <ExperienceReview onNext={this.handleExperienceReview} />;
    case Steps.PartnerReview:
      return <PartnerReview onNext={this.handlePartnerReview} />;
    case Steps.BookingReview:
      return <BookingReview onNext={this.handleBookingReview} />;
    case Steps.Nps:
      return <Nps onNext={this.handleNps} />;
    default:
      return <Loader overlay/>;
    }
  }

  get steps() {
    const steps = [Steps.Author];

    if (this.isMainBookingRelationHasProductId) {
      steps.push(Steps.ExperienceReview);
    }

    if (this.isMainBookingRelationHasProductId && this.isMainBookingRelationHasPartner) {
      steps.push(Steps.PartnerReview);
    }

    steps.push(this.isBookingOrganizer ? Steps.Nps : Steps.BookingReview);

    return steps;
  }

  get isMainBookingRelationHasProductId() {
    return !!getMainBookingRelation(this.props.reviewDetails.booking.data).productId;
  }

  get isMainBookingRelationHasPartner() {
    return !!getMainBookingRelation(this.props.reviewDetails.booking.data).partnerId;
  }

  get lastStep() {
    return this.steps.includes(Steps.Nps) ? Steps.Nps : Steps.BookingReview;
  }

  get currentStepIndicator() {
    return this.steps.map(step => {
      return <Box key={step} w="18px" h="8px" borderRadius={2} bg={step <= this.state.currentStep ? 'white' : 'rgba(255, 255, 255, 0.2)'} />;
    });
  }

  get currentStep() {
    if (!this.state.bookingCuid) {
      return null;
    }

    if (this.props.reviewDetails.booking.status !== FETCH_STATUS.FETCHED) {
      return <Loader overlay/>;
    }

    if (this.state.currentStep === Steps.Thanks) {
      return <Thanks />;
    }

    return (
      <VStack>
        <HStack w="900px" alignItems="stretch" spacing={0} borderRadius={8} overflow="hidden" boxShadow="xl">
          <Summary booking={this.props.reviewDetails.booking.data} />
          <VStack w="50%" minH="420px" bgColor="gray.50" spacing={5} p={10} alignItems="flex-start">
            {this.step}
          </VStack>
        </HStack>
        <HStack pt={8}>
          {this.currentStepIndicator}
        </HStack>
      </VStack>
    );
  }

  render() {
    return (
      <Center h="100%" bgGradient="linear(to-tr,#5C62CC, #7DECEE)">
        {this.currentStep}
      </Center>
    );
  }

}

function mapStateToProps(state: RootState): StateProps {
  const isAuthenticated = state.auth.status === AUTH_STATUS.AUTHENTICATED;

  return {
    isAuthenticated,
    currentUserId: isAuthenticated ? parseJwt<DashboardJwt>(state.auth.token).userId : null,
    reviewDetails: state.reviewDetails,
  };
}

const mapDispatchToProps: DispatchProps = {
  getBookingByCuid,
  createReview,
  createExperienceReview,
  createPartnerReview,
  createBookingReview,
  createNps,
};

export default connect<StateProps, DispatchProps, RouteProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ReviewDetails);