import { Box, HStack, Spacer } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';

interface Props {
  children: React.ReactNode
  onRemove(): void;
}

export default function RemovableItem({ children, onRemove }: Props) {
  return (
    <Box py={1} pr={1} w="100%">
      <HStack minH={8} spacing="10px">
        { children }

        <Spacer />

        <Icon
          icon="squareRoundedX"
          cursor="pointer"
          size={5}
          aria-label="Remove"
          _hover={{ color: 'blackAlpha.700' }}
          onClick={onRemove}
        />
      </HStack>
    </Box>
  );
}