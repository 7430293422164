import { Box, StackProps } from '@chakra-ui/react';

interface Props {
  description: string
}

export default function EmptyStateList({ description }: Props & StackProps) {
  return (
    <Box w="100%" textAlign="center" bg="white" borderRadius={8} p={4}>
      { description }
    </Box>
  );
}