import { FormControl, FormHelperText, FormLabel, Input, InputProps } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultValue: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
}

export function ProcessNumberInput(props: Props & InputProps) {
  const { t } = useTranslation('booking');

  const { defaultValue, isDisabled, isRequired, onChange, ...inputProps } = props;

  return (
    <FormControl id="processNumber" isRequired={isRequired}>
      <FormLabel>{t('processNumber')}</FormLabel>
      { !isRequired && <FormHelperText>{t('steps.commitment.form.processNumber.helperText')}</FormHelperText> }
      <Input
        name="processNumber"
        fontSize="14px"
        placeholder={isDisabled ? null : t('steps.commitment.form.processNumber.placeholder')}
        _placeholder={{ color: 'gray.400' }}
        onChange={onChange}
        defaultValue={defaultValue}
        isDisabled={!!isDisabled}
        _disabled={{ color: 'gray.400', bgColor: 'gray.50', cursor: 'not-allowed' }}
        {...inputProps}
      />
    </FormControl>
  );
}