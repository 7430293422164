import { HStack, Heading, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PrimaryButton } from 'src/components/buttons';
import ScimConfigurationModal from 'src/modals/ScimConfiguration';
import { getCompanySelector } from 'src/store/company/company.selector';

export default function UserProvisioning() {
  const company = useSelector(getCompanySelector);
  const { t } = useTranslation('people');

  return (
    <HStack w="100%" margin="0 auto" spacing={8} alignItems="self-start">
      <VStack bg="white" borderRadius={4} border="1px solid" borderColor="blue.50" p={5} spacing={5} w="60%" alignItems="flex-start">
        <Heading size="md">{t('userProvisioning.heading')}</Heading>
        <Text color="gray.500">{t('userProvisioning.warning')}</Text>
        {
          company.scimConfiguration && (
            <VStack spacing={2} alignItems="flex-start">
              <Text fontWeight={900}>{t('userProvisioning.clientId')}</Text>
              <Text>{company.scimConfiguration.clientId}</Text>
              <Text fontWeight={900}>{t('userProvisioning.clientSecret')}</Text>
              <Text>{''.padStart(32, '*')}</Text>
            </VStack>
          )
        }
        <ScimConfigurationModal />
      </VStack>

      <VStack bg="white" borderRadius={4} border="1px solid" borderColor="blue.50" p={5} spacing={5} w="40%" alignItems="self-start">
        <Heading size="md">{t('userProvisioning.helpHeading')}</Heading>
        <Text color="gray.500">{t('userProvisioning.helpText')}</Text>
        <Link to="https://jurneeio.notion.site/User-provisioning-b4372a7059154a3ebc321707b489fdd1" target="_blank">
          <PrimaryButton size="sm">{t('userProvisioning.helpButton')}</PrimaryButton>
        </Link>
      </VStack>
    </HStack>
  );
}