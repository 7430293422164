import { HStack } from '@chakra-ui/react';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BookingParticipantJSON } from '@jurnee/common/src/entities/BookingParticipant';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import ImportParticipantsModal from 'src/modals/ImportParticipantsModal';
import { NameInput } from './NameInput';
import { StatusSelect } from './StatusSelect';

interface Props {
  booking: BookingJSON;
  statusValue: string;
  onNameChange(e: React.ChangeEvent<HTMLInputElement>): void;
  onStatusChange(e: React.ChangeEvent<HTMLSelectElement>): void;
  onImportParticipants(): void
}

interface Filters {
  nameInput: string;
  status: string;
}

export function filterBookingParticipants(bookingParticipants: BookingParticipantJSON[], { nameInput, status }: Filters) {
  if (isEmpty(nameInput) && !status) {
    return bookingParticipants;
  }

  return bookingParticipants.filter(bookingParticipant => {
    const hasStatus = !isEmpty(status) ? bookingParticipant.status === status : true;
    const matchName = !isEmpty(nameInput) ? new RegExp(nameInput, 'gi').test(getUserLabel(bookingParticipant.participant)) : true;

    return hasStatus && matchName;
  });
}

export function BookingParticipantsFilters(props: Props) {
  return (
    <HStack w="100%" justifyContent="space-between" spacing={4}>
      <HStack w="100%" spacing={4}>
        <NameInput onChange={props.onNameChange} />
        <StatusSelect value={props.statusValue} onChange={props.onStatusChange} />
      </HStack>

      <ImportParticipantsModal
        booking={props.booking}
        onImportParticipants={props.onImportParticipants}
      />
    </HStack>
  );
}