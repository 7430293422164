import { FormControl, FormLabel, Textarea } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  isInvalid: boolean;
  onChange(comment: string): void;
}

export function CommentTextarea({ isInvalid, onChange }: Props) {
  const { t } = useTranslation('booking', { keyPrefix: 'modals.customItem.comment' });

  return (
    <FormControl w="100%" display="flex" flexDirection="column" flexGrow={1} isInvalid={isInvalid} isRequired>
      <FormLabel>{t('label')}</FormLabel>
      <Textarea
        size="sm"
        minH={128}
        flexGrow={1}
        resize="none"
        fontSize={14}
        placeholder={t('placeholder')}
        _placeholder={{ color: 'gray.400' }}
        onChange={e => onChange(e.target.value)}
      />
    </FormControl>
  );
}