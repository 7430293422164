import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { ApprovalResponseJSON } from '@jurnee/common/src/entities/ApprovalResponse';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { getApprovalRequests, updateApprovalResponse } from './approvalRequests.thunks';

const slice = createSlice({
  name: 'approvalRequests',
  initialState: initialState.approvalRequests,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getApprovalRequests.pending, () => {
      return {
        list: [],
        relationships: null,
        error: null,
        status: FETCH_STATUS.FETCHING
      };
    });

    builder.addCase(getApprovalRequests.fulfilled, (_state, action) => {
      const { list, relationships } = action.payload;

      return {
        list,
        relationships,
        error: null,
        status: FETCH_STATUS.FETCHED
      };
    });

    builder.addCase(getApprovalRequests.rejected, () => {
      return {
        list: [],
        relationships: null,
        error: true,
        status: FETCH_STATUS.INITIAL
      };
    });

    builder.addCase(updateApprovalResponse.fulfilled, (state, action) => {
      return {
        ...state,
        list: state.list.map(approvalRequest => {
          return {
            ...approvalRequest,
            approvalResponses: approvalRequest.approvalResponses.map(approvalResponse => {
              if (approvalResponse.id === action.payload.id) {
                return action.payload;
              }

              return approvalResponse as ApprovalResponseJSON;
            })
          };
        })
      };
    });
  }

});

export default slice.reducer;