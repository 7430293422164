import { ButtonProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/buttons';
import { ExperienceCategory } from 'src/utils/experiences';

interface Props {
  type: ExperienceCategory['type']
}

export function Button({ type, ...inheritedProps }: Props & Omit<ButtonProps, 'type'>) {
  const { t } = useTranslation('experiences', { keyPrefix: 'categories' });

  return (
    <PrimaryButton size="xs" colorScheme="black" {...inheritedProps}>
      { t(`${type}.buttonLabel`) } {`->`}
    </PrimaryButton>
  );
}