import { Box, HStack, Input, VStack } from '@chakra-ui/react';
import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { Loader } from '@jurnee/common/src/components/Loader';
import { TeamJSON } from '@jurnee/common/src/entities/Team';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TeamsTable from 'src/components/TeamsTable';
import { PrimaryButton } from 'src/components/buttons';
import TeamDrawer from 'src/drawers/TeamDrawer';
import { useAppDispatch } from 'src/store';
import { getEmployees } from 'src/store/employees/employees.thunks';
import { getTeamsFetchStatusSelector, teamsSelectors } from 'src/store/teams/teams.selectors';
import { deleteTeam } from 'src/store/teams/teams.thunks';

interface NameInputProps {
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
}

interface TeamsFilters {
  nameInput: string;
}

function NameInput(props: NameInputProps) {
  const { t } = useTranslation('teams', { keyPrefix: 'filters.name' });

  return <Input
    size="sm"
    minW="215px"
    type="text"
    name="nameSearch"
    placeholder={t('placeholder')}
    onChange={props.onChange}
  />;
}

function CreateTeamButton() {
  const location = useLocation();
  const { t } = useTranslation('teams', { keyPrefix: 'buttons' });

  const searchParams = new URLSearchParams(location.search);
  const isTeamDrawerOpen = searchParams.get('drawer') === 'team';

  return (
    <TeamDrawer isOpen={isTeamDrawerOpen}>
      <PrimaryButton size="sm">{t('createTeam')}</PrimaryButton>
    </TeamDrawer>
  );
}

function filterTeams(teams: TeamJSON[], { nameInput }: TeamsFilters) {
  if (isEmpty(nameInput)) {
    return teams;
  }

  return teams.filter(team => {
    const matchName = !isEmpty(nameInput) ? new RegExp(nameInput, 'gi').test(team.name) : true;

    return matchName;
  });
}

export default function Teams() {
  const dispatch = useAppDispatch();

  const teams = useSelector(teamsSelectors.selectAll);
  const teamsFetchStatus = useSelector(getTeamsFetchStatusSelector);

  const [nameInput, setNameInput] = useState<string>('');

  async function onDeleteTeam(team: TeamJSON) {
    await dispatch(deleteTeam(team.id));

    if (team.usersTeams.length > 0) {
      dispatch(getEmployees());
    }
  }

  if (teamsFetchStatus !== FETCH_STATUS.FETCHED) {
    return <Loader h={400} />;
  }

  const filteredTeams = filterTeams(teams, { nameInput });

  return (
    <VStack spacing={5}>
      <HStack w="100%" justifyContent="space-between">
        <Box justifyContent="flex-start">
          <NameInput onChange={({ target }) => setNameInput(target.value)} />
        </Box>

        <CreateTeamButton />
      </HStack>

      <TeamsTable teams={filteredTeams} hasFilters={!isEmpty(nameInput)} onDelete={onDeleteTeam} />;
    </VStack>
  );
}