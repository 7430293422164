import { FormControl, FormControlProps, FormLabel, Select, SelectProps } from '@chakra-ui/react';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import * as React from 'react';

interface OwnProps {
  label: string;
  defaultValue: number;
  teams: { id: number, name: string }[];
  size?: SelectProps['size'];
  onTeamChange({ target }: React.ChangeEvent<HTMLSelectElement>): void;
}

export default class TeamSelect extends React.Component<OwnProps & FormControlProps> {

  get options() {
    return sortAlphabeticallyBy(this.props.teams, 'name').map(({ id, name }) => (
      <option key={id} value={id}>{name}</option>
    ));
  }

  render() {
    const { label, defaultValue, teams, onTeamChange, ...inheritedProps } = this.props;

    return (
      <FormControl id="teamId" isRequired={true} {...inheritedProps}>
        <FormLabel>{label}</FormLabel>
        <Select name="teamId" size={this.props.size} bg="white" onChange={onTeamChange} defaultValue={defaultValue}>
          { this.options }
        </Select>
      </FormControl>
    );
  }

}