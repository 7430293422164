import { ScimConfigurationJSON } from '@jurnee/common/src/entities/ScimConfiguration';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { create } from '../../api/scimConfigurations';
import { RootState } from '../state';

export const createScimConfiguration = createAsyncThunk<ScimConfigurationJSON, null, { state: RootState }>('CREATE_SCIM_CONFIGURATION', async (body, thunkAPI) => {
  try {
    const configuration = await create();

    return configuration;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});