import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { BookingOrganizerJSON } from '@jurnee/common/src/entities/BookingOrganizer';
import { createSlice } from '@reduxjs/toolkit';
import { createApprovalRequest } from '../approvalRequests/approvalRequests.thunks';
import { createBooking, updateBooking } from '../bookings/bookings.thunks';
import { createBookingItems, deleteBookingItem, updateBookingItem } from '../bookingsItems/bookingsItems.thunks';
import { createPropositionCommentThunk } from '../propositionComments/propositionComments.thunks';
import { declinePropositionThunk, selectPropositionThunk } from '../propositions/propositions.thunks';
import { createPropositionsGroupThunk } from '../propositionsGroups/propositionsGroups.thunks';
import { cancelQuote } from '../quotes/quotes.thunks';
import { createRegistrationThunk } from '../registrations/registrations.thunks';
import { initialState } from '../state';
import { addBookingOrganizers, cancelBooking, commitByCreditCard, createQuote, getBooking, removeBookingOrganizer } from './bookingDetails.thunks';

const slice = createSlice({
  name: 'bookingDetails',
  initialState: initialState.bookingDetails,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getBooking.pending, (state) => {
      return {
        ...state,
        data: null,
        relationships: null,
        error: null,
        status: FETCH_STATUS.FETCHING,
      };
    });

    builder.addCase(getBooking.fulfilled, (state, action) => {
      const { relationships, data } = action.payload;

      return {
        ...state,
        data,
        relationships,
        error: null,
        status: FETCH_STATUS.FETCHED
      };
    });

    builder.addCase(getBooking.rejected, (state) => {
      return {
        ...state,
        data: null,
        relationships: null,
        error: true,
        status: FETCH_STATUS.INITIAL
      };
    });

    builder.addCase(addBookingOrganizers.fulfilled, (state, { payload: { list }}) => {
      return {
        ...state,
        data: {
          ...state.data,
          bookingsOrganizers: list
        }
      };
    });

    builder.addCase(removeBookingOrganizer.fulfilled, (state, { meta: { arg }}) => {
      return {
        ...state,
        data: {
          ...state.data,
          bookingsOrganizers: state.data.bookingsOrganizers.filter((bookingOrganizer): bookingOrganizer is BookingOrganizerJSON => (
            bookingOrganizer.organizerId !== arg.organizerId
          ))
        }
      };
    });

    builder.addCase(createQuote.fulfilled, (state, { payload }) => {
      return {
        ...state,
        relationships: {
          ...state.relationships,
          quotes: [...state.relationships.quotes, payload]
        }
      };
    });

    builder.addCase(cancelQuote.fulfilled, (state, { payload }) => {
      return {
        ...state,
        relationships: {
          ...state.relationships,
          quotes: state.relationships.quotes.map(quote => {
            if (quote.id === payload.id) {
              return payload;
            }

            return quote;
          })
        }
      };
    });

    builder.addCase(commitByCreditCard.fulfilled, (state, { payload }) => {
      return {
        ...state,
        relationships: {
          ...state.relationships,
          bookingsInvoices: state.relationships.bookingsInvoices.map(bookingInvoice => {
            if (bookingInvoice.providerInvoiceId === payload.providerInvoiceId) {
              return payload;
            }

            return bookingInvoice as BookingInvoiceJSON;
          })
        }
      };
    });

    builder.addCase(cancelBooking.fulfilled, (state, { payload: { data } }) => {
      return {
        ...state,
        data
      };
    });

    builder.addCase(createApprovalRequest.fulfilled, (state, { payload }) => {
      return {
        ...state,
        relationships: {
          ...state.relationships,
          approvalRequests: [...state.relationships.approvalRequests, payload]
        }
      };
    });

    builder.addCase(deleteBookingItem.fulfilled, (state, action) => {
      const { relationships, data } = action.payload;

      return {
        ...state,
        data,
        relationships
      };
    });

    builder.addCase(createBookingItems.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: payload
      };
    });

    builder.addCase(createPropositionsGroupThunk.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: payload.data,
        relationships: payload.relationships
      };
    });

    builder.addCase(createBooking.fulfilled, (state, { payload }) => {
      const { relationships, data } = payload;

      return {
        ...state,
        data,
        relationships,
        error: null,
        status: FETCH_STATUS.FETCHED
      };
    });

    builder.addCase(updateBooking.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: payload,
        error: null,
        status: FETCH_STATUS.FETCHED
      };
    });

    builder.addCase(updateBookingItem.fulfilled, (state, { payload }) => {
      const { relationships, data } = payload;

      return {
        ...state,
        data,
        relationships
      };
    });

    builder.addCase(selectPropositionThunk.fulfilled, (state, { payload }) => {
      const { relationships, data } = payload;

      return {
        ...state,
        data,
        relationships
      };
    });

    builder.addCase(declinePropositionThunk.fulfilled, (state, { payload }) => {
      const { relationships, data } = payload;

      return {
        ...state,
        data,
        relationships
      };
    });

    builder.addCase(createRegistrationThunk.fulfilled, (state, { payload }) => {
      return {
        ...state,
        data: { ...state.data, registrationId: payload.id }
      };
    });

    builder.addCase(createPropositionCommentThunk.fulfilled, (state, { payload }) => {
      return {
        ...state,
        relationships: {
          ...state.relationships,
          propositions: state.relationships.propositions.map(proposition => {
            if (proposition.id === payload.data.propositionId) {
              return { ...proposition, _count: { propositionComments: proposition._count.propositionComments + 1 }};
            }

            return proposition;
          })
        }
      };
    });

  }
});

export default slice.reducer;