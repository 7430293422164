import { HStack, Text } from '@chakra-ui/react';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BookingItemJSON } from '@jurnee/common/src/entities/BookingItem';
import { getItemAddress, getItemTimezone } from '@jurnee/common/src/utils/bookingsItems';
import { formatDate } from '@jurnee/common/src/utils/dates';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  item: BookingItemJSON;
}

abstract class BookingCardLine<T extends Props> extends React.Component<T> {

  abstract get name(): string;

  get participants(): string {
    return this.props.t('participants', { count: this.props.item.participants });
  }

  get date(): string {
    const date = new Date(this.props.item.from);

    return formatDate(date, { timeZone: getItemTimezone(this.props.item), displayTime: true, displayTimeZone: true });
  }

  get city() {
    if (this.props.item.locationType === 'VIRTUAL') {
      return this.props.t('common:virtual');
    }

    const address = getItemAddress(this.props.item);

    return address ? address.city : null;
  }

  render() {
    return (
      <HStack py={3} borderTop="1px solid" borderColor="blue.50" w="100%" justifyContent="space-between" spacing={0}>
        <Text w="40%" noOfLines={1} fontWeight={700}>{this.name}</Text>
        <Text w="30%" noOfLines={1} textAlign="left" color="gray.400">{this.date}</Text>
        <Text w="15%" noOfLines={1} textAlign="center" color="gray.400">{this.city}</Text>
        <Text w="15%" noOfLines={1} textAlign="right" color="gray.400">{this.participants}</Text>
      </HStack>
    );
  }
}

class BaseBookingItemCardLine extends BookingCardLine<Props & { item: BookingJSON['bookingsItems'][number]}> {

  get name() {
    return this.props.item.description;
  }

}

export const BookingItemCardLine = withTranslation('booking')(BaseBookingItemCardLine);