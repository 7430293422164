import * as React from 'react';
import { connect } from 'react-redux';
import { verifyEmail } from '../store/auth/auth.thunks';
import { VerifyEmailBody } from '@jurnee/common/src/dtos/auth';
import { RouteProps } from './Route';
import { RootState } from '../store/state';

interface DispatchProps {
  verifyEmail(payload: VerifyEmailBody): void;
}

type Props = RouteProps & DispatchProps;

class VerifyEmail extends React.Component<Props> {

  constructor(props: Props) {
    super(props);

    const searchParams = new URLSearchParams(this.props.location.search);
    const token = searchParams.get('token');

    if (token) {
      this.handleVerifyEmail(token);
    }
  }

  handleVerifyEmail = async (token: string) => {
    await this.props.verifyEmail({ token });
  };

  render(): JSX.Element {
    return null;
  }
}

const mapDispatchToProps: DispatchProps = {
  verifyEmail
};

export default connect<null, DispatchProps, Record<string, never>, RootState>(
  null,
  mapDispatchToProps
)(VerifyEmail);