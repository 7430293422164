import { Center, HStack, Text } from '@chakra-ui/react';
import * as React from 'react';

interface Props {
  onClick(rating: number): void;
}

interface State {
  rating: number;
}

export default class NpsRating extends React.PureComponent<Props> {

  state: State = {
    rating: 0
  };

  onClick = (rating: number) => {
    this.props.onClick(rating);

    this.setState({ ...this.state, rating });
  };

  getColors = (rating: number) => {
    if (rating <= 6) {
      return {
        color: 'red.600',
        bgColor: 'red.50',
        bgColorHover: 'red.100',
        bgColorActive: 'red.600',
      };
    }

    if (rating <= 8) {
      return {
        color:'orange.400',
        bgColor: 'orange.50',
        bgColorHover: 'orange.100',
        bgColorActive: 'orange.400',
      };
    }

    return {
      color: 'teal.400',
      bgColor: 'teal.50',
      bgColorHover: 'teal.100',
      bgColorActive: 'teal.400',
    };
  };

  get scores() {
    return [...Array(10).keys()].map(value => {
      const rating = value + 1;
      const { color, bgColor, bgColorHover, bgColorActive } = this.getColors(rating);
      const isCurrentScore = rating === this.state.rating;

      return (
        <Center
          key={rating}
          w="28px"
          h="28px"
          cursor="pointer"
          borderRadius={8}
          bgColor={isCurrentScore ? bgColorActive : bgColor}
          _hover={{ bgColor: isCurrentScore ? bgColorActive : bgColorHover }}
          onClick={() => this.onClick(rating)}
        >
          <Text fontSize="13px" fontWeight="bold" color={isCurrentScore ? 'white' : color}>{rating}</Text>
        </Center>
      );
    });
  }

  render() {
    return (
      <HStack w="100%" justifyContent="space-between">
        {this.scores}
      </HStack>
    );
  }

}