import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import './locales/config';
import { ApprovalRequests } from './pages/ApprovalRequests';
import AuthenticatedRoute from './pages/AuthenticatedRoute';
import { BookingDetails } from './pages/BookingDetails';
import Bookings from './pages/Bookings';
import CollectionDetails from './pages/CollectionDetails';
import Collections from './pages/Collections';
import CompanyBilling from './pages/Company/Billing';
import CompanyPeople from './pages/Company/People';
import { CompanySettings } from './pages/Company/Settings';
import Default from './pages/Default';
import ExperienceDetails from './pages/ExperienceDetails';
import Experiences from './pages/Experiences';
import Favorites from './pages/Favorites/Favorites';
import { Home } from './pages/Home';
import Login from './pages/Login';
import PollDetails from './pages/PollDetails';
import PublicRoute from './pages/PublicRoute';
import ResetPassword from './pages/ResetPassword';
import ReviewDetails from './pages/ReviewDetails';
import { RouteProps } from './pages/Route';
import Signup from './pages/Signup';
import { Tasks } from './pages/Tasks';
import VerifyEmail from './pages/VerifyEmail';

export default class App extends React.Component {

  makeRoute(path: string, component: React.ComponentType<RouteProps>, authenticated = true) {
    const element = React.createElement(authenticated ? AuthenticatedRoute : PublicRoute, { path, component });

    return <Route path={path} element={element} />;
  }

  render() {
    return (
      <Routes>
        {this.makeRoute('/signup', Signup, false)}
        {this.makeRoute('/login', Login, false)}
        {this.makeRoute('/reset-password', ResetPassword, false)}
        {this.makeRoute('/verify-email', VerifyEmail, false)}
        {this.makeRoute('/polls', PollDetails, false)}
        {this.makeRoute('/bookings/:cuid/reviews', ReviewDetails, false)}

        {this.makeRoute('/approval-requests', ApprovalRequests)}
        {this.makeRoute('/bookings/:bookingId', BookingDetails)}
        {this.makeRoute('/bookings', Bookings)}
        {this.makeRoute('/collections/:collectionId', CollectionDetails)}
        {this.makeRoute('/collections', Collections)}
        {this.makeRoute('/company/billing', CompanyBilling)}
        {this.makeRoute('/company/people', CompanyPeople)}
        {this.makeRoute('/company/settings', CompanySettings)}
        {this.makeRoute('/experiences/:type/:id', ExperienceDetails)}
        {this.makeRoute('/experiences/:type', Experiences)}
        {this.makeRoute('/experiences', Home)}
        {this.makeRoute('/favorites', Favorites)}
        {this.makeRoute('/tasks', Tasks)}
        {this.makeRoute('*', Default)}
      </Routes>
    );
  }

}
