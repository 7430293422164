import { Input } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange(e: ChangeEvent<HTMLInputElement>): void;
}

export function NameInput({ onChange }: Props) {
  const { t } = useTranslation('settings', { keyPrefix: 'budgets.filters.name' });

  return <Input
    size="sm"
    minW="185px"
    maxW="215px"
    type="text"
    name="nameSearch"
    placeholder={t('placeholder')}
    onChange={onChange}
  />;
}