import { HStack, VStack, useToast } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { MenuButton } from '@jurnee/common/src/components/buttons/MenuButton';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BookingParticipantJSON } from '@jurnee/common/src/entities/BookingParticipant';
import { RegistrationJSON } from '@jurnee/common/src/entities/Registration';
import { getBookingParticipantsStats, getLabeledBookingParticipants } from '@jurnee/common/src/utils/bookingParticipants';
import { sortParticipantsByLabel } from '@jurnee/common/src/utils/participants';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getRegistration } from 'src/api/registrations';
import ParticipantsChart from 'src/components/Registration/ParticipantsChart';
import InviteParticipantsModal from 'src/modals/InviteParticipantsModal';
import { ShareLinkModal } from 'src/modals/ShareLinkModal';
import { useAppDispatch } from 'src/store';
import { getBookingParticipantsFetchStatusSelector, getBookingParticipantsSelector } from 'src/store/bookingParticipants/bookingParticipants.selectors';
import { deleteBookingParticipantThunk } from 'src/store/bookingParticipants/bookingParticipants.thunks';
import { getCompanySelector } from 'src/store/company/company.selector';
import { getEmployeesByEmails, getEmployeesFetchStatusSelector } from 'src/store/employees/employees.selectors';
import { getUserBudgetBreakdownsByEmails, getUserBudgetBreakdownsFetchStatusSelector } from 'src/store/userBudgetBreakdowns/userBudgetBreakdowns.selectors';
import { getUserBudgetBreakdownsByBookingId } from 'src/store/userBudgetBreakdowns/userBudgetBreakdowns.thunks';
import { BookingParticipantsFilters, filterBookingParticipants } from './BookingParticipantsFilters';
import BookingParticipantsTable from './BookingParticipantsTable';
import RegistrationForm from './RegistrationForm';

interface Props {
  booking: BookingJSON;
}

export function Registration(props: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation(['registration', 'common']);

  const { integrations } = useSelector(getCompanySelector);
  const bookingParticipants = useSelector(getBookingParticipantsSelector);
  const bookingParticipantsFetchStatus = useSelector(getBookingParticipantsFetchStatusSelector);
  const employeesFetchStatus = useSelector(getEmployeesFetchStatusSelector);
  const userBudgetBreakdownsFetchStatus = useSelector(getUserBudgetBreakdownsFetchStatusSelector);

  const isLoading = [
    bookingParticipantsFetchStatus,
    employeesFetchStatus,
    userBudgetBreakdownsFetchStatus,
  ].some(fetchStatus => fetchStatus !== 'FETCHED');

  const participantEmails = bookingParticipants.map(({ participant }) => participant.email);
  const userBudgetBreakdowns = useSelector(getUserBudgetBreakdownsByEmails(participantEmails));
  const participantUsers = useSelector(getEmployeesByEmails(participantEmails));

  const shouldDisplayBudget = !props.booking.budgetId && userBudgetBreakdowns.some(({ total }) => total > 0);

  const [registration, setRegistration] = useState<RegistrationJSON>(null);

  const [nameInput, setNameInput] = useState<string>('');
  const [status, setStatus] = useState<string>('');

  const stats = useMemo(() => getBookingParticipantsStats(bookingParticipants), [bookingParticipants]);
  const hasParticipantsToInvite = (stats.INITIAL + stats.INVITED) > 0;
  const hasSlackIntegration = integrations.some(({ provider }) => provider === 'SLACK');

  async function fetchRegistration() {
    const registration = await getRegistration({
      bookingId: props.booking.id,
      registrationId: props.booking.registrationId
    });

    setRegistration(registration);
  }

  useEffect(() => {
    if (props.booking.registrationId) {
      fetchRegistration();
    }
  }, [props.booking.registrationId]);

  async function onRemoveBookingParticipant(bookingParticipant: BookingParticipantJSON) {
    try {
      await dispatch(
        deleteBookingParticipantThunk({
          bookingId: props.booking.id,
          bookingParticipantId: bookingParticipant.id
        })
      ).unwrap();

      toast(getSuccessToast(t('toasts.removeParticipant.success')));
      onChange();
    } catch(error) {
      toast(getErrorToast(t('toasts.removeParticipant.error'), error.message));
    }
  }

  function onChange() {
    if (!props.booking.budgetId) {
      dispatch(getUserBudgetBreakdownsByBookingId({ bookingId: props.booking.id }));
    }
  }

  const bookingsParticipantsLabeled = useMemo(
    () => getLabeledBookingParticipants(bookingParticipants, participantUsers),
    [bookingParticipants, participantUsers]
  );

  const filteredBookingParticipants = useMemo(
    () => sortParticipantsByLabel(
      filterBookingParticipants(bookingsParticipantsLabeled, { nameInput, status })
    ),
    [bookingsParticipantsLabeled, nameInput, status]
  );

  if (isLoading) {
    return <Loader h={400} />;
  }

  return (
    <HStack w="100%" alignItems="flex-start" spacing={5}>
      <VStack w="100%" alignItems="flex-start" spacing={5}>
        <BookingParticipantsFilters
          booking={props.booking}
          statusValue={status}
          onNameChange={({ target }) => setNameInput(target.value)}
          onStatusChange={({ target }) => setStatus(target.value as BookingParticipantJSON['status'])}
          onImportParticipants={onChange}
        />

        <BookingParticipantsTable
          bookingParticipants={filteredBookingParticipants}
          onRemove={onRemoveBookingParticipant}
          onChange={onChange}
          shouldDisplayBudget={shouldDisplayBudget}
        />
      </VStack>

      <VStack w="100%" minW={380} maxW={380} alignItems="stretch" spacing={5}>
        <MenuButton
          label={t('sendInvitations.button')}
          flexShrink={0}
          size="sm"
          alignSelf="flex-end"
          isDisabled={!props.booking.registrationId}
        >
          <ShareLinkModal
            link={`${window.location.origin}/bookings/${props.booking.cuid}/registrations/${props.booking.registrationId}`}
            title={t('sendInvitations.shareALink')}
            formLabel={t('common:link')}
            eventName="share_registration"
          >
            <MenuItemWrapper icon="link" label={t('sendInvitations.shareALink')} />
          </ShareLinkModal>
          {
            hasParticipantsToInvite &&
              <InviteParticipantsModal
                booking={props.booking}
                bookingParticipants={bookingParticipants}
                stats={stats}
                type="EMAIL"
              >
                <MenuItemWrapper icon="sendMail" label={t('sendInvitations.sendByEmails')} />
              </InviteParticipantsModal>
          }
          {
            hasParticipantsToInvite &&
            hasSlackIntegration &&
              <InviteParticipantsModal
                booking={props.booking}
                bookingParticipants={bookingParticipants}
                stats={stats}
                type="SLACK"
              >
                <MenuItemWrapper icon="slack" label={t('sendInvitations.sendBySlack')} />
              </InviteParticipantsModal>
          }
        </MenuButton>

        {
          bookingParticipants.length > 0 &&
            <ParticipantsChart
              bookingParticipants={bookingParticipants}
              shouldDisplayBudget={shouldDisplayBudget}
            />
        }
        <RegistrationForm booking={props.booking} registration={registration} onSave={setRegistration} />
      </VStack>
    </HStack>
  );
}