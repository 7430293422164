import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { CollectionExperience } from '@jurnee/common/src/entities/CollectionExperience';
import { getCdnImageUrl } from '@jurnee/common/src/utils/core';
import * as React from 'react';
import UpvoteButton from 'src/components/buttons/UpvoteButton';
import { isCollectionExperienceUpvoted } from 'src/utils/collectionExperience';

interface Props {
  onUpvote(collectionExperience: CollectionExperience): void;
  onDownvote(collectionExperience: CollectionExperience): void;
  collectionExperience: CollectionExperience;
}

export default class CollectionExperienceCard extends React.Component<Props> {

  onClick = () => {
    if (this.isUpvoted) {
      this.props.onDownvote(this.props.collectionExperience);
    } else {
      this.props.onUpvote(this.props.collectionExperience);
    }
  };

  get isUpvoted() {
    return isCollectionExperienceUpvoted(this.props.collectionExperience);
  }

  render() {
    const { experience, upvotes } = this.props.collectionExperience;

    return (
      <HStack spacing={8}>
        <HStack spacing={0}>
          <Box
            style={{ aspectRatio: '1.5' }}
            h={140}
            borderRadius="8px 0 0 8px"
            bg={`linear-gradient(to top, rgba(50, 10, 100, 0.5) 0%, rgba(200, 0, 200, 0.1) 100%), url('${getCdnImageUrl(experience.experiencesImages[0].image.path)}');`}
            bgPosition="center"
            bgSize="cover"
          />
          <VStack w={400} bg="white" borderRadius="0 8px 8px 0" alignSelf="stretch" p={4} alignItems="flex-start" spacing={2} border="1px solid" borderColor="blue.50" borderLeft={0}>
            <Text noOfLines={1} fontSize={22} fontWeight={700}>{experience.name}</Text>
            <Text noOfLines={3} color="gray.500" fontSize={14}>{experience.shortDescription}</Text>
          </VStack>
        </HStack>

        <UpvoteButton upvotes={upvotes} isUpvoted={this.isUpvoted} onClick={this.onClick} />
      </HStack>
    );
  }

}