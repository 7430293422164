import { EntityUpdateBody } from '@jurnee/common/src/dtos/entities';
import { Entity, EntityJSON } from '@jurnee/common/src/entities/Entity';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/company';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';

export type EntityUpdatePayload = {
  body: EntityUpdateBody;
  id: Entity['id'];
  onError: (error: Error) => void;
  onSuccess: (entity: EntityJSON) => void;
}

export const getEntities = createAsyncThunk<EntityJSON[], void, { state: RootState }>('ENTITIES_GET', async (data, thunkAPI) => {
  try {
    const { company } = thunkAPI.getState();
    const { list } = await api.getEntities(company.data.id);

    return list;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while retrieving your entities`, status: 'error' }));

    return thunkAPI.rejectWithValue(e.message);
  }
});

export const updateEntity = createAsyncThunk<EntityJSON, EntityUpdatePayload, { state: RootState }>('ENTITY_UPDATE', async ({ id, body, onSuccess, onError }, thunkAPI) => {
  try {
    const { company } = thunkAPI.getState();
    const entity = await api.updateEntity(company.data.id, id, body);

    onSuccess(entity);

    return entity;
  } catch (err) {
    onError(err);
    return thunkAPI.rejectWithValue({ error: err.message });
  }
});