import { Box, Checkbox, FormControl, FormHelperText, FormLabel, HStack, Text } from '@chakra-ui/react';
import AddressSearch from '@jurnee/common/src/components/AddressSearch';
import { PlaceAddressDTO } from '@jurnee/common/src/dtos/places';
import { useTranslation } from 'react-i18next';
import { ExperienceCategory } from 'src/utils/experiences';

interface Props {
  type: ExperienceCategory['type'];
  isVirtual: boolean;
  onCityChange(placeAddress: PlaceAddressDTO): void;
  onVirtualChange(isVirtual: boolean): void;
}

export function LocationSelector(props: Props) {
  const { t } = useTranslation('common', { keyPrefix: 'fields' });

  return (
    <Box w="100%" position="relative">
      <FormControl id="location" isRequired>
        <FormLabel>{t('location.label')}</FormLabel>
        {
          props.isVirtual &&
            <FormHelperText>
              { t('location.helperText') }
            </FormHelperText>
        }

        <Box className="react-select-small">
          <AddressSearch
            types='(cities)'
            onChange={({ placeAddress }) => props.onCityChange(placeAddress)}
          />
        </Box>
      </FormControl>

      {
        ['CUSTOM_REQUEST', 'SPECIFIC_PARTNER'].includes(props.type) &&
          <HStack spacing={2} top={0} right={0} position="absolute">
            <Text fontWeight={500}>{t('virtualEvent.label')}</Text>

            <Checkbox
              id="isVirtual"
              isChecked={props.isVirtual}
              onChange={e => props.onVirtualChange(e.target.checked)}
            />
          </HStack>
      }
    </Box>
  );
}