import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export function SsoButton() {
  const { t } = useTranslation('common');

  return (
    <Button background="black" _hover={{ background: 'gray.700' }} color="white" w="100%">
      {t('login.sso.buttons.continue')}
    </Button>
  );
}