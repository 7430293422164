import { Link, Text } from '@chakra-ui/react';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { TeamJSON } from '@jurnee/common/src/entities/Team';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { useTranslation } from 'react-i18next';
import TeamDrawer from 'src/drawers/TeamDrawer';
import { EditEmployeeDrawerLink } from './EditEmployeeDrawerLink';

interface Props {
  user: UserDetails;
  teams: TeamJSON[];
  budgets: BudgetJSON[];
}

function TeamDrawerLink({ user } : Pick<Props, 'user'>) {
  const { t } = useTranslation('people', { keyPrefix: 'teams' });

  return (
    <TeamDrawer userIds={[user.id]}>
      <Link color="blue.400">{t('createTeam')}</Link>
    </TeamDrawer>
  );
}

export function UserTeams({ user, teams, budgets }: Props) {
  const { t } = useTranslation('people');

  if (teams.length === 0) {
    return <Text><TeamDrawerLink user={user} /> {t('common:or')} <EditEmployeeDrawerLink user={user} label={t('teams.addTeam')} budgets={budgets} /></Text>;
  }

  if (teams.length < 3) {
    return teams.map(team => team.name).join(', ');
  }

  return t('teams.andOther', { team: teams[0].name, count: teams.length - 1});
}