import { VStack } from '@chakra-ui/react';
import * as React from 'react';
import { PrimaryButton } from 'src/components/buttons';

interface OwnProps {
  children: React.ReactNode;
  buttonLabel: string;
  isButtonDisabled?: boolean;
  spacing: number;
  onNext(): void;
}

export default class ItemReviewContainer extends React.Component<OwnProps> {

  render() {
    return (
      <VStack w="100%" h="100%" spacing={10} justifyContent="space-between">
        <VStack w="100%" spacing={this.props.spacing} alignItems="flex-start">
          {this.props.children}
        </VStack>

        <PrimaryButton size="md" w="100%" colorScheme="teal" onClick={this.props.onNext} isDisabled={this.props.isButtonDisabled}>{this.props.buttonLabel}</PrimaryButton>
      </VStack>
    );
  }

}