import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { signup } from './signup.thunks';

const slice = createSlice({
  name: 'signup',
  initialState: initialState.signup,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(signup.pending, () => {
      return { data: null, error: null, status: FETCH_STATUS.FETCHING };
    });

    builder.addCase(signup.fulfilled, (state, action) => {
      return { data: action.payload.user, error: null, status: FETCH_STATUS.FETCHED };
    });

    builder.addCase(signup.rejected, () => {
      return { data: null, error: true, status: FETCH_STATUS.INITIAL };
    });

  }
});

export default slice.reducer;