{
  "header": {
    "title": "My events",
    "bookingsScope": {
      "myBookings": "Only the events that I organize",
      "companyBookings": "All company's bookings"
    },
    "tabs": {
      "all": "All",
      "confirmed": "Confirmed",
      "pending": "Pending",
      "actionRequired": "Action required",
      "draft": "Draft",
      "externalCosts": "External costs",
      "past": "Past"
    }
  },
  "list": {
    "all": "All events",
    "confirmed": "Confirmed events",
    "pending": "Pending events",
    "actionRequired": "Action required",
    "draft": "Draft events",
    "externalCosts": "External costs",
    "past": "Past events"
  },
  "actionRequiredCard": {
    "description_one": "A booking requires an action on your side",
    "description_other": "{{count}} bookings requires an action on your side",
    "seeBookings_one": "See the booking ->",
    "seeBookings_other": "See the {{count}} bookings ->"
  },
  "bookingCard": {
    "organizer": "Organizer",
    "team": "Team",
    "budget": "Budget",
    "rating": "{{rating}} out of 5"
  },
  "budgetsOverview": {
    "title": "Budgets",
    "renewedOn": "Renewed on",
    "people": "People",
    "organizers": "Organizers"
  },
  "modal": {
    "newEvent": {
      "title": "Create an event",
      "description": "First give your event a name and get started\nin just a few clicks!",
      "submit": "Create my event",
      "toasts": {
        "error": "An error occurred while creating your event",
        "success": "Your event has been successfully created"
      }
    }
  },
  "emptyState": {
    "upcoming": {
      "heading": "No bookings planned",
      "description": "You don't have any bookings at the moment, but the next ones will appear here."
    },
    "past": {
      "heading": "No past bookings",
      "description": "You don't have any past bookings at the moment, but the next ones will appear here."
    },
    "calendar": {
      "heading": "No scheduled bookings",
      "description": "You don't have any scheduled bookings at the moment, but the next ones will appear here."
    }
  }
}