import { FormControl, FormLabel, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  definitionType: BudgetJSON['definitionType'];
  defaultValue: BudgetJSON['amount'];
  onChange(e: ChangeEvent<HTMLInputElement>): void;
}

export function AmountInput({ definitionType, defaultValue, onChange }: Props) {
  const { t } = useTranslation('settings', { keyPrefix: 'budgets.drawer.form.amount' });

  return (
    <FormControl isRequired>
      <FormLabel>{t('label')}</FormLabel>
      <InputGroup size="sm">
        <InputLeftElement
          pointerEvents="none"
          color="gray.400"
          px={4}
          children="€"
        />
        <Input name="amount" type="number" defaultValue={defaultValue} onChange={onChange} />
        {
          definitionType === 'INDIVIDUAL' && (
            <InputRightElement
              w={100}
              pointerEvents='none'
              color='gray.400'
              children={t('individual')}
            />
          )
        }
      </InputGroup>
    </FormControl>
  );
}