import { BudgetEntity, BudgetJSON } from '@jurnee/common/src/entities/Budget';
import UserEntity, { UserJSON } from '@jurnee/common/src/entities/User';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../state';

export function getEmployeesSelector(state: RootState) {
  return state.employees.list;
}

export function getEmployeesFetchStatusSelector(state: RootState) {
  return state.employees.status;
}

export const getEmployeeByIdSelector = createSelector(
  [state => state.employees.list, (_, id) => id],
  (employees: RootState['employees']['list'], id: UserEntity['id']) => {
    return employees.find(user => user.id === id);
  }
);

export function getEmployeeById(id: UserJSON['id']) {
  return (state: RootState) => getEmployeeByIdSelector(state, id);
}

export const getEmployeesByIdsSelector = createSelector(
  [state => state.employees.list, (_, ids: number[]) => ids],
  (employees: RootState['employees']['list'], ids: number[]) => {
    return employees.filter(user => ids.includes(user.id));
  }
);

export function getEmployeesByIds(ids: number[]) {
  return (state: RootState) => getEmployeesByIdsSelector(state, ids);
}

export const getEmployeesByBudgetIdSelector = createSelector(
  [state => state.employees.list, (_, budgetId) => budgetId],
  (employees: RootState['employees']['list'], budgetId: BudgetEntity['id']) => {
    return employees.filter(user => user.budgetId === budgetId);
  }
);

export const getEmployeesByBudgetId = (budgetId: BudgetJSON['id']) => {
  return (state: RootState) => getEmployeesByBudgetIdSelector(state, budgetId);
};

export const getEmployeesByEmailsSelector = createSelector(
  [state => state.employees.list, (_, emails) => emails],
  (employees: RootState['employees']['list'], emails: UserJSON['email']) => {
    return employees.filter(user => emails.includes(user.email));
  }
);

export function getEmployeesByEmails(emails: UserJSON['email'][]) {
  return (state: RootState) => getEmployeesByEmailsSelector(state, emails);
}