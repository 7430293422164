import { Select } from '@chakra-ui/react';
import { TaskJSON } from '@jurnee/common/src/entities/Task';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  statuses: TaskJSON['status'][];
  onChange(status: TaskJSON['status']): void;
}

export function StatusSelect(props: Props) {
  const { t } = useTranslation('tasks');

  function onChange(e: ChangeEvent<HTMLSelectElement>) {
    if (isEmpty(e.target.value)) {
      return props.onChange(null);
    }

    props.onChange(e.target.value as TaskJSON['status']);
  }

  return (
    <Select size="sm" onChange={onChange}>
      <option value="">{t('filters.status.all')}</option>
      { props.statuses.map(status => <option key={status} value={status}>{t(`status.${status}`)}</option>) }
    </Select>
  );
}