import { MultiCreatableSelect } from '@jurnee/common/src/components/Select';
import { isValidEmail } from '@jurnee/common/src/utils/strings';
import { useState } from 'react';
import { MultiValue } from 'react-select';

interface Props {
  emails: string[];
  onChange(emails: string[]): void;
}

export function EmailCreatableSelector (props: Props ) {
  const [emails, setEmails] = useState(props.emails);
  const [inputValue, setInputValue] = useState('');

  function onEmailsChange(emails: string[]) {
    setEmails(emails);
    setInputValue('');
    props.onChange(emails);
  }

  function checkInputValue() {
    if (isValidEmail(inputValue)) {
      onEmailsChange(emails.concat(inputValue));
    }
  }

  function onKeyDown(event: React.KeyboardEvent) {
    if (['Enter', 'Tab', ' '].includes(event.key)) {
      checkInputValue();
    }
  }

  function onChange(list: MultiValue<{ value: string, label: string }>) {
    onEmailsChange(list.map(({ value }) => value));
  }

  function onInputChange(newValue: string) {
    setInputValue(newValue.trim());
  }

  return (
    <MultiCreatableSelect<string>
      closeMenuOnSelect={false}
      components={ { DropdownIndicator: null } }
      inputValue={inputValue}
      isClearable={false}
      menuIsOpen={false}
      onChange={onChange}
      onInputChange={onInputChange}
      onKeyDown={onKeyDown}
      onBlur={checkInputValue}
      placeholder={'Add an email and press Enter...'}
      value={emails.map(value => ({ value, label: value }))}
    />
  );

}