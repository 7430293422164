import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { updateBooking } from '../bookings/bookings.thunks';
import { initialState } from '../state';
import { getBookingsInvoices } from './bookingsInvoices.thunks';

const slice = createSlice({
  name: 'bookingsInvoices',
  initialState: initialState.bookingsInvoices,
  reducers: { },
  extraReducers: (builder) => {

    builder.addCase(getBookingsInvoices.pending, (state) => {
      return { ...state, list: [], error: false, status: FETCH_STATUS.FETCHING };
    });

    builder.addCase(getBookingsInvoices.fulfilled, (state, action) => {
      return { ...state, list: action.payload, error: false, status: FETCH_STATUS.FETCHED };
    });

    builder.addCase(getBookingsInvoices.rejected, (state) => {
      return { ...state, list: [], error: true, status: FETCH_STATUS.INITIAL };
    });

    builder.addCase(updateBooking.fulfilled, (state, action) => {
      return {
        ...state,
        list: state.list.map(bookingInvoice => {
          if (bookingInvoice.bookingId === action.payload.id) {
            const { budget, team } = action.payload;

            return {
              ...bookingInvoice,
              booking: {
                ...bookingInvoice.booking,
                budget,
                team
              }
            };
          }

          return bookingInvoice;
        })
      };
    });
  }
});
export default slice.reducer;