import { HStack } from '@chakra-ui/react';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/buttons';
import { BudgetDrawer } from 'src/drawers/BudgetDrawer';
import { Props as DefinitionTypeProps, DefinitionTypeSelect } from './DefinitionTypeSelect';
import { NameInput } from './NameInput';

interface Props {
  definitionTypeValue: DefinitionTypeProps['value'];
  onNameChange(e: ChangeEvent<HTMLInputElement>): void;
  onDefinitionTypeChange(e: ChangeEvent<HTMLSelectElement>): void;
}

interface BudgetsFilters {
  definitionType: BudgetJSON['definitionType'] | '';
  nameInput: string;
}

export function filterBudgets(budgets: BudgetJSON[], { definitionType, nameInput }: BudgetsFilters) {
  if (!definitionType && isEmpty(nameInput)) {
    return budgets;
  }

  return budgets.filter(budget => {
    const hasType = definitionType ? budget.definitionType === definitionType : true;
    const matchName = !isEmpty(nameInput) ? new RegExp(nameInput, 'gi').test(budget.name) : true;

    return hasType && matchName;
  });
}

export function BudgetsListFilters(props: Props) {
  const { t } = useTranslation('settings', { keyPrefix: 'budgets.drawer' });

  return (
    <HStack w="100%" justifyContent="space-between" spacing={4}>
      <HStack minW={450} justifyContent="flex-start" spacing={4}>
        <NameInput onChange={props.onNameChange} />
        <DefinitionTypeSelect value={props.definitionTypeValue} onChange={props.onDefinitionTypeChange} />
      </HStack>

      <BudgetDrawer>
        <PrimaryButton size="sm">
          { t('createBudget') }
        </PrimaryButton>
      </BudgetDrawer>
    </HStack>
  );
}