import { Input } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface NameInputProps {
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
}

export function NameInput(props: NameInputProps) {
  const { t } = useTranslation('registration', { keyPrefix: 'filters.name' });

  return <Input
    size="sm"
    minW="185px"
    maxW="215px"
    type="text"
    name="nameSearch"
    placeholder={t('placeholder')}
    onChange={props.onChange}
  />;
}