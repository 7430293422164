import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import {
  addToFavoriteExperiences,
  getUserFavoriteExperiences,
  removeFromFavoriteExperiences
} from './userFavoriteExperiences.thunks';

const slice = createSlice({
  name: 'userFavoriteExperiences',
  initialState: initialState.userFavoriteExperiences,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getUserFavoriteExperiences.pending, () => {
      return { status: FETCH_STATUS.FETCHING, list: [], error: false };
    });

    builder.addCase(getUserFavoriteExperiences.fulfilled, (state, action) => {
      return { status: FETCH_STATUS.FETCHED, list: action.payload.list, error: false };
    });

    builder.addCase(getUserFavoriteExperiences.rejected, () => {
      return { status: FETCH_STATUS.INITIAL, list: [], error: true };
    });

    builder.addCase(addToFavoriteExperiences.pending, (state, action) => {
      return { ...state, list: [ ...state.list, action.meta.arg ] };
    });

    builder.addCase(removeFromFavoriteExperiences.pending, (state, action) => {
      return { ...state, list: state.list.filter(({ id }) => id !== action.meta.arg) };
    });

  }
});

export default slice.reducer;