{
  "createSurvey": "Create survey",
  "drawers": {
    "survey": {
      "addQuestion": "Add question",
      "createTitle": "Create survey",
      "emptyState": {
        "description": "Your survey must contain at least one question"
      },
      "form": {
        "name": {
          "label": "Survey name",
          "placeholder": "Survey name"
        },
        "question": {
          "label": {
            "placeholder": "Enter your question"
          },
          "questionOption": {
            "addOption": "Add option",
            "placeholder": "Enter your option"
          },
          "required": "Required",
          "type": {
            "label": {
              "CHECKBOX": "Checkboxes",
              "SELECT": "Select",
              "TEXT": "Text"
            },
            "placeholder": "Select the answer type"
          }
        }
      },
      "toasts": {
        "create": {
          "error": "An error occurred while creating survey",
          "success": "Survey created"
        },
        "update": {
          "error": "An error occurred while updating survey",
          "success": "Survey updated"
        }
      },
      "updateTitle": "Edit survey"
    },
    "surveyAnswers": {
      "noAnswers": "No answers yet",
      "toasts": {
        "export": {
          "error": "An error occurred while exporting survey results"
        },
        "fetchSurveySubmissions": {
          "error": "An error occurred while retrieving survey answers"
        }
      }
    }
  },
  "submission": {
    "form": {
      "textarea": {
        "placeholder": "Add your answer"
      },
      "select": {
        "placeholder": "Select your answer"
      }
    },
    "success": {
      "title": "Thank you",
      "text": "An email will be sent to you with a link to your answers, so you can edit them later if you wish."
    },
    "toasts": {
      "create": {
        "error": "An error occurred while submitting your answers"
      },
      "init": {
        "error": "An error occurred while retrieving the survey"
      },
      "update": {
        "error": "An error occurred while updating your answers",
        "success": "Answers updated"
      }
    }
  },
  "table": {
    "dotMenu": {
      "delete": "Delete survey",
      "deleteConfirmation": {
        "message": "Are you sure you want to delete <strong>{{surveyName}}</strong>?<br/><strong>All previously submitted answers will also be deleted.</strong>",
        "title": "Delete survey"
      },
      "shareALink": {
        "label": "Share a link",
        "description": "Your survey will no longer be editable after the first answer has been submitted. Make sure it's finished before sharing it."
      },
      "viewAnswers": "View answers"
    },
    "emptyState": {
      "description": "Surveys allow you to collect participants data before the event or gather feedback after.",
      "heading": "No surveys created"
    },
    "name": "Name",
    "questions": "Questions"
  },
  "toasts": {
    "deleteSurvey": {
      "error": "An error occurred while deleting the survey",
      "success": "Survey deleted"
    }
  }
}