import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultValue: string;
  isInvalid: boolean;
  onChange(name: string): void;
}

export function NameInput(props: Props) {
  const { t } = useTranslation('common', { keyPrefix: 'fields.eventName' });

  return (
    <FormControl id="name" isInvalid={props.isInvalid} isRequired>
      <FormLabel>{t('label')}</FormLabel>
      <Input
        name="name"
        size="sm"
        defaultValue={props.defaultValue}
        onChange={e => props.onChange(e.target.value)}
        placeholder={t('placeholder')}
      />
      <FormErrorMessage>{t('error')}</FormErrorMessage>
    </FormControl>
  );
}