import { Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  date: string;
}

export function BudgetRenewalDate({ date }: Props) {
  const { t } = useTranslation('bookings', { keyPrefix: 'budgetsOverview' });

  return (
    <VStack spacing={2} alignItems="flex-start">
      <Text lineHeight="14px" fontWeight={700}>{t('renewedOn')}</Text>

      <Text lineHeight="14px" color="gray.400">{date}</Text>
    </VStack>
  );
}