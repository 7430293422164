import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { EmptyState } from '@jurnee/common/src/components/EmptyState';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { TaskJSON } from '@jurnee/common/src/entities/Task';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { sortByDate } from '@jurnee/common/src/utils/arrays';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskRow } from './TaskRow';

interface Props {
  booking: BookingJSON;
  tasks: TaskJSON[];
  organizers: Pick<UserJSON, 'id' | 'firstName' | 'lastName' | 'email'>[];
  openedTaskId: number;
  hasFilters: boolean;
  onDelete(task: TaskJSON): void;
  onUpdate(task: TaskJSON): void;
}

export function TasksTable({ booking, tasks, organizers, openedTaskId, hasFilters, onDelete, onUpdate }: Props) {
  const { t } = useTranslation(['tasks', 'common']);

  const sortedTasks = useMemo(() => sortByDate(tasks, 'dueAt'), [tasks]);

  const rows = tasks.length === 0 ? (
    <Tr>
      <Td p={16} colSpan={6}>
        <EmptyState
          icon="listSearch"
          heading={t('table.emptyState.heading', { context: hasFilters ? 'filters' : null })}
          description={t('table.emptyState.description', { context: hasFilters ? 'filters' : null })}
        />
      </Td>
    </Tr>
  ) : (
    sortedTasks.map(task =>
      <TaskRow
        key={task.id}
        booking={booking}
        task={task}
        organizers={organizers}
        defaultIsOpen={openedTaskId === task.id}
        onDelete={onDelete}
        onUpdate={onUpdate}
      />
    )
  );

  return (
    <TableContainer w="100%">
      <Table>
        <Thead>
          <Tr>
            <Th w="30%">{t('table.name')}</Th>
            <Th w="15%">{t('table.dueDate')}</Th>
            <Th w="15%">{t('table.priority')}</Th>
            <Th w="25%">{t('table.owner')}</Th>
            <Th w="15%">{t('table.status')}</Th>
            <Th w="32px"></Th>
          </Tr>
        </Thead>
        <Tbody>{rows}</Tbody>
      </Table>
    </TableContainer>
  );
}