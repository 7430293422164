import { Grid, useToast } from '@chakra-ui/react';
import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { Loader } from '@jurnee/common/src/components/Loader';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import api from 'src/api/budgets';
import EmptyState from 'src/components/EmptyState';
import { PrimaryButton } from 'src/components/buttons';
import { BudgetDrawer } from 'src/drawers/BudgetDrawer';
import { useAppDispatch } from 'src/store';
import { budgetsSelectors, getBudgetsFetchStatusSelector } from 'src/store/budgets/budgets.selectors';
import { getBudgets } from 'src/store/budgets/budgets.thunks';
import { getEmployeesFetchStatusSelector } from 'src/store/employees/employees.selectors';
import { getEmployees } from 'src/store/employees/employees.thunks';
import { BudgetsList } from './BudgetsList';
import { BudgetsOverview } from './BudgetsOverview';

function NoBudgetsCard() {
  const { t } = useTranslation('settings', { keyPrefix: 'budgets' });

  return (
    <EmptyState
      imagePath="/assets/illustrations/budget.svg"
      heading={t('emptyState.heading')}
      description={t('emptyState.description')}
    >
      <BudgetDrawer>
        <PrimaryButton size="sm">{t('drawer.createBudget')}</PrimaryButton>
      </BudgetDrawer>
    </EmptyState>
  );
}

export function Budgets() {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation('settings', { keyPrefix: '' });

  const budgets = useSelector(budgetsSelectors.selectAll);
  const budgetsStatus = useSelector(getBudgetsFetchStatusSelector);
  const employeesStatus = useSelector(getEmployeesFetchStatusSelector);

  const [yearlyBudget, setYearlyBudget] = useState(null);

  async function getYearlyBudget() {
    try {
      const yearlyBudget = await api.getYearlyBudget();
      setYearlyBudget(yearlyBudget);
    } catch (error) {
      toast(getErrorToast(t('budgets.toasts.yearlyBudget.error')));
    }
  }

  useEffect(() => {
    dispatch(getBudgets());
    dispatch(getEmployees());
  }, []);

  useEffect(() => {
    getYearlyBudget();
  }, [budgets]);

  if (budgetsStatus !== FETCH_STATUS.FETCHED) {
    return <Loader h={400} />;
  }

  if (employeesStatus !== FETCH_STATUS.FETCHED) {
    return <Loader h={400} />;
  }

  if (!yearlyBudget) {
    return <Loader h={400} />;
  }

  return (
    <Grid templateColumns='256px 1fr' gap={8} alignItems="flex-start">
      <BudgetsOverview yearlyBudget={yearlyBudget} />
      {
        budgets.length > 0 ?
          <BudgetsList budgets={budgets} /> :
          <NoBudgetsCard />
      }
    </Grid>
  );
}