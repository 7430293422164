import { FormControl, FormLabel } from '@chakra-ui/react';
import { FileInput } from '@jurnee/common/src/components/FileInput';
import { DOCUMENT_CONTENT_TYPES } from '@jurnee/common/src/constants/ContentTypes';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange(file: File): void;
}

export function QuoteFileInput(props: Props) {
  const { t } = useTranslation(['booking', 'common']);

  return (
    <FormControl id="partnerDocument" isRequired>
      <FormLabel>{t('modals.payViaJurnee.partnerDocument.label')}</FormLabel>

      <FileInput
        label={t('common:fields.fileDocument.label')}
        sublabel={t('common:fields.fileDocument.documentTypes')}
        contentTypes={DOCUMENT_CONTENT_TYPES}
        imageMaxWidth={1200}
        onChange={props.onChange}
        onRemove={() => props.onChange(null)}
      />
    </FormControl>
  );
}