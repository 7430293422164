import { HStack, useToast, VStack } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { TaskJSON } from '@jurnee/common/src/entities/Task';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { PrimaryButton } from 'src/components/buttons';
import { filterTasks, hasFilters, TasksFilters } from 'src/components/TasksFilters';
import { TaskDrawer } from 'src/drawers/TaskDrawer';
import { useAppDispatch } from 'src/store';
import { deleteTaskThunk } from 'src/store/tasks/tasks.thunks';
import { TasksTable } from './TasksTable';

interface Props {
  booking: BookingJSON;
  tasks: TaskJSON[];
  organizers: Pick<UserJSON, 'id' | 'firstName' | 'lastName' | 'email'>[];
  isLoading: boolean;
  onChange(tasks: TaskJSON[]): void;
}

export function Tasks({ booking, tasks, organizers, isLoading, onChange }: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation('tasks');

  const [searchParams] = useSearchParams();
  const openedTaskId = Number(searchParams.get('taskId'));

  const assignees = useMemo(() => {
    const ids = [...new Set([...tasks.map(task => task.assigneeId)])];
    return organizers.filter(user => ids.includes(user.id));
  }, [tasks, organizers]);

  const [filters, setFilters] = useState<TasksFilters>({
    name: null,
    assigneeId: null,
    bookingId: null,
    priority: null,
    status: null
  });

  const filteredTasks = useMemo(() => hasFilters(filters) ? filterTasks(tasks, filters) : tasks, [tasks, filters]);

  async function onDelete(task: TaskJSON) {
    try {
      const deletedTask = await dispatch(deleteTaskThunk({ bookingId: booking.id, taskId: task.id })).unwrap();

      onChange(tasks.filter(task => task.id !== deletedTask.id));

      toast(getSuccessToast(t('toasts.deleteTask.success')));
    } catch(error) {
      toast(getErrorToast(t('toasts.deleteTask.error'), error.message));
    }
  }

  function onUpdate(updatedTask: TaskJSON) {
    onChange(tasks.map(task => task.id === updatedTask.id ? updatedTask : task));
  }

  if (isLoading) {
    return <Loader h={400} />;
  }

  return (
    <VStack w="100%" spacing={5}>
      <HStack w="100%" justifyContent="space-between">
        <TasksFilters
          maxW="880px"
          filters={filters}
          assignees={assignees}
          statuses={['TODO', 'IN_PROGRESS', 'DONE', 'CANCELED']}
          onChange={setFilters}
          hasPriorityFilter
        />

        <TaskDrawer key={new Date().getTime()} booking={booking} organizers={organizers} onUpsert={task => onChange([...tasks, task])}>
          <PrimaryButton size="sm" colorScheme="black" alignSelf="flex-end" flexShrink={0}>
            { t('createTask') }
          </PrimaryButton>
        </TaskDrawer>
      </HStack>

      <TasksTable
        booking={booking}
        tasks={filteredTasks}
        organizers={organizers}
        openedTaskId={openedTaskId}
        hasFilters={hasFilters(filters)}
        onDelete={onDelete}
        onUpdate={onUpdate}
      />
    </VStack>
  );
}