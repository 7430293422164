import { FormControl, FormLabel, HStack, Heading, Input, Select, Text, VStack, useToast } from '@chakra-ui/react';
import { OidcConfigurationCreate } from '@jurnee/common/src/entities/OidcConfiguration';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PrimaryButton } from 'src/components/buttons';
import { useAppDispatch } from 'src/store';
import { getCompanySelector } from 'src/store/company/company.selector';
import { createOidcConfiguration, updateOidcConfiguration } from 'src/store/oidcConfiguration/oidcConfiguration.thunks';

export default function Sso() {
  const { t } = useTranslation(['settings', 'common']);
  const dispatch = useAppDispatch();
  const toast = useToast();

  const { oidcConfiguration } = useSelector(getCompanySelector);

  const [clientId, setClientId] = useState(oidcConfiguration ? oidcConfiguration.clientId : '');
  const [clientSecret, setClientSecret] = useState('');
  const [issuer, setIssuer] = useState(oidcConfiguration ? oidcConfiguration.issuer : '');
  const [isLoading, setIsLoading] = useState(false);

  const payload: OidcConfigurationCreate = {
    clientId,
    clientSecret,
    issuer,
    provider: 'OKTA'
  };

  async function create() {
    try {
      await dispatch(createOidcConfiguration(payload)).unwrap();
      toast(getSuccessToast(t('sso.toasts.create.success')));
    } catch (err) {
      toast(getErrorToast(err ?? t('sso.toasts.create.error')));
    }
  }

  async function update() {
    try {
      await dispatch(updateOidcConfiguration({ id: oidcConfiguration.id, ...payload })).unwrap();
      toast(getSuccessToast(t('sso.toasts.update.success')));
    } catch (err) {
      toast(getErrorToast(err ?? t('sso.toasts.update.error')));
    }
  }

  async function onSave() {
    setIsLoading(true);
    oidcConfiguration ? update() : create();
    setIsLoading(false);
  }

  return (
    <HStack w="100%" margin="0 auto" spacing={8} alignItems="self-start">
      <VStack bg="white" borderRadius={4} border="1px solid" borderColor="blue.50" p={5} spacing={5} w="60%" alignItems="flex-start">
        <Heading size="md">{t('sso.heading')}</Heading>
        <FormControl isRequired>
          <FormLabel>{t('sso.form.clientId')}</FormLabel>
          <Input type='text' value={clientId} onChange={(e) => setClientId(e.target.value)} size="sm" />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{t('sso.form.clientSecret')}</FormLabel>
          <Input type='text' value={clientSecret} onChange={(e) => setClientSecret(e.target.value)} size="sm" />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{t('sso.form.issuer')}</FormLabel>
          <Input type='text' value={issuer} onChange={(e) => setIssuer(e.target.value)} size="sm" />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>{t('sso.form.provider')}</FormLabel>
          <Select size="sm" >
            <option value="OKTA">Okta</option>
          </Select>
        </FormControl>
        <PrimaryButton colorScheme="teal" isLoading={isLoading} onClick={onSave} size="sm">
          {t('common:buttons.save')}
        </PrimaryButton>
      </VStack>
      <VStack bg="white" borderRadius={4} border="1px solid" borderColor="blue.50" p={5} spacing={5} w="40%" alignItems="self-start">
        <Heading size="md">{t('sso.help.heading')}</Heading>
        <Text color="gray.500">{t('sso.help.text')}</Text>
        <Link to="https://jurneeio.notion.site/Single-Sign-On-ac0b1860e99b4ffcac607fcbc2b50b1f" target="_blank">
          <PrimaryButton size="sm">{t('sso.help.button')}</PrimaryButton>
        </Link>
      </VStack>
    </HStack>
  );

}