import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../state';

export interface TrackEventOptions {
  name: string;
  properties?: Record<string, string | number | boolean >;
}

export const trackEvent = createAsyncThunk<null, TrackEventOptions, { state: RootState }>('ANALYTICS_TRACK', async (options, thunkAPI) => {
  try {
    window.analytics.track(options.name, options.properties || {});
  } catch (e) {
    return thunkAPI.rejectWithValue(null);
  }
});
