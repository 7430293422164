import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { UserBudgetBreakdownJSON } from '@jurnee/common/src/entities/UserBudgetBreakdown';
import { List } from '@jurnee/common/src/serializers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import bookingsApi from '../../api/bookings';
import budgetsApi from '../../api/budgets';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';

export const getUserBudgetBreakdownsByBudgetId = createAsyncThunk<List<UserBudgetBreakdownJSON, never>, { budgetId: BudgetJSON['id'] }, { state: RootState }>('GET_BUDGET_USER_BUDGET_BREAKDOWNS', async (args, thunkAPI) => {
  try {
    const userBudgetBreakdowns = await budgetsApi.getUserBudgetBreakdownsByBudgetId(args.budgetId);
    return userBudgetBreakdowns;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while retrieving users budgets`, status: 'error' }));
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const getUserBudgetBreakdownsByBookingId = createAsyncThunk<List<UserBudgetBreakdownJSON, never>, { bookingId: BookingJSON['id'] }, { state: RootState }>('GET_BOOKING_USER_BUDGET_BREAKDOWNS', async (args, thunkAPI) => {
  try {
    const userBudgetBreakdowns = await bookingsApi.getUserBudgetBreakdownsByBookingId(args.bookingId);
    return userBudgetBreakdowns;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while retrieving users budgets`, status: 'error' }));
    return thunkAPI.rejectWithValue(e.message);
  }
});