{
  "buttons": {
    "addCost": "Add an external cost"
  },
  "dotMenu": {
    "delete": "Delete cost",
    "deleteConfirmation": {
      "message": "Are you sure you want to delete the <strong>{{description}}</strong> cost?",
      "title": "Delete cost"
    },
    "edit": "Edit cost",
    "openDocument": "Open document"
  },
  "drawers": {
    "create": {
      "title": "Add an external cost",
      "toasts": {
        "error": "An error occured while adding your cost",
        "success": "Cost successfully added"
      }
    },
    "edit": {
      "title": "Edit cost",
      "toasts": {
        "error": "An error occured while editing your cost",
        "success": "Cost successfully edited"
      }
    },
    "fields": {
      "amount": {
        "label": "Amount"
      },
      "description": {
        "label": "Description",
        "placeholder": "Ex: Catering, Diner restaurant, Transport…"
      },
      "document": {
        "label": "Add a document"
      },
      "eventDate": {
        "label": "Event date"
      },
      "paymentDate": {
        "label": "Payment date"
      },
      "supplier": {
        "label": "Supplier",
        "placeholder": "Provider name"
      },
      "user": {
        "label": "Owner",
        "placeholder": "Select user"
      }
    }
  },
  "emptyState": {
    "description": "By adding external expenses to this event, you can determine its overall cost and keep your budgets up-to-date.",
    "heading": "No external costs"
  },
  "table": {
    "amount": "Amount",
    "description": "Description",
    "eventDate": "Event date",
    "paymentDate": "Payment date",
    "owner": "Owner",
    "supplier": "Provider"
  },
  "toasts": {
    "delete": {
      "error": "An error occurred while deleting cost",
      "success": "Cost deleted"
    }
  },
  "totalAmount": "<itemsCount>{{itemsCount}}</itemsCount> external spends"
}