import { BudgetCreateBody, BudgetUpdateBody } from '@jurnee/common/src/dtos/budgets';
import { BudgetJSON, BudgetRelationshipsJSON } from '@jurnee/common/src/entities/Budget';
import { Expand, List } from '@jurnee/common/src/serializers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/budgets';
import userApi from '../../api/user';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';

export interface BudgetCreatePayload {
  data: BudgetCreateBody;
}

export interface BudgetUpdatePayload {
  id: BudgetJSON['id'];
  data: BudgetUpdateBody;
}

export interface BudgetDeletePayload {
  id: BudgetJSON['id'];
}

export const getBudgets = createAsyncThunk<List<BudgetJSON, BudgetRelationshipsJSON>, null, { state: RootState }>('BUDGETS_GET', async (_, thunkAPI) => {
  try {
    const budgets = await api.getBudgets();
    return budgets;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while retrieving your budgets`, status: 'error' }));
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const getUserBudgets = createAsyncThunk<List<BudgetJSON, BudgetRelationshipsJSON>, null, { state: RootState }>('GET_USER_BUDGETS', async (_, thunkAPI) => {
  try {
    const { user } = thunkAPI.getState();
    const list = await userApi.getBudgets(user.id);

    return list;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while retrieving your budgets`, status: 'error' }));
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const createBudget = createAsyncThunk<Expand<BudgetJSON, BudgetRelationshipsJSON>, BudgetCreatePayload, { state: RootState }>('BUDGETS_CREATE', async ({ data }, thunkAPI) => {
  try {
    const budget = await api.createBudget(data);
    return budget;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.message);
  }
});

export const updateBudget = createAsyncThunk<Expand<BudgetJSON, BudgetRelationshipsJSON>, BudgetUpdatePayload, { state: RootState }>('BUDGETS_UPDATE', async ({ data, id }, thunkAPI) => {
  try {
    const budget = await api.updateBudget(data, id);
    return budget;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.message);
  }
});

export const deleteBudget = createAsyncThunk<BudgetJSON, BudgetDeletePayload, { state: RootState }>('BUDGETS_DELETE', async ({ id }, thunkAPI) => {
  try {
    const budget = await api.deleteBudget(id);
    return budget;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.message);
  }
});