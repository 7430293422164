import { HStack, Spacer, Text } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { BookingParticipantJSON } from '@jurnee/common/src/entities/BookingParticipant';
import { useTranslation } from 'react-i18next';

interface Props {
  status: BookingParticipantJSON['status'];
  isUpdatable: boolean;
  onDecline(): void;
  onAccept(): void;
}

export function RegistrationFormResponse({ onDecline, onAccept, status, isUpdatable }: Props) {
  const { t } = useTranslation('registration');
  const color = status === 'ACCEPTED' ? 'teal' : 'pink';
  const icon = status === 'ACCEPTED' ? 'squareRoundedCheck' : 'squareRoundedX';
  const onClick = status === 'ACCEPTED' ? onDecline : onAccept;

  if (!['ACCEPTED', 'DECLINED'].includes(status)) {
    return null;
  }

  return (
    <HStack w="100%" bg={`${color}.50`} px={2} h="32px" spacing={2} alignItems="center" borderRadius={4}>
      <Icon icon={icon} color={`${color}.400`} size={5} />
      <Text color={`${color}.400`} lineHeight="14px">{t(status.toLowerCase())}</Text>
      <Spacer />
      <Text color={`${color}.400`} lineHeight="14px" _hover={{ cursor: 'pointer', color: `${color}.500` }} onClick={onClick}>
        { isUpdatable && t('buttons.changeMind') }
      </Text>
    </HStack>
  );
}