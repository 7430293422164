import BookingParticipantEntity, { BookingParticipantJSON } from '../entities/BookingParticipant';
import UserEntity, { UserDetails } from '../entities/User';

export type BookingParticipantsStats = Record<BookingParticipantJSON['status'], number>;

export function getBookingParticipantsStats(bookingParticipants: BookingParticipantJSON[]): BookingParticipantsStats {
  return bookingParticipants.reduce((stats, bookingParticipant) => {
    stats[bookingParticipant.status] +=1;
    return stats;
  }, { ACCEPTED: 0, ATTENDED: 0, DECLINED: 0, INITIAL: 0, INVITED: 0 });
}

export function isRegistered({ status }: BookingParticipantEntity) {
  return ['ACCEPTED', 'ATTENDED'].includes(status);
}

export function getLabeledBookingParticipants(bookingParticipants: BookingParticipantJSON[], users: UserDetails[]) {
  return bookingParticipants.map(bookingParticipant => {
    const user = users.find(user => user.email === bookingParticipant.participant.email);

    if (!user) {
      return bookingParticipant;
    }

    return {
      ...bookingParticipant,
      participant: {
        ...bookingParticipant.participant,
        firstName: user.firstName,
        lastName: user.lastName
      }
    };
  });
}

export function getRegisteredParticipantsCountByBudgetId(bookingParticipants: BookingParticipantEntity[], users: UserEntity[]): Record<string, number> {
  const count: Record<string, number> = { total: 0 };

  for (const bookingParticipant of bookingParticipants) {
    const user = users.find((user) => user.email === bookingParticipant.participant.email);

    if (user && typeof user.budgetId === 'number' && isRegistered(bookingParticipant)) {
      count.total += 1;
      count[user.budgetId] = count[user.budgetId] ? count[user.budgetId] + 1 : 1;
    }
  }

  return count;
}