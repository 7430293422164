import { Link } from '@chakra-ui/react';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { UserDetails } from '@jurnee/common/src/entities/User';
import EditEmployeeDrawer from 'src/drawers/EditEmployeeDrawer';

interface Props {
  label: string;
  user: UserDetails;
  budgets: BudgetJSON[];
}

export function EditEmployeeDrawerLink({ user, label, budgets }: Props) {
  return (
    <EditEmployeeDrawer user={user} budgets={budgets}>
      <Link color="blue.400">{label}</Link>
    </EditEmployeeDrawer>
  );
}