import { HStack, Spacer, Text } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/buttons';

interface Props {
  count: number;
  onClick(): void;
}

export default function ActionRequiredCard(props: Props) {
  const { t } = useTranslation('bookings');

  return (
    <HStack
      mb={2}
      w="100%"
      border="1px solid"
      borderColor="orange.400"
      borderRadius={4}
      bgColor="white"
      p={5}
      spacing={3}
    >
      <Icon icon="warning" color="orange.400" />

      <Text fontSize={14}>
        { t('actionRequiredCard.description', { count: props.count }) }
      </Text>

      <Spacer />

      <PrimaryButton colorScheme='orange' size="sm" fontWeight={500} onClick={props.onClick}>
        { t('actionRequiredCard.seeBookings', { count: props.count }) }
      </PrimaryButton>
    </HStack>
  );

}