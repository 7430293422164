import { Text } from '@chakra-ui/react';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { useTranslation } from 'react-i18next';

interface Props {
  budget: BudgetJSON | null;
}

export function UserIndividualBudget({ budget }: Props) {
  const { t } = useTranslation('people', { keyPrefix: 'individualBudget' });

  if (!budget) {
    return <Text color="gray.400">{t('emptyState')}</Text>;
  }

  return budget.name;
}