import { StackProps, VStack } from '@chakra-ui/react';

interface Props {
  children: React.ReactNode
}

export default function RemovableItemsList(props: Props & StackProps) {
  const { children, ...inheritedProps } = props;

  return (
    <VStack
      w="100%"
      paddingX={2}
      paddingY={1}
      spacing={0}
      borderRadius={4}
      border="1px solid"
      borderColor="gray.200"
      bg="white"
      {...inheritedProps}
    >
      { children }
    </VStack>
  );
}