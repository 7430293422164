import { BookingInvoiceJSON, BookingsInvoicesSearchParams } from '@jurnee/common/src/entities/BookingInvoice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import bookingsInvoicesApi from '../../api/bookingsInvoices';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';

export type ProviderEntityIdGetPayload = {
  providerInvoiceId: BookingInvoiceJSON['providerInvoiceId'];
}

export const getBookingsInvoices = createAsyncThunk<BookingInvoiceJSON[], BookingsInvoicesSearchParams, { state: RootState }>('BOOKINGS_INVOICES_GET', async (args, thunkAPI) => {
  try {
    const { list } = await bookingsInvoicesApi.getBookingsInvoices(args);

    return list;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while retrieving your invoices`, status: 'error' }));

    return thunkAPI.rejectWithValue(e.message);
  }
});