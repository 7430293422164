import { Box } from '@chakra-ui/react';
import { SearchSelect } from '@jurnee/common/src/components/Select';
import { TeamJSON } from '@jurnee/common/src/entities/Team';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  teams: TeamJSON[];
  teamIds: number[];
  onChange({ value: { id } }: { value: TeamJSON }): void;
}

function getOptions({ teams, teamIds }: Omit<Props, 'onChange'>) {
  const filteredTeams = teams.filter(({ id }) => teamIds.every(teamId => teamId !== id));

  return sortAlphabeticallyBy(filteredTeams.map(team => ({ value: team, label: team.name })), 'label');
}

export function TeamsSearchSelect({ onChange, ...props }: Props) {
  const { t } = useTranslation('employees', { keyPrefix: 'drawer.form.teams' });

  const options = useMemo(() => getOptions(props), [props]);

  return (
    <Box w="100%" className="react-select-small">
      <SearchSelect<TeamJSON>
        options={options}
        closeMenuOnSelect={false}
        menuPlacement="top"
        openMenuOnFocus={true}
        maxMenuHeight={200}
        placeholder={t('placeholder')}
        value={null}
        onChange={onChange}
      />
    </Box>
  );
}