import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { getExperiences } from './experiences.thunks';

const slice = createSlice({
  name: 'experiences',
  initialState: initialState.experiences,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getExperiences.pending, () => {
      return { list: [], error: null, status: FETCH_STATUS.FETCHING };
    });

    builder.addCase(getExperiences.fulfilled, (_state, action) => {
      return { list: action.payload.list, error: null, status: FETCH_STATUS.FETCHED };
    });

    builder.addCase(getExperiences.rejected, () => {
      return { list: [], error: true, status: FETCH_STATUS.INITIAL };
    });

  }
});

export default slice.reducer;