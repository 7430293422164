import { api } from '@jurnee/common/src/browser/Api';
import { BookingCreateBody } from '@jurnee/common/src/dtos/bookings';
import { Booking, BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { User } from '@jurnee/common/src/entities/User';
import { Expand, List } from '@jurnee/common/src/serializers';

export default {
  getBookingsByUser(userId: User['id'], params: URLSearchParams): Promise<List<BookingJSON, BookingRelationshipsJSON>> {
    return api.get(`users/${userId}/bookings?${params.toString()}`);
  },
  getBooking(userId: User['id'], bookingId: Booking['id']): Promise<Expand<BookingJSON, BookingRelationshipsJSON>> {
    return api.get(`users/${userId}/bookings/${bookingId}`);
  },
  createBooking(userId: User['id'], data: BookingCreateBody): Promise<Expand<BookingJSON, BookingRelationshipsJSON>> {
    return api.post(`users/${userId}/bookings`, data);
  },
};