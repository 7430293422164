import { HStack, Text, VStack } from '@chakra-ui/react';
import { Currency } from '@jurnee/common/src/entities/Currency';
import { useTranslation } from 'react-i18next';
import ProgressBar from 'src/components/Chart/ProgressBar';
import { LegendRow } from 'src/components/Chart/ProgressBarLegend/LegendRow';
import ProgressBarLegend from '../../Chart/ProgressBarLegend';
import { colors } from '../BudgetChart';

interface Props {
  budgetName: string;
  remaining: number;
  booking: number;
  pending: number;
  spent: number;
  total: number;
  currency: Currency;
}

export function BudgetGlobalCard(props: Props) {
  const { t } = useTranslation(['booking', 'budgets']);

  const segments = [
    { label: t('budgets:remaining'), value: props.remaining, color: colors.remaining },
    { label: t('budgets:booking'), value: props.booking, color: colors.booking },
    { label: t('budgets:pending'), value: props.pending, color: colors.pending },
    { label: t('budgets:spent'), value: props.spent, color: colors.spent }
  ];

  return (
    <VStack w="100%" alignItems="flex-start" spacing={3}>
      <HStack w="100%" justifyContent="space-between">
        <Text fontWeight={700} lineHeight="14px">{t('budget.title')}</Text>
        <Text color="gray.400" lineHeight="14px">{props.budgetName}</Text>
      </HStack>

      <ProgressBar segments={segments} />
      {
        !props.total ?
          <LegendRow label={t('budgets:noBudget')} color={colors.noBudget} /> :
          <ProgressBarLegend segments={segments} currency={props.currency} />
      }
    </VStack>
  );
}