import { Flex, Heading, useToast, VStack } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { TaskJSON } from '@jurnee/common/src/entities/Task';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { sortByDate } from '@jurnee/common/src/utils/arrays';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/store';
import { deleteTaskThunk } from 'src/store/tasks/tasks.thunks';
import { getUserSelector } from 'src/store/user/user.selectors';
import api from '../../api/user';
import { filterTasks, hasFilters, TasksFilters } from '../../components/TasksFilters';
import { TasksTable } from './TasksTable';

export interface GroupedTask {
  task: TaskJSON;
  booking: BookingJSON;
}

export function Tasks() {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('tasks');

  const user = useSelector(getUserSelector);

  const [tasks, setTasks] = useState<TaskJSON[]>([]);
  const [bookings, setBookings] = useState<BookingJSON[]>([]);
  const [organizers, setOrganizers] = useState<UserJSON[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const assignees = useMemo(() => {
    const ids = [...new Set([...tasks.map(task => task.assigneeId)])];
    return organizers.filter(user => ids.includes(user.id));
  }, [tasks, organizers]);

  const [filters, setFilters] = useState<TasksFilters>({
    name: null,
    assigneeId: null,
    bookingId: null,
    priority: null,
    status: null
  });

  const groupedTasks = useMemo(
    () => {
      const filteredTasks = hasFilters(filters) ? filterTasks(tasks, filters) : tasks;

      return sortByDate(filteredTasks, 'dueAt').map(task => ({
        task,
        booking: bookings.find(({ id }) => id === task.bookingId)
      }));
    },
    [tasks, bookings, filters]
  );

  async function fetchTasks() {
    try {
      const { list, relationships: { bookings, users } } = await api.getUserTasks(user.id);
      setTasks(list);
      setBookings(bookings);
      setOrganizers(users);
    } catch(error) {
      toast(getErrorToast(t('toasts.fetchTasks.error')));
    }

    setIsLoading(false);
  }

  useEffect(() => {
    fetchTasks();
  }, []);

  async function onDelete(task: TaskJSON) {
    try {
      const deletedTask = await dispatch(deleteTaskThunk({
        bookingId: task.bookingId,
        taskId: task.id
      })).unwrap();

      setTasks(tasks.filter(task => task.id !== deletedTask.id));
      toast(getSuccessToast(t('toasts.deleteTask.success')));
    } catch(error) {
      toast(getErrorToast(t('toasts.deleteTask.error'), error.message));
    }
  }

  function onUpdate(updatedTask: TaskJSON) {
    setTasks(tasks.map(task => task.id === updatedTask.id ? updatedTask : task));
  }

  return (
    <main>
      <Heading p={8} bg="white" borderBottom="1px solid" borderColor="gray.200">
        { t('heading') }
      </Heading>

      <Flex flexDirection="column">
        {
          isLoading ? (
            <Loader h={400} />
          ) : (
            <VStack w="100%" maxW="1184px" p={8} alignSelf="center" spacing={5}>
              <TasksFilters
                filters={filters}
                assignees={assignees}
                statuses={['TODO', 'IN_PROGRESS']}
                bookings={bookings}
                onChange={setFilters}
              />

              <TasksTable
                tasks={groupedTasks}
                organizers={organizers}
                hasFilters={hasFilters(filters)}
                onDelete={onDelete}
                onUpdate={onUpdate}
              />
            </VStack>
          )
        }
      </Flex>
    </main>
  );
}