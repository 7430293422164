import { BookingStatus as BookingStatusType } from '@jurnee/common/src/entities/Booking';
import { withTranslation } from 'react-i18next';
import BaseStatusBadge, { StatusBadgeProps } from './index';

interface Props extends StatusBadgeProps {
  status: BookingStatusType;
}

class StatusBadge extends BaseStatusBadge<Props> {

  get label() {
    return this.props.t(this.props.status);
  }

  get variant() {
    switch (this.props.status) {
    case 'AWAITING_APPROVAL':
    case 'AWAITING_QUOTE':
      return 'orange';
    case 'CANCELED':
    case 'DRAFT':
      return 'gray';
    case 'CONFIRMED':
    case 'EXTERNAL_COSTS':
      return 'teal';
    case 'CONFIRMING_AVAILABILITY':
    case 'PROCESSING':
      return 'blue';
    case 'FINISHED':
      return 'black';
    case 'PROPOSITION_READY':
      return 'pink';
    default:
      return 'gray';
    }
  }

}

export const BookingStatus = withTranslation('booking', { keyPrefix: 'status' })(StatusBadge);