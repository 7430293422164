import { Box, Button, ButtonProps, Text } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import * as React from 'react';

interface Props extends ButtonProps {
  upvotes: number;
  isUpvoted: boolean;
}

export default class UpvoteButton extends React.PureComponent<Props> {

  get background() {
    return this.props.isUpvoted ? 'teal.400' : 'teal.100';
  }

  get backgroundHover() {
    return this.props.isUpvoted ? 'teal.500' : 'teal.200';
  }

  get color() {
    return this.props.isUpvoted ? 'white' : 'teal.500';
  }

  render() {
    const { isUpvoted, upvotes, ...existingProps } = this.props;

    return (
      <Button pl={2} bg={this.background} color={this.color} _hover={{ background: this.backgroundHover }} _active={{ background: this.backgroundHover }} borderRadius={8} {...existingProps}>
        <Icon icon="upvote" color={this.color}/>
        Upvote
        <Box h="100%" w="1px" bg="white" mx={4}/>
        <Text minW={4}>{upvotes}</Text>
      </Button>
    );
  }

}