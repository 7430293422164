import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { getExperienceStats } from './experienceDetailsStats.thunks';

const slice = createSlice({
  name: 'experienceDetailsStats',
  initialState: initialState.experienceDetailsStats,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getExperienceStats.pending, () => {
      return { data: null, error: null, status: FETCH_STATUS.FETCHING };
    });

    builder.addCase(getExperienceStats.fulfilled, (state, action) => {
      return { data: action.payload, error: null, status: FETCH_STATUS.FETCHED };
    });

    builder.addCase(getExperienceStats.rejected, () => {
      return { data: null, error: true, status: FETCH_STATUS.INITIAL };
    });

  }
});

export default slice.reducer;