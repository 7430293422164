import { Text } from '@chakra-ui/react';
import { ParticipantJSON } from '@jurnee/common/src/entities/Participant';
import { getUserBudgetRemaining } from '@jurnee/common/src/utils/budgets';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PriceInUserCurrency from 'src/components/PriceInUserCurrency';
import { getUserBudgetBreakdownByEmail } from 'src/store/userBudgetBreakdowns/userBudgetBreakdowns.selectors';

interface Props {
  email: ParticipantJSON['email'];
}

export function ParticipantBudget({ email }: Props) {
  const { t } = useTranslation('registration', { keyPrefix: 'table.body' });

  const userBudgetBreakdown = useSelector(getUserBudgetBreakdownByEmail(email));

  if (!userBudgetBreakdown?.total) {
    return <Text color="gray.400">{t('noBudget')}</Text>;
  }

  const remaining = getUserBudgetRemaining(userBudgetBreakdown);

  return (
    <Text color={remaining <= 0 ? 'red.500' : null}>
      <PriceInUserCurrency value={remaining} currency="EUR" />
    </Text>
  );
}