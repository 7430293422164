import { VStack } from '@chakra-ui/react';
import { Segment } from '../ProgressBar';
import { LegendRow } from './LegendRow';

interface Props {
  segments: Segment[];
  currency?: string;
}

export default function ProgressBarLegend({ segments, currency }: Props) {
  return (
    <VStack w="100%" spacing={3}>
      {
        segments.map(({ label, value, color }, index) => {
          if (typeof value !== 'number' || value === 0) {
            return null;
          }

          return <LegendRow
            key={index}
            color={color}
            label={label}
            value={value}
            currency={currency}
          />;
        })
      }
    </VStack>
  );
}