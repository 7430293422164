import { Box, Checkbox, FormControl, FormLabel, HStack, Select, Text } from '@chakra-ui/react';
import { TaskJSON } from '@jurnee/common/src/entities/Task';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { getUserLabel, sortByUserLabel } from '@jurnee/common/src/utils/user';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  assigneeId: TaskJSON['assigneeId'];
  assigneeType: TaskJSON['assigneeType'];
  organizers: Pick<UserJSON, 'id' | 'firstName' | 'lastName' | 'email'>[];
  isCheckboxDisabled: boolean;
  onChange(data: Pick<TaskJSON, 'assigneeId' | 'assigneeType'>): void;
}

export function AssigneeSelect(props: Props) {
  const { t } = useTranslation('tasks', { keyPrefix: 'drawer.form.assignedTo' });

  const isAssignedToJurnee = props.assigneeType === 'JURNEE';

  const options = useMemo(
    () => sortByUserLabel(props.organizers).map(user =>
      <option key={user.id} value={user.id}>{getUserLabel(user)}</option>
    ),
    [props.organizers]
  );

  function onAssigneeIdChange(e: React.ChangeEvent<HTMLSelectElement>) {
    props.onChange({
      assigneeId: Number(e.target.value) || null,
      assigneeType: 'CUSTOMER'
    });
  }

  function onAssigneeTypeChange(e: React.ChangeEvent<HTMLInputElement>) {
    props.onChange({
      assigneeId: null,
      assigneeType: e.target.checked ? 'JURNEE' : 'CUSTOMER'
    });
  }

  return (
    <Box w="100%" position="relative">
      <FormControl id="assigneeId">
        <FormLabel>{t('label')}</FormLabel>

        <Box className="react-select-small">
          <Select
            size="sm"
            onChange={onAssigneeIdChange}
            value={props.assigneeId || 0}
            isDisabled={isAssignedToJurnee}
          >
            <option value={0}>{t('placeholder')}</option>
            { options }
          </Select>
        </Box>
      </FormControl>

      <HStack spacing={2} top={0} right={0} position="absolute">
        <Text fontWeight={500}>{t('jurneeTeam')}</Text>

        <Checkbox isChecked={isAssignedToJurnee} onChange={onAssigneeTypeChange} isDisabled={props.isCheckboxDisabled} />
      </HStack>
    </Box>
  );
}