{
  "header": "Billing",
  "entities": {
    "title": "Entities",
    "table": {
      "name": "Name",
      "address": "Address",
      "taxIdNumber": "VAT Number",
      "edit": "Edit entity"
    },
    "footer": "Need to add multiple entities?\nContact us <intercom>here</intercom> or at <mailto>hello@jurnee.io</mailto>",
    "drawer": {
      "header": "Edit entity"
    }
  },
  "invoices": {
    "title": "Invoices",
    "spent": "Spent from <itemsCount>{{itemsCount}}</itemsCount> invoices",
    "budgets": {
      "placeholder": "All budgets"
    },
    "entities": {
      "placeholder": "All entities"
    },
    "emptyState": {
      "heading": "You have no invoices for this period",
      "description": "You don't have any invoices for this period. Select another period or book your next event!"
    },
    "teams": {
      "placeholder": "All teams"
    },
    "periodicity": {
      "current": "Current",
      "current_month": "Current month",
      "current_quarter": "Current quarter",
      "current_semester": "Current semester",
      "current_year": "Current year",
      "previous": "Previous",
      "previous_month": "Previous month",
      "previous_quarter": "Previous quarter",
      "previous_semester": "Previous semester",
      "previous_year": "Previous year"
    },
    "table": {
      "budget": "Budget",
      "team": "Team",
      "booking": "Event",
      "date": "Invoicing date",
      "amount": "Amount excl. tax",
      "status": "Status",
      "dotMenu": {
        "download": "Download invoice",
        "edit": "Edit",
        "toasts": {
          "error": "An error occurred while retrieving invoice"
        }
      }
    }
  },
  "discount": {
    "title": "Discount",
    "noDiscountApplied": "You have currently no discount code applied to your account.",
    "description": "The discount code <strong>{{name}}</strong> (-{{percent_off}}%) is currently applied",
    "validUntil": "to your account until {{endDate}}",
    "addButton": "Add"
  },
  "document": {
    "toasts": {
      "error": "An error occurred while retrieving the document"
    }
  }
}