import { CompanyUpdateBody } from '@jurnee/common/src/dtos/companies';
import { CompanyJSON } from '@jurnee/common/src/entities/Company';
import { MostFavoriteList } from '@jurnee/common/src/entities/UserFavoriteExperience';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/company';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';

export const updateCompany = createAsyncThunk<CompanyJSON, CompanyUpdateBody, { state: RootState }>('COMPANY_UPDATE', async (payload, thunkAPI) => {
  try {
    const { company } = thunkAPI.getState();

    const updatedCompany = await api.update(company.data.id, payload);

    thunkAPI.dispatch(showToast({ title: `Company settings updated`, status: 'success' }));

    return updatedCompany;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while updating your company settings`, status: 'error' }));
    return thunkAPI.rejectWithValue({ error: e.message });
  }
});

export const getCompanyFavoriteExperiences = createAsyncThunk<MostFavoriteList, null, { state: RootState }>('COMPANY_GET_FAVORITE_EXPERIENCES', async (arg, thunkAPI) => {
  try {
    const { company } = thunkAPI.getState();

    return api.getFavoriteExperiences(company.data.id);
  } catch (err) {
    return [];
  }
});