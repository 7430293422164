import { Flex, useToast } from '@chakra-ui/react';
import { api } from '@jurnee/common/src/browser/Api';
import { Loader } from '@jurnee/common/src/components/Loader';
import { SurveyJSON } from '@jurnee/common/src/entities/Survey';
import { SurveySubmissionJSON } from '@jurnee/common/src/entities/SurveySubmission';
import { setMobileViewport } from '@jurnee/common/src/utils/core';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Params, useParams, useSearchParams } from 'react-router-dom';
import { getSurvey, getSurveySubmission } from 'src/api/surveys';
import { SurveySubmissionForm } from './SurveySubmissionForm';

type ParamsKeys = 'cuid' | 'surveyId';

export function Survey() {
  const toast = useToast();
  const { t } = useTranslation('surveys', { keyPrefix: 'submission.toasts' });

  const { cuid, surveyId } = useParams<Params<ParamsKeys>>();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const surveySubmissionId = searchParams.get('surveySubmissionId');

  const [survey, setSurvey] = useState<SurveyJSON>(null);
  const [submission, setSubmission] = useState<SurveySubmissionJSON>(null);

  async function fetchSurvey() {
    try {
      const survey = await getSurvey({
        bookingCuid: cuid,
        surveyId
      });

      setSurvey(survey);
    } catch(err) {
      toast(getErrorToast(t('init.error')));
    }
  }

  async function fetchSurveySubmission() {
    const submission = await getSurveySubmission({
      bookingCuid: cuid,
      surveyId,
      surveySubmissionId
    });

    setSubmission(submission);
  }

  useEffect(() => {
    setMobileViewport();

    try {
      fetchSurvey();

      if (token && surveySubmissionId) {
        api.setToken(token);
        fetchSurveySubmission();
      }
    } catch (err) {
      toast(getErrorToast(t('init.error')));
    }
  }, [token, surveySubmissionId]);

  if (!survey) {
    return <Loader/>;
  }

  if (surveySubmissionId && !submission) {
    return <Loader />;
  }

  return (
    <Flex w="100%" justifyContent="center" alignItems="center" minH="100vh" p={5} bgColor="gray.10">
      <Flex w="100%" maxW={500} bg="white" border="1px solid" borderColor="gray.200" borderRadius={4} p={5}>
        <SurveySubmissionForm
          survey={survey}
          submission={submission}
          bookingCuid={cuid}
          onSave={setSubmission}
        />
      </Flex>
    </Flex>
  );
}