import { createSlice } from '@reduxjs/toolkit';
import { login, loginSso } from '../auth/auth.thunks';
import { bootstrap } from '../bootstrap/bootstrap.thunks';
import { updateEmployee } from '../employees/employees.thunks';
import { signup } from '../signup/signup.thunks';
import { initialState } from '../state';
import { createTeam, deleteTeam, updateTeam } from '../teams/teams.thunks';
import { updateUser } from './user.thunks';

const slice = createSlice({
  name: 'user',
  initialState: initialState.user,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(updateEmployee.fulfilled, (state, action) => {
      if (action.payload.id === state.id) {
        return action.payload;
      }

      return state;
    });

    builder.addCase(updateUser.fulfilled, (state, action) => action.payload);
    builder.addCase(bootstrap.fulfilled, (state, action) => action.payload.user);
    builder.addCase(login.fulfilled, (state, action) => action.payload.user);
    builder.addCase(loginSso.fulfilled, (state, action) => action.payload.user);
    builder.addCase(signup.fulfilled, (state, action) => action.payload.user);

    builder.addCase(createTeam.fulfilled, (state, action) => {
      if (action.payload.usersTeams.find(({ user }) => user.id === state.id)) {
        const { id, name, workModel, teamsTags, estimatedSize, usersTeams } = action.payload;

        return {
          ...state,
          usersTeams: [
            ...state.usersTeams,
            {
              team: {
                id,
                name,
                workModel,
                estimatedSize,
                usersTeams,
                teamsTags: teamsTags.map(({ tag }) => {
                  return { tagId: tag.id };
                })
              }
            }
          ]
        };
      }
    });

    builder.addCase(updateTeam.fulfilled, (state, action) => {
      const stateUserTeam = state.usersTeams.find(({ team }) => team.id === action.payload.id);

      if (stateUserTeam && !action.payload.usersTeams.find(({ user }) => user.id === state.id)) {
        return {
          ...state,
          usersTeams: state.usersTeams.filter(({ team }) => team.id !== action.payload.id)
        };
      }

      if (!stateUserTeam && action.payload.usersTeams.find(({ user }) => user.id === state.id)) {
        const { id, name, workModel, teamsTags, estimatedSize, usersTeams } = action.payload;

        return {
          ...state,
          usersTeams: [
            ...state.usersTeams,
            {
              team: {
                id,
                name,
                workModel,
                estimatedSize,
                usersTeams,
                teamsTags: teamsTags.map(({ tag }) => {
                  return { tagId: tag.id };
                })
              }
            }
          ]
        };
      }
    });

    builder.addCase(deleteTeam.fulfilled, (state, action) => {
      if (state.usersTeams.find(({ team }) => team.id === action.payload.id)) {
        return {
          ...state,
          usersTeams: state.usersTeams.filter(({ team }) => team.id !== action.payload.id)
        };
      }
    });

  }
});

export default slice.reducer;