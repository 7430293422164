import { Center, HStack, Text } from '@chakra-ui/react';

interface Props {
  n: number;
  label: string;
}

export function Step({ n, label }: Props) {
  return (
    <HStack spacing={3} pl={3}>
      <Center
        minW="24px"
        minH="24px"
        bg="white"
        fontWeight={700}
        borderRadius={4}
        color='black'
      >
        { n }
      </Center>

      <Text>{label}</Text>
    </HStack>
  );
}