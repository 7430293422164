import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  HStack,
  VStack,
  useToast
} from '@chakra-ui/react';
import { InputFormControl } from '@jurnee/common/src/components/InputFormControl';
import { EmployeeUpdateBody } from '@jurnee/common/src/dtos/users';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { TeamJSON } from '@jurnee/common/src/entities/Team';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { haveSameContent } from '@jurnee/common/src/utils/arrays';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { getUserRole } from '@jurnee/common/src/utils/user';
import { cloneElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/store';
import { trackEvent } from 'src/store/analytics/analytics.thunks';
import { getApprovalProcessesSelector } from 'src/store/approvalProcesses/approvalProcesses.selectors';
import { getApprovalProcesses } from 'src/store/approvalProcesses/approvalProcesses.thunks';
import { updateEmployee } from 'src/store/employees/employees.thunks';
import { teamsSelectors } from 'src/store/teams/teams.selectors';
import { getTeams } from 'src/store/teams/teams.thunks';
import { getUserSelector } from 'src/store/user/user.selectors';
import { PrimaryButton, SecondaryButton } from '../../components/buttons';
import { ApprovalProcessSelect } from './ApprovalProcessSelect';
import { IndividualBudgetSelect } from './IndividualBudgetSelect';
import { RemovableTeamsList } from './RemovableTeamsList';
import { RoleSelect } from './RoleSelect';
import { TeamsSearchSelect } from './TeamsSearchSelect';

interface Props {
  user: UserDetails;
  budgets: BudgetJSON[];
  children: React.ReactElement;
}

export default function EditEmployeeDrawer(props: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation(['employees', 'common']);

  const approvalProcesses = useSelector(getApprovalProcessesSelector);
  const teams = useSelector(teamsSelectors.selectAll);
  const currentUser = useSelector(getUserSelector);
  const role = getUserRole(props.user);

  const [isOpen, setIsOpen] = useState(false);
  const [firstName, setFirstName] = useState(props.user.firstName);
  const [lastName, setLastName] = useState(props.user.lastName);
  const [email, setEmail] = useState(props.user.email);
  const [roleKey, setRoleKey] = useState(role?.key);
  const [approvalProcessId, setApprovalProcessId] = useState(props.user.approvalProcessId);
  const [budgetId, setBudgetId] = useState(props.user.budgetId);
  const [teamIds, setTeamIds] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const employeeBody: EmployeeUpdateBody = {
    firstName,
    lastName,
    email,
    roleKey: roleKey || null,
    approvalProcessId: approvalProcessId || null,
    budgetId: budgetId || null,
    teamIds
  };

  function onClose() {
    setIsOpen(false);
    setIsSaving(false);
  }

  function onOpen(event: React.MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    setIsOpen(true);
    setTeamIds(props.user.usersTeams.map(({ team }) => team.id));

    dispatch(trackEvent({
      name: 'opened_edit_employee_drawer'
    }));
  }

  function onUpdate() {
    return dispatch(
      updateEmployee({
        id: props.user.id,
        data: employeeBody
      })
    );
  }

  async function onSave() {
    setIsSaving(true);

    try {
      await onUpdate().unwrap();
      toast(getSuccessToast(t('drawer.toasts.success')));
      onClose();

      if (approvalProcessId !== props.user.approvalProcessId) {
        dispatch(getApprovalProcesses());
      }

      const teamIdsChanged = !haveSameContent(teamIds, props.user.usersTeams.map(userTeam => userTeam.team.id));

      if (teamIdsChanged) {
        dispatch(getTeams());
      }
    } catch(error) {
      setIsSaving(false);
      toast(getErrorToast(t('drawer.toasts.error'), error.message));
    }
  }

  function onRemoveTeam({ id }: TeamJSON) {
    setTeamIds(teamIds.filter(teamId => teamId !== id));
  }

  function onAddTeam({ value: { id } }: { value: TeamJSON }) {
    setTeamIds([...teamIds, id]);
  }

  return (
    <>
      { cloneElement(props.children, { onClick: onOpen }) }

      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{t('drawer.title')}</DrawerHeader>

          <DrawerBody>
            <VStack spacing={5}>
              <InputFormControl
                size="sm"
                name="firstName"
                value={firstName}
                hasPlaceholder={true}
                onChange={setFirstName}
              />

              <InputFormControl
                size="sm"
                name="lastName"
                value={lastName}
                hasPlaceholder={true}
                onChange={setLastName}
              />

              <InputFormControl
                size="sm"
                name="email"
                value={email}
                onChange={setEmail}
              />

              <RoleSelect
                defaultValue={roleKey}
                isDisabled={currentUser.id === props.user.id}
                onChange={({ target }) => setRoleKey(target.value)}
              />

              <ApprovalProcessSelect
                approvalProcesses={approvalProcesses}
                defaultValue={approvalProcessId}
                onChange={({ target }) => setApprovalProcessId(Number(target.value))}
              />

              <IndividualBudgetSelect
                budgets={props.budgets}
                defaultValue={budgetId}
                onChange={({ target }) => setBudgetId(Number(target.value))}
              />

              <FormControl>
                <FormLabel id="teams">{t('drawer.form.teams.label')}</FormLabel>

                <VStack w="100%" spacing="10px">
                  <TeamsSearchSelect teams={teams} teamIds={teamIds} onChange={onAddTeam} />
                  <RemovableTeamsList teams={teams} teamIds={teamIds} onRemoveTeam={onRemoveTeam} />
                </VStack>
              </FormControl>
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <HStack justifyContent="space-between" w="100%">
              <SecondaryButton colorScheme="pink" size="sm" onClick={onClose}>
                { t('common:buttons.close') }
              </SecondaryButton>

              <PrimaryButton colorScheme="teal" size="sm" isLoading={isSaving} onClick={onSave}>
                { t('common:buttons.apply') }
              </PrimaryButton>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}