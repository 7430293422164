{
  "drawer": {
    "title": {
      "create": "Create team",
      "edit": "Edit team"
    },
    "form": {
      "estimatedSize": {
        "label": "How many people are in this team?"
      },
      "name": {
        "label": "What is this team's name?",
        "placeholder": "Write team name"
      },
      "usersTeams": {
        "label": "Who are the event organizers on this team?",
        "placeholder": "Search an organizer to add to the team",
        "noMembers": "There is currently no members in this team"
      },
      "workModel": {
        "label": "What is your team working style?"
      }
    },
    "toasts": {
      "update": {
        "error": "An error occurred while updating your team",
        "success": "Team updated"
      },
      "create": {
        "error": "An error occurred while creating your team",
        "success": "Team created"
      }
    }
  },
  "emptyState": {
    "default": {
      "heading": "You currently have no team",
      "description": "Teams allow you to group people together so that you never forget anyone when you organize events and facilitate budget management"
    },
    "filters": {
      "heading": "No teams found",
      "description": "We can't find any teams matching these filters"
    }
  },
  "buttons": {
    "createTeam": "Create team"
  },
  "table": {
    "name": "Name",
    "members": "Organizers",
    "headcount": "Headcount"
  },
  "filters": {
    "name": {
      "placeholder": "Search a team"
    }
  },
  "workModel": {
    "HYBRID": "Hybrid",
    "REMOTE": "Remote",
    "OFFICE": "In-Office"
  },
  "dotMenu":{
    "edit": "Edit team",
    "delete": "Delete team",
    "deleteConfirmation": {
      "title": "Delete team",
      "message": "Are you sure you want to delete <strong>{{name}}</strong> team?"
    }
  }
}