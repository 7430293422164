import { HStack, Spacer, Text, VStack } from '@chakra-ui/react';
import { Currency } from '@jurnee/common/src/entities/Currency';
import { ProductJSON } from '@jurnee/common/src/entities/Product';
import { getMaxThreshold, isCustomRequestProduct, isParticipantsRangeValid } from '@jurnee/common/src/utils/products';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { PrimaryButton } from 'src/components/buttons';
import PriceInUserCurrency from 'src/components/PriceInUserCurrency';

interface OwnProps {
  product: ProductJSON;
  participants: number;
  children: React.ReactNode;
  currency: Currency;
  onSubmit(): void;
  price: number;
  submitDisabled: boolean;
  isSubmitting?: boolean;
  submitLabel: string;
}

export default class Step extends React.Component<OwnProps> {

  get price() {
    if (!this.props.participants) {
      return null;
    }

    if (!isParticipantsRangeValid(this.props.product, this.props.participants)) {
      return (
        <Text fontSize={14} lineHeight="26px" color="red.500">
          <Trans
            i18nKey='experience:form.steps.information.participants.error'
            values={{ min: 1, max: getMaxThreshold(this.props.product) }}
          />
        </Text>
      );
    }

    if (isCustomRequestProduct(this.props.product)) {
      return null;
    }

    return (
      <>
        <Text fontSize={24} fontWeight={700}>
          <PriceInUserCurrency value={this.props.price} currency={this.props.currency}/>
        </Text>
        <Text fontSize={14} lineHeight="26px" color="gray.400" h={5}>excl. tax</Text>
      </>
    );
  }

  render() {
    return (
      <VStack w="100%" spacing={5} mt={5}>
        <VStack w="100%" p={0} spacing={4}>
          { this.props.children }
        </VStack>

        <HStack w="100%" p={0}>
          { this.price }
          <Spacer/>
          <PrimaryButton isDisabled={this.props.submitDisabled || !!this.props.isSubmitting} onClick={this.props.onSubmit} isLoading={!!this.props.isSubmitting}>{this.props.submitLabel}</PrimaryButton>
        </HStack>
      </VStack>
    );
  }

}