import { Experience, ExperienceJSON, ExperienceRelationshipsJSON } from '@jurnee/common/src/entities/Experience';
import { List } from '@jurnee/common/src/serializers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/user';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';

export const getUserFavoriteExperiences = createAsyncThunk<List<ExperienceJSON, ExperienceRelationshipsJSON>, null, { state: RootState }>('FETCH_USER_FAVORITE_EXPERIENCES', async (arg, thunkAPI) => {
  try {
    const { user } = thunkAPI.getState();

    return api.getFavoriteExperiences(user.id);
  } catch (e) {
    showToast({ title: 'An error occurred while retrieving your favorites experiences', status: 'error' });
  }
});

export const addToFavoriteExperiences = createAsyncThunk<ExperienceJSON, ExperienceJSON, { state: RootState }>('ADD_USER_FAVORITE_EXPERIENCE', async (experience, thunkAPI) => {
  try {
    const { user } = thunkAPI.getState();

    return api.addToFavoriteExperiences(user.id, experience.id);
  } catch (e) {
    showToast({ title: 'An error occurred while adding this experience to your favorites', status: 'error' });
  }
});

export const removeFromFavoriteExperiences = createAsyncThunk<ExperienceJSON, Experience['id'], { state: RootState }>('REMOVE_USER_FAVORITE_EXPERIENCE', async (experienceId, thunkAPI) => {
  try {
    const { user } = thunkAPI.getState();

    return api.removeFromFavoriteExperiences(user.id, experienceId);
  } catch (e) {
    showToast({ title: 'An error occurred while removing this experience from your favorites', status: 'error' });
  }
});