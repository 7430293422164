import { HStack, Select } from '@chakra-ui/react';
import { BookingInvoiceJSON, BookingsInvoicesSearchParams } from '@jurnee/common/src/entities/BookingInvoice';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { EntityJSON } from '@jurnee/common/src/entities/Entity';
import { TeamJSON } from '@jurnee/common/src/entities/Team';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import { getInvoicesTotal } from '@jurnee/common/src/utils/bookingInvoices';
import { getTimesFromPeriodicity } from '@jurnee/common/src/utils/dates';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import TotalAmountCount from './TotalAmountCount';

interface Props extends WithTranslation {
  budgets: BudgetJSON[];
  entities: EntityJSON[];
  bookingsInvoices: BookingInvoiceJSON[];
  teams: TeamJSON[];
  onChange(filters: BookingsInvoicesSearchParams): void;
  budgetId: number | null;
  periodicity: string;
}

class BookingsInvoicesFilters extends React.Component<Props> {

  state: BookingsInvoicesSearchParams = this.getDefaultState();

  getDefaultState(): BookingsInvoicesSearchParams {
    return {
      budgetIds: this.props.budgetId ? [this.props.budgetId] : undefined,
      entityIds: undefined,
      teamIds: undefined,
      ...getTimesFromPeriodicity(this.props.periodicity)
    };
  }

  onSelectChange = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    const value = Number(target.value) > 0 ? [Number(target.value)] : undefined;
    const newState: BookingsInvoicesSearchParams = { ...this.state, [target.name]: value };
    this.setState(newState);
    this.props.onChange(newState);
  };

  onPeriodicityChange = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    const periodicity = getTimesFromPeriodicity(target.value);
    const newState: BookingsInvoicesSearchParams = { ...this.state, ...periodicity };
    this.setState(newState);
    this.props.onChange(newState);
  };

  get totalAmountCount() {
    return <TotalAmountCount
      minW="200px"
      totalAmount={getInvoicesTotal(this.props.bookingsInvoices)}
      currency="EUR"
      itemsCount={this.props.bookingsInvoices.length}
      i18nKey="billing:invoices.spent"
    />;
  }

  get entitySelector() {
    return (
      <Select
        size="sm"
        bg="white"
        name="entityIds"
        onChange={this.onSelectChange}
        placeholder={this.props.t('entities.placeholder')}
        w="20%"
      >
        {sortAlphabeticallyBy(this.props.entities, 'name').map(({ id, name }) => (
          <option key={id} value={id}>{name}</option>
        ))}
      </Select>
    );
  }

  get budgetSelector() {
    return (
      <Select
        size="sm"
        bg="white"
        name="budgetIds"
        onChange={this.onSelectChange}
        placeholder={this.props.t('budgets.placeholder')}
        defaultValue={this.props.budgetId ? this.props.budgetId : undefined}
        w="20%"
      >
        {this.props.budgets.map(({ id, name }) => (
          <option key={id} value={id}>{name}</option>
        ))}
      </Select>

    );
  }

  get teamSelector() {
    return (
      <Select
        size="sm"
        bg="white"
        name="teamIds"
        onChange={this.onSelectChange}
        placeholder={this.props.t('teams.placeholder')}
        w="20%"
      >
        {sortAlphabeticallyBy(this.props.teams, 'name').map(({ id, name }) => (
          <option key={id} value={id}>{name}</option>
        ))}
      </Select>
    );
  }

  get periodicityOptions() {
    const periodicities = ['month', 'quarter', 'semester', 'year'];

    return ['current', 'previous'].map((type, i) => {
      const options = periodicities.map(periodicity => `${type}_${periodicity}`);

      return (
        <optgroup key={i} label={this.props.t(`periodicity.${type}`)}>
          {options.map((option, j) => {
            const value = option.replace(/\s/, '_').toLowerCase();
            return <option key={j} value={value}>{this.props.t(`periodicity.${value}`)}</option>;
          })}
        </optgroup>
      );
    });
  }

  get periodicitySelector() {
    return (
      <Select
        size="sm"
        bg="white"
        name="periodicity"
        onChange={this.onPeriodicityChange}
        w="20%"
        defaultValue={this.props.periodicity}
      >
        {this.periodicityOptions}
      </Select>
    );
  }

  render() {
    return (
      <HStack w="100%" justifyContent="space-between" spacing={4}>
        { this.totalAmountCount }

        <HStack w="100%" spacing={4} justifyContent="flex-end">
          { this.entitySelector }
          { this.budgetSelector }
          { this.teamSelector }
          { this.periodicitySelector }
        </HStack>
      </HStack>
    );
  }

}

export default withTranslation('billing', { keyPrefix: 'invoices' })(BookingsInvoicesFilters);