import { SimpleGrid } from '@chakra-ui/react';
import * as React from 'react';

interface OwnProps {
  children: React.ReactNode;
}

export default class ExperiencesGrid extends React.PureComponent<OwnProps> {

  render() {
    return (
      <SimpleGrid w="100%" spacing={5} columns={{ sm: 2, md: 3, lg: 4, xl: 5, '2xl': 6 }}>
        { this.props.children }
      </SimpleGrid>
    );
  }

}