import { FormControl, FormLabel } from '@chakra-ui/react';
import AmountInput, { AmountInputData } from '@jurnee/common/src/components/AmountInput';
import { useTranslation } from 'react-i18next';

interface Props {
  currencies: string[];
  isInvalid: boolean;
  onChange(data: AmountInputData): void
}

export function TotalAmountInput(props: Props) {
  const { t } = useTranslation('booking', { keyPrefix: 'modals.payViaJurnee.amount' });

  return (
    <FormControl id="amount" isRequired>
      <FormLabel>{t('label')}</FormLabel>

      <AmountInput
        placeholder="1234.56"
        currencies={props.currencies}
        onChange={props.onChange}
        isInvalid={props.isInvalid}
      />
    </FormControl>
  );
}