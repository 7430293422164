import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { Expand } from '@jurnee/common/src/serializers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { declineProposition, PropositionDeclineParams, PropositionParams, selectProposition } from '../../api/propositions';
import { getBookings } from '../bookings/bookings.thunks';
import { RootState } from '../state';

export const selectPropositionThunk = createAsyncThunk<Expand<BookingJSON, BookingRelationshipsJSON>, PropositionParams, { state: RootState }>('PROPOSITIONS_SELECT', async (params, thunkAPI) => {
  try {
    const booking = await selectProposition(params);
    const searchParams = new URLSearchParams({ after: Date.now().toString() });

    thunkAPI.dispatch(getBookings({ scope: 'user' , searchParams }));

    return booking;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.message);
  }
});

export const declinePropositionThunk = createAsyncThunk<Expand<BookingJSON, BookingRelationshipsJSON>, PropositionDeclineParams, { state: RootState }>('PROPOSITIONS_DECLINE', async (params, thunkAPI) => {
  try {
    const booking = await declineProposition(params);
    const searchParams = new URLSearchParams({ after: Date.now().toString() });

    thunkAPI.dispatch(getBookings({ scope: 'user' , searchParams }));

    return booking;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.message);
  }
});