import { HStack, StackProps } from '@chakra-ui/react';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { TaskJSON } from '@jurnee/common/src/entities/Task';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { AssigneeSelect } from './AssigneeSelect';
import { BookingSelect } from './BookingSelect';
import { NameInput } from './NameInput';
import { PrioritySelect } from './PrioritySelect';
import { StatusSelect } from './StatusSelect';

export interface TasksFilters {
  name: string;
  assigneeId: number | 'JURNEE';
  bookingId: TaskJSON['bookingId'];
  priority: TaskJSON['priority'];
  status: TaskJSON['status'];
}

interface Props {
  filters: TasksFilters;
  assignees: Pick<UserJSON, 'id' | 'firstName' | 'lastName' | 'email'>[];
  statuses: TaskJSON['status'][];
  bookings?: BookingJSON[];
  hasPriorityFilter?: boolean;
  onChange(filters: TasksFilters): void;
}

export function hasFilters({ name, assigneeId, bookingId, status, priority }: TasksFilters) {
  return !isEmpty(name) || !!assigneeId || !!bookingId || !!status || !!priority;
}

export function filterTasks(tasks: TaskJSON[], { name, assigneeId, bookingId, status, priority }: TasksFilters) {
  return tasks.filter(task => {
    const hasAssignee = assigneeId ? (task.assigneeType === 'CUSTOMER' && assigneeId === task.assigneeId) || (task.assigneeType === 'JURNEE' && assigneeId === 'JURNEE') : true;
    const hasBooking = bookingId ? task.bookingId === bookingId : true;
    const hasStatus = status ? status === task.status : true;
    const hasPriority = priority ? priority === task.priority : true;
    const matchName = !isEmpty(name) ? new RegExp(name, 'gi').test(task.name) : true;

    return hasAssignee && hasBooking && hasStatus && hasPriority && matchName;
  });
}

export function TasksFilters({ filters, assignees, statuses, bookings, hasPriorityFilter, onChange, ...inheritedProps }: Props & Omit<StackProps, 'onChange'>) {
  return (
    <HStack w="100%" justifyContent="space-between" spacing={4} {...inheritedProps}>
      <NameInput onChange={name => onChange({ ...filters, name })} />
      { bookings && <BookingSelect bookings={bookings} onChange={bookingId => onChange({ ...filters, bookingId })} /> }
      <AssigneeSelect assignees={assignees} onChange={assigneeId => onChange({ ...filters, assigneeId })} />
      { hasPriorityFilter && <PrioritySelect onChange={priority => onChange({ ...filters, priority })} /> }
      <StatusSelect statuses={statuses} onChange={status => onChange({ ...filters, status })} />
    </HStack>
  );
}