import { HStack, Text, VStack } from '@chakra-ui/react';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { sortByBookingsItemsFrom } from '@jurnee/common/src/utils/bookings';
import { getDayOfMonth, getDayOfWeekPrefix } from '@jurnee/common/src/utils/dates';
import BookingLine from './BookingLine';

interface Props {
  time: number;
  bookings: BookingJSON[];
  relationships: BookingRelationshipsJSON;
}

export default function BookingsCalendarDay(props: Props) {
  const sortedBookings = sortByBookingsItemsFrom(props.bookings);

  return (
    <HStack w="100%" borderBottom="1px solid" borderColor="blue.50" _last={{ borderBottom: 0 }} spacing={0}>
      <VStack minW="60px" spacing={0}>
        <Text fontSize={10} lineHeight="12px" color="gray.400">{getDayOfWeekPrefix(new Date(props.time)).toUpperCase()}</Text>
        <Text fontSize={14} lineHeight="16px" fontWeight={700}>{getDayOfMonth(new Date(props.time))}</Text>
      </VStack>

      <VStack w="100%" px={2} py="10px" borderLeft="1px solid" borderColor="blue.50" spacing={0}>
        { sortedBookings.map(booking => <BookingLine key={booking.id} booking={booking} relationships={props.relationships} />) }
      </VStack>
    </HStack>
  );

}