import { Text, VStack } from '@chakra-ui/react';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { useTranslation } from 'react-i18next';

interface Props {
  definitionType: BudgetJSON['definitionType'];
}

export function TotalZeroBlock({ definitionType }: Props) {
  const { t } = useTranslation('budgets');

  return (
    <VStack justifySelf="center" spacing={2} alignItems="center" justifyContent="center">
      <Text fontSize={16} fontWeight={700} lineHeight="18px" color='black'>
        { t('totalBudgetZero') }
      </Text>
      <Text color="gray.400" lineHeight="20px" textAlign="center">
        { t(`zeroBudgetReason.${definitionType}`) }
      </Text>
    </VStack>
  );
}