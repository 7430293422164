import { ExternalCostUpsertBody } from '@jurnee/common/src/dtos/bookings';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { ExternalCostJSON } from '@jurnee/common/src/entities/ExternalCost';
import { List } from '@jurnee/common/src/serializers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ExternalCostsParams, createExternalCost, deleteExternalCost, deleteExternalCostDocument, getExternalCosts, updateExternalCost, uploadExternalCostDocument } from 'src/api/externalCosts';
import { RootState } from '../state';

interface ExternalCostDeletePayload {
  bookingId: BookingJSON['id'];
  externalCostId: ExternalCostJSON['id'];
}

export interface ExternalCostUpsertPayload {
  bookingId: BookingJSON['id'];
  externalCostId: ExternalCostJSON['id'];
  data: ExternalCostUpsertBody;
}

interface ExternalCostDocumentUploadPayload {
  bookingId: BookingJSON['id'];
  externalCostId: ExternalCostJSON['id'];
  file: File;
}

export const getExternalCostsThunk = createAsyncThunk<List<ExternalCostJSON, never>, Pick<ExternalCostsParams, 'bookingId'>, { state: RootState }>('EXTERNAL_COSTS_GET', async (params, thunkAPI) => {
  try {
    const externalCosts = await getExternalCosts(params);
    return externalCosts;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const deleteExternalCostThunk = createAsyncThunk<ExternalCostJSON, ExternalCostDeletePayload, { state: RootState }>('EXTERNAL_COST_DELETE', async (params, thunkAPI) => {
  try {
    const externalCost = await deleteExternalCost(params);
    return externalCost;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const createExternalCostThunk = createAsyncThunk<ExternalCostJSON, Omit<ExternalCostUpsertPayload, 'externalCostId'>, { state: RootState }>('EXTERNAL_COST_CREATE', async ({ bookingId, data }, thunkAPI) => {
  try {
    const externalCost = await createExternalCost({ bookingId }, data);
    return externalCost;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const updateExternalCostThunk = createAsyncThunk<ExternalCostJSON, ExternalCostUpsertPayload, { state: RootState }>('EXTERNAL_COST_UPDATE', async ({ bookingId, externalCostId, data }, thunkAPI) => {
  try {
    const externalCost = await updateExternalCost({ bookingId, externalCostId }, data);
    return externalCost;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const uploadExternalCostDocumentThunk = createAsyncThunk<ExternalCostJSON, ExternalCostDocumentUploadPayload, { state: RootState }>('EXTERNAL_COST_DOCUMENT_UPLOAD', async ({ bookingId, externalCostId, file }, thunkAPI) => {
  try {
    const externalCost = await uploadExternalCostDocument({ bookingId, externalCostId }, file);
    return externalCost;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const deleteExternalCostDocumentThunk = createAsyncThunk<ExternalCostJSON, Omit<ExternalCostDocumentUploadPayload, 'file'>, { state: RootState }>('EXTERNAL_COST_DOCUMENT_DELETE', async ({ bookingId, externalCostId }, thunkAPI) => {
  try {
    const externalCost = await deleteExternalCostDocument({ bookingId, externalCostId });
    return externalCost;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});