import { HStack, StackProps } from '@chakra-ui/react';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { Catalog } from './Catalog';
import { CustomCards } from './CustomCards';

interface Props {
  booking?: BookingJSON;
}

export function ExploreCard({ booking, ...inheritedProps }: Props & StackProps) {
  return (
    <HStack spacing={5} {...inheritedProps}>
      <Catalog minW={280} booking={booking} />
      <CustomCards booking={booking} />
    </HStack>
  );
}