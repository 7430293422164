import { ApprovalRequestJSON } from '@jurnee/common/src/entities/ApprovalRequest';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { BookingParticipantJSON } from '@jurnee/common/src/entities/BookingParticipant';
import { BudgetBreakdownJSON } from '@jurnee/common/src/entities/BudgetBreakdown';
import { EntityJSON } from '@jurnee/common/src/entities/Entity';
import { User, UserJSON } from '@jurnee/common/src/entities/User';
import { UserBudgetBreakdownJSON } from '@jurnee/common/src/entities/UserBudgetBreakdown';
import { getApprovalRequestUserIds } from '@jurnee/common/src/utils/approvalRequests';
import { isRegistered } from '@jurnee/common/src/utils/bookingParticipants';
import { createSelector } from '@reduxjs/toolkit';
import { isProcessedByApprover } from 'src/utils/approvalRequest';
import { RootState } from '../state';

export type ApprovalRequestData = ApprovalRequestJSON & {
  booking: BookingJSON;
  bookingParticipants: BookingParticipantJSON[];
  bookingsInvoices: BookingInvoiceJSON[];
  budgetBreakdown: BudgetBreakdownJSON | null;
  entity: EntityJSON;
  participantUsers: UserJSON[];
  userBudgetBreakdowns: UserBudgetBreakdownJSON[];
  users: UserJSON[];
}

export function getApprovalRequestsFetchStatusSelector(state: RootState) {
  return state.approvalRequests.status;
}

export const getApprovalRequestListJSON = createSelector(
  (state: RootState) => state.approvalRequests,
  ({ list, relationships }: RootState['approvalRequests']): ApprovalRequestData[] => {

    return list.map(approvalRequest => {
      const booking = relationships.bookings.find(({ id }) => id === approvalRequest.bookingId);
      const bookingParticipants = relationships.bookingsParticipants.filter(bookingParticipant => bookingParticipant.bookingId === booking.id && isRegistered(bookingParticipant));
      const bookingsInvoices = relationships.bookingsInvoices.filter(({ bookingId }) => bookingId === booking.id);
      const budgetBreakdown = booking.budgetId ? relationships.budgetBreakdowns.find(({ budgetId }) => budgetId === booking.budgetId) : null;
      const entity = relationships.entities.find(({ id }) => id === booking.entityId);
      const participantEmails = bookingParticipants.map(({ participant }) => participant.email);
      const participantUsers = relationships.users.filter(user => participantEmails.includes(user.email) && !!user.budgetId);
      const userBudgetBreakdowns = relationships.userBudgetBreakdowns.filter(({ userId }) => participantUsers.map(user=> user.id).includes(userId));
      const users = relationships.users.filter(({ id }) => getApprovalRequestUserIds(approvalRequest).includes(id));

      return {
        ...approvalRequest,
        booking,
        bookingParticipants,
        bookingsInvoices,
        budgetBreakdown,
        entity,
        participantUsers,
        userBudgetBreakdowns,
        users
      };
    });
  }
);

export type ApprovalRequestsCategorized = {
  pending: ApprovalRequestData[],
  processed: ApprovalRequestData[]
}

export const getApprovalRequestsCategorized = createSelector(
  getApprovalRequestListJSON,
  (_: RootState, userId: User['id']) => userId,
  (approvalRequests: ApprovalRequestData[], userId: User['id']): ApprovalRequestsCategorized => {
    return approvalRequests.reduce((filtered, approvalRequest) => {
      if (isProcessedByApprover(approvalRequest, userId)) {
        filtered.processed.push(approvalRequest);
      } else {
        filtered.pending.push(approvalRequest);
      }

      return filtered;
    }, {
      pending: [],
      processed: []
    });
  }
);

export function getApprovalRequestsCategorizedByUserId(userId: UserJSON['id']) {
  return (state: RootState) => getApprovalRequestsCategorized(state, userId);
}