import { FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { Collection } from '@jurnee/common/src/entities/Collection';
import * as React from 'react';
import { connect } from 'react-redux';
import { PrimaryButton } from '../components/buttons';
import { TrackEventOptions, trackEvent } from '../store/analytics/analytics.thunks';

interface OwnProps {
  collection: Collection;
  onEdit(data: Pick<Collection, 'title'>): void;
}

interface State {
  isOpen: boolean;
  title: string;
}

interface DispatchProps {
  trackEvent(opts: TrackEventOptions): void;
}

class EditCollectionModal extends React.PureComponent<OwnProps & DispatchProps, State> {

  state: State = {
    isOpen: false,
    title: this.props.collection.title,
  };

  onSave = () => {
    this.props.onEdit({
      title: this.state.title
    });

    this.onClose();
  };

  onClose = () => {
    this.setState({ isOpen: false });
  };

  onOpen = () => {
    this.setState({ isOpen: true });

    this.props.trackEvent({
      name: 'opened_edit_collection_modal'
    });
  };

  handleTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      title: event.target.value
    });
  };

  handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.onSave();
    }
  };

  render() {
    return (
      <>
        <MenuItemWrapper icon="edit" label="Rename list" onClick={this.onOpen} />

        <Modal isOpen={this.state.isOpen} onClose={this.onClose} size="md" scrollBehavior="inside">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Rename list
              <ModalCloseButton />
            </ModalHeader>

            <ModalBody>
              <FormControl id="title">
                <FormLabel>Give a name to your list</FormLabel>
                <Input
                  defaultValue={this.state.title}
                  name="title"
                  onChange={this.handleTitle}
                  onKeyDown={this.handleKeyDown}
                  type="text"
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <HStack spacing={4} justifyContent="flex-end" w="100%">
                <PrimaryButton colorScheme="teal" size="sm" onClick={this.onSave}>Save</PrimaryButton>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }

}

const mapDispatchToProps: DispatchProps = {
  trackEvent
};

export default connect<null, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps
)(EditCollectionModal);