import { Center, Text } from '@chakra-ui/react';
import * as React from 'react';

interface Props {
  label: string;
}

export default class AwaitingInfoCard extends React.PureComponent<Props> {

  render() {
    return (
      <Center w="100%" height="40px" bgColor="gray.400" borderRadius={8}>
        <Text color="white" fontSize={16} fontWeight={700}>{this.props.label}</Text>
      </Center>
    );
  }

}