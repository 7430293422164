import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { getUserStatsThunk } from './userStats.thunks';

const slice = createSlice({
  name: 'userStats',
  initialState: initialState.userStats,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getUserStatsThunk.fulfilled, (_state, { payload }) => {
      return { data: payload, error: null, status: 'FETCHED' };
    });

  }
});

export default slice.reducer;