import { Box, HStack, Text, VStack } from '@chakra-ui/react';

interface Props {
  label: string;
  value: number;
  total: number
}

export function AnswerChart({ label, value, total }: Props) {
  return (
    <VStack w="100%" spacing={1} alignItems="flex-start">
      <HStack w="100%" justifyContent="space-between">
        <Text lineHeight="16px">{label}</Text>
        <Text color="gray.400" lineHeight="16px">{value}</Text>
      </HStack>

      <Box w="100%" h="4px" bgColor="gray.100" borderRadius="3px">
        {
          value > 0 && <Box
            w={`${value * 100 / total}%`}
            h="4px"
            borderRadius="3px"
            bgColor="blue.400"
          />
        }
      </Box>
    </VStack>
  );
}