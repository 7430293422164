import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultValue: BudgetJSON['visibility'];
  onChange(visibility: BudgetJSON['visibility']): void;
}

export function VisibilitySelect({ defaultValue, onChange }: Props) {
  const { t } = useTranslation('settings', { keyPrefix: 'budgets.drawer.form.visibility' });

  return (
    <FormControl isRequired>
      <FormLabel>{t('label')}</FormLabel>
      <Select size="sm" name="visibility" defaultValue={defaultValue} onChange={e => onChange(e.target.value as BudgetJSON['visibility'])} bg="white">
        {['ALL_ORGANIZERS', 'OWNERS_ONLY'].map((value) => (
          <option key={value} value={value}>
            { t(`options.${value}`) }
          </option>
        ))}
      </Select>
    </FormControl>
  );
}