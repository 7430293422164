import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { getExperience } from './experienceDetails.thunks';

const slice = createSlice({
  name: 'experienceDetails',
  initialState: initialState.experienceDetails,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getExperience.pending, () => {
      return { data: null, error: null, status: FETCH_STATUS.FETCHING };
    });

    builder.addCase(getExperience.fulfilled, (state, { payload: { data }}) => {
      return { data, error: null, status: FETCH_STATUS.FETCHED };
    });

    builder.addCase(getExperience.rejected, () => {
      return { data: null, error: true, status: FETCH_STATUS.INITIAL };
    });

  }
});

export default slice.reducer;