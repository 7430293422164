{
  "comments": {
    "addComment": "Add comment",
    "comments": "Comments"
  },
  "drawers": {
    "propositionComments": {
      "title": "Comments"
    }
  },
  "emptyStates": {
    "noPropositions": {
      "description": "You don't have any proposals for this request.",
      "heading": "No proposals found"
    },
    "waitingProposal": {
      "description": "Your request has been sent to several of our partners who meet your criteria. We are now awaiting their proposals and quotes.",
      "heading": "Waiting for partners propositions"
    }
  },
  "modals": {
    "declineProposition": {
      "button": "Yes, decline",
      "message": "Are you sure you want to decline this proposition?\nBy declining it, you will no longer be able to select it.",
      "reason": {
        "label": "Reason for declining",
        "placeholder": "Please add any useful information"
      },
      "title": "Decline proposition"
    },
    "selectProposition": {
      "buttonLabel": "Yes, select",
      "message": "Once validated, you will no longer be able to choose another option but it doesn't commit you in any way. You must sign a quote, send an approval request or pay by credit card in the next step to confirm your choice.",
      "title": "Select proposition"
    }
  },
  "noEventDate": "No event date",
  "propositions": {
    "declinedAt": "Proposition declined on {{date}}",
    "expired": "Option expired on <strong>{{date}}</strong>, we cannot ensure availability",
    "heading": "Propositions received",
    "selectedAt": "Proposition selected on {{date}}",
    "stepOf": "Step {{step}} of {{total}}",
    "validUntil": "Option valid until <strong>{{date}}</strong> after which we cannot ensure availability"
  },
  "requests": {
    "heading": "Requests sent"
  },
  "status": {
    "CLOSED": "Closed",
    "READY": "Ready",
    "SELECTED": "Selected",
    "WAITING_PROPOSALS": "Waiting proposals"
  },
  "toasts": {
    "createComment": {
      "error": "An error occurred while submitting your comment"
    },
    "declineProposition": {
      "error": "An error occurred while declining the proposition"
    },
    "downloadFile": {
      "error": "An error occurred while downloading the attachment"
    },
    "selectProposition": {
      "error": "An error occurred while selecting the proposition"
    }
  }
}