import { Td, Tr, useDisclosure } from '@chakra-ui/react';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { SurveyJSON } from '@jurnee/common/src/entities/Survey';
import { SurveyDrawer } from 'src/drawers/SurveyDrawer';
import { SurveyDotMenu } from './SurveyDotMenu';

interface Props {
  survey: SurveyJSON;
  bookingCuid: BookingJSON['cuid'];
  onDelete(survey: SurveyJSON): void;
  onUpdate(survey: SurveyJSON): void;
}

export function SurveyRow({ survey, bookingCuid, onDelete, onUpdate }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Tr cursor="pointer" onClick={onOpen}>
      <Td>{survey.name}</Td>
      <Td>{survey.questions.length}</Td>
      <Td pl={0} pr={3} textAlign="right">
        <SurveyDotMenu survey={survey} bookingCuid={bookingCuid} onDelete={onDelete} />
      </Td>
      <Td hidden>
        <SurveyDrawer
          key={survey.id}
          bookingId={survey.bookingId}
          survey={survey}
          isOpen={isOpen}
          onClose={onClose}
          onUpdate={onUpdate}
        />
      </Td>
    </Tr>
  );
}