import { Input } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange(value: string): void;
}

export function NameInput(props: Props) {
  const { t } = useTranslation('tasks', { keyPrefix: 'filters.name' });

  return <Input
    size="sm"
    type="text"
    name="nameSearch"
    placeholder={t('placeholder')}
    onChange={({ target }) => props.onChange(target.value)}
  />;
}