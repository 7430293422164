import { DotMenu } from '@jurnee/common/src/components/DotMenu';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { Collection } from '@jurnee/common/src/entities/Collection';
import { ConfirmModal } from '@jurnee/common/src/modals/ConfirmModal';
import * as React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import EditCollectionModal from '../modals/EditCollectionModal';

interface OwnProps {
  collection: Collection;
  onEdit(data: Pick<Collection, 'title'>): void;
  onDelete(): void;
}

type Props = OwnProps & WithTranslation;

class CollectionEditMenu extends React.Component<Props> {

  render() {
    return (
      <DotMenu size="sm" bg="gray.50" _hover={{ background: 'gray.100' }} color="gray">
        <EditCollectionModal collection={this.props.collection} onEdit={this.props.onEdit} />
        <ConfirmModal
          title={this.props.t('delete.title')}
          message={<Trans i18nKey='collections:delete.text' values={{ name: this.props.collection.title }} />}
          buttonLabel={this.props.t('common:buttons.delete')}
          onConfirm={this.props.onDelete}
        >
          <MenuItemWrapper icon="trash" label={this.props.t('delete.buttonLabel')} />
        </ConfirmModal>
      </DotMenu>
    );
  }

}

export default withTranslation('collections')(CollectionEditMenu);