import { Box, Text } from '@chakra-ui/react';
import { formatPrice, PayViaJurneePricing } from '@jurnee/common/src/utils/prices';
import { Trans, useTranslation } from 'react-i18next';

export function FeeDescription({ totalAmount, feeAmount, sourceCurrency, targetCurrency, conversionRate }: PayViaJurneePricing) {
  const { t } = useTranslation('booking', { keyPrefix: 'modals.payViaJurnee.fees' });

  return (
    <Box>
      <Text fontSize={16} fontWeight={700}>{t('label')}</Text>

      <Text>
        {
          feeAmount ?
            <Trans
              i18nKey="booking:modals.payViaJurnee.fees.description"
              values={{ feeAmount: formatPrice(feeAmount, targetCurrency) }}
            /> :
            t('emptyState')
        }
      </Text>

      {
        sourceCurrency !== targetCurrency &&
          <Text>
            <Trans
              i18nKey="booking:modals.payViaJurnee.fees.conversion"
              values={{ sourceId: sourceCurrency, targetId: targetCurrency, rate: conversionRate.toFixed(2) }}
            />
          </Text>
      }

      {
        totalAmount > 0 &&
          <Text>
            <Trans
              i18nKey="booking:modals.payViaJurnee.fees.totalPrice"
              values={{ total: formatPrice(totalAmount, targetCurrency) }}
            />
          </Text>
      }
    </Box>
  );
}