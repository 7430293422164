import { Box, BoxProps, HStack, Text, VStack } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { PlaceResultDTO } from '@jurnee/common/src/dtos/places';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface OwnProps {
  key: number;
  place: PlaceResultDTO;
}

type Props = OwnProps & WithTranslation & Pick<BoxProps, 'onClick'>;

class PartnerPlaceCard extends React.Component<Props> {

  get partnerAddress() {
    const address = this.props.place.formattedAddress.split(',');
    const cityCountry = address.splice(-2);

    return (
      [
        <Text key="dot" fontSize="14px" fontWeight={500} lineHeight="16px">·</Text>,
        <Text key="address" fontSize="14px" fontWeight={500} lineHeight="16px" noOfLines={1}>{cityCountry[0]}</Text>
      ]
    );
  }

  get subtitle(): JSX.Element {
    const { rating } = this.props.place;

    return (
      <HStack spacing={1}>
        <Icon mt="-1px" icon="star" size={4} />
        <Box fontSize={14} fontWeight={500} lineHeight="16px">{rating.average.toFixed(1)}</Box>
        <Box fontSize={14} fontWeight={500} lineHeight="16px">({rating.count})</Box>
        { this.partnerAddress }
      </HStack>
    );
  }

  render() {
    const { name, photos } = this.props.place;

    return (
      <VStack w="100%" spacing={4} alignItems="flex-start">
        <Box
          w="100%"
          style={{ aspectRatio: '1' }}
          p={4}
          bg={`linear-gradient(rgba(30, 0, 50, 0.1), rgba(30, 0, 50, 0.1)), url('${photos[0]}') no-repeat center / cover`}
          bgColor="gray.400"
          borderRadius={8}
          onClick={this.props.onClick}
          cursor="pointer"
        />

        <VStack spacing={2} alignItems="flex-start">
          <Text fontSize={14} fontWeight={700} lineHeight="16px" noOfLines={1}>{name}</Text>

          { this.subtitle }
        </VStack>
      </VStack>
    );
  }

}

export default withTranslation('experience')(PartnerPlaceCard);