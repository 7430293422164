{
  "header": "People",
  "tabs": {
    "members": "Members",
    "teams": "Teams",
    "userProvisioning": "User Provisioning"
  },
  "suspended": "Suspended",
  "table": {
    "approvalProcess": "Approval process",
    "individualBudget": "Individual budget",
    "name": "Name",
    "role": "Role",
    "teams": "Teams"
  },
  "filters": {
    "approvalProcesses": {
      "all": "All approval processes"
    },
    "roles": {
      "all": "All roles",
      "admins": "Admins",
      "organizers": "Organizers"
    },
    "teams": {
      "all": "All teams"
    },
    "name": {
      "placeholder": "Search a name"
    }
  },
  "individualBudget": {
    "emptyState": "No budget"
  },
  "teams": {
    "andOther": "{{team}} and {{count}} others",
    "createTeam": "Create a team",
    "addTeam": "Add a team"
  },
  "approvalProcess": {
    "add": "Add an approval process"
  },
  "invitePeople": "Invite people",
  "dotMenu":{
    "edit": "Edit user",
    "delete": "Delete user",
    "deleteConfirmation": {
      "title": "Delete user",
      "message": "Are you sure you want to delete the account associated to the email <strong>{{email}}</strong>?"
    },
    "resendInvitation": "Resend invitation"
  },
  "emptyState": {
    "heading": "No members found",
    "description": "We can't find any members matching these filters"
  },
  "userProvisioning": {
    "heading": "SCIM credentials",
    "warning": "Please note that for security reasons, we do not display your credentials again after you generate them.",
    "clientId": "Client ID",
    "clientSecret": "Client Secret",
    "generateCredentials": "Generate credentials",
    "helpHeading": "Need help?",
    "helpText": "We'll guide you through the process of creating and configuring a new SCIM integration for your Jurnee account.",
    "helpButton": "Open documentation",
    "toasts": {
      "create": {
        "error": "An error occurred while generating your credentials"
      }
    }
  }
}