import { Select } from '@chakra-ui/react';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  assignees: Pick<UserJSON, 'id' | 'firstName' | 'lastName' | 'email'>[];
  onChange(assigneeId: number | 'JURNEE'): void;
}

export function AssigneeSelect({ assignees, onChange }: Props) {
  const { t } = useTranslation(['tasks', 'common']);

  const options = useMemo(() => {
    const data = sortAlphabeticallyBy(
      [
        { label: t('common:jurneeTeam'), value: 'JURNEE' },
        ...assignees.map(assignee => ({ label: getUserLabel(assignee), value: assignee.id }))
      ],
      'label'
    );

    return [
      <option key={0} value={0}>{t('filters.assignee.all')}</option>,
      ...data.map(({ label, value }) => <option key={value} value={value}>{label}</option>)
    ];
  }, [assignees]);

  return (
    <Select size="sm" onChange={e => onChange(e.target.value === 'JURNEE' ? 'JURNEE' : Number(e.target.value))}>
      { options }
    </Select>
  );
}