import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { bootstrap } from './bootstrap.thunks';

const slice = createSlice({
  name: 'bootstrap',
  initialState: initialState.bootstrap,
  reducers: { },
  extraReducers: (builder) => {

    builder.addCase(bootstrap.pending, () => {
      return { status: FETCH_STATUS.FETCHING };
    });

    builder.addCase(bootstrap.fulfilled, () => {
      return { status: FETCH_STATUS.FETCHED };
    });

    builder.addCase(bootstrap.rejected, () => {
      return { status: FETCH_STATUS.INITIAL };
    });

  }
});

export default slice.reducer;