import { Heading, Stack, VStack, useToast } from '@chakra-ui/react';
import { InputFormControl } from '@jurnee/common/src/components/InputFormControl';
import { QuestionAnswerUpdateBody } from '@jurnee/common/src/dtos/surveySubmissions';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { SurveyJSON } from '@jurnee/common/src/entities/Survey';
import { SurveySubmissionJSON } from '@jurnee/common/src/entities/SurveySubmission';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { areAllRequiredQuestionsAnswered } from '@jurnee/common/src/utils/surveys';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSurveySubmission, updateSurveySubmission } from 'src/api/surveys';
import { PrimaryButton } from 'src/components/buttons';
import { Questions } from './Questions';
import { Success } from './Success';

interface Props {
  survey: SurveyJSON;
  submission: SurveySubmissionJSON;
  bookingCuid: BookingJSON['cuid'];
  onSave(submission: SurveySubmissionJSON): void;
}

export function SurveySubmissionForm(props: Props) {
  const toast = useToast();
  const { t } = useTranslation(['surveys', 'common']);

  const { survey, submission, bookingCuid } = props;

  const [firstName, setFirstName] = useState(submission?.participant.firstName);
  const [lastName, setLastName] = useState(submission?.participant.lastName);
  const [email, setEmail] = useState(submission?.participant.email);

  const [questionAnswers, setQuestionAnswers] = useState<QuestionAnswerUpdateBody[]>(submission?.questionAnswers || []);

  const [isSaving, setIsSaving] = useState(false);
  const [success, setSuccess] = useState(false);

  const hasMissingAnswers = useMemo(
    () => !areAllRequiredQuestionsAnswered(props.survey.questions, questionAnswers),
    [props.survey.questions, questionAnswers]
  );

  const isSubmitDisabled = [firstName, lastName, email].some(value => isEmpty(value)) || hasMissingAnswers;

  async function create() {
    try {
      const submission = await createSurveySubmission({
        bookingCuid,
        surveyId: survey.id
      }, {
        participant: { firstName, lastName, email },
        questionAnswers
      });

      setSuccess(true);

      return submission;
    } catch(error) {
      toast(getErrorToast(t('submission.toasts.create.error'), error.message));
    }
  }

  async function update() {
    try {
      const updatedSubmission = await updateSurveySubmission({
        bookingCuid,
        surveyId: survey.id,
        surveySubmissionId: submission.id
      }, {
        participant: { firstName, lastName },
        questionAnswers
      });

      toast(getSuccessToast(t('submission.toasts.update.success')));

      return updatedSubmission;
    } catch(error) {
      toast(getErrorToast(t('submission.toasts.update.error'), error.message));
    }
  }

  async function onSave() {
    setIsSaving(true);

    const submission = props.submission ? await update() : await create();

    props.onSave(submission);

    setIsSaving(false);
  }

  if (success) {
    return <Success/>;
  }

  return (
    <VStack w="100%" spacing={5} alignItems="flex-start">
      <Heading size="md">{survey.name}</Heading>

      <Stack w="100%" direction={['column', 'row']} spacing={5}>
        <InputFormControl
          size="sm"
          name="firstName"
          value={firstName}
          onChange={setFirstName}
          hasPlaceholder={true}
          isRequired={true}
        />

        <InputFormControl
          size="sm"
          name="lastName"
          value={lastName}
          onChange={setLastName}
          hasPlaceholder={true}
          isRequired={true}
        />
      </Stack>

      <InputFormControl
        size="sm"
        name="email"
        type="email"
        value={email}
        onChange={setEmail}
        hasPlaceholder={true}
        isRequired={true}
        isDisabled={!!submission?.participant.email}
      />

      <Questions
        questions={survey.questions}
        questionAnswers={questionAnswers}
        onChange={setQuestionAnswers}
      />

      <PrimaryButton w="100%" size="sm" type="submit" isDisabled={isSubmitDisabled} isLoading={isSaving} onClick={onSave}>
        { t('common:buttons.submit') }
      </PrimaryButton>
    </VStack>
  );
}