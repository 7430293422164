import { BoxProps, HStack, StackProps, Text, VStack } from '@chakra-ui/react';
import { BookingParticipantJSON } from '@jurnee/common/src/entities/BookingParticipant';
import { BookingParticipantsStats, getBookingParticipantsStats } from '@jurnee/common/src/utils/bookingParticipants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from 'src/components/Chart/ProgressBar';
import ProgressBarLegend from 'src/components/Chart/ProgressBarLegend';

interface Props {
 bookingParticipants: BookingParticipantJSON[];
}

const statuses: BookingParticipantJSON['status'][] = [
  'ATTENDED',
  'ACCEPTED',
  'DECLINED',
  'INVITED',
  'INITIAL'
];

const colors: { [key: string]: BoxProps['bgColor'] } = {
  ACCEPTED: 'teal.400',
  ATTENDED: 'black',
  DECLINED: 'pink.400',
  INITIAL: 'gray.400',
  INVITED: 'blue.400'
};

function getSegments(stats: BookingParticipantsStats) {
  const { t } = useTranslation('registration', { keyPrefix: 'status' });

  return statuses.map(status => {
    return {
      label: t(status),
      value: stats[status],
      color: colors[status]
    };
  });
}

export function ParticipantsStatusesChart({ bookingParticipants, ...inheritedProps }: Props & StackProps) {
  const { t } = useTranslation('registration');

  const stats = useMemo(() => getBookingParticipantsStats(bookingParticipants), [bookingParticipants]);
  const segments = getSegments(stats);

  return (
    <VStack w="100%" spacing={3} alignItems="stretch" {...inheritedProps}>
      <HStack justifyContent="space-between">
        <Text fontSize={16} fontWeight={700} lineHeight="21px">{t('participants')}</Text>
        <Text lineHeight="21px" color="gray.400">{bookingParticipants.length}</Text>
      </HStack>

      <ProgressBar segments={segments} />
      <ProgressBarLegend segments={segments} />
    </VStack>
  );
}