import {
  Checkbox,
  CheckboxGroup,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useToast
} from '@chakra-ui/react';
import { BookingParticipantsInviteBody } from '@jurnee/common/src/dtos/bookingsParticipants';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BookingParticipantJSON } from '@jurnee/common/src/entities/BookingParticipant';
import { BookingParticipantsStats } from '@jurnee/common/src/utils/bookingParticipants';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { cloneElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/store';
import { trackEvent } from 'src/store/analytics/analytics.thunks';
import { inviteBookingParticipantsThunk } from 'src/store/bookingParticipants/bookingParticipants.thunks';
import { PrimaryButton, SecondaryButton } from '../components/buttons';

interface Props {
  booking: BookingJSON;
  bookingParticipants: BookingParticipantJSON[];
  stats: BookingParticipantsStats;
  type: 'EMAIL' | 'SLACK';
  children: React.ReactElement;
}

export default function InviteParticipantsModal(props: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['registration', 'common']);
  const toast = useToast();

  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [statuses, setStatuses] = useState<BookingParticipantsInviteBody['status']>([]);

  function onClose() {
    setIsOpen(false);
  }

  function onOpen(event: React.MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    setStatuses(props.stats.INITIAL > 0 ? ['INITIAL'] : []);

    setIsOpen(true);

    dispatch(trackEvent({
      name: 'opened_invite_participants_modal',
      properties: {
        type: props.type
      }
    }));
  }

  function inviteParticipants() {
    return dispatch(
      inviteBookingParticipantsThunk({
        bookingId: props.booking.id,
        data: { status: statuses, inviteType: props.type }
      })
    );
  }

  async function onInvite() {
    setIsSaving(true);

    try {
      await inviteParticipants().unwrap();
      toast(getSuccessToast(t('inviteModal.toasts.success')));
      onClose();
    } catch(error) {
      toast(getErrorToast(t('inviteModal.toasts.error'), error.message));
    }

    setIsSaving(false);
  }

  const totalCount = useMemo(() => props.bookingParticipants.filter(({ status }) => statuses.includes(status)).length, [props.bookingParticipants, statuses]);
  const isNextInvites = useMemo(() => props.bookingParticipants.length !== props.stats.INITIAL, [props.bookingParticipants]);

  return (
    <>
      { cloneElement(props.children, { onClick: onOpen }) }

      <Modal size="md" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            { t(`inviteModal.title.${props.type}`) }

            <ModalCloseButton />
          </ModalHeader>

          <ModalBody>
            <VStack w="100%" alignItems="flex-start" spacing={5}>
              <Text>{ isNextInvites ? t('inviteModal.descriptionNextInvites') : t('inviteModal.description') }</Text>

              <CheckboxGroup defaultValue={statuses} onChange={value => setStatuses(value as BookingParticipantJSON['status'][])}>
                <VStack alignItems="flex-start" spacing={2}>
                  <Checkbox value="INVITED" isDisabled={props.stats.INVITED === 0}>{t(`inviteModal.checkboxes.INVITED`, { count: props.stats.INVITED })}</Checkbox>
                  <Checkbox value="INITIAL" isDisabled={props.stats.INITIAL === 0}>{t(`inviteModal.checkboxes.INITIAL`, { count: props.stats.INITIAL })}</Checkbox>
                </VStack>
              </CheckboxGroup>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <HStack w="100%" justifyContent="space-between">
              <SecondaryButton colorScheme="pink" size="sm" onClick={onClose}>
                { t('common:buttons.close') }
              </SecondaryButton>

              <PrimaryButton colorScheme="teal" size="sm" onClick={onInvite} isLoading={isSaving} isDisabled={statuses.length === 0}>
                { t('inviteModal.button', { count: totalCount }) }
              </PrimaryButton>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}