import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  onAdd(): void;
}

export function AddOptionButton(props: Props) {
  const { t } = useTranslation('surveys', { keyPrefix: 'drawers.survey.form.question.questionOption' });

  return (
    <Text
      color="blue.400"
      cursor="pointer"
      _hover={{ color: 'blue.600' }}
      marginLeft="auto"
      onClick={props.onAdd}
    >
      { t('addOption') }
    </Text>
  );
}