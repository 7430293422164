import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Spacer,
  VStack
} from '@chakra-ui/react';
import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { BackButton } from '@jurnee/common/src/components/buttons/BackButton';
import { Loader } from '@jurnee/common/src/components/Loader';
import { CollectionUpdateBody } from '@jurnee/common/src/dtos/collections';
import { Collection } from '@jurnee/common/src/entities/Collection';
import { CollectionExperience } from '@jurnee/common/src/entities/CollectionExperience';
import { Currency } from '@jurnee/common/src/entities/Currency';
import { distanceToNow } from '@jurnee/common/src/utils/dates';
import { deleteCollection, getCollection, removeFromCollection, updateCollection } from '@jurnee/dashboard/src/store/collectionDetails/collectionDetails.thunks';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PrimaryButton } from 'src/components/buttons';
import CollectionDetailsForm from 'src/components/CollectionDetailsForm';
import CollectionEditMenu from 'src/components/CollectionEditMenu';
import CollectionExperienceItem from 'src/components/CollectionExperienceItem';
import CollectionExperienceRank from 'src/components/CollectionExperienceRank';
import CollectionMetric from 'src/components/CollectionMetric';
import { ShareLinkModal } from 'src/modals/ShareLinkModal';
import { RootState } from 'src/store/state';
import { RouteProps } from '../Route';

interface StateProps {
  collectionDetails: RootState['collectionDetails'];
  currency: Currency;
}

interface DispatchProps {
  deleteCollection(data: Collection): void;
  getCollection(id: Collection['id']): void;
  removeFromCollection(data: CollectionExperience): void;
  updateCollection(data: CollectionUpdateBody & { collectionId: Collection['id'] }): void;
}

class CollectionDetails extends React.Component<StateProps & DispatchProps & RouteProps & WithTranslation> {

  componentDidMount() {
    this.props.getCollection(this.props.match.params.collectionId);
  }

  onRemoveFromCollection = (collectionExperience: CollectionExperience) => {
    this.props.removeFromCollection(collectionExperience);
  };

  onDeleteCollection = () => {
    this.props.deleteCollection(this.props.collectionDetails.data);
  };

  onEditCollection = (data: CollectionUpdateBody) => {
    this.props.updateCollection({
      collectionId: this.props.collectionDetails.data.id,
      ...data
    });
  };

  get votesCount() {
    return this.props.collectionDetails.data.collectionsExperiences.reduce((total, { upvotes }) => {
      return total + upvotes;
    }, 0);
  }

  get timeRemaining() {
    if (!this.props.collectionDetails.data.pollDeadline) {
      return (
        <GridItem>
          <CollectionMetric
            value="-"
            iconName="history"
            label="No poll closing date"
          />
        </GridItem>
      );
    }

    const daysRemainingLabel = distanceToNow(new Date(this.props.collectionDetails.data.pollDeadline), false);

    return (
      <GridItem>
        <CollectionMetric
          value={daysRemainingLabel}
          iconName="history"
          label="before poll closing"
        />
      </GridItem>
    );
  }

  get collectionMetrics() {
    return (
      <Box bg="white" borderRadius={8} border="1px solid" borderColor="blue.50" p={5}>
        <Grid templateColumns="repeat(4, 1fr)" w="100%">
          <GridItem>
            <CollectionMetric
              value={`${this.props.collectionDetails.data.collectionsExperiences.length}`}
              iconName="target"
              label="experiences"
            />
          </GridItem>
          <GridItem>
            <CollectionMetric
              value={`${this.props.collectionDetails.data.views}`}
              iconName="eyeWide"
              label="views"
            />
          </GridItem>
          <GridItem>
            <CollectionMetric
              value={`${this.votesCount}`}
              iconName="commentHeart"
              label="votes"
            />
          </GridItem>
          {this.timeRemaining}
        </Grid>
      </Box>
    );
  }

  get rankedCollectionsExperiences() {
    const { collectionsExperiences } = this.props.collectionDetails.data;
    return [...collectionsExperiences].sort((a,b) => b.upvotes - a.upvotes);
  }

  get collectionItems() {
    const equalVotes = this.rankedCollectionsExperiences.length > 1 && this.rankedCollectionsExperiences[0].upvotes === this.rankedCollectionsExperiences[1].upvotes;

    return this.rankedCollectionsExperiences.map((collectionExperience, idx) => {
      return (
        <HStack w="100%" spacing={8} key={idx}>
          <CollectionExperienceRank index={idx} upvotes={collectionExperience.upvotes} disableFirstRankedBadge={equalVotes} />
          <CollectionExperienceItem collectionExperience={collectionExperience} currency={this.props.currency} onRemoveFromCollection={this.onRemoveFromCollection} />
        </HStack>
      );
    });
  }

  render() {
    if (this.props.collectionDetails.status !== FETCH_STATUS.FETCHED) {
      return <Loader/>;
    }

    return (
      <main>
        <HStack justifyContent="space-between" bg="white" p={8} borderBottom="1px solid" borderColor="blue.50" spacing={5}>
          <Link to="/collections">
            <BackButton />
          </Link>
          <Heading>{this.props.collectionDetails.data.title}</Heading>
          <Spacer />
          <ShareLinkModal
            link={`${window.location.origin}/polls?id=${this.props.collectionDetails.data.id}`}
            title={this.props.t('sendToMyTeam')}
            formLabel={this.props.t('common:link')}
            eventName="share_collection"
          >
            <PrimaryButton size="sm">
              { this.props.t('sendToMyTeam') }
            </PrimaryButton>
          </ShareLinkModal>
          <CollectionEditMenu collection={this.props.collectionDetails.data} onEdit={this.onEditCollection} onDelete={this.onDeleteCollection} />
        </HStack>
        <Flex flexDirection="column">
          <Box w="100%" maxW="1184px" p={8} alignSelf="center">
            {this.collectionMetrics}
            <HStack py={8} spacing={8} alignItems="flex-start">
              <VStack spacing={6} w="100%">
                {this.collectionItems}
              </VStack>
              <CollectionDetailsForm collection={this.props.collectionDetails.data} currency={this.props.currency} onSave={this.onEditCollection} isLoading={this.props.collectionDetails.updating} />
            </HStack>
          </Box>
        </Flex>
      </main>
    );
  }
}

function mapStateToProps(state: RootState): StateProps {
  return {
    currency: state.company.data.currency,
    collectionDetails: state.collectionDetails
  };
}

const mapDispatchToProps: DispatchProps = {
  deleteCollection,
  getCollection,
  removeFromCollection,
  updateCollection,
};

export default connect<StateProps, DispatchProps, RouteProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('collections')(CollectionDetails));