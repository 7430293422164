import { Badge, Box, Divider, HStack, Spacer, Text, VStack, useToast } from '@chakra-ui/react';
import { DotMenu } from '@jurnee/common/src/components/DotMenu';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { ConfirmModal } from '@jurnee/common/src/modals/ConfirmModal';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { deleteBudget as deleteBudgetThunk } from '@jurnee/dashboard/src/store/budgets/budgets.thunks';
import { Trans, useTranslation } from 'react-i18next';
import PriceInUserCurrency from 'src/components/PriceInUserCurrency';
import { BudgetDrawer } from 'src/drawers/BudgetDrawer';
import { BudgetSpendingStatementDrawer } from 'src/drawers/BudgetSpendingStatementDrawer';
import { useAppDispatch } from 'src/store';

interface Props {
  budget: BudgetJSON;
  users: UserDetails[];
}

export function BudgetCard(props: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation('budgets');

  function deleteBudget() {
    return dispatch(deleteBudgetThunk({
      id: props.budget.id
    }));
  }

  async function onDelete() {
    try {
      await deleteBudget().unwrap();
      toast(getSuccessToast(t('toasts.delete.success')));
    } catch(error) {
      toast(getErrorToast(t('toasts.delete.error'), error.message));
    }
  }

  const people = props.budget.definitionType === 'GLOBAL' ?
    props.budget.budgetsOrganizers.length :
    props.users.length;

  return (
    <VStack bg="white" borderRadius={4} border="1px solid" borderColor="blue.50" p={4} spacing={5} w="100%">
      <HStack w="100%" justifyContent="space-between" h="16px">
        <Text fontSize={16} fontWeight={700} noOfLines={1}>
          {props.budget.name}
        </Text>

        <Spacer />

        <Badge variant={props.budget.definitionType === 'GLOBAL' ? 'blue' : 'teal'}>{props.budget.definitionType}</Badge>

        <DotMenu size="sm" color="black" placement="bottom-end">
          <BudgetDrawer budget={props.budget}>
            <MenuItemWrapper icon="edit" label={t('menu.edit')} />
          </BudgetDrawer>
          <BudgetSpendingStatementDrawer budget={props.budget}>
            <MenuItemWrapper icon="file" label={t('menu.statement')} />
          </BudgetSpendingStatementDrawer>
          <ConfirmModal
            title={t('modals.delete.title')}
            message={<Trans i18nKey='budgets:modals.delete.text' values={{ name: props.budget.name }} />}
            buttonLabel={t('common:buttons.delete')}
            onConfirm={onDelete}
          >
            <MenuItemWrapper icon="trash" label={t('menu.delete')} />
          </ConfirmModal>
        </DotMenu>
      </HStack>

      <Divider />

      <HStack w="100%" justifyContent="space-between">
        <Box w="95px">
          <Text lineHeight={3} fontWeight={700}>
            { t('fields.frequency') }
          </Text>
          <Text lineHeight={3} color="gray.400" mt={2}>
            { t(`frequencies.${props.budget.frequency}`) }
          </Text>
        </Box>
        <Box w="85px">
          <Text lineHeight={3} fontWeight={700}>
            { t('fields.amount') }
          </Text>
          <Text lineHeight={3} color="gray.400" mt={2}>
            <PriceInUserCurrency value={props.budget.amount} currency={props.budget.currency} fractionDigits={0} />
          </Text>
        </Box>
        <Box w="100px">
          <Text lineHeight={3} fontWeight={700}>
            { t(`fields.${props.budget.definitionType === 'GLOBAL' ? 'organizers' : 'people' }`) }
          </Text>
          <Text lineHeight={3} color={people > 0 ? 'gray.400' : 'red.500' } mt={2}>
            { people }
          </Text>
        </Box>
      </HStack>
    </VStack>
  );
}