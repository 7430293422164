
import { CollectionExperienceVoteBody, CollectionUpdateBody } from '@jurnee/common/src/dtos/collections';
import { Collection, CollectionJSON } from '@jurnee/common/src/entities/Collection';
import { CollectionExperience, CollectionExperienceJSON } from '@jurnee/common/src/entities/CollectionExperience';
import { createAsyncThunk } from '@reduxjs/toolkit';
import router from 'src/router';
import api from '../../api/collections';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';

export const getCollection = createAsyncThunk<CollectionJSON, Collection['id'], { state: RootState }>('COLLECTION_GET', async (id, thunkAPI) => {
  try {
    return api.getCollection(id);
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while retrieving list`, status: 'error' }));

    return thunkAPI.rejectWithValue(e.message);
  }
});

export const deleteCollection = createAsyncThunk<CollectionJSON, Collection, { state: RootState }>('COLLECTION_DELETE', async (collection, thunkAPI) => {
  try {
    const deletedCollection = await api.deleteCollection(collection.id);

    router.navigate('/bookings');

    thunkAPI.dispatch(showToast({ title: `${deletedCollection.title} has been successfully deleted`, status: 'success' }));

    return deletedCollection;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while deleting list`, status: 'error' }));

    return thunkAPI.rejectWithValue(e.message);
  }
});

export const updateCollection = createAsyncThunk<CollectionJSON, CollectionUpdateBody & { collectionId: Collection['id'] }, { state: RootState }>('COLLECTION_UPDATE', async (data, thunkAPI) => {
  try {
    const { collectionId, ...body } = data;

    const collection = await api.updateCollection(collectionId, body);

    thunkAPI.dispatch(showToast({ title: `${collection.title} has been successfully updated`, status: 'success' }));

    return collection;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while updating list`, status: 'error' }));

    return thunkAPI.rejectWithValue(e.message);
  }
});

export const removeFromCollection = createAsyncThunk<CollectionExperienceJSON, CollectionExperience, { state: RootState }>('COLLECTION_REMOVE_FROM', async (collectionExperience, thunkAPI) => {
  try {
    const { collectionId, experienceId } = collectionExperience;

    return api.removeFromCollection(collectionId, experienceId);
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while removing the experience`, status: 'error' }));
  }
});

export const upvoteExperience = createAsyncThunk<CollectionExperienceJSON, CollectionExperienceVoteBody, { state: RootState }>('COLLECTION_EXPERIENCE_UPVOTE', async (body, thunkAPI) => {
  try {
    return api.upvoteExperience(body);
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: e.message ?? `An error occurred while upvoting this experience`, status: 'error' }));

    return thunkAPI.rejectWithValue(e.message);
  }
});

export const downvoteExperience = createAsyncThunk<CollectionExperienceJSON, CollectionExperienceVoteBody, { state: RootState }>('COLLECTION_EXPERIENCE_DOWNVOTE', async (body, thunkAPI) => {
  try {
    return api.downvoteExperience(body);
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: e.message ?? `An error occurred while downvoting this experience`, status: 'error' }));

    return thunkAPI.rejectWithValue(e.message);
  }
});

export const incrementViews = createAsyncThunk<void, Collection['id'], { state: RootState }>('COLLECTION_INCREMENT_VIEWS', async (body, thunkAPI) => {
  try {
    await api.incrementViews(body);
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});