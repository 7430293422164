import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { sortByUserLabel } from '@jurnee/common/src/utils/user';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizerDotMenu } from './OrganizerDotMenu';

interface Props {
  organizers: UserDetails[];
  mainOrganizerId: UserDetails['id'];
  onRemove(organizer: UserDetails): void;
}

export function OrganizersTable({ organizers, mainOrganizerId, onRemove }: Props) {
  const { t } = useTranslation(['booking', 'common']);

  const sortedOrganizers = useMemo(() => sortByUserLabel(organizers), [organizers]);

  const rows = sortedOrganizers.map((organizer, idx) => {
    return (
      <Tr key={idx}>
        <Td>{organizer.firstName || '-'}</Td>
        <Td>{organizer.lastName || '-'}</Td>
        <Td>{organizer.email}</Td>
        <Td pl={0} pr={3} textAlign="right">
          {
            organizer.id !== mainOrganizerId &&
              <OrganizerDotMenu organizer={organizer} onRemove={onRemove} />
          }
        </Td>
      </Tr>
    );
  });

  return (
    <TableContainer w="100%">
      <Table>
        <Thead>
          <Tr>
            <Th w="30%">{t('common:fields.firstName.label')}</Th>
            <Th w="30%">{t('common:fields.lastName.label')}</Th>
            <Th w="30%">{t('common:fields.email.label')}</Th>
            <Th w="32px"></Th>
          </Tr>
        </Thead>
        <Tbody>{rows}</Tbody>
      </Table>
    </TableContainer>
  );
}