import { ErrorPage } from '@jurnee/common/src/components/ErrorPage';
import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import Registration from './pages/Registration';
import { Sso } from './pages/Sso';
import { Survey } from './pages/Survey';

const router = createBrowserRouter([
  { path: '/bookings/:cuid/registrations/:registrationId', element: <Registration/> },
  { path: '/bookings/:cuid/surveys/:surveyId', element: <Survey /> },
  { path: '/login/sso', element: <Sso/> },
  { path: '*', element: <App />, errorElement: <ErrorPage/> }
]);

export default router;