import { useToast } from '@chakra-ui/react';
import { DotMenu } from '@jurnee/common/src/components/DotMenu';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { TeamJSON } from '@jurnee/common/src/entities/Team';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { useTranslation } from 'react-i18next';
import api from 'src/api/bookings';
import { EditBookingDrawer } from 'src/drawers/EditBookingDrawer';

interface Props {
  bookingInvoice: BookingInvoiceJSON;
  budgets: BudgetJSON[];
  teams: TeamJSON[];
}

export function InvoiceDotMenu({ bookingInvoice, budgets, teams }: Props) {
  const toast = useToast();
  const { t } = useTranslation('billing', { keyPrefix: 'invoices.table.dotMenu' });

  if (!['OPEN', 'PAID'].includes(bookingInvoice.status)) {
    return null;
  }

  async function onClick() {
    try {
      const payload = await api.getBookingInvoiceFileUrl(bookingInvoice.bookingId, bookingInvoice.providerInvoiceId);

      window.open(payload.providerInvoiceFileUrl, '_blank');
    } catch(error) {
      toast(getErrorToast(t('toasts.error'), error.message));
    }
  }

  return (
    <DotMenu size="sm" color="black" placement="bottom-end">
      <EditBookingDrawer booking={bookingInvoice.booking} budgets={budgets} teams={teams}>
        <MenuItemWrapper icon="edit" label={t('edit')} />
      </EditBookingDrawer>

      <MenuItemWrapper icon="download" label={t('download')} onClick={onClick} />
    </DotMenu>
  );
}