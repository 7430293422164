import {
  Heading,
  HStack,
  Square,
  Text,
  VStack
} from '@chakra-ui/react';
import { Icon, IconKey } from '@jurnee/common/src/components/Icon';
import * as React from 'react';

interface OwnProps {
  value: string;
  iconName: IconKey;
  label: string;
}

export default class CollectionMetric extends React.PureComponent<OwnProps> {

  render() {
    return (
      <HStack>
        <Square size="40px" bg="gray.100" borderRadius={8}>
          <Icon size={6} icon={this.props.iconName} color="blue" />
        </Square>
        <VStack alignItems="flex-start" spacing={0} pl={1}>
          <Heading size="md" noOfLines={1}>
            {this.props.value}
          </Heading>
          <Text color="gray.400" fontSize={14} lineHeight="16px" noOfLines={1}>
            {this.props.label}
          </Text>
        </VStack>
      </HStack>
    );
  }

}