import { BookingParticipantJSON } from '../entities/BookingParticipant';
import { ParticipantJSON } from '../entities/Participant';
import { trimFullName } from './user';

const EMPTY_LABEL_VALUE = '-';

export function getParticipantLabel(participant: Pick<ParticipantJSON, 'firstName' | 'lastName'>) {
  if (participant.firstName) {
    return trimFullName(participant);
  } else {
    return EMPTY_LABEL_VALUE;
  }
}

export function sortParticipantsByLabel(list: BookingParticipantJSON[]) {
  return [...list].sort((a,b) => {
    const labelA = getParticipantLabel(a.participant);
    const labelB = getParticipantLabel(b.participant);

    if (labelA === EMPTY_LABEL_VALUE) {
      return 1;
    }

    if (labelB === EMPTY_LABEL_VALUE) {
      return -1;
    }

    return labelA.localeCompare(labelB);
  });
}