import { FormControl, FormLabel, HStack, Textarea, VStack } from '@chakra-ui/react';
import { ApprovalResponseUpdateBody } from '@jurnee/common/src/dtos/approvalResponse';
import { ApprovalResponseJSON } from '@jurnee/common/src/entities/ApprovalResponse';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { ConfirmModal } from '@jurnee/common/src/modals/ConfirmModal';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ProcessNumberInput } from 'src/components/ProcessNumberInput';
import { PrimaryButton } from 'src/components/buttons';
import { ApprovalResponseUpdatePayload } from 'src/store/approvalRequests/approvalRequests.thunks';

interface OwnProps {
  approvalResponse: ApprovalResponseJSON;
  processNumber: BookingJSON['processNumber'];
  onUpdate(payload: Pick<ApprovalResponseUpdatePayload, 'approvalResponse'| 'data'>): void;
}

type Props = OwnProps & WithTranslation;

type State = {
  data: Omit<ApprovalResponseUpdateBody, 'status'>;
  isUpdating: boolean;
};

class ApprovalResponseForm extends React.Component<Props> {

  state: State = {
    data: {
      processNumber: this.props.processNumber,
      comment: this.props.approvalResponse.comment,
    },
    isUpdating: false
  };

  updateApprovalResponse = async (status: ApprovalResponseJSON['status']) => {
    this.setState({ ...this.state, isUpdating: true });

    const { data } = this.state;

    await this.props.onUpdate({
      approvalResponse: this.props.approvalResponse,
      data: {
        ...data,
        status
      }
    });

    this.setState({ ...this.state, isUpdating: false });
  };

  onChange = ({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [target.name]: target.value
      }
    });
  };

  render() {
    return (
      <VStack w="100%" p={5} spacing={5}>
        <ProcessNumberInput
          size="sm"
          defaultValue={this.state.data.processNumber}
          onChange={this.onChange}
        />

        <FormControl id='comment'>
          <FormLabel>{this.props.t('approvalResponse.form.comment.label')}</FormLabel>
          <Textarea
            size="sm"
            name="comment"
            height="118px"
            fontSize="14px"
            resize="none"
            placeholder={this.props.t('approvalResponse.form.comment.placeholder')}
            _placeholder={{ color: 'gray.400' }}
            onChange={this.onChange}
            defaultValue={this.state.data.comment}
          />
        </FormControl>

        <HStack w="100%" spacing={5}>
          <ConfirmModal
            title={this.props.t('approvalResponse.confirmationModal.reject.title')}
            message={this.props.t('approvalResponse.confirmationModal.reject.message')}
            buttonLabel={this.props.t('approvalResponse.reject')}
            onConfirm={() => this.updateApprovalResponse('REJECTED')}
          >
            <PrimaryButton size="sm" w="50%" colorScheme="pink" type="submit" isDisabled={this.state.isUpdating}>
              {this.props.t('approvalResponse.reject')}
            </PrimaryButton>
          </ConfirmModal>

          <ConfirmModal
            buttonColor="teal"
            title={this.props.t('approvalResponse.confirmationModal.approve.title')}
            message={this.props.t('approvalResponse.confirmationModal.approve.message')}
            buttonLabel={this.props.t('approvalResponse.approve')}
            onConfirm={() => this.updateApprovalResponse('APPROVED')}
          >
            <PrimaryButton size="sm" w="50%" colorScheme="teal" type="submit" isDisabled={this.state.isUpdating}>
              {this.props.t('approvalResponse.approve')}
            </PrimaryButton>
          </ConfirmModal>
        </HStack>
      </VStack>
    );
  }

}

export default withTranslation('approvalRequests')(ApprovalResponseForm);