import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { Loader } from '@jurnee/common/src/components/Loader';
import { CollectionExperienceVoteBody } from '@jurnee/common/src/dtos/collections';
import { Collection } from '@jurnee/common/src/entities/Collection';
import { CollectionExperience } from '@jurnee/common/src/entities/CollectionExperience';
import { distanceToNow, isDatePast } from '@jurnee/common/src/utils/dates';
import * as React from 'react';
import { connect } from 'react-redux';
import { downvoteExperience, getCollection, incrementViews, upvoteExperience } from 'src/store/collectionDetails/collectionDetails.thunks';
import { RootState } from 'src/store/state';
import { RouteProps } from '../Route';
import CollectionExperienceCard from './CollectionExperienceCard';

interface StateProps {
  collection: RootState['collectionDetails'];
}

interface DispatchProps {
  downvoteExperience(body: CollectionExperienceVoteBody): void;
  getCollection(id: Collection['id']): void;
  incrementViews(id: Collection['id']): void;
  upvoteExperience(body: CollectionExperienceVoteBody): void;
}

type Props = StateProps & DispatchProps & RouteProps;

class PollDetails extends React.Component<Props> {

  componentDidMount() {
    const { searchParams } = new URL(window.location.href);

    if (searchParams.has('id')) {
      const id = searchParams.get('id');

      this.props.getCollection(id);
      this.props.incrementViews(id);
    }
  }

  onUpvote = ({ collectionId, experienceId }: CollectionExperience) => {
    this.props.upvoteExperience({ collectionId, experienceId });
  };

  onDownvote = ({ collectionId, experienceId }: CollectionExperience) => {
    this.props.downvoteExperience({ collectionId, experienceId });
  };

  get experiencesList() {
    return (
      <VStack mt={16} spacing={8} maxW={800} mx="auto" alignItems="flex-start">
        {this.props.collection.data.collectionsExperiences.map((collectionExperience) => (
          <CollectionExperienceCard
            key={collectionExperience.experienceId}
            collectionExperience={collectionExperience}
            onUpvote={this.onUpvote}
            onDownvote={this.onDownvote}
          />
        ))}
      </VStack>
    );
  }

  get deadline() {
    if (!this.props.collection.data.pollDeadline) {
      return null;
    }

    const date = new Date(this.props.collection.data.pollDeadline);
    const label = isDatePast(date) ? 'Poll is closed' : `Voting ends ${distanceToNow(date)}`;

    return (
      <Text color="gray.500" textAlign="center" mt={4}>
        {label}
      </Text>
    );
  }

  render() {
    if (this.props.collection.status !== FETCH_STATUS.FETCHED) {
      return <Loader overlay/>;
    }

    return (
      <Box py={24}>
        <Heading textAlign="center">What is your favorite activity?</Heading>
        { this.deadline }
        { this.experiencesList }
      </Box>
    );
  }
}

function mapStateToProps(state: RootState): StateProps {
  return {
    collection: state.collectionDetails
  };
}

const mapDispatchToProps: DispatchProps = {
  downvoteExperience,
  getCollection,
  incrementViews,
  upvoteExperience
};

export default connect<StateProps, DispatchProps, RouteProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(PollDetails);