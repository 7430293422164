import { Divider, Text, VStack } from '@chakra-ui/react';
import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { Loader } from '@jurnee/common/src/components/Loader';
import { QuoteCreateBody } from '@jurnee/common/src/dtos/quotes';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { QuoteJSON } from '@jurnee/common/src/entities/Quote';
import { getBookingInvoicesTotalDetails } from '@jurnee/common/src/utils/bookingInvoices';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AwaitingInfoCard from 'src/components/Booking/AwaitingInfoCard';
import ProcessNumber from 'src/components/Booking/ProcessNumber';
import BookingPriceDetailsCard from 'src/components/BookingPriceDetailsCard';
import { ProcessNumberInput } from 'src/components/ProcessNumberInput';
import QuoteRecipientSelector, { State as RecipientData, defaultState as defaultRecipientData } from 'src/components/QuoteRecipientSelector';
import { PrimaryButton } from 'src/components/buttons';
import { getEmployees } from 'src/store/employees/employees.thunks';
import { RootState } from 'src/store/state';
import Quotes from '../../Quotes';

interface OwnProps {
  quote: QuoteJSON | null;
  processNumber: BookingJSON['processNumber'];
  bookingsInvoices: BookingInvoiceJSON[];
  disabled: boolean;
  onSubmit(data: QuoteCreateBody): Promise<void>;
}

interface DispatchProps {
  getEmployees(): void;
}

interface StateProps {
  employees: RootState['employees'];
  user: RootState['user'];
}

type Props = OwnProps & DispatchProps & StateProps & WithTranslation;

type State = {
  recipientData: RecipientData;
  processNumber: BookingJSON['processNumber'];
  isSubmitting: boolean;
};

class QuoteCard extends React.Component<Props> {

  state: State = {
    recipientData: {
      ...defaultRecipientData,
      data: {
        ...defaultRecipientData.data,
        employee: this.props.user,
        employeeFirstName: this.props.user.firstName,
        employeeLastName: this.props.user.lastName
      }
    },
    processNumber: this.props.processNumber,
    isSubmitting: false
  };

  onSubmit = async () => {
    this.setState({ ...this.state, isSubmitting: true });

    const { employee, employeeFirstName, employeeLastName, firstName, lastName, email } = this.state.recipientData.data;

    if (this.state.recipientData.selectedRadio === 'SEND_TO_EMAIL') {
      await this.props.onSubmit({
        email,
        firstName,
        lastName,
        processNumber: this.state.processNumber
      });
    } else {
      await this.props.onSubmit({
        email: employee.email,
        userId: employee.id,
        firstName: employeeFirstName,
        lastName: employeeLastName,
        processNumber: this.state.processNumber
      });
    }

    this.setState({ ...this.state, isSubmitting: false });
  };

  onProcessNumberChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, processNumber: target.value });
  };

  get processNumber() {
    if (this.props.quote) {
      if (!this.props.processNumber) {
        return null;
      }

      return <ProcessNumber label={this.props.t('processNumber')} processNumber={this.props.processNumber} />;
    }

    return <ProcessNumberInput
      defaultValue={this.props.processNumber}
      isDisabled={!!this.props.quote}
      onChange={this.onProcessNumberChange}
    />;
  }

  get quoteSection() {
    if (this.props.quote) {
      return (
        <VStack w="100%" alignItems="flex-start" spacing={5}>
          <VStack w="100%" alignItems="flex-start" spacing={3}>
            <VStack w="100%" alignItems="flex-start" spacing={1}>
              <Text fontSize={16} fontWeight={700}>{this.props.t(`steps.commitment.quote.form.requireQuote.label`)}</Text>
              <Text fontSize={14} color="gray.400">{this.props.t(`steps.commitment.quote.form.requireQuote.description`)}</Text>
            </VStack>

            <Quotes quotes={[this.props.quote]} bookingsInvoices={this.props.bookingsInvoices} />
          </VStack>

          <Divider />

          <BookingPriceDetailsCard
            subtotal={this.priceDetails.subtotal}
            totalTax={this.priceDetails.totalTax}
            totalDiscount={this.priceDetails.totalDiscount}
            total={this.priceDetails.total}
            currency={this.priceDetails.currency}
            showCurrencyWarning={true}
          />

          <AwaitingInfoCard label={this.props.t('steps.commitment.quote.form.awaitingQuote')} />
        </VStack>
      );
    }

    return (
      <VStack w="100%" alignItems="flex-start" spacing={5}>
        <Text lineHeight="16px" fontWeight={700}>{this.props.t('steps.commitment.quote.form.title')}</Text>

        <QuoteRecipientSelector
          user={this.props.user}
          employees={this.props.employees.list}
          currentUserSelectLabel={this.props.t('steps.commitment.quote.form.currentUserSelectLabel.label')}
          selectLabel={this.props.t('steps.commitment.quote.form.select.label')}
          formLabel={this.props.t('steps.commitment.quote.form.email.label')}
          onChange={recipientData => this.setState({ ...this.state, recipientData })}
        />

        <Divider />

        <BookingPriceDetailsCard
          subtotal={this.priceDetails.subtotal}
          totalTax={this.priceDetails.totalTax}
          totalDiscount={this.priceDetails.totalDiscount}
          total={this.priceDetails.total}
          currency={this.priceDetails.currency}
          showCurrencyWarning={true}
        />

        <PrimaryButton
          w="100%"
          type="submit"
          colorScheme='blue'
          onClick={this.onSubmit}
          isLoading={this.state.isSubmitting}
          isDisabled={!this.state.recipientData.isValidState || this.props.disabled}
        >
          { this.props.t(`steps.commitment.quote.form.${this.state.recipientData.selectedRadio === 'SIGN_MYSELF' ? 'signQuote' : 'sendQuote'}`) }
        </PrimaryButton>
      </VStack>
    );
  }

  get priceDetails() {
    return getBookingInvoicesTotalDetails(this.props.bookingsInvoices);
  }

  render() {
    if (this.props.employees.status !== FETCH_STATUS.FETCHED) {
      return <Loader />;
    }

    return (
      <>
        { this.processNumber }

        { this.quoteSection }
      </>
    );
  }

}

function mapStateToProps(state: RootState): StateProps {
  return {
    employees: state.employees,
    user: state.user
  };
}

const mapDispatchToProps: DispatchProps = {
  getEmployees,
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('booking')(QuoteCard));