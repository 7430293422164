export function rowToString(array: (string | number | null)[]) {
  return array.map(value => value ? `"${value.toString().replace(/"/g, '""')}"` : null);
}

export function rowsToCsvData(headers: string[], rows: (string | null)[][]) {
  return [
    headers,
    ...rows
  ].map(row => row.join(','))
    .join('\n');
}