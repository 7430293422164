import { Box, Center, HStack, VStack } from '@chakra-ui/react';

interface Props {
  onMouseDown(): void;
  onMouseLeave(): void;
}

export function DotHandle(props: Props) {
  return (
    <Center
      borderRadius={4}
      bgColor="gray.100"
      p={1}
      cursor="grab"
      onMouseDown={props.onMouseDown}
      onMouseLeave={props.onMouseLeave}
    >
      <VStack spacing="2px">
        {[1,2,3].map(i =>
          <HStack key={i} spacing="2px">
            {[1,2].map(j =>
              <Box key={j} bgColor="black" w="2px" h="2px" />
            )}
          </HStack>
        )}
      </VStack>
    </Center>
  );
}