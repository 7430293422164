import { Box, HStack, Input, Text, VStack } from '@chakra-ui/react';
import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { Loader } from '@jurnee/common/src/components/Loader';
import { ProviderDiscountJSON } from '@jurnee/common/src/entities/ProviderDiscount';
import { formatShortWithTime } from '@jurnee/common/src/utils/dates';
import * as React from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/buttons';
import { RootState } from 'src/store/state';

interface Props extends WithTranslation {
  providerDiscounts: RootState['providerDiscounts'];
  onAddDiscountCode(entityId: ProviderDiscountJSON['entityId'], value: string): void;
}

interface State {
  discountCode: string;
}

class DiscountCode extends React.Component<Props, State> {

  state: State = {
    discountCode: ''
  };

  get providerDiscount() {
    return this.props.providerDiscounts.list[0];
  }

  onDiscountCodeChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      discountCode: target.value
    });
  };

  onAddDiscountCode = (entityId: ProviderDiscountJSON['entityId']) => {
    this.props.onAddDiscountCode(entityId, this.state.discountCode);
  };

  renderNoDiscountApplied(providerDiscount: ProviderDiscountJSON) {
    return (
      <Box p={5} w="100%" bg="white" border="1px solid" borderColor="blue.50" borderRadius={4}>
        <VStack spacing={5}>
          <Text textAlign="center">
            {this.props.t('noDiscountApplied')}
          </Text>
          <HStack>
            <Input size="sm" bg="white" onChange={this.onDiscountCodeChange} />
            <PrimaryButton size="sm" onClick={() => this.onAddDiscountCode(providerDiscount.entityId)}>
              {this.props.t('addButton')}
            </PrimaryButton>
          </HStack>
        </VStack>
      </Box>
    );
  }

  renderValidUntil(endDate: Date) {
    if (!endDate) {
      return null;
    }

    return (
      <Text textAlign="center">
        {endDate && this.props.t('validUntil', { endDate: formatShortWithTime(endDate) })}
      </Text>
    );
  }

  render() {
    if (this.props.providerDiscounts.status !== FETCH_STATUS.FETCHED) {
      return <Loader h={400} />;
    }

    if (!this.providerDiscount.coupon) {
      return this.renderNoDiscountApplied(this.providerDiscount);
    }

    const { name, percent_off, redeem_by } = this.providerDiscount.coupon;
    const endDate = redeem_by ? new Date(redeem_by * 1000) : null;

    return (
      <Box p={5} w="100%" bg="white" border="1px solid" borderColor="blue.50" borderRadius={4}>
        <Text textAlign="center">
          <Trans i18nKey='billing:discount.description' values={{ name, percent_off }} />
        </Text>

        { this.renderValidUntil(endDate) }
      </Box>
    );
  }

}

export default withTranslation('billing', { keyPrefix: 'discount' })(DiscountCode);