import { RegistrationUpsertBody } from '@jurnee/common/src/dtos/registrations';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { RegistrationJSON } from '@jurnee/common/src/entities/Registration';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createRegistration } from 'src/api/registrations';
import { RootState } from '../state';

interface RegistrationUpsertPayload {
  bookingId: BookingJSON['id'];
  data: RegistrationUpsertBody;
}

export const createRegistrationThunk = createAsyncThunk<RegistrationJSON, RegistrationUpsertPayload, { state: RootState }>('REGISTRATIONS_CREATE', async ({ bookingId, data }, thunkAPI) => {
  try {
    const registration = await createRegistration({ bookingId }, data);
    return registration;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});