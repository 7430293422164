import { StatusBadge } from '@jurnee/common/src/components/StatusBadge';
import { useTranslation } from 'react-i18next';

function getStatusBadgeColor(status: string) {
  switch (status) {
  case 'CANCELED': return 'gray';
  case 'DONE': return 'teal';
  case 'IN_PROGRESS': return 'orange';
  default: return 'blue';
  }
}

export function TaskStatus({ status }: { status: string }) {
  const { t } = useTranslation('tasks', { keyPrefix: 'status' });

  return <StatusBadge
    label={t(status)}
    color={getStatusBadgeColor(status)}
  />;
}