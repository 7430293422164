import { Box, Checkbox, FormControl, FormLabel, HStack, Text, Textarea } from '@chakra-ui/react';
import { BookingDeadlineTooltip } from '@jurnee/common/src/components/BookingDeadlineTooltip';
import { DatePicker } from '@jurnee/common/src/components/DatePicker';
import { TimeSlotSelector } from '@jurnee/common/src/components/TimeSlotSelector';
import { add } from '@jurnee/common/src/utils/dates';
import { useTranslation } from 'react-i18next';
import { DEFAULT_BOOKING_DEADLINE } from 'src/utils/booking';

interface Props {
  date: Date | null;
  comment: string;
  isFlexibleDate: boolean;
  onDateChange(date: Date): void;
  onTimeChange(time: string): void;
  onIsFlexibleChange(isFlexible: boolean): void
  onCommentChange(comment: string): void
}

export function DateTimeSelector(props: Props) {
  const { t } = useTranslation(['booking', 'common']);
  const today = new Date();
  const minDate = add(today, DEFAULT_BOOKING_DEADLINE);
  const hasTooltip = props.date && props.date < minDate;

  function onDateChange(date: Date) {
    if (!date) {
      return;
    }

    props.onDateChange(date);
  }

  return (
    <FormControl id="date" isRequired>
      <HStack justifyContent="space-between">
        <HStack spacing={0}>
          <FormLabel mr={2}>{t('modals.customItem.dateTime.label')}</FormLabel>
          { hasTooltip && <BookingDeadlineTooltip position="absolute" top={0} /> }
        </HStack>

        <HStack spacing={2} pb={2}>
          <Text fontWeight={500}>{t('modals.customItem.dateTime.flexible.label')}</Text>

          <Checkbox
            id="isFlexibleDate"
            isChecked={props.isFlexibleDate}
            onChange={e => props.onIsFlexibleChange(e.target.checked)}
          />
        </HStack>
      </HStack>

      <HStack spacing={5}>
        <Box w="100%">
          <DatePicker
            popperPlacement="bottom-start"
            dateFormat="dd MMM yyyy"
            selected={props.date}
            minDate={today}
            placeholderText={t('common:fields.date.placeholder')}
            onChange={onDateChange}
            inputProps={{ size: 'sm' }}
          />
        </Box>

        <TimeSlotSelector
          size="sm"
          date={props.date || new Date()}
          onPick={({ from }) => props.onTimeChange(from ? from : '')}
          placeholder={t('common:fields.time.placeholder')}
        />
      </HStack>

      {
        props.isFlexibleDate &&
          <Textarea
            size="sm"
            mt={2}
            onChange={({ target }) => props.onCommentChange(target.value)}
            rows={2}
            resize="none"
            fontSize={14}
            value={props.comment || ''}
            placeholder={t('modals.customItem.dateTime.flexible.placeholder')}
            _placeholder={{ color: 'gray.400' }}
          />
      }
    </FormControl>
  );
}