import { HStack, VStack } from '@chakra-ui/react';
import { AUTH_STATUS, FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { Loader } from '@jurnee/common/src/components/Loader';
import * as React from 'react';
import { connect } from 'react-redux';
import { Navigate, useLocation, useMatch, useNavigate, useParams } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { useAppDispatch } from 'src/store';
import { getBookings } from 'src/store/bookings/bookings.thunks';
import Help from '../components/Help';
import Navbar from '../components/Navbar/Navbar';
import { bootstrap } from '../store/bootstrap/bootstrap.thunks';
import { RootState } from '../store/state';
import { OwnRouteProps } from './Route';

const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID;

interface StateProps {
  company: RootState['company'],
  user: RootState['user'],
  bookings: RootState['bookings'],
  isAuthenticated: boolean;
  isBootstrapped: boolean;
  isBootstrapping: boolean;
}

interface DispatchProps {
  bootstrap(): void;
}

type Props = OwnRouteProps & StateProps & DispatchProps;

function AuthenticatedRoute(props: Props) {
  const { isAuthenticated, isBootstrapped, isBootstrapping, path } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const match = useMatch(path);
  const params = useParams();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (isAuthenticated && !isBootstrapped && !isBootstrapping) {
      props.bootstrap();
    }

    if (isAuthenticated && isBootstrapped && props.bookings.status === 'INITIAL') {
      const searchParams = new URLSearchParams({ after: Date.now().toString() });
      dispatch(getBookings({ scope: 'user' , searchParams }));
    }
  }, [isAuthenticated, isBootstrapped, isBootstrapping]);

  if (!isAuthenticated) {
    return <Navigate to={`/login?redirect=${location.pathname}`} />;
  }

  if (!isBootstrapped) {
    return <Loader overlay />;
  }

  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <HStack h="100%" w="100%" spacing={0}>
        <Navbar />
        <VStack m="0 !important" w="100%" h="100%" borderLeft="1px solid" borderColor="blue.50">
          { React.createElement(props.component, { location, navigate, match, params }) }
        </VStack>
        <Help />
      </HStack>
    </IntercomProvider>
  );

}

function mapStateToProps(state: RootState): StateProps {
  return {
    company: state.company,
    user: state.user,
    bookings: state.bookings,
    isBootstrapping: state.bootstrap.status === FETCH_STATUS.FETCHING,
    isBootstrapped: state.bootstrap.status === FETCH_STATUS.FETCHED,
    isAuthenticated: state.auth.status === AUTH_STATUS.AUTHENTICATED
  };
}

const mapDispatchToProps: DispatchProps = {
  bootstrap
};

export default connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps)(AuthenticatedRoute);