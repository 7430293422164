import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import {
  createBookingReview,
  createExperienceReview,
  createNps,
  createPartnerReview,
  createReview,
  getBookingByCuid
} from './reviewDetails.thunks';

const slice = createSlice({
  name: 'reviewDetails',
  initialState: initialState.reviewDetails,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getBookingByCuid.pending, (state) => {
      return {
        ...state,
        booking: { data: null, error: null, status: FETCH_STATUS.FETCHING }
      };
    });

    builder.addCase(getBookingByCuid.fulfilled, (state, action) => {
      return {
        ...state,
        booking: { data: action.payload, error: null, status: FETCH_STATUS.FETCHED }
      };
    });

    builder.addCase(getBookingByCuid.rejected, (state) => {
      return {
        ...state,
        booking: { data: null, error: true, status: FETCH_STATUS.INITIAL }
      };
    });

    builder.addCase(createReview.pending, (state) => {
      return {
        ...state,
        data: null,
        error: null,
        status: FETCH_STATUS.FETCHING
      };
    });

    builder.addCase(createReview.fulfilled, (state, action) => {
      return {
        ...state,
        data: action.payload,
        error: null,
        status: FETCH_STATUS.FETCHED
      };
    });

    builder.addCase(createReview.rejected, (state) => {
      return {
        ...state,
        data: null,
        error: true,
        status: FETCH_STATUS.INITIAL
      };
    });

    builder.addCase(createExperienceReview.pending, (state) => {
      return {
        ...state,
        experienceReview: { data: null, error: null, status: FETCH_STATUS.FETCHING }
      };
    });

    builder.addCase(createExperienceReview.fulfilled, (state, action) => {
      return {
        ...state,
        experienceReview: { data: action.payload, error: null, status: FETCH_STATUS.FETCHED }
      };
    });

    builder.addCase(createExperienceReview.rejected, (state) => {
      return {
        ...state,
        experienceReview: { data: null, error: true, status: FETCH_STATUS.INITIAL }
      };
    });

    builder.addCase(createPartnerReview.pending, (state) => {
      return {
        ...state,
        partnerReview: { data: null, error: null, status: FETCH_STATUS.FETCHING }
      };
    });

    builder.addCase(createPartnerReview.fulfilled, (state, action) => {
      return {
        ...state,
        partnerReview: { data: action.payload, error: null, status: FETCH_STATUS.FETCHED }
      };
    });

    builder.addCase(createPartnerReview.rejected, (state) => {
      return {
        ...state,
        partnerReview: { data: null, error: true, status: FETCH_STATUS.INITIAL }
      };
    });

    builder.addCase(createBookingReview.pending, (state) => {
      return {
        ...state,
        bookingReview: { data: null, error: null, status: FETCH_STATUS.FETCHING }
      };
    });

    builder.addCase(createBookingReview.fulfilled, (state, action) => {
      return {
        ...state,
        bookingReview: { data: action.payload, error: null, status: FETCH_STATUS.FETCHED }
      };
    });

    builder.addCase(createBookingReview.rejected, (state) => {
      return {
        ...state,
        bookingReview: { data: null, error: true, status: FETCH_STATUS.INITIAL }
      };
    });

    builder.addCase(createNps.pending, (state) => {
      return {
        ...state,
        nps: { data: null, error: null, status: FETCH_STATUS.FETCHING }
      };
    });

    builder.addCase(createNps.fulfilled, (state, action) => {
      return {
        ...state,
        nps: { data: action.payload, error: null, status: FETCH_STATUS.FETCHED }
      };
    });

    builder.addCase(createNps.rejected, (state) => {
      return {
        ...state,
        nps: { data: null, error: true, status: FETCH_STATUS.INITIAL }
      };
    });

  }
});

export default slice.reducer;