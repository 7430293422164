import { HStack, Text, VStack } from '@chakra-ui/react';
import { ApprovalResponseJSON } from '@jurnee/common/src/entities/ApprovalResponse';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import * as React from 'react';

interface Props {
  user: UserJSON;
  status: JSX.Element;
  comment: ApprovalResponseJSON['comment'];
  date: string;
}

export default class ApprovalStatus extends React.PureComponent<Props> {

  render() {
    return (
      <VStack w="100%" spacing={1} pl={3} paddingY="10px" borderLeft="4px solid" borderColor="gray.200" alignItems="flex-start">
        <HStack w="100%" justifyContent="space-between">
          <Text fontSize={14} noOfLines={1} fontWeight={700} lineHeight="16px">{getUserLabel(this.props.user)}</Text>
          { this.props.status }
        </HStack>

        {this.props.comment && <Text fontSize={14} my={2} fontStyle="italic" lineHeight="16px">{this.props.comment}</Text>}

        <Text fontSize={14} color="gray.400" lineHeight="16px">{this.props.date}</Text>
      </VStack>
    );
  }

}