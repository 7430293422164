import { Heading, SimpleGrid, VStack, useToast } from '@chakra-ui/react';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getSlackAuthorizationUrl } from 'src/api/integrations';
import { getCompanySelector } from 'src/store/company/company.selector';
import { IntegrationCard } from './IntegrationCard';

export function Integrations() {
  const { t } = useTranslation('settings');
  const toast = useToast();

  const { integrations } = useSelector(getCompanySelector);
  const [isSlackLoading, setIsSlackLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const error = searchParams.get('error');

  useEffect(() => {
    if (error) {
      toast(getErrorToast(t(`integrations.errors.${error}`)));
    }
  }, []);

  async function onSlackConnect() {
    setIsSlackLoading(true);
    const { redirectUri } = await getSlackAuthorizationUrl();
    document.location.href = redirectUri;
  }

  return (
    <VStack w="100%" spacing={8} alignItems="flex-start">
      <Heading>{t('header.tabs.integrations')}</Heading>

      <SimpleGrid spacing={8} minChildWidth="360px">
        <IntegrationCard
          maxW="360"
          name="slack"
          onConnect={onSlackConnect}
          isLoading={isSlackLoading}
          isConnected={integrations.some(({ provider }) => provider === 'SLACK')}
        />
      </SimpleGrid>
    </VStack>
  );
}