import { useTranslation } from 'react-i18next';
import EmptyState from 'src/components/EmptyState';

export function CanceledStep() {
  const { t } = useTranslation('booking');

  return <EmptyState
    imagePath="/assets/illustrations/circle_x.svg"
    heading={t('emptyStates.canceled.heading')}
    description={t('emptyStates.canceled.description')}
  />;
}