import { HStack, Input, Select, VStack } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { ApprovalProcessJSON } from '@jurnee/common/src/entities/ApprovalProcess';
import { RoleJSON } from '@jurnee/common/src/entities/Role';
import { TeamJSON } from '@jurnee/common/src/entities/Team';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { getUserLabel, getUserRole } from '@jurnee/common/src/utils/user';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import InviteEmployeesModal from 'src/modals/InviteEmployeesModal';
import { useAppDispatch } from 'src/store';
import { getApprovalProcessesFetchStatusSelector, getApprovalProcessesSelector } from 'src/store/approvalProcesses/approvalProcesses.selectors';
import { getBudgetsByDefinitionType, getBudgetsFetchStatusSelector } from 'src/store/budgets/budgets.selectors';
import { getBudgets } from 'src/store/budgets/budgets.thunks';
import { getEmployeesFetchStatusSelector, getEmployeesSelector } from 'src/store/employees/employees.selectors';
import { getTeamsFetchStatusSelector, teamsSelectors } from 'src/store/teams/teams.selectors';
import { getTeams } from 'src/store/teams/teams.thunks';
import { getUserSelector } from 'src/store/user/user.selectors';
import { deleteEmployee, sendInvitation } from '../../../store/employees/employees.thunks';
import EmployeesTable from './EmployeesTable';

interface ApprovalProcessSelectProps {
  approvalProcesses: ApprovalProcessJSON[];
  value: ApprovalProcessJSON['id'];
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
}

interface NameInputProps {
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
}

interface RoleSelectProps {
  value: RoleJSON['key'];
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
}

interface TeamSelectProps {
  teams: TeamJSON[];
  value: TeamJSON['id'];
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
}

interface UsersFilters {
  approvalProcessId: number;
  roleKey: RoleJSON['key'];
  teamId: number;
  nameInput: string;
}

function ApprovalProcessSelect(props: ApprovalProcessSelectProps) {
  if (props.approvalProcesses.length === 0) {
    return null;
  }

  const { t } = useTranslation('people', { keyPrefix: 'filters.approvalProcesses' });

  return (
    <Select size="sm" minW="185px" maxW="215px" onChange={props.onChange} value={props.value}>
      <option value={0}>{t('all')}</option>
      {
        sortAlphabeticallyBy(props.approvalProcesses, 'name').map(({ id, name }) => {
          return <option key={id} value={id}>{name}</option>;
        })
      }
    </Select>
  );
}

function NameInput(props: NameInputProps) {
  const { t } = useTranslation('people', { keyPrefix: 'filters.name' });

  return <Input
    size="sm"
    minW="185px"
    maxW="215px"
    type="text"
    name="nameSearch"
    placeholder={t('placeholder')}
    onChange={props.onChange}
  />;
}

function RoleSelect(props: RoleSelectProps) {
  const { t } = useTranslation('people', { keyPrefix: 'filters.roles' });

  return (
    <Select size="sm" minW="185px" maxW="215px" onChange={props.onChange} value={props.value}>
      <option value=''>{t('all')}</option>
      <option value='ADMIN'>{t('admins')}</option>
      <option value='ORGANIZER'>{t('organizers')}</option>
    </Select>
  );
}

function TeamSelect(props: TeamSelectProps) {
  if (props.teams.length === 0) {
    return null;
  }

  const { t } = useTranslation('people', { keyPrefix: 'filters.teams' });

  return (
    <Select size="sm" minW="185px" maxW="215px" onChange={props.onChange} value={props.value}>
      <option value={0}>{t('all')}</option>
      {
        sortAlphabeticallyBy(props.teams, 'name').map(({ id, name }) => {
          return <option key={id} value={id}>{name}</option>;
        })
      }
    </Select>
  );
}

function filterUsers(users: UserDetails[], { teamId, roleKey, approvalProcessId, nameInput }: UsersFilters) {
  if (!teamId && !roleKey && !approvalProcessId && isEmpty(nameInput)) {
    return users;
  }

  return users.filter(user => {
    const hasTeam = teamId ? user.usersTeams.some(({ team }) => team.id === teamId) : true;
    const hasRole = !isEmpty(roleKey) ? getUserRole(user)?.key === roleKey : true;
    const hasApprovalProcess = approvalProcessId ? user.approvalProcessId === approvalProcessId : true;
    const matchName = !isEmpty(nameInput) ? new RegExp(nameInput, 'gi').test(getUserLabel(user)) : true;

    return hasTeam && hasRole && hasApprovalProcess && matchName;
  });
}

export default function Employees() {
  const dispatch = useAppDispatch();

  const approvalProcesses = useSelector(getApprovalProcessesSelector);
  const budgets = useSelector(getBudgetsByDefinitionType('INDIVIDUAL'));
  const employees = useSelector(getEmployeesSelector);
  const teams = useSelector(teamsSelectors.selectAll);
  const user = useSelector(getUserSelector);

  const approvalProcessesFetchStatus = useSelector(getApprovalProcessesFetchStatusSelector);
  const budgetsFetchStatus = useSelector(getBudgetsFetchStatusSelector);
  const employeesFetchStatus = useSelector(getEmployeesFetchStatusSelector);
  const teamsFetchStatus = useSelector(getTeamsFetchStatusSelector);

  const isLoading = [
    approvalProcessesFetchStatus,
    budgetsFetchStatus,
    employeesFetchStatus,
    teamsFetchStatus
  ].some(fetchStatus => fetchStatus !== 'FETCHED');

  const [nameInput, setNameInput] = useState<string>('');
  const [roleKey, setRoleKey] = useState<RoleJSON['key']>('');
  const [teamId, setTeamId] = useState<number>(0);
  const [approvalProcessId, setApprovalProcessId] = useState<number>(0);

  useEffect(() => {
    dispatch(getBudgets());
  }, []);

  async function onDeleteEmployee(user: UserDetails) {
    await dispatch(deleteEmployee(user));

    if (user.usersTeams.length > 0) {
      dispatch(getTeams());
    }
  }

  function onSendInvitation(user: UserDetails) {
    return dispatch(sendInvitation(user));
  }

  if (isLoading) {
    return <Loader h={400} />;
  }

  const users = filterUsers(employees, { roleKey, teamId, approvalProcessId, nameInput });

  return (
    <VStack spacing={5}>
      <HStack w="100%" justifyContent="space-between" spacing={4}>
        <HStack w="100%" justifyContent="flex-start" spacing={4}>
          <NameInput onChange={({ target }) => setNameInput(target.value)} />
          <RoleSelect value={roleKey} onChange={({ target }) => setRoleKey(target.value)} />
          <TeamSelect teams={teams} value={teamId} onChange={({ target }) => setTeamId(Number(target.value))} />
          <ApprovalProcessSelect approvalProcesses={approvalProcesses} value={approvalProcessId} onChange={({ target }) => setApprovalProcessId(Number(target.value))} />
        </HStack>

        <InviteEmployeesModal />
      </HStack>

      <EmployeesTable
        currentUser={user}
        approvalProcesses={approvalProcesses}
        budgets={budgets}
        users={users}
        teams={teams}
        onDeleteEmployee={onDeleteEmployee}
        onSendInvitation={onSendInvitation}
      />
    </VStack>
  );
}