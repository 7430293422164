import { Box, ListItem, UnorderedList } from '@chakra-ui/react';
import { QuestionAnswerJSON } from '@jurnee/common/src/entities/SurveySubmission';

interface Props {
  questionAnswers: QuestionAnswerJSON[];
}

export function AnswersText({ questionAnswers }: Props) {
  return (
    <Box
      h="160px"
      w="100%"
      bgColor="white"
      border="1px solid"
      borderColor="gray.200"
      borderRadius={4}
      overflowY="auto"
    >
      <UnorderedList spacing={2} p={2} pl={3} color="gray.400">
        {
          questionAnswers.map(questionAnswer =>
            <ListItem key={questionAnswer.id} whiteSpace="pre-wrap">
              { questionAnswer.value }
            </ListItem>
          )
        }
      </UnorderedList>
    </Box>
  );
}