import { HStack, Text } from '@chakra-ui/react';
import Avatar from '@jurnee/common/src/components/Avatar';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { getUserLabel } from '@jurnee/common/src/utils/user';

interface Props {
  user: UserDetails;
}

export function UserName({ user }: Props) {
  return (
    <HStack spacing="10px">
      <Avatar user={user} size="xs" borderRadius={4} />
      <Text isTruncated>{getUserLabel(user)}</Text>
    </HStack>
  );
}