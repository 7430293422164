import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { getTags } from './tags.thunks';

const slice = createSlice({
  name: 'tags',
  initialState: initialState.tags,
  reducers: { },
  extraReducers: (builder) => {

    builder.addCase(getTags.pending, () => {
      return { list: [], error: false, status: FETCH_STATUS.FETCHING };
    });

    builder.addCase(getTags.fulfilled, (_state, action) => {
      return { list: action.payload, error: false, status: FETCH_STATUS.FETCHED };
    });

    builder.addCase(getTags.rejected, () => {
      return { list: [], error: true, status: FETCH_STATUS.INITIAL };
    });

  }
});

export default slice.reducer;