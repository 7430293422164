import { CircularProgress, CircularProgressLabel, HStack, StackProps, Text, VStack, chakra } from '@chakra-ui/react';
import { BookingParticipantJSON } from '@jurnee/common/src/entities/BookingParticipant';
import { isRegistered } from '@jurnee/common/src/utils/bookingParticipants';
import { getUserBudgetRemaining } from '@jurnee/common/src/utils/budgets';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import PriceInUserCurrency from 'src/components/PriceInUserCurrency';
import { getUserBudgetBreakdownsByEmails } from 'src/store/userBudgetBreakdowns/userBudgetBreakdowns.selectors';

interface Props {
  bookingParticipants: BookingParticipantJSON[];
}

export function ParticipantsBudgetsChart({ bookingParticipants, ...inheritedProps }: Props & StackProps) {
  const participantEmails = bookingParticipants.map(({ participant }) => participant.email);
  const userBudgetBreakdowns = useSelector(getUserBudgetBreakdownsByEmails(participantEmails));

  const registeredBookingParticipants = bookingParticipants.filter(isRegistered);
  const registeredParticipantEmails = registeredBookingParticipants.map(({ participant }) => participant.email);
  const registeredUserBudgetBreakdowns = useSelector(getUserBudgetBreakdownsByEmails(registeredParticipantEmails));

  const committedBudget = registeredUserBudgetBreakdowns.reduce((out, userBudgetBreakdown) =>
    out += Math.max(getUserBudgetRemaining(userBudgetBreakdown), 0)
  , 0);

  const totalBudget = userBudgetBreakdowns.reduce((out, userBudgetBreakdown) =>
    out += Math.max(getUserBudgetRemaining(userBudgetBreakdown), 0)
  , 0);

  const committedPercentage = totalBudget > 0 ? Math.round(committedBudget / totalBudget * 100) : 0;

  return (
    <HStack w="100%" spacing={2} {...inheritedProps}>
      <CircularProgress
        value={committedPercentage}
        size={140}
        thickness={4}
        color="teal.400"
        capIsRound={true}
      >
        <CircularProgressLabel>
          <VStack spacing={0} pt="18px">
            <Text fontSize={14} lineHeight="18px" fontWeight={700}>
              <PriceInUserCurrency value={committedBudget} currency="EUR" />
            </Text>
            <Text fontSize={12} color="gray.400">
              <PriceInUserCurrency value={totalBudget} currency="EUR" />
            </Text>
          </VStack>
        </CircularProgressLabel>
      </CircularProgress>

      <VStack w="100%" spacing={2}>
        <Text color="gray.400">
          <Trans i18nKey="registration:budgetChart.committedBudget"
            values={{ count: registeredBookingParticipants.length }}
            components={{
              amount: <PriceInUserCurrency value={committedBudget} currency="EUR" />,
              strong: <chakra.span whiteSpace="nowrap" color="black" />
            }}
          />
        </Text>

        <Text color="gray.400">
          <Trans i18nKey="registration:budgetChart.potentialBudget"
            values={{ count: bookingParticipants.length }}
            components={{
              amount: <PriceInUserCurrency value={totalBudget} currency="EUR" />,
              strong: <chakra.span whiteSpace="nowrap" color="black" />
            }}
          />
        </Text>
      </VStack>
    </HStack>
  );
}