import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { searchPlaces } from './places.thunks';

const slice = createSlice({
  name: 'places',
  initialState: initialState.places,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(searchPlaces.pending, (state) => {
      return { ...state, list: [], error: false, status: FETCH_STATUS.FETCHING };
    });

    builder.addCase(searchPlaces.fulfilled, (state, { payload: { list }}) => {
      return { ...state, list, error: false, status: FETCH_STATUS.FETCHED };
    });

    builder.addCase(searchPlaces.rejected, (state) => {
      return { ...state, list: [], error: true, status: FETCH_STATUS.INITIAL };
    });

  }
});

export default slice.reducer;