import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultValue: BudgetJSON['definitionType'];
  isDisabled: boolean;
  onChange(e: ChangeEvent<HTMLSelectElement>): void;
}

export function DefinitionTypeSelect({ defaultValue, isDisabled, onChange }: Props) {
  const { t } = useTranslation('settings', { keyPrefix: 'budgets.drawer.form.definitionType' });

  return (
    <FormControl isRequired>
      <FormLabel>{t('label')}</FormLabel>
      <Select name="definitionType" size="sm" bg="white" defaultValue={defaultValue} onChange={onChange} isDisabled={isDisabled}>
        {['GLOBAL', 'INDIVIDUAL'].map((value) => (
          <option key={value} value={value}>{t(`options.${value}`)}</option>
        ))}
      </Select>
    </FormControl>
  );
}