import { HStack, Spacer, StackProps, Text } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { useTranslation } from 'react-i18next';
import { ExperienceCategory } from 'src/utils/experiences';

interface Props {
  category: ExperienceCategory;
}

export function ExperienceCategoryCard({ category, ...inheritedProps }: Props & StackProps) {
  const { t } = useTranslation('experiences');

  return (
    <HStack
      w="100%"
      px={2}
      py="6px"
      bg="gray.50"
      borderRadius={4}
      spacing={3}
      _hover={{ bgColor: 'gray.100' }}
      cursor="pointer"
      {...inheritedProps}
    >
      <Icon icon={category.icon} size={5} />

      <Text fontWeight={400} lineHeight="20px">
        { t(`categories.${category.type}`) }
      </Text>

      <Spacer />

      <Text fontWeight={400} lineHeight="20px">{'->'}</Text>
    </HStack>
  );
}