import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { getBookings, getPastBookings } from './bookings.thunks';

const slice = createSlice({
  name: 'bookings',
  initialState: initialState.bookings,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getBookings.pending, (state) => {
      return {
        ...state,
        list: [],
        relationships: null,
        error: null,
        status: FETCH_STATUS.FETCHING
      };
    });

    builder.addCase(getBookings.fulfilled, (state, action) => {
      const { list, relationships } = action.payload;

      return {
        ...state,
        list,
        relationships,
        error: null,
        status: FETCH_STATUS.FETCHED
      };
    });

    builder.addCase(getBookings.rejected, (state) => {
      return {
        ...state,
        list: [],
        relationships: null,
        error: true,
        status: FETCH_STATUS.INITIAL
      };
    });

    builder.addCase(getPastBookings.pending, (state) => {
      return {
        ...state,
        past: {
          list: [],
          relationships: null,
          error: null,
          status: FETCH_STATUS.FETCHING
        }
      };
    });

    builder.addCase(getPastBookings.fulfilled, (state, action) => {
      const { list, relationships } = action.payload;

      return {
        ...state,
        past: {
          list,
          relationships,
          error: null,
          status: FETCH_STATUS.FETCHED
        }
      };
    });

    builder.addCase(getPastBookings.rejected, (state) => {
      return {
        ...state,
        past: {
          list: [],
          relationships: null,
          error: true,
          status: FETCH_STATUS.INITIAL
        }
      };
    });

  }
});

export default slice.reducer;