import { UserStatsJSON } from '@jurnee/common/src/entities/User';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/user';
import { RootState } from '../state';

export const getUserStatsThunk = createAsyncThunk<UserStatsJSON, number | void, { state: RootState }>('USER_STATS_FETCH', async (userId, thunkAPI) => {
  try {
    const id = userId || thunkAPI.getState().user.id;
    const stats = await api.getUserStats(id);
    return stats;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});