import { QuestionAnswerUpdateBody } from '../dtos/surveySubmissions';
import { QuestionJSON, QuestionOptionJSON } from '../entities/Survey';
import { QuestionAnswerJSON, SurveySubmissionJSON } from '../entities/SurveySubmission';
import { rowToString, rowsToCsvData } from './csv';

export function getQuestionsAnswers(surveySubmissions: Pick<SurveySubmissionJSON, 'questionAnswers'>[]) {
  return surveySubmissions.reduce((result, { questionAnswers }) => {
    for (const answer of questionAnswers) {
      if (Array.isArray(result[answer.questionId])) {
        result[answer.questionId].push(answer);
        continue;
      }
      result[answer.questionId] = [answer];
    }

    return result;
  }, {} as Record<QuestionJSON['id'], QuestionAnswerJSON[]>);
}

function getOptionsValues(questions: QuestionJSON[]) {
  return questions.reduce((result, { questionOptions }) => {
    for (const { id, value } of questionOptions) {
      result[id] = value;
    }
    return result;
  }, {} as Record<QuestionOptionJSON['id'], QuestionOptionJSON['value']>);
}

export function generateSurveyAnswersCsv(surveySubmissions: SurveySubmissionJSON[], questions: QuestionJSON[]) {
  const optionsValues = getOptionsValues(questions);

  const rows = surveySubmissions.map(submission => {
    const row = [
      submission.participant.firstName,
      submission.participant.lastName,
      submission.participant.email
    ];

    const questionsAnswers = getQuestionsAnswers([submission]);

    for (const { id, type } of questions) {
      const values = (questionsAnswers[id] || []).map(({ value, questionOptionId }) =>
        type === 'TEXT' ? value : optionsValues[questionOptionId]
      );

      row.push(values.join(', '));
    }

    return rowToString(row);
  });

  return rowsToCsvData(
    [
      'Firstname',
      'Lastname',
      'Email',
      ...questions.map(question => question.label)
    ],
    rows
  );
}

export function getSurveyAnswersCsvFilename(name: string) {
  const string = name.toLowerCase().replace(/[^a-zA-Z0-9 -]/g, '').replace(/\s+/g, '-');
  return `survey-${string}.csv`;
}

export function areAllRequiredQuestionsAnswered(questions: QuestionJSON[], questionAnswers: QuestionAnswerUpdateBody[]) {
  const ids = questionAnswers.map(({ questionId }) => questionId);
  const requiredQuestions = questions.filter(({ required }) => required);

  return requiredQuestions.every(question => ids.includes(question.id));
}