import { ParticipantJSON } from '@jurnee/common/src/entities/Participant';
import { UserBudgetBreakdownJSON } from '@jurnee/common/src/entities/UserBudgetBreakdown';
import { createSelector } from '@reduxjs/toolkit';
import { getEmployeesByEmailsSelector } from '../employees/employees.selectors';
import { RootState } from '../state';
import { userBudgetBreakdownsAdapter } from './userBudgetBreakdowns.adapter';

export const userBudgetBreakdownsSelectors = userBudgetBreakdownsAdapter.getSelectors(
  (state: RootState) => state.userBudgetBreakdowns
);

export function getUserBudgetBreakdownsFetchStatusSelector(state: RootState) {
  return state.userBudgetBreakdowns.status;
}

export function getUserBudgetBreakdownById(userId: number) {
  return (state: RootState) => userBudgetBreakdownsSelectors.selectById(state, userId);
}

const getUserBudgetBreakdownsByIdsSelector = createSelector(
  [state => userBudgetBreakdownsSelectors.selectAll(state), (_, ids) => ids],
  (userBudgetBreakdowns: UserBudgetBreakdownJSON[], ids: number[]) => {
    return userBudgetBreakdowns.filter(({ userId }) => ids.find(id => id === userId));
  }
);

export function getUserBudgetBreakdownsByIds(userIds: number[]) {
  return (state: RootState) => getUserBudgetBreakdownsByIdsSelector(state, userIds);
}

const getUserBudgetBreakdownsByEmailsSelector = createSelector(
  [state => state, (_, emails) => emails],
  (state: RootState, emails: ParticipantJSON['email'][]) => {
    const users = getEmployeesByEmailsSelector(state, emails);
    const userIds = users.filter(user => !!user.budgetId).map(user => user.id);

    return userBudgetBreakdownsSelectors
      .selectAll(state)
      .filter(({ userId }) => userIds.includes(userId));
  }
);

export function getUserBudgetBreakdownsByEmails(emails: ParticipantJSON['email'][]) {
  return (state: RootState) => getUserBudgetBreakdownsByEmailsSelector(state, emails);
}

const getUserBudgetBreakdownByEmailSelector = createSelector(
  [state => state, (_, email) => email],
  (state: RootState, email: ParticipantJSON['email']) => {
    const user = state.employees.list.find(user => user.email === email);
    return user ? userBudgetBreakdownsSelectors.selectById(state, user.id) : null;
  }
);

export function getUserBudgetBreakdownByEmail(email: ParticipantJSON['email']) {
  return (state: RootState) => getUserBudgetBreakdownByEmailSelector(state, email);
}