import { Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/buttons';

interface Props {
  onAddQuestion(): void;
}

export function EmptyState({ onAddQuestion }: Props) {
  const { t } = useTranslation('surveys', { keyPrefix: 'drawers.survey' });

  return (
    <VStack w="100%" bgColor="white" border="1px solid" borderColor="gray.200" borderRadius={4} p={6} mt={2} spacing={2}>
      <Text color="gray.400">{t('emptyState.description')}</Text>

      <PrimaryButton size="sm" colorScheme="blue" onClick={onAddQuestion}>
        { t('addQuestion') }
      </PrimaryButton>
    </VStack>
  );
}