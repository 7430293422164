import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { bootstrap } from '../bootstrap/bootstrap.thunks';
import { initialState } from '../state';

const slice = createSlice({
  name: 'geolocation',
  initialState: initialState.geolocation,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(bootstrap.pending, () => {
      return { data: null, error: false, status: FETCH_STATUS.FETCHING };
    });

    builder.addCase(bootstrap.fulfilled, (_state, action) => {
      return { data: action.payload.geolocation, error: false, status: FETCH_STATUS.FETCHED };
    });

    builder.addCase(bootstrap.rejected, () => {
      return { data: null, error: true, status: FETCH_STATUS.INITIAL };
    });

  }
});

export default slice.reducer;