import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { createExternalCostThunk, deleteExternalCostDocumentThunk, deleteExternalCostThunk, getExternalCostsThunk, updateExternalCostThunk, uploadExternalCostDocumentThunk } from './externalCosts.thunks';

const slice = createSlice({
  name: 'externalCosts',
  initialState: initialState.externalCosts,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getExternalCostsThunk.pending, () => {
      return { list: [], error: null, status: FETCH_STATUS.FETCHING };
    });

    builder.addCase(getExternalCostsThunk.fulfilled, (_state, action) => {
      return { list: action.payload.list, error: null, status: FETCH_STATUS.FETCHED };
    });

    builder.addCase(getExternalCostsThunk.rejected, () => {
      return { list: [], error: true, status: FETCH_STATUS.INITIAL };
    });

    builder.addCase(createExternalCostThunk.fulfilled, (state, action) => {
      return {
        ...state,
        list: [...state.list, action.payload]
      };
    });

    builder.addCase(updateExternalCostThunk.fulfilled, (state, action) => {
      return {
        ...state,
        list: state.list.map(externalCost => {
          if (externalCost.id === action.payload.id) {
            return action.payload;
          }

          return externalCost;
        })
      };
    });

    builder.addCase(uploadExternalCostDocumentThunk.fulfilled, (state, action) => {
      return {
        ...state,
        list: state.list.map(externalCost => {
          if (externalCost.id === action.payload.id) {
            return action.payload;
          }

          return externalCost;
        })
      };
    });

    builder.addCase(deleteExternalCostDocumentThunk.fulfilled, (state, action) => {
      return {
        ...state,
        list: state.list.map(externalCost => {
          if (externalCost.id === action.payload.id) {
            return action.payload;
          }

          return externalCost;
        })
      };
    });

    builder.addCase(deleteExternalCostThunk.fulfilled, (state, action) => {
      return {
        ...state,
        list: state.list.filter(({ id }) => id !== action.payload.id)
      };
    });

  }
});

export default slice.reducer;