import { Checkbox, HStack, StackProps, VStack } from '@chakra-ui/react';
import { QuestionUpsertBody } from '@jurnee/common/src/dtos/surveys';
import { getTmpId } from '@jurnee/common/src/utils/strings';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteButton } from './DeleteButton';
import { DotHandle } from './DotHandle';
import { LabelInput } from './LabelInput';
import { QuestionOptions } from './QuestionOptions';
import { TypeSelect } from './TypeSelect';

interface Props {
  question: QuestionUpsertBody;
  onQuestionChange(question: QuestionUpsertBody): void;
  onQuestionDelete(): void;
}

export function QuestionForm({ question, onQuestionChange, onQuestionDelete, ...inheritedProps }: Props & StackProps) {
  const { t } = useTranslation('surveys', { keyPrefix: 'drawers.survey.form.question' });

  const [isDraggable, setIsDraggable] = useState(false);

  function handleLabel(e: ChangeEvent<HTMLInputElement>) {
    onQuestionChange({ ...question, label: e.target.value });
  }

  function handleRequired(e: ChangeEvent<HTMLInputElement>) {
    onQuestionChange({ ...question, required: e.target.checked });
  }

  function handleType(e: ChangeEvent<HTMLSelectElement>) {
    const newType = e.target.value as QuestionUpsertBody['type'];
    const newQuestionOptions = [...question.questionOptions];

    if (['CHECKBOX', 'SELECT'].includes(newType)) {
      if (newQuestionOptions.length === 0) {
        newQuestionOptions.push({ id: getTmpId(), value: '' });
      }
    } else if (newType === 'TEXT') {
      newQuestionOptions.length = 0;
    }

    onQuestionChange({ ...question, type: newType, questionOptions: newQuestionOptions });
  }

  function handleQuestionOptions(questionOptions: QuestionUpsertBody['questionOptions']) {
    onQuestionChange({ ...question, questionOptions });
  }

  return (
    <HStack w="100%" spacing="10px" alignItems="normal" display="flex" draggable={isDraggable} {...inheritedProps}>
      <DotHandle
        onMouseDown={() => setIsDraggable(true)}
        onMouseLeave={() => setIsDraggable(false)}
      />

      <VStack w="100%" spacing="10px" alignItems="flex-start">
        <HStack w="100%" justifyContent="space-between">
          <Checkbox isChecked={question.required} onChange={handleRequired}>
            { t('required') }
          </Checkbox>

          <DeleteButton onDelete={onQuestionDelete} />
        </HStack>

        <LabelInput label={question.label} onChange={handleLabel} />

        <TypeSelect type={question.type} onSelect={handleType} />

        {
          ['CHECKBOX', 'SELECT'].includes(question.type) &&
            <QuestionOptions
              type={question.type}
              questionOptions={question.questionOptions}
              onChange={handleQuestionOptions}
            />
        }
      </VStack>
    </HStack>
  );
}