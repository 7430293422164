import { Heading, StackProps, VStack } from '@chakra-ui/react';
import { BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { PropositionsGroupJSON } from '@jurnee/common/src/entities/PropositionsGroup';
import { useMemo } from 'react';
import { getPropositionsByPropositionsGroupId } from 'src/utils/booking';
import { PropositionsGroupCard } from './PropositionsGroupCard';

interface Props {
  relationships: Pick<BookingRelationshipsJSON, 'propositionsGroups' | 'propositions'>;
  heading: string;
  selected?: PropositionsGroupJSON['id'];
  onClick(propositionsGroupId: PropositionsGroupJSON['id']): void
}

export function PropositionsGroups({ relationships, heading, selected, onClick, ...inheritedProps }: Props & Omit<StackProps, 'onClick'>) {
  const propositionsGroups = useMemo(
    () => relationships.propositionsGroups.map(propositionsGroup => {
      const propositions = getPropositionsByPropositionsGroupId(relationships.propositions, propositionsGroup.id);

      return <PropositionsGroupCard
        key={propositionsGroup.id}
        propositionsGroup={propositionsGroup}
        propositions={propositions}
        selected={selected === propositionsGroup.id}
        onClick={() => onClick(propositionsGroup.id)}
      />;
    }),
    [relationships, selected]
  );

  return (
    <VStack w="100%" alignItems="stretch" spacing={3} {...inheritedProps}>
      <Heading size="md">{heading}</Heading>

      <VStack w="100%" spacing={0} border="1px solid" borderColor="gray.200" borderRadius={4} overflow="hidden">
        { propositionsGroups }
      </VStack>
    </VStack>
  );
}