import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { getBudgetRemaining } from '@jurnee/common/src/utils/budgets';
import { useSelector } from 'react-redux';
import BudgetChart from 'src/components/Budget/BudgetChart';
import { getBudgetBreakdownById } from 'src/store/budgetBreakdowns/budgetBreakdowns.selectors';
import { TotalZeroBlock } from './TotalZeroBlock';

interface Props {
  budget: BudgetJSON;
}

export function BudgetBreakdown({ budget }: Props) {
  const breakdown = useSelector(getBudgetBreakdownById(budget.id));

  if (breakdown.total === 0) {
    return <TotalZeroBlock definitionType={budget.definitionType} />;
  }

  const remaining = getBudgetRemaining(breakdown);

  return (
    <BudgetChart
      spent={breakdown.spent}
      pending={breakdown.pending}
      remaining={remaining}
      total={breakdown.total}
      currency={budget.currency}
    />
  );
}