import { Select } from '@chakra-ui/react';
import { TaskJSON } from '@jurnee/common/src/entities/Task';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange(priority: TaskJSON['priority']): void;
}

export function PrioritySelect(props: Props) {
  const { t } = useTranslation('tasks');

  const priorities = [
    'LOW',
    'MEDIUM',
    'HIGH',
    'URGENT'
  ];

  function onChange(e: ChangeEvent<HTMLSelectElement>) {
    if (isEmpty(e.target.value)) {
      return props.onChange(null);
    }

    props.onChange(e.target.value as TaskJSON['priority']);
  }

  return (
    <Select size="sm" onChange={onChange}>
      <option value="">{t('filters.priority.all')}</option>
      { priorities.map(priority => <option key={priority} value={priority}>{t(`priority.${priority}`)}</option>) }
    </Select>
  );
}