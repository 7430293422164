import { api } from '@jurnee/common/src/browser/Api';
import { ApprovalRequestCreateBody } from '@jurnee/common/src/dtos/approvalRequests';
import { AddBookingOrganizersBody, BookingCreateBody, BookingItemUpdateBody, BookingUpdateBody } from '@jurnee/common/src/dtos/bookings';
import { BookingInvoiceFileUrlResponse } from '@jurnee/common/src/dtos/bookingsInvoices';
import { FileDownload } from '@jurnee/common/src/dtos/files';
import { QuoteCreateBody } from '@jurnee/common/src/dtos/quotes';
import { ItemReviewCreateBody, NetPromoterScoreCreateBody, ReviewCreateBody } from '@jurnee/common/src/dtos/reviews';
import { ApprovalRequestJSON } from '@jurnee/common/src/entities/ApprovalRequest';
import { Booking, BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { BookingItemJSON } from '@jurnee/common/src/entities/BookingItem';
import { BookingOrganizerJSON } from '@jurnee/common/src/entities/BookingOrganizer';
import { BookingReviewJSON } from '@jurnee/common/src/entities/BookingReview';
import { ExperienceReviewJSON } from '@jurnee/common/src/entities/ExperienceReview';
import { NetPromoterScoreJSON } from '@jurnee/common/src/entities/NetPromoterScore';
import { PartnerReviewJSON } from '@jurnee/common/src/entities/PartnerReview';
import { QuoteJSON } from '@jurnee/common/src/entities/Quote';
import { ReviewJSON } from '@jurnee/common/src/entities/Review';
import { User } from '@jurnee/common/src/entities/User';
import { UserBudgetBreakdownJSON } from '@jurnee/common/src/entities/UserBudgetBreakdown';
import { Expand, List } from '@jurnee/common/src/serializers';
import { CreditCardCommitPayload } from 'src/store/bookingDetails/bookingDetails.thunks';

export default {
  addBookingOrganizers(bookingId: Booking['id'], body: AddBookingOrganizersBody): Promise<List<BookingOrganizerJSON, never>> {
    return api.post(`bookings/${bookingId}/organizers`, body);
  },
  removeBookingOrganizer(bookingId: Booking['id'], organizerId: User['id']): Promise<BookingOrganizerJSON> {
    return api.delete(`bookings/${bookingId}/organizers/${organizerId}`);
  },
  createQuote(bookingId: Booking['id'], body: QuoteCreateBody): Promise<QuoteJSON> {
    return api.post(`bookings/${bookingId}/quotes`, body);
  },
  commitByCreditCard(payload: CreditCardCommitPayload): Promise<BookingInvoiceJSON> {
    const { bookingId, invoiceId, data } = payload;
    return api.put(`bookings/${bookingId}/bookings-invoices/${invoiceId}`, data);
  },
  cancelBooking(bookingId: Booking['id']): Promise<Expand<BookingJSON, BookingRelationshipsJSON>> {
    return api.post(`bookings/${bookingId}/cancel`);
  },
  getBookingInvoiceFileUrl(bookingId: Booking['id'], invoiceId: BookingInvoiceJSON['providerInvoiceId']): Promise<BookingInvoiceFileUrlResponse> {
    return api.get(`bookings/${bookingId}/bookings-invoices/${invoiceId}/file-url`);
  },
  downloadQuote(bookingId: Booking['id'], quoteId: QuoteJSON['id']): Promise<FileDownload> {
    return api.get(`bookings/${bookingId}/quotes/${quoteId}/download`);
  },
  getQuoteLink(bookingId: Booking['id'], quoteId: QuoteJSON['id']): Promise<{ url: string }> {
    return api.get(`bookings/${bookingId}/quotes/${quoteId}/url`);
  },
  cancelQuote(bookingId: Booking['id'], quoteId: QuoteJSON['id']): Promise<QuoteJSON> {
    return api.put(`bookings/${bookingId}/quotes/${quoteId}/cancel`);
  },
  getBookingByCuid(bookingCuid: Booking['cuid']): Promise<BookingJSON> {
    return api.get(`bookings/${bookingCuid}/reviews`);
  },
  createReview(bookingCuid: Booking['cuid'], body: ReviewCreateBody): Promise<ReviewJSON> {
    return api.post(`bookings/${bookingCuid}/reviews`, body);
  },
  createExperienceReview(bookingCuid: Booking['cuid'], body: ItemReviewCreateBody): Promise<ExperienceReviewJSON> {
    return api.post(`bookings/${bookingCuid}/reviews/experience`, body);
  },
  createPartnerReview(bookingCuid: Booking['cuid'], body: ItemReviewCreateBody): Promise<PartnerReviewJSON> {
    return api.post(`bookings/${bookingCuid}/reviews/partner`, body);
  },
  createBookingReview(bookingCuid: Booking['cuid'], body: ItemReviewCreateBody): Promise<BookingReviewJSON> {
    return api.post(`bookings/${bookingCuid}/reviews/booking`, body);
  },
  createNps(bookingCuid: Booking['cuid'], body: NetPromoterScoreCreateBody): Promise<NetPromoterScoreJSON> {
    return api.post(`bookings/${bookingCuid}/nps`, body);
  },
  updateBooking(bookingId: Booking['id'], body: BookingUpdateBody): Promise<BookingJSON> {
    return api.put(`bookings/${bookingId}`, body);
  },
  createApprovalRequest(bookingId: Booking['id'], body: ApprovalRequestCreateBody): Promise<ApprovalRequestJSON> {
    return api.post(`bookings/${bookingId}/approval-requests`, body);
  },
  createBookingItems(bookingId: BookingJSON['id'], body: Pick<BookingCreateBody, 'bookingsItems'>): Promise<BookingJSON> {
    return api.post(`bookings/${bookingId}/bookings-items`, body);
  },
  deleteBookingItem(bookingId: BookingJSON['id'], bookingItemId: BookingItemJSON['id']): Promise<Expand<BookingJSON, BookingRelationshipsJSON>> {
    return api.delete(`bookings/${bookingId}/bookings-items/${bookingItemId}`);
  },
  updateBookingItem(bookingId: BookingJSON['id'], bookingItemId: BookingItemJSON['id'], body: BookingItemUpdateBody): Promise<Expand<BookingJSON, BookingRelationshipsJSON>> {
    return api.put(`bookings/${bookingId}/bookings-items/${bookingItemId}`, body);
  },
  getUserBudgetBreakdownsByBookingId(bookingId: BookingJSON['id']): Promise<List<UserBudgetBreakdownJSON, never>> {
    return api.get(`bookings/${bookingId}/user-budget-breakdowns`);
  },
};