import { Location } from '@jurnee/common/src/entities/Location';
import { List } from '@jurnee/common/src/serializers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/locations';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';

export const getLocations = createAsyncThunk<List<Location, never>, null, { state: RootState }>('LOCATIONS_FETCH', async (args, thunkAPI) => {
  try {
    const locations = await api.getLocations();

    return locations;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while retrieving locations`, status: 'error' }));
    return thunkAPI.rejectWithValue(e.message);
  }
});