import { FormControl, FormLabel, Heading, HStack, IconButton, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, VStack } from '@chakra-ui/react';
import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { Icon } from '@jurnee/common/src/components/Icon';
import { Loader } from '@jurnee/common/src/components/Loader';
import { CollectionCreateBody, CollectionExperienceAddBody } from '@jurnee/common/src/dtos/collections';
import { Collection } from '@jurnee/common/src/entities/Collection';
import { Experience } from '@jurnee/common/src/entities/Experience';
import { PayloadAction } from '@reduxjs/toolkit';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PrimaryButton, SecondaryButton } from 'src/components/buttons';
import { RootState } from 'src/store/state';
import { trackEvent, TrackEventOptions } from '../store/analytics/analytics.thunks';
import { addToCollection, createCollection, getCollections } from '../store/collections/collections.thunks';

interface OwnProps {
  children: React.ReactElement;
  experienceId?: Experience['id'];
  onClose?(step: State['step']): void;
}

export type AddCreateCollectionModalStep = 'create' | 'add' | 'success';

interface State {
  addedToCollectionId?: Collection['id'];
  isAdding: boolean;
  isCreating: boolean;
  isOpen: boolean;
  step: AddCreateCollectionModalStep;
  title: string;
}

interface DispatchProps {
  addToCollection(data: CollectionExperienceAddBody & { collectionId: Collection['id'] }): void;
  trackEvent(opts: TrackEventOptions): void;
  createCollection(data: CollectionCreateBody): PayloadAction<Collection & { error?: string }>;
  getCollections(): void;
}

interface StateProps {
  collections: RootState['collections']
}

const initialState: State = {
  addedToCollectionId: null,
  isAdding: false,
  isCreating: false,
  isOpen: false,
  step: 'create',
  title: ''
};

class CreateCollectionModal extends React.Component<OwnProps & DispatchProps & StateProps, State> {

  state: State = initialState;

  onClose = () => {
    this.setState({
      ...this.state,
      isOpen: false
    });

    if (typeof this.props.onClose === 'function') {
      this.props.onClose(this.state.step);
    }
  };

  get collections() {
    return this.props.collections.list.filter((collection) => {
      return collection.collectionsExperiences.every(({ experienceId }) => experienceId !== this.props.experienceId);
    });
  }

  onOpen = async (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    const step = this.props.experienceId ? 'add' : 'create';

    this.setState({ ...initialState, isOpen: true, step });

    await this.props.getCollections();

    if (step === 'add' && this.collections.length === 0) {
      this.setState({ step: 'create' });
    }

    this.props.trackEvent({
      name: 'opened_create_collection_modal'
    });
  };

  onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      title: event.target.value
    });
  };

  onTitleKeydown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.onCreate();
    }
  };

  onCreate = async () => {
    this.setState({ ...this.state, isCreating: true });

    const { payload } = await this.props.createCollection({ title: this.state.title });

    if (payload.error) {
      this.setState({
        ...this.state,
        isCreating: false
      });

      return;
    }

    if (this.props.experienceId) {
      await this.onAdd(payload.id);
    }

    this.setState({
      ...this.state,
      addedToCollectionId: payload.id,
      isCreating: false,
      step: 'success'
    });
  };

  async onAdd(collectionId: Collection['id']) {
    this.setState({ ...this.state, isAdding: true });

    await this.props.addToCollection({
      collectionId,
      experienceId: this.props.experienceId
    });

    this.setState({ ...this.state, isAdding: false, step: 'success', addedToCollectionId: collectionId });
  }

  get bodyAddToCollection() {
    if (
      this.props.collections.status !== FETCH_STATUS.FETCHED ||
      this.state.isAdding
    ) {
      return (
        <ModalBody>
          <Loader h="150px"/>
        </ModalBody>
      );
    }

    return (
      <ModalBody>
        <VStack spacing={4}>
          {this.collections.map(collection => (
            <HStack onClick={this.onAdd.bind(this, collection.id)} w="100%" cursor="pointer" key={collection.id}>
              <IconButton
                bg="black"
                borderRadius={8}
                aria-label="Add to a list"
                icon={<Icon icon="folderIn" size={6} color="white" />}
              />
              <Text>{collection.title} ({collection.collectionsExperiences.length})</Text>
            </HStack>
          ))}
          <HStack onClick={() => this.setState({ ...this.state, step: 'create' })} w="100%" cursor="pointer">
            <IconButton
              colorScheme="blue"
              borderRadius={8}
              aria-label="Create a list"
              icon={<Icon icon="folderAdd" size={6} color="white" />}
            />
            <Text color="blue.500">Create new list</Text>
          </HStack>
        </VStack>
      </ModalBody>
    );
  }

  get bodyCreateCollection() {
    return (
      <ModalBody>
        <FormControl id="firstName">
          <FormLabel>Give a name to your list</FormLabel>
          <Input
            defaultValue={this.state.title}
            name="title"
            onChange={this.onTitleChange}
            onKeyDown={this.onTitleKeydown}
            type="text"
          />
        </FormControl>
        <Text color="gray.400" mt={4}>
          Create a list to group activities together, then send it to your team to vote on.
        </Text>
      </ModalBody>
    );
  }

  get bodySuccess() {
    if (this.props.experienceId) {
      return (
        <ModalBody textAlign="center">
          <Icon icon='circleCheckLine' size={10} color="teal.400" />
          <Heading size="md" mt={4}>Activity added!</Heading>
          <Text mt={2} color="gray.400">
            You can now share this list with your team or
            see the activities it contains <Link style={{ textDecoration: 'underline' }} to={`/collections/${this.state.addedToCollectionId}`}>here</Link>.
          </Text>
        </ModalBody>
      );
    }

    return (
      <ModalBody textAlign="center">
        <Icon icon='circleCheckLine' size={10} color="teal.400" />
        <Heading size="md" mt={4}>List created</Heading>
        <Text mt={2} color="gray.400">
          You can now add activities to your list<br />by clicking on <Icon mt={-1} icon='folderIn' size={6} />
        </Text>
        <Image mt={4} src="/assets/images/add_to_collection.jpg" mx="auto" />
      </ModalBody>
    );
  }

  get body() {
    return {
      'add': this.bodyAddToCollection,
      'create': this.bodyCreateCollection,
      'success': this.bodySuccess,
    }[this.state.step];
  }

  get footerButton() {
    if (this.state.step === 'create') {
      return (
        <PrimaryButton colorScheme="teal" onClick={this.onCreate} size="sm" isLoading={this.state.isCreating}>Save</PrimaryButton>
      );
    }

    return <SecondaryButton colorScheme="pink" onClick={this.onClose} size="sm">Close</SecondaryButton>;
  }

  get footer() {
    return (
      <ModalFooter>
        { this.footerButton }
      </ModalFooter>
    );
  }

  get header() {
    return (
      <ModalHeader>
        {this.props.experienceId ? 'Add to a list' : 'Create a list'}
        <ModalCloseButton />
      </ModalHeader>
    );
  }

  render() {
    return (
      <>
        <Modal isOpen={this.state.isOpen} onClose={this.onClose} size="md" scrollBehavior="inside">
          <ModalOverlay />
          <ModalContent>
            { this.header }
            { this.body }
            { this.footer }
          </ModalContent>
        </Modal>
        { React.cloneElement(this.props.children, { onClick: this.onOpen }) }
      </>
    );
  }

}

const mapStateToProps = (state: RootState): StateProps => ({
  collections: state.collections
});

const mapDispatchToProps: DispatchProps = {
  trackEvent,
  addToCollection,
  createCollection: createCollection as unknown as DispatchProps['createCollection'],
  getCollections
};

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(CreateCollectionModal);