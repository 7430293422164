import { Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { EmptyState } from '@jurnee/common/src/components/EmptyState';
import { ApprovalProcessJSON } from '@jurnee/common/src/entities/ApprovalProcess';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { TeamJSON } from '@jurnee/common/src/entities/Team';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { sortByUserLabel } from '@jurnee/common/src/utils/user';
import { useTranslation } from 'react-i18next';
import { EditMenu } from './EditMenu';
import { UserApprovalProcess } from './UserApprovalProcess';
import { UserIndividualBudget } from './UserIndividualBudget';
import { UserName } from './UserName';
import { UserRole } from './UserRole';
import { UserTeams } from './UserTeams';

interface Props {
  currentUser: UserDetails;
  approvalProcesses: ApprovalProcessJSON[];
  budgets: BudgetJSON[];
  users: UserDetails[];
  teams: TeamJSON[];
  onDeleteEmployee(user: UserDetails): void;
  onSendInvitation(user: UserDetails): void;
}

function Header() {
  const { t } = useTranslation('people', { keyPrefix: 'table' });

  return (
    <Thead>
      <Tr>
        <Th pl="46px" w="30%">{t('name')}</Th>
        <Th w="20%">{t('teams')}</Th>
        <Th w="20%">{t('individualBudget')}</Th>
        <Th w="20%">{t('approvalProcess')}</Th>
        <Th textAlign="right">{t('role')}</Th>
        <Th w={8}></Th>
      </Tr>
    </Thead>
  );
}

function Body(props: Props) {
  const { t } = useTranslation('people');

  if (props.users.length === 0) {
    return (
      <Tbody>
        <Tr>
          <Td p={16} colSpan={5}>
            <EmptyState
              icon="listSearch"
              heading={t('emptyState.heading')}
              description={t('emptyState.description')}
            />
          </Td>
        </Tr>
      </Tbody>
    );
  }

  return (
    <Tbody>
      {
        sortByUserLabel(props.users).map(user => {
          const approvalProcess = props.approvalProcesses.find(({ users }) => users.find(({ id }) => id === user.id));
          const budget = props.budgets.find(budget => budget.id === user.budgetId);
          const teams = props.teams.filter(team => team.usersTeams.some(({ userId }) => userId === user.id));
          const { active } = user;

          return (
            <Tr key={user.id} bg={active ? 'white' : 'gray.10'} color={active ? 'black' : 'gray.400'}>
              <Td pl={3} maxW={1}><UserName user={user} /></Td>
              <Td><UserTeams user={user} teams={teams} budgets={props.budgets} /></Td>
              <Td><UserIndividualBudget budget={budget} /></Td>
              <Td><UserApprovalProcess approvalProcess={approvalProcess} user={user} budgets={props.budgets} /></Td>
              <Td textAlign="right">
                {active ? <UserRole user={user} /> : <Text>{t('suspended')}</Text>}
              </Td>
              <Td pl={0} pr={3}><EditMenu user={user} {...props} /></Td>
            </Tr>
          );
        })
      }
    </Tbody>
  );
}

export default function EmployeesTable(props: Props) {
  return (
    <TableContainer w="100%">
      <Table>
        <Header />
        <Body {...props} />
      </Table>
    </TableContainer>
  );
}