import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { BudgetBreakdownJSON } from '@jurnee/common/src/entities/BudgetBreakdown';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../state';
import { budgetBreakdownsAdapter } from './budgetBreakdowns.adapter';

export const budgetBreakdownsSelectors = budgetBreakdownsAdapter.getSelectors(
  (state: RootState) => state.budgetBreakdowns
);

export const getBudgetBreakdownById = (budgetId: BudgetJSON['id']) => {
  return (state: RootState) => budgetBreakdownsSelectors.selectById(state, budgetId);
};

const getBudgetBreakdownsByIdsSelector = createSelector(
  [state => budgetBreakdownsSelectors.selectAll(state), (_, ids) => ids],
  (budgetBreakdowns: BudgetBreakdownJSON[], ids: number[]) => {
    return budgetBreakdowns.filter(({ budgetId })=> ids.find(id => id === budgetId));
  }
);

export function getBudgetBreakdownsByIds(ids: number[]) {
  return (state: RootState) => getBudgetBreakdownsByIdsSelector(state, ids);
}