import { FormControl, FormControlProps, FormLabel, Select, SelectProps } from '@chakra-ui/react';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import { useMemo } from 'react';

interface Props {
  label: string;
  placeholder: string;
  budgets: BudgetJSON[];
  size?: SelectProps['size'];
  onChange(budgetId: number): void;
}

export function BudgetSelect({ label, placeholder, budgets, size, onChange, ...inheritedProps }: Props & Omit<FormControlProps, 'onChange'>) {
  const options = useMemo(
    () => sortAlphabeticallyBy(budgets, 'name').map(({ id, name }) => (
      <option key={id} value={id}>{name}</option>
    )),
    [budgets]
  );

  return (
    <FormControl id="budgetId" {...inheritedProps}>
      <FormLabel>{label}</FormLabel>
      <Select name="budgetId" size={size} bg="white" onChange={e => onChange(Number(e.target.value))}>
        <option key={0} value={0}>{placeholder}</option>
        { options }
      </Select>
    </FormControl>
  );
}