import { Box, BoxProps, HStack, Text } from '@chakra-ui/react';
import PriceInUserCurrency from 'src/components/PriceInUserCurrency';

interface Props {
  color: BoxProps['bgColor']
  label: string;
  value?: number;
  currency?: string;
}

export function LegendRow({ color, label, value, currency }: Props) {
  return (
    <HStack w="100%" justifyContent="space-between">
      <HStack spacing="6px">
        <Box bgColor={color} w="6px" h="6px" borderRadius="50%" />
        <Text fontSize="14px" lineHeight="10px">{label}</Text>
      </HStack>

      <Text fontSize="14px" lineHeight="10px" color="gray.400">
        { value && currency ? <PriceInUserCurrency value={value} currency={currency} fractionDigits={0} /> : value }
      </Text>
    </HStack>
  );
}