import { Box, Center, Checkbox, Divider, FormControl, HStack, Image, Input, Link, Stack, Text } from '@chakra-ui/react';
import { AUTH_STATUS, FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { SignupBody, SsoProviderName, VerifyBody } from '@jurnee/common/src/dtos/auth';
import * as React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import MicrosoftButton from 'src/components/buttons/MicrosoftButton';
import { RootState } from 'src/store/state';
import LogoedCenteredBox from '../components/LogoedCenteredBox';
import { GoogleButton, PrimaryButton } from '../components/buttons';
import { loginSso, verifyUser } from '../store/auth/auth.thunks';
import { signup as handleSignup } from '../store/signup/signup.thunks';
import { RouteProps } from './Route';

interface StateProps {
  auth: RootState['auth'];
  signup: RootState['signup'];
}

interface DispatchProps {
  handleSignup(payload: SignupBody): void;
  loginSso(ssoProviderName: SsoProviderName): void;
  verifyUser(payload: VerifyBody): void;
}

type Props = RouteProps & StateProps & DispatchProps;

interface State {
  credentials: SignupBody;
  acceptedTermsAndConditions: boolean;
  submitted: boolean;
}

class Signup extends React.Component<Props, State> {

  state: State = {
    credentials: {
      email: '',
      password: '',
      lastName: '',
      firstName: '',
      companyName: '',
      token: null
    },
    acceptedTermsAndConditions: false,
    submitted: false
  };

  async componentDidMount() {
    this.checkSearchParams();
    await this.handleUserVerification();
  }

  get redirect() {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.has('redirect')) {
      return searchParams.get('redirect');
    }

    return '/';
  }

  private checkSearchParams() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const email = searchParams.get('email');
    const token = searchParams.get('token');

    this.setState({
      ...this.state,
      credentials: {
        ...this.state.credentials,
        email,
        token
      }
    });
  }

  private async handleUserVerification() {
    const token = new URLSearchParams(this.props.location.search).get('token');

    if (token) {
      await this.props.verifyUser({ token });
    }
  }

  setValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      credentials: {
        ...this.state.credentials,
        [event.target.name]: event.target.value
      }
    });
  };

  submit = async () => {
    this.setState({ ...this.state, submitted: true });

    if (this.state.acceptedTermsAndConditions) {
      await this.props.handleSignup(this.state.credentials);
    }
  };

  loginSsoGoogle = () => {
    this.setState({ ...this.state, submitted: true });

    if (this.state.acceptedTermsAndConditions) {
      this.props.loginSso('google');
    }
  };

  loginSsoMicrosoft = () => {
    this.setState({ ...this.state, submitted: true });

    if (this.state.acceptedTermsAndConditions) {
      this.props.loginSso('microsoft');
    }
  };

  renderGoogleButton() {
    return <GoogleButton labelprefix='Continue with' onClick={this.loginSsoGoogle} />;
  }

  renderMicrosoftButton() {
    return <MicrosoftButton labelprefix='Continue with' onClick={this.loginSsoMicrosoft} />;
  }

  onChangeTermsAndConditions = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, acceptedTermsAndConditions: event.target.checked });
  };

  isFormInvalid = () => {
    return !this.state.acceptedTermsAndConditions && this.state.submitted;
  };

  render() {
    if (this.props.auth.status === AUTH_STATUS.AUTHENTICATED) {
      return <Navigate to={this.redirect} />;
    }

    if (this.props.signup.status === FETCH_STATUS.FETCHED) {
      return (
        <LogoedCenteredBox title='Welcome! 👋'>
          <Box w="100%" textAlign="center">
            Your account is almost setup, we just sent you an email in order to verify your email address! 📮
          </Box>
        </LogoedCenteredBox>
      );
    }

    return (
      <Center h='100%' w='450px' margin='auto'>
        <Stack w='100%' spacing={4}>
          <Image mx="auto" w={200} src='/assets/images/logo.svg' />

          <Stack borderRadius={8} border="1px solid" borderColor="blue.50" p={5} w="100%" justify="center" spacing={5} bg="white">
            <Input size="md" placeholder='Work email' name='email' defaultValue={this.state.credentials.email} onChange={this.setValue} type="email" />
            <Input size="md" placeholder='Password' type='password' name='password' onChange={this.setValue}/>
            <HStack spacing={4}>
              <Input size="md" placeholder='First name' name='firstName' onChange={this.setValue}/>
              <Input size="md" placeholder='Last name' name='lastName' onChange={this.setValue}/>
            </HStack>
            <Input size="md" placeholder='Company' name='companyName' onChange={this.setValue}/>
            <FormControl isInvalid={this.isFormInvalid()}>
              <Checkbox py={2} isChecked={this.state.acceptedTermsAndConditions} onChange={this.onChangeTermsAndConditions} spacing={3} colorScheme="blue">
                <Text color={this.isFormInvalid() ? 'red.500' : 'gray.400'} fontSize={14} fontStyle="italic">
                  I agree to the <Link textDecoration="underline" href="https://www.jurnee.io/assets/documents/terms_and_conditions.pdf" target="_blank">Terms of Service</Link> and <Link textDecoration="underline" href="https://jurneeio.notion.site/Privacy-Policy-4c47bf21c74a413cacf3013fed1a045a" target="_blank">Privacy Policy</Link>.
                </Text>
              </Checkbox>
            </FormControl>
            <PrimaryButton size="md" isLoading={this.props.signup.status === FETCH_STATUS.FETCHING} onClick={this.submit}>Signup</PrimaryButton>
            <Divider/>
            {this.renderGoogleButton()}
            {this.renderMicrosoftButton()}
          </Stack>
          <Center p={6}>
            <Link color='blue.200' href='/login'>Already have an account? Log in</Link>
          </Center>
        </Stack>
      </Center>
    );
  }
}

function mapStateToProps(state: RootState): StateProps {
  return {
    auth: state.auth,
    signup: state.signup
  };
}

const mapDispatchToProps: DispatchProps = {
  handleSignup,
  loginSso,
  verifyUser
};

export default connect<StateProps, DispatchProps, RouteProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Signup);
