import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionProps } from './QuestionProps';

export function QuestionSelect({ question, questionAnswers, onChange }: QuestionProps) {
  const { t } = useTranslation('surveys', { keyPrefix: 'submission.form' });

  function onSelectChange({ target }: ChangeEvent<HTMLSelectElement>) {
    const optionId = Number(target.value);

    if (optionId === 0) {
      return onChange([], question);
    }

    onChange([{
      questionId: question.id,
      questionOptionId: optionId,
      value: null
    }], question);
  }

  return (
    <FormControl isRequired={question.required}>
      <FormLabel>{question.label}</FormLabel>

      <Select
        size="sm"
        placeholder={t('select.placeholder')}
        onChange={onSelectChange}
        value={questionAnswers[0]?.questionOptionId}
      >
        {
          question.questionOptions.map(({ id, value }) => (
            <option key={id} value={id}>{value}</option>
          ))
        }
      </Select>
    </FormControl>
  );
}