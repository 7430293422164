import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultValue: BudgetJSON['frequency'];
  onChange(e: ChangeEvent<HTMLSelectElement>): void;
}

export function FrequencySelect({ defaultValue, onChange }: Props) {
  const { t } = useTranslation('settings', { keyPrefix: 'budgets.drawer.form.frequency' });

  return (
    <FormControl isRequired>
      <FormLabel>{t('label')}</FormLabel>
      <Select size="sm" name="frequency" defaultValue={defaultValue} onChange={onChange} bg="white">
        {['MONTHLY', 'QUARTERLY', 'SEMIANNUALLY', 'YEARLY'].map((value) => (
          <option key={value} value={value}>
            { t(`options.${value}`) }
          </option>
        ))}
      </Select>
    </FormControl>
  );
}