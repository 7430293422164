import { Box, Button, ButtonProps } from '@chakra-ui/react';
import * as React from 'react';

interface OwnProps {
  labelprefix: string;
}

export default class MicrosoftButton extends React.PureComponent<OwnProps & ButtonProps> {

  render() {
    return (
      <Button background="black" _hover={{ background: 'gray.700' }} color="white" {...this.props}>
        <Box mr={4}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21 21">
            <title>MS-SymbolLockup</title>
            <rect x="1" y="1" width="9" height="9" fill="#f25022"/>
            <rect x="1" y="11" width="9" height="9" fill="#00a4ef"/>
            <rect x="11" y="1" width="9" height="9" fill="#7fba00"/>
            <rect x="11" y="11" width="9" height="9" fill="#ffb900"/>
          </svg>
        </Box>
        {this.props.labelprefix} Microsoft
      </Button>
    );
  }

}