import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import * as React from 'react';

export default class TrashButton extends React.PureComponent<IconButtonProps> {

  render() {
    return (
      <IconButton
        borderRadius={8}
        onClick={this.props.onClick}
        background="pink.50"
        _hover={{ background: 'pink.100' }}
        size="sm"
        icon={<Icon icon="trash" size={5} color="pink.500" />}
        { ...this.props }
      />
    );
  }

}