import { TeamJSON } from '@jurnee/common/src/entities/Team';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../state';
import { teamsAdapter } from './teams.adapter';

export const teamsSelectors = teamsAdapter.getSelectors(
  (state: RootState) => state.teams
);

export function getTeamsFetchStatusSelector(state: RootState) {
  return state.teams.status;
}

export function getTeamsSelector(state: RootState) {
  return teamsSelectors.selectAll(state);
}

const getTeamsByIdsSelector = createSelector(
  [state => teamsSelectors.selectAll(state), (_, ids: number[]) => ids],
  (teams: TeamJSON[], ids: number[]) => {
    return teams.filter(team=> ids.find(id => id === team.id));
  }
);

export function getTeamsByIds(ids: number[]) {
  return (state: RootState) => getTeamsByIdsSelector(state, ids);
}