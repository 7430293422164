import { PropositionCommentJSON, PropositionCommentRelationshipsJSON } from '@jurnee/common/src/entities/PropositionComment';
import { Expand } from '@jurnee/common/src/serializers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CommentCreateParams, createPropositionComment } from '../../api/propositions';
import { RootState } from '../state';

export const createPropositionCommentThunk = createAsyncThunk<Expand<PropositionCommentJSON, PropositionCommentRelationshipsJSON>, CommentCreateParams, { state: RootState }>('PROPOSITION_COMMENTS_CREATE', async (params, thunkAPI) => {
  try {
    const comment = await createPropositionComment(params);
    return comment;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.message);
  }
});