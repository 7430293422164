import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { EmptyState } from '@jurnee/common/src/components/EmptyState';
import { TaskJSON } from '@jurnee/common/src/entities/Task';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { useTranslation } from 'react-i18next';
import { GroupedTask } from '..';
import { TaskRow } from './TaskRow';

interface Props {
  tasks: GroupedTask[];
  organizers: UserJSON[];
  hasFilters: boolean;
  onDelete(task: TaskJSON): void;
  onUpdate(task: TaskJSON): void;
}

export function TasksTable({ tasks, organizers, hasFilters, onDelete, onUpdate }: Props) {
  const { t } = useTranslation('tasks', { keyPrefix: 'table' });

  const rows = tasks.length === 0 ? (
    <Tr>
      <Td p={16} colSpan={6}>
        <EmptyState
          icon="listSearch"
          heading={t('emptyState.heading', { context: hasFilters ? 'filters' : null })}
          description={t('emptyState.description', { context: hasFilters ? 'filters' : null })}
        />
      </Td>
    </Tr>
  ) : (
    tasks.map(({ task, booking }) =>
      <TaskRow
        key={task.id}
        booking={booking}
        task={task}
        organizers={organizers}
        onDelete={onDelete}
        onUpdate={onUpdate}
      />
    )
  );

  return (
    <TableContainer w="100%">
      <Table>
        <Thead>
          <Tr>
            <Th w="25%">{t('event')}</Th>
            <Th w="25%">{t('name')}</Th>
            <Th w="25%">{t('owner')}</Th>
            <Th w="15%">{t('dueDate')}</Th>
            <Th w="10%">{t('status')}</Th>
            <Th w="32px"></Th>
          </Tr>
        </Thead>
        <Tbody>{rows}</Tbody>
      </Table>
    </TableContainer>
  );
}