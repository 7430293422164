import { api } from '@jurnee/common/src/browser/Api';
import { ExternalCostUpsertBody } from '@jurnee/common/src/dtos/bookings';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { ExternalCostJSON } from '@jurnee/common/src/entities/ExternalCost';
import { List } from '@jurnee/common/src/serializers';

export interface ExternalCostsParams {
  bookingId: BookingJSON['id'];
  externalCostId: ExternalCostJSON['id'];
}

export function getExternalCosts({ bookingId }: Pick<ExternalCostsParams, 'bookingId'>): Promise<List<ExternalCostJSON, never>> {
  return api.get(`bookings/${bookingId}/external-costs`);
}

export function createExternalCost({ bookingId }: Omit<ExternalCostsParams, 'externalCostId'>, data: ExternalCostUpsertBody): Promise<ExternalCostJSON> {
  return api.post(`bookings/${bookingId}/external-costs`, data);
}

export function updateExternalCost({ bookingId, externalCostId }: ExternalCostsParams, data: ExternalCostUpsertBody): Promise<ExternalCostJSON> {
  return api.put(`bookings/${bookingId}/external-costs/${externalCostId}`, data);
}

export function deleteExternalCost({ bookingId, externalCostId }: ExternalCostsParams): Promise<ExternalCostJSON> {
  return api.delete(`bookings/${bookingId}/external-costs/${externalCostId}`);
}

export function uploadExternalCostDocument({ bookingId, externalCostId }: ExternalCostsParams, file: File): Promise<ExternalCostJSON> {
  return api.uploadFile(`bookings/${bookingId}/external-costs/${externalCostId}/document`, file);
}

export function deleteExternalCostDocument({ bookingId, externalCostId }: ExternalCostsParams): Promise<ExternalCostJSON> {
  return api.delete(`bookings/${bookingId}/external-costs/${externalCostId}/document`);
}

export function getExternalCostDocumentUrl({ bookingId, externalCostId }: ExternalCostsParams): Promise<{ url: string }> {
  return api.get(`bookings/${bookingId}/external-costs/${externalCostId}/document`);
}