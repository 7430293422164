import { api } from '@jurnee/common/src/browser/Api';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { PropositionJSON } from '@jurnee/common/src/entities/Proposition';
import { PropositionCommentJSON, PropositionCommentRelationshipsJSON } from '@jurnee/common/src/entities/PropositionComment';
import { PropositionCommentAttachmentJSON } from '@jurnee/common/src/entities/PropositionCommentAttachment';
import { PropositionsGroupJSON } from '@jurnee/common/src/entities/PropositionsGroup';
import { Expand, List } from '@jurnee/common/src/serializers';

export interface PropositionParams {
  bookingId: BookingJSON['id'];
  propositionsGroupId: PropositionsGroupJSON['id'];
  propositionId: PropositionJSON['id'];
}

export interface PropositionDeclineParams extends PropositionParams {
  declinedReason: string;
}

export interface CommentCreateParams extends PropositionParams {
  data: { content: string };
}

interface CommentParams extends PropositionParams {
  commentId: PropositionCommentJSON['id'];
}

interface CommentAttachmentParams extends CommentParams {
  attachmentId: PropositionCommentAttachmentJSON['id'];
}

export function declineProposition({ bookingId, propositionsGroupId, propositionId, declinedReason }: PropositionDeclineParams): Promise<Expand<BookingJSON, BookingRelationshipsJSON>> {
  return api.put(`/bookings/${bookingId}/propositions-groups/${propositionsGroupId}/propositions/${propositionId}/decline`, { declinedReason });
}

export function selectProposition({ bookingId, propositionsGroupId, propositionId }: PropositionParams): Promise<Expand<BookingJSON, BookingRelationshipsJSON>> {
  return api.put(`/bookings/${bookingId}/propositions-groups/${propositionsGroupId}/propositions/${propositionId}/select`);
}

export function getPropositionComments({ bookingId, propositionsGroupId, propositionId }: PropositionParams): Promise<List<PropositionCommentJSON, PropositionCommentRelationshipsJSON>> {
  return api.get(`/bookings/${bookingId}/propositions-groups/${propositionsGroupId}/propositions/${propositionId}/comments`);
}

export function createPropositionComment({ bookingId, propositionsGroupId, propositionId, data }: CommentCreateParams): Promise<Expand<PropositionCommentJSON, PropositionCommentRelationshipsJSON>> {
  return api.post(`/bookings/${bookingId}/propositions-groups/${propositionsGroupId}/propositions/${propositionId}/comments`, data);
}

export function getPropositionCommentAttachmentUrl({ bookingId, propositionsGroupId, propositionId, commentId, attachmentId }: CommentAttachmentParams): Promise<{ url: string }> {
  return api.get(`/bookings/${bookingId}/propositions-groups/${propositionsGroupId}/propositions/${propositionId}/comments/${commentId}/attachments/${attachmentId}/url`);
}

export function uploadPropositionCommentAttachment({ bookingId, propositionsGroupId, propositionId, commentId }: CommentParams, file: File): Promise<PropositionCommentAttachmentJSON> {
  return api.uploadFile(`/bookings/${bookingId}/propositions-groups/${propositionsGroupId}/propositions/${propositionId}/comments/${commentId}/attachments`, file);
}