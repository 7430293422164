import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, HStack, Input, VStack, useToast } from '@chakra-ui/react';
import AmountInput, { AmountInputData } from '@jurnee/common/src/components/AmountInput';
import { DatePicker } from '@jurnee/common/src/components/DatePicker';
import { FileInput } from '@jurnee/common/src/components/FileInput';
import { Loader } from '@jurnee/common/src/components/Loader';
import { SearchSelect } from '@jurnee/common/src/components/Select';
import { DOCUMENT_CONTENT_TYPES } from '@jurnee/common/src/constants/ContentTypes';
import { ExternalCostUpsertBody } from '@jurnee/common/src/dtos/bookings';
import { DEFAULT_UTC_TIMEZONE } from '@jurnee/common/src/entities/Address';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { Currency } from '@jurnee/common/src/entities/Currency';
import { ExternalCostJSON } from '@jurnee/common/src/entities/ExternalCost';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import { convertToTimezone, getCurrentTimeZone } from '@jurnee/common/src/utils/dates';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import { cloneElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PrimaryButton, SecondaryButton } from 'src/components/buttons';
import { useAppDispatch } from 'src/store';
import { trackEvent } from 'src/store/analytics/analytics.thunks';
import { getCurrenciesFetchStatusSelector, getCurrenciesSelector } from 'src/store/currencies/currencies.selectors';
import { getCurrenciesThunk } from 'src/store/currencies/currencies.thunk';
import { getEmployeeByIdSelector, getEmployeesFetchStatusSelector, getEmployeesSelector } from 'src/store/employees/employees.selectors';
import { createExternalCostThunk, deleteExternalCostDocumentThunk, updateExternalCostThunk, uploadExternalCostDocumentThunk } from 'src/store/externalCosts/externalCosts.thunks';
import { RootState } from 'src/store/state';
import { getUserSelector } from 'src/store/user/user.selectors';

interface Props {
  booking: BookingJSON;
  externalCost?: ExternalCostJSON;
  children: React.ReactElement;
  onChange(): void;
}

interface UserSelectProps {
  users: UserDetails[];
  selected: UserDetails;
  onChange(user: UserDetails): void;
}

function getUserSelectOptions(users: UserDetails[], currentUserId: UserDetails['id']) {
  const options = users
    .filter(({ id }) => id !== currentUserId)
    .map(user => ({ label: getUserLabel(user), value: user }));

  return sortAlphabeticallyBy(options, 'label');
}

function UserSelect({ users, selected, onChange }: UserSelectProps) {
  const options = useMemo(() => getUserSelectOptions(users, selected.id), [users, selected.id]);

  return (
    <Box className="react-select-small">
      <SearchSelect<UserDetails>
        options={options}
        closeMenuOnSelect={true}
        menuPlacement="top"
        openMenuOnFocus={true}
        maxMenuHeight={200}
        value={{ label: getUserLabel(selected), value: selected }}
        onChange={({ value }) => onChange(value)}
      />
    </Box>
  );
}

export function ExternalCostDrawer(props: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation(['externalCosts', 'common']);

  const action = props.externalCost ? 'edit' : 'create';

  const currentUser = useSelector(getUserSelector);
  const users = useSelector(getEmployeesSelector);
  const currencies = useSelector(getCurrenciesSelector);

  const employeesFetchStatus = useSelector(getEmployeesFetchStatusSelector);
  const currenciesFetchStatus = useSelector(getCurrenciesFetchStatusSelector);

  const isLoading = [
    employeesFetchStatus,
    currenciesFetchStatus
  ].some(fetchStatus => fetchStatus !== 'FETCHED');

  const externalCostUser = useSelector<RootState, UserDetails>(state =>
    getEmployeeByIdSelector(state, props.externalCost?.userId)
  );

  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [description, setDescription] = useState<string>(props.externalCost?.description);
  const [supplier, setSupplier] = useState<string>(props.externalCost?.supplier);
  const [user, setUser] = useState<UserDetails>(externalCostUser || currentUser);
  const [eventDate, setEventDate] = useState<Date>(props.externalCost ? new Date(props.externalCost.eventDate) : undefined);
  const [paymentDate, setPaymentDate] = useState<Date>(props.externalCost ? new Date(props.externalCost.paymentDate) : undefined);
  const [amount, setAmount] = useState<number>(props.externalCost?.amount);
  const [currency, setCurrency] = useState<Currency>(props.externalCost?.currency || 'EUR');
  const [document, setDocument] = useState<File>(null);
  const [filename, setFilename] = useState<string>(props.externalCost?.filename);

  const isDescriptionFieldInvalid = typeof description === 'string' && isEmpty(description);
  const isSupplierFieldInvalid = typeof supplier === 'string' && isEmpty(supplier);
  const isAmountIFieldInvalid = typeof amount === 'number' && amount <= 0;

  function onClose() {
    setIsOpen(false);
    setIsSaving(false);
  }

  function onOpen(event: React.MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    setIsOpen(true);

    if (currenciesFetchStatus !== 'FETCHED') {
      dispatch(getCurrenciesThunk());
    }

    dispatch(trackEvent({
      name: 'opened_external_cost_drawer'
    }));
  }

  function isSaveDisabled() {
    if (isDescriptionFieldInvalid) {
      return true;
    }

    if (isSupplierFieldInvalid) {
      return true;
    }

    if (isAmountIFieldInvalid) {
      return true;
    }

    return [
      amount,
      currency,
      description,
      eventDate,
      paymentDate,
      supplier,
      user,
    ].some(prop => [null, undefined].includes(prop));
  }

  function getBody(): ExternalCostUpsertBody {
    const paymentDateUTC = convertToTimezone(paymentDate, getCurrentTimeZone(), DEFAULT_UTC_TIMEZONE);
    const eventDateUTC = convertToTimezone(eventDate, getCurrentTimeZone(), DEFAULT_UTC_TIMEZONE);

    return {
      amount,
      currency,
      description,
      eventDate: eventDateUTC,
      paymentDate: paymentDateUTC,
      supplier,
      userId: user.id
    };
  }

  function create() {
    return dispatch(
      createExternalCostThunk({
        bookingId: props.booking.id,
        data: getBody()
      })
    );
  }

  function update() {
    return dispatch(
      updateExternalCostThunk({
        bookingId: props.booking.id,
        externalCostId: props.externalCost.id,
        data: getBody()
      })
    );
  }

  function uploadDocument({ id, bookingId }: ExternalCostJSON) {
    return dispatch(
      uploadExternalCostDocumentThunk({
        bookingId,
        externalCostId: id,
        file: document
      })
    );
  }

  function deleteDocument({ id, bookingId }: ExternalCostJSON) {
    return dispatch(
      deleteExternalCostDocumentThunk({
        bookingId,
        externalCostId: id
      })
    );
  }

  async function onSave() {
    setIsSaving(true);

    try {
      const externalCost = action === 'edit' ?
        await update().unwrap() :
        await create().unwrap();

      if (document) {
        await uploadDocument(externalCost).unwrap();
      }

      if (props.externalCost?.path && !filename) {
        await deleteDocument(externalCost).unwrap();
      }

      toast(getSuccessToast(t(`drawers.${action}.toasts.success`)));
      onClose();
      props.onChange();
    } catch(error) {
      setIsSaving(false);
      toast(getErrorToast(t(`drawers.${action}.toasts.error`), error.message));
    }
  }

  function handleAmountChange({ amount, currency }: AmountInputData) {
    setAmount(amount);
    setCurrency(currency as Currency);
  }

  function handleDocument(file: File) {
    setDocument(file);
    setFilename(file?.name);
  }

  return (
    <>
      { cloneElement(props.children, { onClick: onOpen }) }

      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{t(`drawers.${action}.title`)}</DrawerHeader>

          <DrawerBody>
            {
              isLoading ?
                <Loader /> :
                <VStack spacing={5}>
                  <FormControl id="description" isInvalid={isDescriptionFieldInvalid} isRequired>
                    <FormLabel>{t('drawers.fields.description.label')}</FormLabel>

                    <Input
                      size="sm"
                      placeholder={t('drawers.fields.description.placeholder')}
                      defaultValue={description}
                      onChange={({ target }) => setDescription(target.value.trim())}
                    />
                  </FormControl>

                  <FormControl id="supplier" isInvalid={isSupplierFieldInvalid} isRequired>
                    <FormLabel>{t('drawers.fields.supplier.label')}</FormLabel>

                    <Input
                      size="sm"
                      placeholder={t('drawers.fields.supplier.placeholder')}
                      defaultValue={supplier}
                      onChange={({ target }) => setSupplier(target.value.trim())}
                    />
                  </FormControl>

                  <FormControl id="user" isRequired>
                    <FormLabel>{t('drawers.fields.user.label')}</FormLabel>

                    <UserSelect users={users} selected={user} onChange={setUser} />
                  </FormControl>

                  <FormControl id="eventDate" isInvalid={eventDate === null} isRequired>
                    <FormLabel>{t('drawers.fields.eventDate.label')}</FormLabel>
                    <DatePicker
                      popperPlacement="top"
                      dateFormat="dd MMM yyyy"
                      selected={eventDate}
                      placeholderText={t('common:fields.date.placeholder')}
                      onChange={setEventDate}
                      inputProps={{ size: 'sm' }}
                    />
                  </FormControl>

                  <FormControl id="paymentDate" isInvalid={paymentDate === null} isRequired>
                    <FormLabel>{t('drawers.fields.paymentDate.label')}</FormLabel>
                    <DatePicker
                      popperPlacement="top"
                      dateFormat="dd MMM yyyy"
                      selected={paymentDate}
                      placeholderText={t('common:fields.date.placeholder')}
                      onChange={setPaymentDate}
                      inputProps={{ size: 'sm' }}
                    />
                  </FormControl>

                  <FormControl id="amount" isRequired>
                    <FormLabel>{t('drawers.fields.amount.label')}</FormLabel>

                    <AmountInput
                      placeholder='1234.56'
                      currencies={Object.keys(currencies)}
                      defaultAmount={amount}
                      defaultCurrency={currency}
                      onChange={handleAmountChange}
                      isInvalid={isAmountIFieldInvalid}
                    />
                  </FormControl>

                  <FormControl id="document">
                    <FormLabel>{t('drawers.fields.document.label')}</FormLabel>

                    <FileInput
                      label={t('common:fields.fileDocument.label')}
                      sublabel={t('common:fields.fileDocument.documentTypes')}
                      contentTypes={DOCUMENT_CONTENT_TYPES}
                      imageMaxWidth={1200}
                      defaultName={props.externalCost?.filename}
                      defaultSize={props.externalCost?.size}
                      isSavedFile={!!props.externalCost?.path && !!filename && !document}
                      onChange={handleDocument}
                      onRemove={() => handleDocument(null)}
                    />
                  </FormControl>
                </VStack>
            }
          </DrawerBody>

          <DrawerFooter>
            <HStack justifyContent="space-between" w="100%">
              <SecondaryButton colorScheme="pink" size="sm" onClick={onClose}>
                { t('common:buttons.close') }
              </SecondaryButton>

              <PrimaryButton colorScheme="teal" size="sm" isLoading={isSaving} isDisabled={isSaveDisabled()} onClick={onSave}>
                { t('common:buttons.save') }
              </PrimaryButton>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}