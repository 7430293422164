import { Select } from '@chakra-ui/react';
import { TeamJSON } from '@jurnee/common/src/entities/Team';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  onKeyDown?(e: React.KeyboardEvent): void;
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
  defaultValue?: TeamJSON['workModel'];
}

class WorkModelSelector extends React.PureComponent<Props> {

  render() {
    return (
      <Select
        size="sm"
        onKeyDown={this.props.onKeyDown}
        placeholder="Select work model"
        bg="white"
        onChange={this.props.onChange}
        defaultValue={this.props.defaultValue}
      >
        <option value="HYBRID">{this.props.t('HYBRID')}</option>
        <option value="REMOTE">{this.props.t('REMOTE')}</option>
        <option value="OFFICE">{this.props.t('OFFICE')}</option>
      </Select>
    );
  }

}

export default withTranslation('teams', { keyPrefix: 'workModel' })(WorkModelSelector);