import ApprovalRequestEntity, { ApprovalRequestJSON } from '../entities/ApprovalRequest';
import ApprovalResponseEntity from '../entities/ApprovalResponse';
import { BookingExport } from '../entities/Booking';
import { User } from '../entities/User';
import { DateRange, isInDateRange } from './dates';

export function getInitialApprovalResponses(approvalRequest: ApprovalRequestEntity): ApprovalResponseEntity[] {
  return approvalRequest.approvalResponses.filter(({ status }) => status === 'INITIAL');
}

export function hasInitialApprovalResponses(approvalRequest: ApprovalRequestEntity): boolean {
  return approvalRequest.approvalResponses.some(({ status }) => status === 'INITIAL');
}

export function hasAnApprovalRequestRejected(approvalRequests: ApprovalRequestEntity[]): boolean {
  return approvalRequests.some(({ approvalResponses }) => approvalResponses.some(({ status }) => status === 'REJECTED'));
}

export function getApprovalRequestUserIds({ userId, approvalResponses }: ApprovalRequestEntity | ApprovalRequestJSON): User['id'][] {
  const userIds = approvalResponses.map(({ userId }) => userId);

  if (userId) {
    userIds.push(userId);
  }

  return userIds;
}

export function isApprovalRequestApproved({ approvalResponses }: ApprovalRequestEntity | BookingExport['approvalRequests'][0], range?: DateRange) {
  if (approvalResponses.length === 0) {
    return false;
  }

  return approvalResponses.every(({ status, approvedAt }) => {
    const inRange = range ? isInDateRange(approvedAt, range) : true;

    return status === 'APPROVED' && inRange;
  });
}

export function hasAnApprovalRequestApproved(approvalRequests: (ApprovalRequestEntity | BookingExport['approvalRequests'][0])[], range?: DateRange): boolean {
  return approvalRequests.some(approvalRequest => isApprovalRequestApproved(approvalRequest, range));
}