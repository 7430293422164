import { ApprovalResponse, ApprovalResponseJSON } from '@jurnee/common/src/entities/ApprovalResponse';
import { ApprovalRequestData } from 'src/store/approvalRequests/approvalRequests.selectors';

export function isProcessedByApprover(approvalRequest: ApprovalRequestData, approverId: ApprovalResponse['userId']): boolean {
  return approvalRequest.approvalResponses.some(({ status, userId }) => status !== 'INITIAL' && userId === approverId);
}

export function getApproverApprovalResponse(approvalRequest: ApprovalRequestData, approverId: ApprovalResponse['userId']): ApprovalResponseJSON {
  return approvalRequest.approvalResponses.find((approvalResponse): approvalResponse is ApprovalResponseJSON => {
    return approvalResponse.userId === approverId;
  });
}