import { api } from '@jurnee/common/src/browser/Api';
import { SurveySubmissionCreateBody, SurveySubmissionUpdateBody } from '@jurnee/common/src/dtos/surveySubmissions';
import { SurveyUpsertBody } from '@jurnee/common/src/dtos/surveys';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { SurveyJSON } from '@jurnee/common/src/entities/Survey';
import { SurveySubmissionJSON } from '@jurnee/common/src/entities/SurveySubmission';
import { List } from '@jurnee/common/src/serializers';

interface SurveyParams {
  bookingId: BookingJSON['id'];
  surveyId: SurveyJSON['id'];
}

interface SurveySubmissionParams {
  bookingCuid: BookingJSON['cuid'];
  surveyId: SurveyJSON['id'];
  surveySubmissionId: SurveySubmissionJSON['id'];
}

export function getSurveys({ bookingId }: Pick<SurveyParams, 'bookingId'>): Promise<List<SurveyJSON, never>> {
  return api.get(`bookings/${bookingId}/surveys`);
}

export function createSurvey({ bookingId }: Pick<SurveyParams, 'bookingId'>, body: SurveyUpsertBody): Promise<SurveyJSON> {
  return api.post(`bookings/${bookingId}/surveys`, body);
}

export function updateSurvey({ bookingId, surveyId }: SurveyParams, body: SurveyUpsertBody): Promise<SurveyJSON> {
  return api.put(`bookings/${bookingId}/surveys/${surveyId}`, body);
}

export function deleteSurvey({ bookingId, surveyId }: SurveyParams): Promise<SurveyJSON> {
  return api.delete(`bookings/${bookingId}/surveys/${surveyId}`);
}

export function getSurveySubmissions({ bookingId, surveyId }: SurveyParams): Promise<List<SurveySubmissionJSON, never>> {
  return api.get(`bookings/${bookingId}/surveys/${surveyId}/submissions`);
}

export function getSurvey({ bookingCuid, surveyId }: Omit<SurveySubmissionParams, 'surveySubmissionId'>): Promise<SurveyJSON> {
  return api.get(`bookings/${bookingCuid}/surveys/${surveyId}`);
}

export function getSurveySubmission({ bookingCuid, surveyId, surveySubmissionId }: SurveySubmissionParams): Promise<SurveySubmissionJSON> {
  return api.get(`bookings/${bookingCuid}/surveys/${surveyId}/submissions/${surveySubmissionId}`);
}

export function createSurveySubmission({ bookingCuid, surveyId }: Omit<SurveySubmissionParams, 'surveySubmissionId'>, body: SurveySubmissionCreateBody): Promise<SurveySubmissionJSON> {
  return api.post(`bookings/${bookingCuid}/surveys/${surveyId}/submissions`, body);
}

export function updateSurveySubmission({ bookingCuid, surveyId, surveySubmissionId }: SurveySubmissionParams, body: SurveySubmissionUpdateBody): Promise<SurveySubmissionJSON> {
  return api.put(`bookings/${bookingCuid}/surveys/${surveyId}/submissions/${surveySubmissionId}`, body);
}