import { VStack } from '@chakra-ui/react';
import { BookingItemJSON } from '@jurnee/common/src/entities/BookingItem';
import { sortByDate } from '@jurnee/common/src/utils/arrays';
import { useMemo } from 'react';
import { BookingItemLine } from 'src/components/BookingItemLine';

interface Props {
  bookingsItems: BookingItemJSON[];
}

export function BookingItemsList(props: Props) {
  const bookingItems = useMemo(() => sortByDate(props.bookingsItems, 'from'), [props.bookingsItems]);

  return (
    <VStack w="100%" spacing={5}>
      { bookingItems.map((bookingItem, i) => <BookingItemLine key={i} item={bookingItem} />) }
    </VStack>
  );
}