import { BookingParticipantUpdateBody, BookingParticipantsImportBody, BookingParticipantsInviteBody } from '@jurnee/common/src/dtos/bookingsParticipants';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BookingParticipantJSON } from '@jurnee/common/src/entities/BookingParticipant';
import { List } from '@jurnee/common/src/serializers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteBookingParticipant, getBookingParticipants, importBookingParticipants, inviteBookingParticipants, updateBookingParticipant } from '../../api/registrations';
import { RootState } from '../state';

interface BookingParticipantDeletePayload {
  bookingId: BookingJSON['id'];
  bookingParticipantId: BookingParticipantJSON['id'];
}

interface BookingParticipantUpdatePayload {
  bookingId: BookingJSON['id'];
  bookingParticipantId: BookingParticipantJSON['id'];
  data: BookingParticipantUpdateBody
}

interface BookingParticipantsImportPayload {
  bookingId: BookingJSON['id'];
  data: BookingParticipantsImportBody;
}

interface BookingParticipantsInvitePayload {
  bookingId: BookingJSON['id'];
  data: BookingParticipantsInviteBody;
}

export const getBookingParticipantsThunk = createAsyncThunk<List<BookingParticipantJSON, never>, { bookingId: BookingJSON['id'] }, { state: RootState }>('BOOKING_PARTICIPANTS_GET', async (params, thunkAPI) => {
  try {
    const bookingParticipants = await getBookingParticipants(params);
    return bookingParticipants;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const importBookingParticipantsThunk = createAsyncThunk<List<BookingParticipantJSON, never>, BookingParticipantsImportPayload, { state: RootState }>('BOOKING_PARTICIPANTS_IMPORT', async ({ bookingId, data }, thunkAPI) => {
  try {
    const bookingParticipants = await importBookingParticipants({ bookingId }, data);
    return bookingParticipants;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const inviteBookingParticipantsThunk = createAsyncThunk<List<BookingParticipantJSON, never>, BookingParticipantsInvitePayload, { state: RootState }>('BOOKING_PARTICIPANTS_INVITE', async ({ bookingId, data }, thunkAPI) => {
  try {
    const bookingParticipants = await inviteBookingParticipants({ bookingId }, data);
    return bookingParticipants;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const deleteBookingParticipantThunk = createAsyncThunk<BookingParticipantJSON, BookingParticipantDeletePayload, { state: RootState }>('BOOKING_PARTICIPANT_DELETE', async (params, thunkAPI) => {
  try {
    const bookingParticipant = await deleteBookingParticipant(params);
    return bookingParticipant;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const updateBookingParticipantThunk = createAsyncThunk<BookingParticipantJSON, BookingParticipantUpdatePayload, { state: RootState }>('BOOKING_PARTICIPANT_UPDATE', async ({ bookingId, bookingParticipantId, data }, thunkAPI) => {
  try {
    const bookingParticipant = await updateBookingParticipant({ bookingId, bookingParticipantId }, data);
    return bookingParticipant;
  } catch (e) {
    return thunkAPI.rejectWithValue({ message: e.message });
  }
});