import { Box, VStack } from '@chakra-ui/react';
import { EmptyState } from '@jurnee/common/src/components/EmptyState';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BudgetRow } from './BudgetRow';
import { BudgetsListFilters, filterBudgets } from './BudgetsListFilters';

interface Props {
  budgets: BudgetJSON[];
}

export function BudgetsList({ budgets }: Props) {
  const { t } = useTranslation('settings', { keyPrefix: 'budgets.filters.emptyState' });

  const [nameInput, setNameInput] = useState<string>('');
  const [definitionType, setdefinitionType] = useState<BudgetJSON['definitionType'] | ''>('');

  const filteredBudgets = useMemo(
    () => filterBudgets(budgets, { definitionType, nameInput }),
    [budgets, definitionType, nameInput]
  );

  return (
    <VStack spacing={5}>
      <BudgetsListFilters
        definitionTypeValue={definitionType}
        onNameChange={({ target }) => setNameInput(target.value)}
        onDefinitionTypeChange={({ target }) => setdefinitionType(target.value as BudgetJSON['definitionType'])}
      />

      {
        filteredBudgets.length > 0 ? (
          <VStack spacing={5} w="100%">
            { filteredBudgets.map(budget => <BudgetRow key={budget.id} budget={budget} />) }
          </VStack>
        ) : (
          <Box w="100%" bg="white" borderRadius={4} border="1px solid" borderColor="blue.50" p={16}>
            <EmptyState
              icon="listSearch"
              heading={t('heading')}
              description={t('description')}
            />
          </Box>
        )
      }
    </VStack>
  );
}