import { FormControl, FormLabel, InputGroup, InputGroupProps, InputLeftElement, NumberInput, NumberInputField } from '@chakra-ui/react';
import { getCurrencyChar } from '@jurnee/common/src/utils/prices';

export interface Props {
  id: string;
  label: string;
  defaultValue?: number;
  value?: number;
  currency: string;
  onChange(value: string): void;
}

export function PriceInput(props: Props & Pick<InputGroupProps, 'size'>) {
  const { id, label, currency, defaultValue, value, onChange, ...inheritedProps } = props;

  return (
    <FormControl id={id}>
      <FormLabel>{label}</FormLabel>
      <InputGroup {...inheritedProps}>
        <InputLeftElement pointerEvents="none" color="gray.400">
          { getCurrencyChar(currency) }
        </InputLeftElement>

        <NumberInput precision={2} defaultValue={defaultValue} value={value} onChange={onChange}>
          <NumberInputField bg="white" pl={8} pr={4} />
        </NumberInput>
      </InputGroup>
    </FormControl>
  );
}