import { Image, Input, Text, VStack } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { PrimaryButton } from 'src/components/buttons';
import { useAppDispatch } from 'src/store';
import { setToken } from 'src/store/auth/auth.thunks';

export function Sso() {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(searchParams.has('token'));
  const hasError = searchParams.has('error');
  const error = hasError ? searchParams.get('error') : null;

  function onSubmit() {
    setIsLoading(true);
    document.location.href = `${process.env.API_URL}/auth/sso?email=${encodeURIComponent(email)}`;
  }

  if (searchParams.has('token')) {
    dispatch(setToken(searchParams.get('token')));
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <VStack justifyContent="center" w="100%" h="100%" spacing={5}>
      <Image mx="auto" w={200} src="/assets/images/logo.svg" />
      <VStack w={400} spacing={5} bg="white" p={5} border="1px solid" borderColor="gray.200" borderRadius={8}>
        {hasError && (
          <Text color="red.500" textAlign="center">
            {t(`login.sso.errors.${error}`)}
          </Text>
        )}
        <Input name="email" placeholder="Email" value={email} type="email" onChange={(e) => setEmail(e.target.value)} />
        <PrimaryButton w="100%" onClick={onSubmit}>
          {t('login.sso.buttons.login')}
        </PrimaryButton>
      </VStack>
    </VStack>
  );
}