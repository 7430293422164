import { EmployeeUpdateBody, InviteEmployeesBody } from '@jurnee/common/src/dtos/users';
import UserEntity, { User, UserDetails } from '@jurnee/common/src/entities/User';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/employees';
import { getApprovalProcesses } from '../approvalProcesses/approvalProcesses.thunks';
import { RootState } from '../state';
import { getTeams } from '../teams/teams.thunks';
import { showToast } from '../toasts/toasts.thunks';

interface EmployeeUpdatePayload {
  id: UserEntity['id'];
  data: EmployeeUpdateBody;
}

export const getEmployees = createAsyncThunk<UserDetails[], null, { state: RootState }>('EMPLOYEES_FETCH', async (args, thunkAPI) => {
  try {
    const { company } = thunkAPI.getState();

    return await api.getEmployees(company.data.id);
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while retrieving employees`, status: 'error' }));
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const inviteEmployees = createAsyncThunk<number, InviteEmployeesBody, { state: RootState }>('EMPLOYEES_INVITE', async (payload, thunkAPI) => {
  try {
    const { company } = thunkAPI.getState();

    await api.inviteEmployees(company.data.id, payload);

    thunkAPI.dispatch(showToast({
      title: `${payload.users.length} employees invited`,
      status: 'success'
    }));

    thunkAPI.dispatch(getEmployees());

    if (typeof payload.approvalProcessId === 'number') {
      thunkAPI.dispatch(getApprovalProcesses());
    }

    if (typeof payload.teamId === 'number') {
      thunkAPI.dispatch(getTeams());
    }
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while inviting employees`, status: 'error' }));
    return thunkAPI.rejectWithValue(null);
  }
});

export const sendInvitation = createAsyncThunk<void, User, { state: RootState }>('EMPLOYEES_SEND_INVITATION', async (user, thunkAPI) => {
  try {
    await api.sendInvitation(user.companyId, user.id, user.email);

    thunkAPI.dispatch(showToast({
      title: `Invitation sent to ${user.email}`,
      status: 'success'
    }));
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while sending the invitation`, status: 'error' }));
    return thunkAPI.rejectWithValue(null);
  }
});

export const deleteEmployee = createAsyncThunk<null, UserDetails, { state: RootState }>('EMPLOYEES_DELETE', async (user, thunkAPI) => {
  try {
    const { company } = thunkAPI.getState();

    await api.deleteEmployee(company.data.id, user.id);

    thunkAPI.dispatch(showToast({ title: `Account deleted`, status: 'success' }));
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: e.message || `An error occurred while deleting this account`, status: 'error' }));
    return thunkAPI.rejectWithValue(user);
  }
});

export const updateEmployee = createAsyncThunk<UserDetails, EmployeeUpdatePayload, { state: RootState }>('EMPLOYEE_UPDATE', async ({ id, data }, thunkAPI) => {
  try {
    const { company } = thunkAPI.getState();
    const employee = await api.updateEmployee(company.data.id, id, data);

    return employee;
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: error.message });
  }
});