import { Center, HStack, Text } from '@chakra-ui/react';
import { Icon, IconKey } from '@jurnee/common/src/components/Icon';

interface Props {
  icon: IconKey;
  colorScheme: string;
  label: string
}

export function IconTitle({ icon, colorScheme, label }: Props) {
  const color = colorScheme === 'gray' ? 'black' : `${colorScheme}.400`;

  return (
    <HStack w="100%" spacing="10px">
      <Center w="32px" h="32px" bg={`${colorScheme}.50`} borderRadius={4}>
        <Icon icon={icon} size={5} color={color}/>
      </Center>

      <Text fontWeight={700}>{label}</Text>
    </HStack>
  );
}