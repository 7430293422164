import { Divider, HStack, Heading, Spacer, VStack } from '@chakra-ui/react';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { TeamJSON } from '@jurnee/common/src/entities/Team';
import { getBudgetRemaining, getBudgetRenewedOn } from '@jurnee/common/src/utils/budgets';
import { formatDate } from '@jurnee/common/src/utils/dates';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BudgetChart from 'src/components/Budget/BudgetChart';
import { getBudgetBreakdownsByIds } from 'src/store/budgetBreakdowns/budgetBreakdowns.selectors';
import { getBudgetsByIds } from 'src/store/budgets/budgets.selectors';
import { getTeamBudgetBreakdownsByIds } from 'src/store/teamBudgetBreakdowns/teamBudgetBreakdowns.selectors';
import { getTeamsByIds } from 'src/store/teams/teams.selectors';
import { BudgetRenewalDate } from './BudgetRenewalDate';
import { BudgetTeamSelect, DefaultBudgetTeamValue } from './BudgetTeamSelect';
import { PeopleHeadcount } from './PeopleHeadcount';

interface Props {
  budgetIds: BudgetJSON['id'][];
  teamIds: TeamJSON['id'][];
}

export function BudgetsOverview(props: Props) {
  const { t } = useTranslation('bookings');

  const budgetBreakdowns = useSelector(getBudgetBreakdownsByIds(props.budgetIds));
  const budgetIds = budgetBreakdowns.filter(({ total }) => total).map(({ budgetId }) => budgetId);
  const budgets = useSelector(getBudgetsByIds(budgetIds));

  const teamBudgetBreakdowns = useSelector(getTeamBudgetBreakdownsByIds(props.teamIds));
  const teamIds = teamBudgetBreakdowns.filter(({ total }) => total).map(({ teamId }) => teamId);
  const teams = useSelector(getTeamsByIds(teamIds));

  const [option, setOption] = useState<DefaultBudgetTeamValue>(getDefaultOption());

  function getDefaultOption(): DefaultBudgetTeamValue {
    if (budgets.length > 0) {
      return { type: 'BUDGET', id: budgets[0].id };
    }

    if (teams.length > 0) {
      return { type: 'TEAM', id: teams[0].id };
    }

    return null;
  }

  if (budgets.length === 0 && teams.length === 0) {
    return null;
  }

  const headcount = useMemo(() => {
    if (option.type === 'TEAM') {
      const team = teams.find(team => team.id === option.id);
      return team.usersTeams.length;
    }

    const budget = budgets.find(budget => budget.id === option.id);
    return budget.budgetsOrganizers.length;
  }, [option]);

  const renewalDate = useMemo(() => {
    if (option.type === 'TEAM') {
      return null;
    }

    const budget = budgets.find(budget => budget.id === option.id);
    return formatDate(getBudgetRenewedOn(budget.frequency));
  }, [option]);

  const breakdown = useMemo(() => {
    if (option.type === 'TEAM') {
      return teamBudgetBreakdowns.find(({ teamId }) => teamId === option.id);
    }

    return budgetBreakdowns.find(({ budgetId }) => budgetId === option.id);
  }, [option]);

  const remaining = useMemo(() => getBudgetRemaining(breakdown), [option]);

  return (
    <VStack position="sticky" top={8} minW={256} alignItems="stretch" spacing={3}>
      <Heading size="md" h={8}>{t('budgetsOverview.title')}</Heading>

      <VStack bg="white" borderRadius={4} border="1px solid" borderColor="blue.50" spacing={0} alignItems="stretch">
        <BudgetTeamSelect
          px={5}
          py={3}
          budgets={budgets}
          teams={teams}
          defaultValue={option}
          onChange={setOption}
        />

        <Divider />

        <BudgetChart
          px={5} py={4}
          spent={breakdown.spent}
          pending={breakdown.pending}
          remaining={remaining}
          total={breakdown.total}
          currency='EUR'
        />

        <Divider />

        <HStack w="100%" px={5} py={4}>
          { option.type === 'BUDGET' && <BudgetRenewalDate date={renewalDate}/> }
          <Spacer />
          <PeopleHeadcount type={option.type} headcount={headcount} />
        </HStack>
      </VStack>
    </VStack>
  );
}