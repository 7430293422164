import { PriceInCurrency } from '@jurnee/common/src/components/PriceInCurrency';
import { useSelector } from 'react-redux';
import { getUserSelector } from 'src/store/user/user.selectors';

interface Props {
  value: number;
  currency: string;
  fractionDigits?: number;
}

export default function PriceInUserCurrency({ value, currency, fractionDigits }: Props) {
  const user = useSelector(getUserSelector);

  return <PriceInCurrency
    value={value}
    currency={currency}
    targetCurrency={user.currency}
    fractionDigits={fractionDigits}
  />;
}