import { Box, HStack, IconButton, Text, VStack } from '@chakra-ui/react';
import { ExperienceAverageRating } from '@jurnee/common/src/components/experiences/ExperienceAverageRating';
import { ExperienceRecommendedLabel } from '@jurnee/common/src/components/experiences/ExperienceRecommendedLabel';
import { Icon } from '@jurnee/common/src/components/Icon';
import { Currency } from '@jurnee/common/src/entities/Currency';
import { ExperienceJSON, ExperienceRatingJSON } from '@jurnee/common/src/entities/Experience';
import { getCdnImageUrl } from '@jurnee/common/src/utils/core';
import { getExperienceMaxParticipants, getExperiencePath, isCompanyRecommended, isGeneric } from '@jurnee/common/src/utils/experiences';
import { getCheapestPackageUnitPrice, isCustomRequestProduct } from '@jurnee/common/src/utils/products';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import FavoriteButton from 'src/components/buttons/FavoriteButton';
import ExperiencePriceLabel from 'src/components/Experience/ExperiencePriceLabel';
import AddCreateCollectionModal from 'src/modals/AddCreateCollectionModal';
import { experiencesRatingsSelectors } from 'src/store/experiencesRatings/experiencesRatings.selectors';
import { RootState } from 'src/store/state';
import { addToFavoriteExperiences, removeFromFavoriteExperiences } from 'src/store/userFavoriteExperiences/userFavoriteExperiences.thunks';
import LinkQuery from '../LinkQuery';
import PriceInUserCurrency from '../PriceInUserCurrency';

interface OwnProps {
  key: number;
  experience: ExperienceJSON;
  participants?: number;
}

interface StateProps {
  currency: Currency;
  isFavorite: boolean;
  isCompanyRecommended: boolean;
  rating: ExperienceRatingJSON;
}

interface DispatchProps {
  addToFavoriteExperiences(experience: ExperienceJSON): void;
  removeFromFavoriteExperiences(id: ExperienceJSON['id']): void;
}

type Props = StateProps & DispatchProps & OwnProps & WithTranslation;

class ExperienceCard extends React.Component<Props> {

  get pricePerUnit() {
    const products = this.props.experience.products.filter(product => !isCustomRequestProduct(product));

    if (products.length === 0) {
      return (
        <Text fontSize={14} fontWeight={700} lineHeight="16px" noOfLines={1}>
          { this.props.t('price.priceUponRequest') }
        </Text>
      );
    }

    return (
      <ExperiencePriceLabel
        experience={this.props.experience}
        currency={this.props.currency}
        participants={this.props.participants}
      />
    );
  }

  get totalPrice() {
    if (!this.props.participants) {
      return null;
    }

    if (this.props.experience.products.every(isCustomRequestProduct)) {
      return null;
    }

    const products = this.props.experience.products.filter(product => product.currency === this.props.currency && !isCustomRequestProduct(product));
    const totalPrice = getCheapestPackageUnitPrice(products, this.props.participants) * this.props.participants;

    return (
      <Text fontSize={14} fontWeight={500} lineHeight="16px" color="gray.400" noOfLines={1}>
        <PriceInUserCurrency value={totalPrice} currency={this.props.currency}/> {this.props.t('experience:price.inTotal')}
      </Text>
    );
  }

  get buttons() {
    return (
      <HStack w="100%" spacing={4} alignItems="flex-start">
        <FavoriteButton
          onClick={this.onFavoriteClick}
          aria-label="Favorite Experience"
          isActive={this.props.isFavorite}
          _groupHover={{ opacity: 1 }}
          opacity={this.props.isFavorite ? 1 : 0}
        />
        <AddCreateCollectionModal experienceId={this.props.experience.id}>
          <IconButton
            aria-label="Add to list"
            borderRadius={4}
            bg="white"
            _groupHover={{ opacity: 1 }}
            opacity={0}
            _hover={{ background: 'whiteAlpha.800' }}
            size="sm"
            icon={<Icon icon="folderIn" size={5} color="black" />}
          />
        </AddCreateCollectionModal>
      </HStack>
    );
  }

  onFavoriteClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (this.props.isFavorite) {
      this.props.removeFromFavoriteExperiences(this.props.experience.id);
    } else {
      this.props.addToFavoriteExperiences(this.props.experience);
    }
  };

  get partnerAddress() {
    if (!this.props.experience.partner?.address) {
      return null;
    }

    const { city, postalCode } = this.props.experience.partner.address;

    return (
      [
        <Text key="dot" fontSize="14px" fontWeight={500} lineHeight="16px">·</Text>,
        <Text key="address" fontSize="14px" fontWeight={500} lineHeight="16px" noOfLines={1}>{`${city} ${postalCode}`}</Text>
      ]
    );
  }

  get subtitle(): JSX.Element {

    return (
      <HStack spacing={1}>
        <ExperienceAverageRating rating={this.props.rating} />
        { this.partnerAddress }
      </HStack>
    );
  }

  get specifications() {
    return (
      <HStack spacing={1}>
        {
          this.props.experience.type !== 'BOX_SHIPPING' &&
            [
              <Text key="maxDuration" fontSize={14} fontWeight={500} lineHeight="16px">
                { this.props.t('specs.durationInMin', { maxDuration: this.props.experience.maxDuration }) }
              </Text>,
              <Text key="dot" fontSize={14} fontWeight={500} lineHeight="16px">·</Text>
            ]
        }

        <Text fontSize={14} fontWeight={500} lineHeight="16px">{this.props.t('specs.participants', { maxParticipants: getExperienceMaxParticipants(this.props.experience) })}</Text>
      </HStack>
    );
  }

  get customRequestInspiration() {
    return (
      <HStack alignItems="center" spacing={1} height={4}>
        <Icon icon="bulb" color="yellow.500" size={5}/>
        <Text fontWeight={500} color="yellow.500">
          {this.props.t('specs.customRequestInspiration')}
        </Text>
      </HStack>
    );
  }

  get category() {
    if (this.props.experience.experiencesTags.length === 0) {
      return null;
    }

    return <Text fontSize={14} fontWeight={500} lineHeight="16px">{this.props.experience.experiencesTags[0].tag.name}</Text>;
  }

  render() {
    const { experience } = this.props;

    return (
      <LinkQuery to={getExperiencePath(experience)} target="_blank" role="group">
        <VStack w="100%" spacing={4} alignItems="flex-start">
          <Box
            w="100%"
            style={{ aspectRatio: '1' }}
            p={4}
            bg={`linear-gradient(rgba(30, 0, 50, 0.1), rgba(30, 0, 50, 0.1)), url('${getCdnImageUrl(experience.experiencesImages[0].image.path)}') no-repeat center / cover`}
            bgColor="gray.400"
            borderRadius={8}
          >
            { this.buttons }
          </Box>

          <VStack spacing={2} alignItems="flex-start">
            <Text fontSize={14} fontWeight={700} lineHeight="16px" noOfLines={1}>{experience.name}</Text>

            { isGeneric(this.props.experience) ? this.customRequestInspiration : this.subtitle }
          </VStack>

          <VStack spacing={2} alignItems="flex-start" color="gray.400">
            { this.specifications }

            { this.props.isCompanyRecommended ? <ExperienceRecommendedLabel /> : this.category }
          </VStack>

          <VStack spacing={2} alignItems="flex-start">
            { this.pricePerUnit }

            { this.totalPrice }
          </VStack>
        </VStack>
      </LinkQuery>
    );
  }

}

function mapStateToProps(state: RootState, props: OwnProps): StateProps {
  return {
    currency: state.company.data.currency,
    isFavorite: state.userFavoriteExperiences.list.some(({ id }) => id === props.experience.id),
    isCompanyRecommended: isCompanyRecommended(props.experience, Object.values(state.companyRecommendedPartners.entities)),
    rating: experiencesRatingsSelectors.selectById(state, props.experience.id)
  };
}

const mapDispatchToProps: DispatchProps = {
  addToFavoriteExperiences,
  removeFromFavoriteExperiences
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('experience')(ExperienceCard));