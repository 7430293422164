import { chakra, StackProps, Text, VStack } from '@chakra-ui/react';
import { Currency } from '@jurnee/common/src/entities/Currency';
import { formatPrice } from '@jurnee/common/src/utils/prices';
import { Trans } from 'react-i18next';

interface Props {
  totalAmount: number;
  currency: Currency;
  itemsCount: number;
  i18nKey: string;
}

export default function TotalAmountCount({ totalAmount, currency, itemsCount, i18nKey, ...inheritedProps }: Props & StackProps) {
  return (
    <VStack spacing={1} alignItems="stretch" {...inheritedProps}>
      <Text fontSize={20} lineHeight="18px" fontWeight={700}>
        { formatPrice(totalAmount, currency) }
      </Text>

      <Text color="gray.400" textTransform='uppercase' fontSize={12} lineHeight="10px">
        <Trans
          i18nKey={i18nKey}
          components={{ itemsCount: <chakra.span color='black' /> }}
          values={{ itemsCount: itemsCount }}
        />
      </Text>
    </VStack>
  );
}