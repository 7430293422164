import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, HStack, Input, VStack, useToast } from '@chakra-ui/react';
import { QuestionUpsertBody, SurveyUpsertBody } from '@jurnee/common/src/dtos/surveys';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { SurveyJSON } from '@jurnee/common/src/entities/Survey';
import { getTmpId } from '@jurnee/common/src/utils/strings';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSurvey, updateSurvey } from 'src/api/surveys';
import { useAppDispatch } from 'src/store';
import { trackEvent } from 'src/store/analytics/analytics.thunks';
import { PrimaryButton, SecondaryButton } from '../../components/buttons';
import { EmptyState } from './EmptyState';
import { Questions } from './Questions';

interface Props {
  bookingId: BookingJSON['id'];
  survey?: SurveyJSON;
  children?: React.ReactElement;
  isOpen: boolean;
  onClose(): void;
  onCreate?(survey: SurveyJSON): void;
  onUpdate?(survey: SurveyJSON): void;
}

export function SurveyDrawer(props: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation(['surveys', 'common']);

  const [isSaving, setIsSaving] = useState(false);

  const [name, setName] = useState(props.survey?.name || '');
  const [questions, setQuestions] = useState<QuestionUpsertBody[]>(props.survey?.questions || []);

  function onClose() {
    props.onClose();

    setIsSaving(false);
  }

  function onOpen(event?: React.MouseEvent) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    dispatch(trackEvent({
      name: 'opened_survey_drawer'
    }));
  }

  useEffect(() => {
    if (props.isOpen) {
      onOpen();
    }
  }, [props.isOpen]);

  function getBody(): SurveyUpsertBody {
    return {
      name,
      questions
    };
  }

  async function update() {
    setIsSaving(true);

    try {
      const body = getBody();
      const survey = await updateSurvey({
        bookingId: props.bookingId,
        surveyId: props.survey.id
      }, body);

      props.onUpdate(survey);

      setIsSaving(false);
      onClose();
      return toast(getSuccessToast(t('drawers.survey.toasts.update.success')));
    } catch(error) {
      setIsSaving(false);
      return toast(getErrorToast(t('drawers.survey.toasts.update.error'), error.message));
    }
  }

  async function create() {
    setIsSaving(true);

    try {
      const body = getBody();
      const survey = await createSurvey({
        bookingId: props.bookingId
      }, body);

      props.onCreate(survey);

      onClose();
      return toast(getSuccessToast(t('drawers.survey.toasts.create.success')));
    } catch(error) {
      setIsSaving(false);
      return toast(getErrorToast(t('drawers.survey.toasts.create.error'), error.message));
    }
  }

  function onSave() {
    return props.survey ? update() : create();
  }

  function addQuestion() {
    const newQuestion: QuestionUpsertBody = {
      id: getTmpId(),
      label: null,
      rank: questions.length,
      required: false,
      type: null,
      questionOptions: []
    };

    setQuestions([...questions, newQuestion]);
  }

  return (
    <>
      { props.children }

      <Drawer size="md" isOpen={props.isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            { props.survey ? t('drawers.survey.updateTitle') : t('drawers.survey.createTitle') }
          </DrawerHeader>

          <DrawerBody>
            <VStack spacing={2}>
              <FormControl id="name" isRequired>
                <FormLabel>{t('drawers.survey.form.name.label')}</FormLabel>
                <Input
                  size="sm"
                  autoFocus
                  placeholder={t('drawers.survey.form.name.placeholder')}
                  onChange={e => setName(e.target.value)}
                  defaultValue={name}
                />
              </FormControl>

              {
                questions.length === 0 ? (
                  <EmptyState onAddQuestion={addQuestion} />
                ) : (
                  <Questions
                    questions={questions}
                    onChange={setQuestions}
                    onAdd={addQuestion}
                  />
                )
              }
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <HStack justifyContent="space-between" w="100%">
              <SecondaryButton size="sm" colorScheme="pink" onClick={onClose}>
                { t('common:buttons.close') }
              </SecondaryButton>

              <PrimaryButton size="sm" colorScheme="teal" isLoading={isSaving} isDisabled={questions.length === 0} onClick={onSave}>
                { t('common:buttons.save') }
              </PrimaryButton>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}