import { PropositionJSON } from '../entities/Proposition';
import { PropositionItemJSON } from '../entities/PropositionItem';
import { getBookingItemTotalPrice } from './bookingsItems';

export function getTotalPrice({ propositionsItems }: { propositionsItems: Pick<PropositionItemJSON, 'unitAmount' | 'quantity'>[] }) {
  return propositionsItems.reduce((total, propositionItem) => {
    return total + getBookingItemTotalPrice(propositionItem);
  }, 0);
}

export function getPriceRange(propositions: PropositionJSON[]): { from: number, to: number } {
  const grouped = propositions.reduce((out, { propositionsGroupId, ...proposition }) => {
    const price = getTotalPrice(proposition);

    if (Array.isArray(out[propositionsGroupId])) {
      out[propositionsGroupId].push(price);
    } else {
      out[propositionsGroupId] = [price];
    }

    return out;
  }, {} as Record<number, number[]>);

  const prices = Object.values(grouped);

  return {
    from: prices.map(prices => Math.min(...prices)).reduce((total, price) => total += price, 0),
    to: prices.map(prices => Math.max(...prices)).reduce((total, price) => total += price, 0)
  };
}

export function isSelectedOrDeclined(proposition: PropositionJSON) {
  return !!proposition.selectedAt || !!proposition.declinedAt;
}