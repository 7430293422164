import { Divider, HStack, Spacer, Text, VStack } from '@chakra-ui/react';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { ProductJSON } from '@jurnee/common/src/entities/Product';
import { PropositionJSON } from '@jurnee/common/src/entities/Proposition';
import { PropositionItemJSON } from '@jurnee/common/src/entities/PropositionItem';
import { PropositionsGroupJSON } from '@jurnee/common/src/entities/PropositionsGroup';
import { getById, sortByDateAndAlphabetically } from '@jurnee/common/src/utils/arrays';
import { getTotalPrice, isSelectedOrDeclined } from '@jurnee/common/src/utils/propositions';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { useMemo } from 'react';
import ItemCard from 'src/components/ItemCard';
import PriceInUserCurrency from 'src/components/PriceInUserCurrency';
import ItemCommentDrawer from 'src/drawers/ItemCommentDrawer';
import { PropositionCommentsDrawer } from 'src/drawers/PropositionCommentsDrawer';
import { Actions } from './Actions';
import { Comment } from './Comment';
import { ExpirationDate } from './ExpirationDate';

interface Props {
  bookingId: BookingJSON['id'];
  proposition: PropositionJSON;
  products: ProductJSON[];
  experiences: ExperienceJSON[];
  status: PropositionsGroupJSON['status'];
  onDecline(proposition: PropositionJSON, reason: string): Promise<void>;
  onSelect(proposition: PropositionJSON): void;
}

export function PropositionCard({ bookingId, proposition, products, experiences, status, onDecline, onSelect }: Props) {
  const total = getTotalPrice(proposition);
  const hasExpirationDate = status !== 'CLOSED' && !isSelectedOrDeclined(proposition) && proposition.expiredAt;

  const propositionItems = useMemo(
    () => {
      return sortByDateAndAlphabetically(proposition.propositionsItems, 'from', 'description').map((propositionItem, i) => {
        const product = getById(products, propositionItem.productId);
        const experience = getById(experiences, product?.experienceId);

        return <ItemCard
          key={propositionItem.id}
          stackProps={i > 0 ? { pt: 5, borderTop: '1px solid var(--chakra-colors-blue-50)' } : null}
          item={propositionItem as PropositionItemJSON}
          experience={experience}
          currency={product ? product.currency : 'EUR'}
        >
          {
            propositionItem.comment &&
              <ItemCommentDrawer
                heading={propositionItem.description}
                comment={propositionItem.comment}
              />
          }
        </ItemCard>;
      });
    },
    [proposition, products, experiences]
  );

  return (
    <VStack w="100%" p={5} bg="white" border="1px solid" borderColor="gray.200" borderRadius={4} spacing={4}>
      <HStack w="100%" justifyContent="space-between">
        <Text variant="label">{proposition.name}</Text>

        <PropositionCommentsDrawer
          bookingId={bookingId}
          proposition={proposition}
          count={proposition._count.propositionComments}
        />
      </HStack>

      <Divider />

      { !isEmpty(proposition.comment) && <Comment value={proposition.comment} /> }

      {
        hasExpirationDate ?
          <ExpirationDate expiredAt={proposition.expiredAt} /> :
          !isEmpty(proposition.comment) && <Divider />
      }

      <VStack w="100%" spacing={5}>
        { propositionItems }
      </VStack>

      <Divider />

      <HStack w="100%">
        <Actions
          proposition={proposition}
          status={status}
          onDecline={reason => onDecline(proposition, reason)}
          onSelect={() => onSelect(proposition)}
        />

        <Spacer />

        <Text variant="label">
          <PriceInUserCurrency value={total} currency="EUR" />
        </Text>
      </HStack>
    </VStack>
  );
}