import { HStack } from '@chakra-ui/react';
import { BookingParticipantJSON } from '@jurnee/common/src/entities/BookingParticipant';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/buttons';

interface Props {
  status: BookingParticipantJSON['status'];
  onDecline(): void;
  onAccept(): void;
}

export function RegistrationFormButtons({ onDecline, onAccept, status }: Props) {
  if (!['INVITED', 'INITIAL', null].includes(status)) {
    return null;
  }

  const { t } = useTranslation('registration');

  return (
    <HStack spacing={5} w="100%">
      <PrimaryButton size="sm" colorScheme="teal" w="50%" onClick={onAccept}>
        {t('buttons.register')}
      </PrimaryButton>
      <PrimaryButton size="sm" colorScheme="pink" w="50%" onClick={onDecline}>
        {t('buttons.decline')}
      </PrimaryButton>
    </HStack>
  );
}