{
  "form": {
    "select": {
      "placeholder": "Select a user"
    },
    "firstName": {
      "placeholder": "First name"
    },
    "lastName": {
      "placeholder": "Last name"
    },
    "email": {
      "placeholder": "Email"
    }
  },
  "drawer": {
    "title": "Edit",
    "form": {
      "role": {
        "label": "Role"
      },
      "approvalProcess": {
        "label": "Approval process",
        "noApprovalProcess": "No approval process"
      },
      "individualBudget": {
        "label": "Individual budget",
        "noIndividualBudget": "No individual budget"
      },
      "teams": {
        "label": "Teams",
        "placeholder": "Search a team to add this user to",
        "noTeams": "This member is currently not part of any team"
      }
    },
    "toasts": {
      "error": "An error occurred while updating the user",
      "success": "User has been successfully updated"
    }
  },
  "role": {
    "ADMIN": "Admin",
    "ORGANIZER": "Organizer",
    "PARTICIPANT": "Participant",
    "tooltip": "In addition to organizers rights, admins can access all company events, make settings changes, and view billing details."
  },
  "modal": {
    "invite": {
      "description": "Add new members to your Jurnee account. You can add multiple members by separating their email addresses with a comma, space or newline.",
      "emailsTextArea": {
        "placeholder": "Enter email addresses separated by comma, space or newline"
      },
      "role": {
        "label": "These members will have the role:",
        "ADMIN": {
          "helpText": "In addition to organizers rights, admins can access all company events, make settings changes, and view billing details. They will receive an email invitation login."
        },
        "ORGANIZER": {
          "helpText": "Organizers can plan events, access their budgets, collaborate and invite participants to register. They will receive an email invitation login."
        },
        "PARTICIPANT": {
          "helpText": "This role is intended for registration and budgets tracking but participants are not expected to log in to this dashboard. They will not receive an email invitation login."
        }
      },
      "team": {
        "label": "Add to team"
      },
      "approvalProcess": {
        "label": "Add to approval process"
      }
    }
  }
}