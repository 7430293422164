import { Center, Checkbox, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, HStack, Select, Text, VStack } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { LanguageSelect } from '@jurnee/common/src/components/LanguageSelect';
import { Loader } from '@jurnee/common/src/components/Loader';
import { NumberInput } from '@jurnee/common/src/components/NumberInput';
import { CompanyJSON } from '@jurnee/common/src/entities/Company';
import { Currency } from '@jurnee/common/src/entities/Currency';
import { ExperiencesSearchParams } from '@jurnee/common/src/entities/Experience';
import { LanguageJSON } from '@jurnee/common/src/entities/Language';
import { TagJSON } from '@jurnee/common/src/entities/Tag';
import { TeamJSON } from '@jurnee/common/src/entities/Team';
import { TeamBudgetBreakdownJSON } from '@jurnee/common/src/entities/TeamBudgetBreakdown';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import { getBudgetRemaining } from '@jurnee/common/src/utils/budgets';
import * as React from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import TeamSelect from 'src/components/Booking/TeamSelect';
import BudgetChart from 'src/components/Budget/BudgetChart';
import { PriceInput } from 'src/components/PriceInput';
import { ExperiencesFiltersParams } from 'src/store/experiences/experiences.thunks';
import { RootState } from 'src/store/state';
import { teamBudgetBreakdownsSelectors } from 'src/store/teamBudgetBreakdowns/teamBudgetBreakdowns.selectors';
import { teamsSelectors } from 'src/store/teams/teams.selectors';
import { PrimaryButton, SecondaryButton } from '../components/buttons';
import { TrackEventOptions, trackEvent } from '../store/analytics/analytics.thunks';
import { getUserTeams } from '../store/teams/teams.thunks';

interface OwnProps {
  filters: ExperiencesSearchParams;
  languages: LanguageJSON[];
  tags: TagJSON[];
  currency: Currency;
  children: React.ReactElement;
  basedOnBudget?: boolean;
  onSave(filters: ExperiencesFiltersParams): void;
}

interface State {
  isOpen: boolean;
  teamId: number;
  budgetTotal: number;
  filters: ExperiencesFiltersParams;
  isLoading: boolean;
}

interface DispatchProps {
  getUserTeams(): void
  trackEvent(opts: TrackEventOptions): void;
}

interface StateProps {
  company: CompanyJSON;
  teamBudgetBreakdowns: TeamBudgetBreakdownJSON[];
  teams: TeamJSON[];
  user: RootState['user'];
}

type Props = OwnProps & DispatchProps & StateProps & WithTranslation;

class ExperiencesFiltersDrawer extends React.PureComponent<Props, State> {

  state: State = {
    isOpen: false,
    teamId: null,
    budgetTotal: null,
    filters: this.props.filters,
    isLoading: true
  };

  async componentDidMount() {
    if (this.props.basedOnBudget) {
      await this.props.getUserTeams();

      const teamId = this.props.user.usersTeams.length > 0 ? this.props.user.usersTeams[0].team.id : null;

      if (!teamId) {
        return;
      }

      this.prefillWithTeamData(this.state, teamId);
    }

    this.setState({ ...this.state, isLoading: false });
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props !== prevProps) {
      const newState = {
        ...this.state,
        budgetTotal: this.roundValue(this.props.filters.price * this.props.filters.participants),
        filters: this.props.filters
      };

      this.setState(newState);

      if (this.props.basedOnBudget) {
        const teamId = this.props.user.usersTeams.length > 0 ? this.props.user.usersTeams[0].team.id : null;

        if (!teamId) {
          return;
        }

        if (this.state.isLoading) {
          return;
        }

        this.prefillWithTeamData(newState, teamId);
      }
    }
  }

  getTeamParticipantsCount = (team: TeamJSON) => {
    return team.estimatedSize || team.usersTeams.length || this.state.filters.participants;
  };

  prefillWithTeamData = (state: State, teamId: TeamJSON['id']) => {
    const team = this.getTeam(teamId);
    const teamBudgetBreakdown = this.props.teamBudgetBreakdowns.find(({ teamId }) => teamId === team.id);
    const remaining = teamBudgetBreakdown ? getBudgetRemaining(teamBudgetBreakdown) : null;

    const participants = this.getTeamParticipantsCount(team);
    const price = remaining ? this.roundValue(remaining / participants) : state.filters.price;

    this.setState({
      ...state,
      teamId,
      budgetTotal: this.roundValue(price * participants),
      filters: {
        ...state.filters,
        participants,
        price
      }
    });
  };

  onClose = () => {
    this.setState({ ...this.state, isOpen: false });
  };

  onOpen = () => {
    this.setState({
      ...this.state,
      isOpen: true
    });

    this.props.trackEvent({
      name: `opened_experiences_filters${this.props.basedOnBudget ? '_my_team' : ''}_drawer`
    });
  };

  onSave = () => {
    this.props.onSave(this.state.filters);

    this.onClose();
  };

  setTeamId = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    const teamId = Number(target.value);

    this.prefillWithTeamData(this.state, teamId);
  };

  setLanguageId = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ ...this.state, filters: { ...this.state.filters, languageId: Number(e.target.value) } });
  };

  setParticipants = (value: string) => {
    const participants = Number(value);

    if (isNaN(participants) || participants < 1) {
      return;
    }

    this.setState({
      ...this.state,
      budgetTotal: this.roundValue(this.state.filters.price * participants),
      filters: {
        ...this.state.filters,
        participants
      }
    });
  };

  setTagId = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ ...this.state, filters: { ...this.state.filters, tagIds: Number(e.target.value) ? [Number(e.target.value)] : [] } });
  };

  setBudgetPerPers = (value: string) => {
    const price = Number(value);

    if (isNaN(price)) {
      return;
    }

    this.setState({
      ...this.state,
      budgetTotal: this.roundValue(price * this.state.filters.participants),
      filters: {
        ...this.state.filters,
        price
      }
    });
  };

  setBudgetTotal = (value: string) => {
    const budgetTotal = Number(value);

    if (isNaN(budgetTotal)) {
      return;
    }

    this.setState({
      ...this.state,
      budgetTotal,
      filters: {
        ...this.state.filters,
        price: this.roundValue(budgetTotal / this.state.filters.participants)
      }
    });
  };

  getTeam = (teamId: number) => {
    return this.props.teams.find(({ id }) => id === teamId);
  };

  roundValue = (value: number) => {
    return Math.max((Math.round(value * 100) / 100), 0);
  };

  setIsCompanyRecommended = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, filters: { ...this.state.filters, isCompanyRecommended: target.checked } });
  };

  get teamBudget() {
    if (!this.props.basedOnBudget) {
      return null;
    }

    return [
      this.teamSelect,
      this.remainingBudget
    ];
  }

  get teamSelect() {
    if (this.props.user.usersTeams.length < 2) {
      return null;
    }

    return <TeamSelect
      size="sm"
      key="teamSelect"
      label={this.props.t('drawer.selectTeam.label')}
      teams={this.props.user.usersTeams.map(({ team }) => ({ id: team.id, name: team.name }))}
      onTeamChange={this.setTeamId}
      defaultValue={this.state.teamId}
    />;
  }

  get remainingBudget() {
    const team = this.getTeam(this.state.teamId);

    if (!team) {
      return null;
    }

    const teamBudgetBreakdown = this.props.teamBudgetBreakdowns.find(({ teamId }) => teamId === team.id);
    const remaining = getBudgetRemaining(teamBudgetBreakdown);

    if (!teamBudgetBreakdown.total) {
      return null;
    }

    return (
      <VStack key="budgetRemaining" width="100%" spacing={2} alignItems="flex-start">
        <Text fontWeight={700}>{this.props.t('drawer.budgetRemaining.label')}</Text>
        <BudgetChart
          w="100%"
          p={5}
          bg='white'
          borderRadius={4}
          border="1px solid"
          borderColor="blue.50"
          spent={teamBudgetBreakdown.spent}
          pending={teamBudgetBreakdown.pending}
          remaining={remaining}
          total={teamBudgetBreakdown.total}
          currency={this.props.currency}
        />
      </VStack>
    );
  }

  get participantsInput() {
    return (
      <FormControl id="participants">
        <FormLabel>{this.props.t('drawer.participants.label')}</FormLabel>
        <NumberInput
          size="sm"
          onChange={this.setParticipants}
          defaultValue={this.state.filters.participants}
        />
      </FormControl>
    );
  }

  get categoryOptions() {
    return sortAlphabeticallyBy(this.props.tags, 'name').map((tag, i) => (
      <option key={i} value={tag.id}>{tag.name}</option>
    ));
  }

  get categorySelect() {
    const defaultValue = this.props.filters.tagIds && this.props.filters.tagIds.length > 0 ? this.props.filters.tagIds[0] : '';

    return (
      <FormControl id="tagId">
        <FormLabel>{this.props.t('drawer.category.label')}</FormLabel>
        <Select
          size="sm"
          bgColor="white"
          defaultValue={defaultValue}
          placeholder={this.props.t('drawer.category.placeholder')}
          onChange={this.setTagId}
        >
          { this.categoryOptions }
        </Select>
      </FormControl>
    );
  }

  get recommendedByCompany() {
    return (
      <FormControl id="isCompanyRecommended">
        <FormLabel>
          <Trans
            i18nKey="experiences:drawer.recommendedByCompany.label"
            values={{ companyName: this.props.company.name }}
          />
        </FormLabel>
        <Checkbox isChecked={this.state.filters.isCompanyRecommended} onChange={this.setIsCompanyRecommended}>
          { this.props.t('drawer.recommendedByCompany.description') }
        </Checkbox>
      </FormControl>
    );
  }

  get body() {
    if(this.state.isLoading) {
      return <Loader />;
    }

    return (
      <DrawerBody p={5}>
        <VStack w="100%" spacing={5}>
          { this.teamBudget }

          <HStack w="100%" spacing="10px">
            <PriceInput
              size="sm"
              id="budgetPerPers"
              label={this.props.t('drawer.budgetPerPers.label')}
              value={this.state.filters.price}
              currency={this.props.user.currency.id}
              onChange={this.setBudgetPerPers}
            />

            <Center alignSelf="flex-end" h="32px">
              <Icon icon="arrowLeftRight" color="gray.400" size={4} />
            </Center>

            <PriceInput
              size="sm"
              id="budgetTotal"
              label={this.props.t('drawer.budgetTotal.label')}
              value={this.state.budgetTotal}
              currency={this.props.user.currency.id}
              onChange={this.setBudgetTotal}
            />
          </HStack>

          <HStack w="100%" spacing={8}>
            <LanguageSelect
              size="sm"
              label={this.props.t('drawer.language.label')}
              languages={this.props.languages}
              languageId={this.props.filters.languageId}
              placeholder={this.props.t('drawer.language.placeholder')}
              onChange={this.setLanguageId}
            />

            { this.participantsInput }
          </HStack>

          { this.categorySelect }
          { this.recommendedByCompany }
        </VStack>
      </DrawerBody>
    );
  }

  get header() {
    return (
      <DrawerHeader>
        { this.props.t('drawer.title') }
      </DrawerHeader>
    );
  }

  get footer() {
    return (
      <DrawerFooter>
        <HStack justifyContent="space-between" w="100%">
          <SecondaryButton size="sm" colorScheme="pink" onClick={this.onClose}>
            { this.props.t('buttons.close', { ns: 'common' }) }
          </SecondaryButton>
          <PrimaryButton size="sm" colorScheme="teal" onClick={this.onSave} isDisabled={this.state.filters.price <= 0}>
            { this.props.t('buttons.apply', { ns: 'common' }) }
          </PrimaryButton>
        </HStack>
      </DrawerFooter>
    );
  }

  render() {
    return (
      <>
        { React.cloneElement(this.props.children, { onClick: this.onOpen }) }

        <Drawer isOpen={this.state.isOpen} onClose={this.onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            { this.header }
            { this.body }
            { this.footer }
          </DrawerContent>
        </Drawer>
      </>
    );
  }

}

function mapStateToProps(state: RootState): StateProps {
  return {
    company: state.company.data,
    teamBudgetBreakdowns: teamBudgetBreakdownsSelectors.selectAll(state),
    teams: teamsSelectors.selectAll(state),
    user: state.user
  };
}

const mapDispatchToProps: DispatchProps = {
  getUserTeams,
  trackEvent
};

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('experiences')(ExperiencesFiltersDrawer));