{
  "form": {
    "name": {
      "label": "Name",
      "placeholder": "ACME Corporation",
      "error": "Name is required"
    },
    "address": {
      "label": "Address",
      "placeholder": "1, rue des Activités",
      "error": "Address is required"
    },
    "additionalInformation": {
      "label": "Additional information",
      "placeholder": "3rd floor"
    },
    "postalCode": {
      "label": "Postal code",
      "placeholder": "75001",
      "error": "Postal code and city are required"
    },
    "city": {
      "label": "City",
      "placeholder": "Paris"
    },
    "state": {
      "label": "State",
      "placeholder": "Île-de-France"
    },
    "country": {
      "label": "Country",
      "error": "Country is required"
    },
    "taxIdNumber": {
      "label": "VAT Number",
      "placeholder": "FRAB123456789",
      "error": "VAT Number is required"
    }
  },
  "toasts": {
    "update": {
      "success": "Your billing information has been updated",
      "error": "An error occurred while updating your billing information"
    }
  },
  "billedEntity": "Entity"
}