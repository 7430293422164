import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { bootstrap } from '../bootstrap/bootstrap.thunks';
import { createOidcConfiguration, updateOidcConfiguration } from '../oidcConfiguration/oidcConfiguration.thunks';
import { createScimConfiguration } from '../scimConfigurations/scimConfigurations.thunks';
import { initialState } from '../state';
import { getCompanyTags } from './../tags/tags.thunks';
import { updateCompany } from './company.thunks';

const slice = createSlice({
  name: 'company',
  initialState: initialState.company,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(updateCompany.fulfilled, (state, action) => {
      return {
        ...state,
        data: action.payload,
        error: null,
        status: FETCH_STATUS.FETCHED
      };
    });

    builder.addCase(bootstrap.fulfilled, (state, action) => {
      return {
        ...state,
        data: action.payload.company,
        error: null,
        status: FETCH_STATUS.FETCHED
      };
    });

    builder.addCase(getCompanyTags.pending, (state) => {
      return {
        ...state,
        tags: { list: [], error: false, status: FETCH_STATUS.FETCHING }
      };
    });

    builder.addCase(getCompanyTags.fulfilled, (state, action) => {
      return {
        ...state,
        tags: { list: action.payload, error: false, status: FETCH_STATUS.FETCHED }
      };
    });

    builder.addCase(getCompanyTags.rejected, (state) => {
      return {
        ...state,
        tags: { list: [], error: true, status: FETCH_STATUS.INITIAL }
      };
    });

    builder.addCase(createOidcConfiguration.fulfilled, (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          oidcConfiguration: action.payload
        }
      };
    });

    builder.addCase(updateOidcConfiguration.fulfilled, (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          oidcConfiguration: action.payload
        }
      };
    });

    builder.addCase(createScimConfiguration.fulfilled, (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          scimConfiguration: action.payload
        }
      };
    });

  }
});

export default slice.reducer;