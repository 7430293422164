import { Heading, Text, VStack } from '@chakra-ui/react';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { getBookingImageUrl, getMainBookingRelation } from '@jurnee/common/src/utils/bookings';
import { formatDate } from '@jurnee/common/src/utils/dates';
import * as React from 'react';

interface OwnProps {
  booking: BookingJSON;
}

export default class Summary extends React.Component<OwnProps> {

  get mainBookingRelation() {
    return getMainBookingRelation(this.props.booking);
  }

  get imageUrl() {
    return getBookingImageUrl(this.props.booking);
  }

  get bookingItemFrom() {
    return formatDate(new Date(this.mainBookingRelation.from));
  }

  render() {
    return (
      <VStack
        w="50%"
        spacing={8}
        p={8}
        justifyContent="center"
        alignItems="center"
        bg={`linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${this.imageUrl}')`}
        bgSize="cover"
        bgPos="center"
      >
        <Heading textAlign="center" color='white'>{this.props.booking.name}</Heading>
        <Text color='gray.200'>{this.bookingItemFrom}</Text>
      </VStack>
    );
  }

}