import { FormControl, FormLabel, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, VStack, useToast } from '@chakra-ui/react';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RemovableUsersList } from 'src/components/RemovableUsersList';
import { UsersSearchSelect } from 'src/components/UsersSearchSelect';
import { useAppDispatch } from 'src/store';
import { trackEvent } from 'src/store/analytics/analytics.thunks';
import { addBookingOrganizers } from 'src/store/bookingDetails/bookingDetails.thunks';
import { PrimaryButton, SecondaryButton } from '../components/buttons';

interface Props {
  booking: BookingJSON;
  organizers: UserDetails[];
  users: UserDetails[];
}

export default function AddOrganizersModal({ booking, organizers, users }: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation(['booking', 'common']);

  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [organizerIds, setOrganizerIds] = useState([]);

  function onClose() {
    setIsOpen(false);
  }

  function onOpen(event: React.MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    setOrganizerIds([]);
    setIsOpen(true);

    dispatch(trackEvent({
      name: 'opened_add_organizers_modal'
    }));
  }

  function addOrganizers() {
    return dispatch(
      addBookingOrganizers({
        bookingId: booking.id,
        data: { organizerIds }
      })
    );
  }

  async function onAddOrganizers() {
    setIsSaving(true);

    try {
      await addOrganizers().unwrap();
      toast(getSuccessToast(t('modals.addOrganizers.toasts.success')));
      onClose();
    } catch(error) {
      toast(getErrorToast(t('modals.addOrganizers.toasts.error'), error.message));
    }

    setIsSaving(false);
  }

  function onAddUser({ value: { id } }: { value: UserDetails }) {
    setOrganizerIds([...organizerIds, id]);
  }

  function onRemoveUser({ id }: UserDetails) {
    setOrganizerIds(organizerIds.filter(userId => userId !== id));
  }

  return (
    <>
      <PrimaryButton size="sm" colorScheme="black" alignSelf="flex-end" onClick={onOpen}>
        { t('organizers.addOrganizer') }
      </PrimaryButton>

      <Modal size="sm" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent overflow="initial">
          <ModalHeader>
            { t('modals.addOrganizers.title') }

            <ModalCloseButton />
          </ModalHeader>

          <ModalBody>
            <FormControl>
              <FormLabel>{t('modals.addOrganizers.users.label')}</FormLabel>

              <VStack w="100%" spacing="10px">
                <UsersSearchSelect users={users} userIds={[...organizers.map(({ id }) => id), ...organizerIds]} onAddUser={onAddUser} />
                <RemovableUsersList users={users} userIds={organizerIds} onRemoveUser={onRemoveUser} />
              </VStack>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <HStack w="100%" justifyContent="space-between">
              <SecondaryButton colorScheme="pink" size="sm" onClick={onClose}>
                { t('common:buttons.close') }
              </SecondaryButton>

              <PrimaryButton colorScheme="teal" size="sm" onClick={onAddOrganizers} isLoading={isSaving} isDisabled={organizerIds.length === 0}>
                { t('modals.addOrganizers.inviteOrganizers') }
              </PrimaryButton>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}