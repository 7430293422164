import { FileDownload } from '@jurnee/common/src/dtos/files';

export function openBase64File({ data, type }: FileDownload) {
  const decodedData = window.atob(data);

  const uInt8Array = new Uint8Array(decodedData.length);

  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }

  const blob = new Blob([uInt8Array], { type });

  const blobUrl = URL.createObjectURL(blob);

  window.open(blobUrl, '_blank');
}