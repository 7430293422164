import { Heading, Text, VStack } from '@chakra-ui/react';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserSelector } from 'src/store/user/user.selectors';

export function Header() {
  const { t } = useTranslation('home');

  const user = useSelector(getUserSelector);

  return (
    <VStack spacing={2}>
      <Heading>
        { t('heading', !isEmpty(user.firstName) ? { context: 'name', firstName: user.firstName } : {}) }
      </Heading>

      <Text maxW={520} color="gray.400" textAlign="center">
        { t('subheading') }
      </Text>
    </VStack>
  );
}