import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { formatDateShort } from '@jurnee/common/src/utils/dates';

interface Props {
  bookingInvoice: BookingInvoiceJSON;
}

export function InvoiceDate({ bookingInvoice }: Props) {
  if (!['OPEN', 'PAID'].includes(bookingInvoice.status)) {
    return '-';
  }

  return formatDateShort(new Date(bookingInvoice.finalizedAt));
}