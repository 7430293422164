import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { getEntities, updateEntity } from './entities.thunks';

const slice = createSlice({
  name: 'entities',
  initialState: initialState.entities,
  reducers: { },
  extraReducers: (builder) => {

    builder.addCase(getEntities.pending, (state) => {
      return { ...state, list: [], error: false, status: FETCH_STATUS.FETCHING };
    });

    builder.addCase(getEntities.fulfilled, (state, action) => {
      return { ...state, list: action.payload, error: false, status: FETCH_STATUS.FETCHED };
    });

    builder.addCase(getEntities.rejected, (state) => {
      return { ...state, list: [], error: true, status: FETCH_STATUS.INITIAL };
    });

    builder.addCase(updateEntity.fulfilled, (state, action) => {
      const list = state.list.map(entity => {
        if (entity.id === action.payload.id) {
          return action.payload;
        }

        return entity;
      });

      return { ...state, list };
    });

  }
});

export default slice.reducer;