import { SkeletonText as ChakraSkeletonText, SkeletonTextProps } from '@chakra-ui/react';
import * as React from 'react';

export default class SkeletonText extends React.PureComponent<SkeletonTextProps> {

  render() {
    return <ChakraSkeletonText
      noOfLines={1}
      speed={0.8}
      startColor="gray.100"
      endColor="gray.200"
      { ...this.props }
    />;
  }

}