{
  "categories": {
    "BAR": "Bars",
    "BOX_SHIPPING": "Box shipping",
    "CUSTOM_REQUEST": {
      "buttonLabel": "Send my request",
      "description": "Send a request to receive proposals and quotes for your event.",
      "title": "On demand event services"
    },
    "IN_OFFICE": "Office animations",
    "IN_PERSON": "In person activities",
    "MEETING_ROOM": "Meeting rooms",
    "PAY_VIA_JURNEE": {
      "buttonLabel": "Pay via Jurnee",
      "description": "Everything is already organized and you have a quote? Approve the spend and manage the payment.",
      "title": "Pay via Jurnee"
    },
    "RESTAURANT": "Restaurants",
    "SPECIFIC_PARTNER": {
      "buttonLabel": "Request specific provider",
      "description": "Ask to receive quotes from a specific event provider you have already in mind.",
      "title": "Specific provider"
    },
    "TRANSPORT": "Transport",
    "VIRTUAL": "Virtual experiences"
  },
  "drawer": {
    "budgetPerPers": {
      "label": "Budget per person"
    },
    "budgetRemaining": {
      "label": "Remaining budget"
    },
    "budgetTotal": {
      "label": "Budget in total"
    },
    "category": {
      "label": "Category",
      "placeholder": "All"
    },
    "language": {
      "label": "Language",
      "placeholder": "All"
    },
    "participants": {
      "label": "Participants"
    },
    "recommendedByCompany": {
      "description": "Show only vendors pre-screened by your company",
      "label": "{{companyName}} recommended vendors"
    },
    "selectTeam": {
      "label": "Select your team"
    },
    "title": "Filters"
  },
  "explore": {
    "subtitle": "Browse our selection for ideas and inspiration",
    "title": "Explore catalog"
  },
  "header": {
    "experiencesResultCount": "{{count}} activities are matching your filters",
    "filters": "Filters",
    "lists": "My lists",
    "myTeam": "For my team",
    "participantsCount": "{{count}} people",
    "title": "Explore"
  },
  "noResults": {
    "cta": "Make a custom request",
    "description": "Please try searching with different filters",
    "heading": "Sorry we couldn't find any matching results"
  },
  "places": {
    "heading": "Also available via request"
  },
  "placeSearch": {
    "error": "An error occured while searching places"
  },
  "searchbar": {
    "placeholder": {
      "activity": "Search an activity…",
      "bar": "Search a bar…",
      "box": "Search a box…",
      "restaurant": "Search a restaurant…"
    }
  }
}