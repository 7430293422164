import { Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import * as React from 'react';
import { BookingsTabs } from 'src/utils/booking';

interface Props {
  tabItems: TabItem[];
  currentTab: BookingsTabs;
  onChange(tab: BookingsTabs): void;
}

export interface TabItem {
  label: string;
  count: number;
  key: BookingsTabs;
  panelContent: React.ReactNode;
}

export default function BookingsTabs(props: Props) {

  function formatLabel(label: TabItem['label'], count: number) {
    const counter = count > 0 ? `(${count})` : null;

    return <Text noOfLines={1}>{label} {counter}</Text>;
  }

  function tabList() {
    const tabs = props.tabItems.map(({ key, label, count }) => {
      return (
        <Tab key={key} onClick={() => props.onChange(key)}>
          { formatLabel(label, count) }
        </Tab>
      );
    });

    return (
      <TabList>
        { tabs }
      </TabList>
    );
  }

  function tabPanels() {
    const tabPanels = props.tabItems.map(({ key, panelContent }) => {
      return (
        <TabPanel key={key}>
          { panelContent }
        </TabPanel>
      );
    });

    return (
      <TabPanels>
        { tabPanels }
      </TabPanels>
    );
  }

  return (
    <Tabs index={props.tabItems.findIndex(({ key }) => key === props.currentTab)}>
      { tabList() }
      { tabPanels() }
    </Tabs>
  );

}