import { Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, HStack, VStack, useToast } from '@chakra-ui/react';
import { TaskUpsertBody } from '@jurnee/common/src/dtos/tasks';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { TaskEntity, TaskJSON } from '@jurnee/common/src/entities/Task';
import { TaskAttachmentJSON } from '@jurnee/common/src/entities/TaskAttachment';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { getOrganizerIds } from '@jurnee/common/src/utils/bookings';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { cloneElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteTaskAttachment, getTaskAttachments, uploadTaskAttachment } from 'src/api/tasks';
import { useAppDispatch } from 'src/store';
import { trackEvent } from 'src/store/analytics/analytics.thunks';
import { createTaskThunk, updateTaskThunk } from 'src/store/tasks/tasks.thunks';
import { PrimaryButton, SecondaryButton } from '../../components/buttons';
import { AssigneeSelect } from './AssigneeSelect';
import { Attachments } from './Attachments';
import { Comments } from './Comments';
import { DescriptionTextArea } from './DescriptionTextArea';
import { DueDateDatePicker } from './DueDateDatePicker';
import { NameInput } from './NameInput';
import { PrioritySelect } from './PrioritySelect';
import { StatusSelect } from './StatusSelect';

interface Props {
  booking: BookingJSON;
  organizers: Pick<UserJSON, 'id' | 'firstName' | 'lastName' | 'email'>[];
  task?: TaskJSON;
  children?: React.ReactElement;
  isOpen?: boolean;
  onUpsert(task: TaskJSON): void;
  onClose?(): void;
}

export function TaskDrawer(props: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation(['tasks', 'common']);

  const [isOpen, setIsOpen] = useState(!!props.isOpen);
  const [isSaving, setIsSaving] = useState(false);

  const [assigneeId, setAssigneeId] = useState(props.task?.assigneeId || null);
  const [assigneeType, setAssigneeType] = useState(props.task?.assigneeType || 'CUSTOMER');
  const [description, setDescription] = useState(props.task?.description || '');
  const [dueAt, setDueAt] = useState(props.task?.dueAt ? new Date(props.task.dueAt) : null);
  const [name, setName] = useState(props.task?.name || '');
  const [priority, setPriority] = useState(props.task?.priority || 'MEDIUM');
  const [status, setStatus] = useState(props.task?.status || 'TODO');

  const [areAttachmentsLoading, setAreAttachmentsLoading] = useState(!!props.task);
  const [attachments, setAttachments] = useState<TaskAttachmentJSON[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [attachmentIdsToDelete, setAttachmentIdsToDelete] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const isEditable = !props.task || props.task.ownerType === 'CUSTOMER';

  const organizers = useMemo(
    () => props.organizers.filter(user => getOrganizerIds(props.booking).includes(user.id)),
    [props.organizers, props.booking]
  );

  const taskBody: TaskUpsertBody = {
    assigneeId: assigneeType === 'CUSTOMER' ? assigneeId : null,
    assigneeType,
    description,
    dueAt,
    name,
    priority,
    status
  };

  async function fetchTaskAttachments() {
    setAreAttachmentsLoading(true);

    const { list } = await getTaskAttachments({
      bookingId: props.booking.id,
      taskId: props.task.id
    });

    setAttachments(list);
    setAreAttachmentsLoading(false);
  }

  function onClose() {
    props.onClose && props.onClose();

    setIsOpen(false);
    setIsSaving(false);
  }

  function onOpen(event?: React.MouseEvent) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    setIsOpen(true);

    if (props.task) {
      fetchTaskAttachments();
    }

    dispatch(trackEvent({
      name: 'opened_task_drawer'
    }));
  }

  useEffect(() => {
    if (props.isOpen) {
      onOpen();
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (isOpen && props.task) {
      fetchTaskAttachments();
    }
  }, []);

  async function handleUploadedFiles(task: TaskEntity) {
    const uploadedAttachments: TaskAttachmentJSON[] = [];
    const uploadedFileIdentifiers: number[] = [];

    setIsUploading(true);

    for (const file of files) {
      try {
        const attachment = await uploadTaskAttachment(
          { bookingId: props.booking.id, taskId: task.id },
          file
        );

        uploadedAttachments.push(attachment);
        uploadedFileIdentifiers.push(file.lastModified);
      } catch(error) {
        toast(getErrorToast(t('drawer.toasts.uploadFile.error'), error.message));
      }
    }

    setIsUploading(false);

    return { uploadedAttachments, uploadedFileIdentifiers };
  }

  async function handleDeletedAttachments(task: TaskEntity) {
    const deletedAttachmentIds: number[] = [];

    for (const taskAttachmentId of attachmentIdsToDelete) {
      try {
        const { id } = await deleteTaskAttachment({
          bookingId: props.booking.id,
          taskId: task.id,
          taskAttachmentId
        });

        deletedAttachmentIds.push(id);
      } catch(error) {
        toast(getErrorToast(t('drawer.toasts.deleteFile.error'), error.message));
      }
    }

    return deletedAttachmentIds;
  }

  async function update() {
    setIsSaving(true);

    try {
      const task = await dispatch(updateTaskThunk(
        {
          bookingId: props.booking.id,
          taskId: props.task.id,
          body: taskBody,
          prevStatus: props.task.status
        }
      )).unwrap();

      const { uploadedAttachments, uploadedFileIdentifiers } = await handleUploadedFiles(task);
      const deletedAttachmentIds = await handleDeletedAttachments(task);
      const filteredAttachments = attachments.filter(({ id }) => !deletedAttachmentIds.includes(id));

      setAttachments([...filteredAttachments, ...uploadedAttachments]);
      setAttachmentIdsToDelete(attachmentIdsToDelete.filter(id => !deletedAttachmentIds.includes(id)));
      setFiles(files.filter(({ lastModified }) => !uploadedFileIdentifiers.includes(lastModified)));

      props.onUpsert(task);

      setIsSaving(false);
      return toast(getSuccessToast(t('drawer.toasts.update.success')));
    } catch(error) {
      setIsSaving(false);
      return toast(getErrorToast(t('drawer.toasts.update.error'), error));
    }
  }

  async function create() {
    setIsSaving(true);

    try {
      const task = await dispatch(createTaskThunk({
        bookingId: props.booking.id,
        body: taskBody
      })).unwrap();

      const { uploadedAttachments, uploadedFileIdentifiers } = await handleUploadedFiles(task);

      setAttachments(uploadedAttachments);
      setFiles(files.filter(({ lastModified }) => !uploadedFileIdentifiers.includes(lastModified)));

      props.onUpsert(task);

      onClose();
      return toast(getSuccessToast(t('drawer.toasts.create.success')));
    } catch(error) {
      setIsSaving(false);
      return toast(getErrorToast(t('drawer.toasts.create.error'), error));
    }
  }

  function onSave() {
    return props.task ? update() : create();
  }

  function onAssigneeChange({ assigneeId, assigneeType }: Pick<TaskJSON, 'assigneeId' | 'assigneeType'>) {
    setAssigneeId(assigneeId);
    setAssigneeType(assigneeType);
  }

  function onRemoveAttachment(id: TaskAttachmentJSON['id']) {
    setAttachments(attachments.filter(attachment => attachment.id !== id));
    setAttachmentIdsToDelete([...attachmentIdsToDelete, id]);
  }

  function onAddFile(file: File) {
    setFiles([...files, file]);
  }

  function onRemoveFile(identifier: number) {
    setFiles(files.filter(file => file.lastModified !== identifier));
  }

  return (
    <>
      { props.children && cloneElement(props.children, { onClick: onOpen }) }

      <Drawer size='xl' isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            { props.task ? t('drawer.updateTitle') : t('drawer.createTitle') }
          </DrawerHeader>

          <DrawerBody p={0}>
            <HStack h="100%" w="100%" spacing={0}>
              <VStack
                h="100%"
                w="420px"
                spacing={0}
                bgColor="gray.10"
                borderRight="1px solid"
                borderColor="gray.200"
              >
                <VStack w="100%" maxH={props.task ? '60%' : '100%'} pt={3} spacing={0}>
                  <NameInput
                    defaultValue={name}
                    isDisabled={!isEditable}
                    onChange={e => setName(e.target.value)}
                  />

                  <DescriptionTextArea
                    defaultValue={description}
                    isDisabled={!isEditable}
                    onChange={setDescription}
                  />
                </VStack>

                {
                  props.task &&
                    <>
                      <Divider />

                      <Comments task={props.task} />
                    </>
                }
              </VStack>

              <VStack h="100%" w="300px" bgColor="white" p={5} spacing={4} overflowY="auto">
                <StatusSelect
                  defaultValue={status}
                  onChange={e => setStatus(e.target.value as TaskJSON['status'])}
                />

                <PrioritySelect
                  defaultValue={priority}
                  isDisabled={!isEditable}
                  onChange={e => setPriority(e.target.value as TaskJSON['priority'])}
                />

                <DueDateDatePicker
                  selected={dueAt}
                  isDisabled={!isEditable}
                  onChange={date => date ? setDueAt(date) : null}
                />

                <AssigneeSelect
                  assigneeId={assigneeId}
                  assigneeType={assigneeType}
                  organizers={organizers}
                  isCheckboxDisabled={!isEditable}
                  onChange={onAssigneeChange}
                />

                <Attachments
                  task={props.task}
                  attachments={attachments}
                  files={files}
                  isLoading={areAttachmentsLoading}
                  isUploading={isUploading}
                  isDeleteDisabled={!isEditable}
                  onRemoveAttachment={onRemoveAttachment}
                  onAddFile={onAddFile}
                  onRemoveFile={onRemoveFile}
                />
              </VStack>
            </HStack>
          </DrawerBody>

          <DrawerFooter>
            <HStack justifyContent="space-between" w="100%">
              <SecondaryButton size="sm" colorScheme="pink" onClick={onClose}>
                { t('common:buttons.close') }
              </SecondaryButton>

              <PrimaryButton size="sm" colorScheme="teal" isLoading={isSaving} onClick={onSave}>
                { t('common:buttons.save') }
              </PrimaryButton>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}