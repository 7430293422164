import { Box, HStack } from '@chakra-ui/react';

type Steps = 'RECEIVED' | 'CONTACTING' | 'WAITING' | 'CREATING';

type StepsProgress = Record<Steps, number>;

const ONE_HOUR = 1000 * 60 * 60;
const TWO_HOURS = ONE_HOUR * 2;
const ONE_DAY = ONE_HOUR * 24;
const TWO_DAYS = ONE_DAY * 2;

export function getStepsProgress(item: { createdAt: string }): StepsProgress {
  const createdAt = new Date(item.createdAt);
  const now = new Date();
  const duration = now.getTime() - createdAt.getTime();

  const progress = {
    RECEIVED: 100,
    CONTACTING: 100,
    WAITING: 100,
    CREATING: 40
  };

  if ((duration - ONE_DAY) < TWO_DAYS) {
    progress.CREATING = 0;
    progress.WAITING = (duration - ONE_DAY) / TWO_DAYS * 100;
  }

  if ((duration - TWO_HOURS) < ONE_DAY) {
    progress.WAITING = 0;
    progress.CONTACTING = (duration - TWO_HOURS) / ONE_DAY * 100;
  }

  if (duration < TWO_HOURS) {
    progress.CONTACTING = 0;
    progress.RECEIVED = duration / TWO_HOURS * 100;
  }

  return progress;
}

export function Stepper({ progress }: { progress: StepsProgress }) {
  return (
    <HStack spacing={1} w="100%" h="3px">
      <Box bg="gray.200" h="100%" w="25%">
        <Box bg="black" w={`${progress.RECEIVED}%`} h="100%"/>
      </Box>
      <Box bg="gray.200" h="100%" w="25%">
        <Box bg="black" w={`${progress.CONTACTING}%`} h="100%"/>
      </Box>
      <Box bg="gray.200" h="100%" w="25%">
        <Box bg="black" w={`${progress.WAITING}%`} h="100%"/>
      </Box>
      <Box bg="gray.200" h="100%" w="25%">
        <Box bg="black" w={`${progress.CREATING}%`} h="100%"/>
      </Box>
    </HStack>
  );
}