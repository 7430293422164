import { FormControl, FormLabel, Textarea } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionProps } from './QuestionProps';

export function QuestionText({ question, questionAnswers, onChange }: QuestionProps) {
  const { t } = useTranslation('surveys', { keyPrefix: 'submission.form' });

  function onTextChange({ target }: ChangeEvent<HTMLTextAreaElement>) {
    onChange([{
      questionId: question.id,
      questionOptionId: null,
      value: target.value
    }], question);
  }

  return (
    <FormControl isRequired={question.required}>
      <FormLabel>{question.label}</FormLabel>

      <Textarea
        size="sm"
        h="80px"
        resize="none"
        placeholder={t('textarea.placeholder')}
        onChange={onTextChange}
        value={questionAnswers[0]?.value}
      />
    </FormControl>
  );
}