import { api } from '@jurnee/common/src/browser/Api';
import { TaskCommentCreateBody, TaskUpsertBody } from '@jurnee/common/src/dtos/tasks';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { TaskJSON } from '@jurnee/common/src/entities/Task';
import { TaskAttachmentJSON } from '@jurnee/common/src/entities/TaskAttachment';
import { TaskCommentJSON, TaskCommentRelationshipsJSON } from '@jurnee/common/src/entities/TaskComment';
import { Expand, List } from '@jurnee/common/src/serializers';

export interface TaskParams {
  bookingId: BookingJSON['id'];
  taskId: TaskJSON['id'];
}

export type TaskCreateParams = Pick<TaskParams, 'bookingId'> & {
  body: TaskUpsertBody;
}

export type TaskUpdateParams = TaskParams & {
  body: TaskUpsertBody;
}

type TaskAttachmentParams = TaskParams & {
  taskAttachmentId: TaskAttachmentJSON['id'];
}

export function getTasks({ bookingId }: Pick<TaskParams, 'bookingId'>): Promise<List<TaskJSON, never>> {
  return api.get(`bookings/${bookingId}/tasks`);
}

export function createTask({ bookingId, body }: TaskCreateParams): Promise<TaskJSON> {
  return api.post(`bookings/${bookingId}/tasks`, body);
}

export function updateTask({ bookingId, taskId, body }: TaskUpdateParams): Promise<TaskJSON> {
  return api.put(`bookings/${bookingId}/tasks/${taskId}`, body);
}

export function deleteTask({ bookingId, taskId }: TaskParams): Promise<TaskJSON> {
  return api.delete(`bookings/${bookingId}/tasks/${taskId}`);
}

export function getTaskAttachments({ bookingId, taskId }: TaskParams): Promise<List<TaskAttachmentJSON, never>> {
  return api.get(`bookings/${bookingId}/tasks/${taskId}/attachments`);
}

export function uploadTaskAttachment({ bookingId, taskId }: TaskParams, file: File): Promise<TaskAttachmentJSON> {
  return api.uploadFile(`bookings/${bookingId}/tasks/${taskId}/attachments`, file);
}

export function deleteTaskAttachment({ bookingId, taskId, taskAttachmentId }: TaskAttachmentParams): Promise<TaskAttachmentJSON> {
  return api.delete(`bookings/${bookingId}/tasks/${taskId}/attachments/${taskAttachmentId}`);
}

export function getTaskAttachmentUrl({ bookingId, taskId, taskAttachmentId }: TaskAttachmentParams): Promise<{ url: string }> {
  return api.get(`bookings/${bookingId}/tasks/${taskId}/attachments/${taskAttachmentId}/url`);
}

export function getTaskComments({ bookingId, taskId }: TaskParams): Promise<List<TaskCommentJSON, TaskCommentRelationshipsJSON>> {
  return api.get(`bookings/${bookingId}/tasks/${taskId}/comments`);
}

export function createTaskComment({ bookingId, taskId }: TaskParams, body: TaskCommentCreateBody): Promise<Expand<TaskCommentJSON, TaskCommentRelationshipsJSON>> {
  return api.post(`bookings/${bookingId}/tasks/${taskId}/comments`, body);
}