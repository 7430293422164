import { HStack, Text, useClipboard } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { SecondaryButton } from './buttons';

interface Props {
  value: string;
  isLink?: boolean;
}

export default function InputCopy({ value, isLink }: Props) {
  const { hasCopied, onCopy } = useClipboard(value);
  const icon = hasCopied ? 'check' : 'copy';
  const color = hasCopied ? 'teal' : 'gray';
  const iconColor = hasCopied ? 'teal.400' : 'black';

  function onOpen() {
    window.open(value, '_blank');
  }

  return (
    <HStack border="1px solid" bg="white" borderColor="blue.50" p="5px 6px 5px 8px" borderRadius={4} justifyContent="space-between" spacing={3}>
      <Text color="gray.400" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
        { value }
      </Text>

      <SecondaryButton h="24px" colorScheme={color} onClick={onCopy} p={0} size="xs" borderRadius={2}>
        <Icon icon={icon} color={iconColor} size={4} />
      </SecondaryButton>

      {
        isLink && (
          <SecondaryButton h="24px" colorScheme="blue" onClick={onOpen} p={0} size="xs" borderRadius={2}>
            <Icon icon="externalLink" color="blue.400" size={4} />
          </SecondaryButton>
        )
      }
    </HStack>
  );
}