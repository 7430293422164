import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, HStack, Input, Select, Text, VStack, useToast } from '@chakra-ui/react';
import { InputFormControl } from '@jurnee/common/src/components/InputFormControl';
import { BookingParticipantUpdateBody } from '@jurnee/common/src/dtos/bookingsParticipants';
import { BookingParticipantJSON } from '@jurnee/common/src/entities/BookingParticipant';
import { ParticipantJSON } from '@jurnee/common/src/entities/Participant';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { cloneElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'src/components/buttons';
import { useAppDispatch } from 'src/store';
import { trackEvent } from 'src/store/analytics/analytics.thunks';
import { updateBookingParticipantThunk } from 'src/store/bookingParticipants/bookingParticipants.thunks';

interface Props {
  bookingParticipant: BookingParticipantJSON;
  children: React.ReactElement;
  onChange(): void;
}

interface StatusSelectProps {
  defaultValue: string;
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
}

function StatusSelect(props: StatusSelectProps) {
  const { t } = useTranslation('registration');

  return (
    <Select size="sm" onChange={props.onChange} defaultValue={props.defaultValue}>
      {
        ['ACCEPTED', 'ATTENDED', 'INVITED', 'DECLINED', 'INITIAL'].map((status, i) => {
          return <option key={i} value={status}>{t(`status.${status}`)}</option>;
        })
      }
    </Select>
  );
}

export default function EditBookingParticipantDrawer(props: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation(['registration', 'common']);

  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [firstName, setFirstName] = useState<ParticipantJSON['firstName']>();
  const [lastName, setLastName] = useState<ParticipantJSON['lastName']>();
  const [status, setStatus] = useState<BookingParticipantJSON['status']>();

  const bookingParticipantBody: BookingParticipantUpdateBody = {
    participant: {
      firstName,
      lastName
    },
    comment: props.bookingParticipant.comment,
    status
  };

  function onClose() {
    setIsOpen(false);
    setIsSaving(false);
  }

  function onOpen(event: React.MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    const { participant } = props.bookingParticipant;

    setFirstName(participant.firstName);
    setLastName(participant.lastName);
    setStatus(props.bookingParticipant.status);

    setIsOpen(true);

    dispatch(trackEvent({
      name: 'opened_edit_participant_drawer'
    }));
  }

  function onUpdate() {
    return dispatch(
      updateBookingParticipantThunk({
        bookingId: props.bookingParticipant.bookingId,
        bookingParticipantId: props.bookingParticipant.id,
        data: bookingParticipantBody
      })
    );
  }

  async function onSave() {
    setIsSaving(true);

    try {
      await onUpdate().unwrap();
      toast(getSuccessToast(t('drawer.toasts.success')));
      onClose();
      props.onChange();
    } catch(error) {
      setIsSaving(false);
      toast(getErrorToast(t('drawer.toasts.error'), error.message));
    }
  }

  return (
    <>
      { cloneElement(props.children, { onClick: onOpen }) }

      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{t('drawer.title')}</DrawerHeader>

          <DrawerBody>
            <VStack spacing={5}>
              <InputFormControl
                size="sm"
                name="firstName"
                value={firstName}
                hasPlaceholder={true}
                onChange={setFirstName}
              />

              <InputFormControl
                size="sm"
                name="lastName"
                value={lastName}
                hasPlaceholder={true}
                onChange={setLastName}
              />

              <FormControl>
                <FormLabel>{t('common:fields.email.label')}</FormLabel>
                <Input size="sm" name="email" value={props.bookingParticipant.participant.email} isDisabled={true} />
              </FormControl>

              <FormControl id="status">
                <FormLabel>{t('drawer.form.status.label')}</FormLabel>
                <StatusSelect defaultValue={status} onChange={({ target }) => setStatus(target.value as BookingParticipantJSON['status'])} />
              </FormControl>

              <FormControl id="comment">
                <FormLabel>{t('drawer.form.comment.label')}</FormLabel>
                <Text color="gray.400" whiteSpace="pre-line">{ props.bookingParticipant.comment || '-' }</Text>
              </FormControl>
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <HStack justifyContent="space-between" w="100%">
              <SecondaryButton colorScheme="pink" size="sm" onClick={onClose}>
                { t('common:buttons.close') }
              </SecondaryButton>

              <PrimaryButton colorScheme="teal" size="sm" isLoading={isSaving} onClick={onSave}>
                { t('common:buttons.save') }
              </PrimaryButton>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}