import {
  Badge,
  HStack,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { Currency } from '@jurnee/common/src/entities/Currency';
import { LanguageJSON } from '@jurnee/common/src/entities/Language';
import { TagJSON } from '@jurnee/common/src/entities/Tag';
import { formatPrice } from '@jurnee/common/src/utils/prices';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SearchBar, { SearchBarParams } from 'src/components/SearchBar';
import SkeletonText from 'src/components/Skeleton/SkeletonText';
import TagClosable from 'src/components/TagClosable';
import { PrimaryButton } from 'src/components/buttons';
import ExperiencesFiltersDrawer from 'src/drawers/ExperiencesFiltersDrawer';
import { ExperiencesFiltersParams } from 'src/store/experiences/experiences.thunks';
import { RootState } from 'src/store/state';
import { DEFAULT_EXPERIENCE_PARTICIPANTS, DEFAULT_EXPERIENCE_PRICE, countExperiencesFilters } from 'src/utils/experiences';

interface OwnProps {
  languages: LanguageJSON[];
  tags: TagJSON[];
  filters: ExperiencesFiltersParams;
  currency: Currency;
  resultsCount: number;
  isLoading: boolean;
  defaultCityValue: string;
  hasCitySearch: boolean;
  onRemoveFilter(e: React.MouseEvent): void;
  onSearchSubmitted(data: SearchBarParams): void;
  onFiltersSubmitted(filters: ExperiencesFiltersParams): void
}

interface StateProps {
  user: RootState['user'];
}

type Props = OwnProps & StateProps & WithTranslation;

class ExperiencesFilters extends React.PureComponent<Props> {

  onSearchSubmitted = (data: SearchBarParams) => {
    this.props.onSearchSubmitted(data);
  };

  get languageTag() {
    if (!this.props.filters.languageId) {
      return null;
    }

    const language = this.props.languages.find(({ id }) => id === this.props.filters.languageId);

    return language ? <TagClosable id="languageId" label={language.name} onRemove={this.props.onRemoveFilter} /> : null;
  }

  get categoryTag() {
    if (!this.props.filters.tagIds || this.props.filters.tagIds.length === 0) {
      return null;
    }

    const tag = this.props.tags.find(({ id }) => id === this.props.filters.tagIds[0]);

    return tag ? <TagClosable id="tagIds" label={tag.name} onRemove={this.props.onRemoveFilter} /> : null;
  }

  get participantsTag() {
    if (!this.props.filters.participants) {
      return null;
    }

    if (this.props.filters.participants === DEFAULT_EXPERIENCE_PARTICIPANTS) {
      return null;
    }

    return <TagClosable id="participants" label={this.props.t('header.participantsCount', { count:this.props.filters.participants })} onRemove={this.props.onRemoveFilter} />;
  }

  get priceTag() {
    if (!this.props.filters.price) {
      return null;
    }

    if (this.props.filters.price === DEFAULT_EXPERIENCE_PRICE) {
      return null;
    }

    return <TagClosable id="price" label={formatPrice(this.props.filters.price, this.props.user.currency.id)} onRemove={this.props.onRemoveFilter} />;
  }

  get filters() {
    return (
      <HStack w="100%">
        {
          this.props.isLoading ?
            <SkeletonText pt="6px" w="240px" h="21px" skeletonHeight="10px" /> :
            <Text fontSize={14} fontWeight={500} color="gray.500">
              { this.props.t('header.experiencesResultCount', { count: this.props.resultsCount }) }
            </Text>
        }

        <Spacer />

        <HStack spacing={5}>
          { this.languageTag }
          { this.categoryTag }
          { this.participantsTag }
          { this.priceTag }
        </HStack>
      </HStack>
    );
  }

  get forMyTeamButton() {
    if (this.props.user.usersTeams.length === 0) {
      return;
    }

    return (
      <ExperiencesFiltersDrawer
        filters={this.props.filters}
        languages={this.props.languages}
        tags={this.props.tags}
        currency={this.props.currency}
        onSave={this.props.onFiltersSubmitted}
        basedOnBudget={true}
      >
        <PrimaryButton
          colorScheme='blue'
          size="sm"
          leftIcon={<Icon icon='sparkles' size={4} color="white" />}
          fontWeight={500}
        >
          { this.props.t('header.myTeam') }
        </PrimaryButton>
      </ExperiencesFiltersDrawer>
    );
  }

  get filtersCount() {
    return countExperiencesFilters(this.props.filters);
  }

  get searchBarPlaceholder() {
    switch (this.props.filters.type) {
    case 'RESTAURANT':
      return this.props.t('searchbar.placeholder.restaurant');
    case 'BAR':
      return this.props.t('searchbar.placeholder.bar');
    case 'BOX_SHIPPING':
      return this.props.t('searchbar.placeholder.box');
    default:
      return this.props.t('searchbar.placeholder.activity');
    }
  }

  render() {
    return (
      <VStack w="100%" spacing={5} py={5} borderBottom="1px solid" borderColor="gray.200">
        <HStack w="100%" justifyContent="space-between" spacing={5}>
          <SearchBar
            onSubmit={this.onSearchSubmitted}
            query={this.props.filters.query}
            placeholder={this.searchBarPlaceholder}
            hasCitySearch={this.props.hasCitySearch}
            defaultCityValue={this.props.defaultCityValue}
          />

          <Spacer />

          <Link to="/collections">
            <PrimaryButton
              colorScheme='black'
              size="sm"
              leftIcon={<Icon icon='folder' size={4} color="white" />}
              fontWeight={500}
            >
              { this.props.t('header.lists') }
            </PrimaryButton>
          </Link>

          <ExperiencesFiltersDrawer
            filters={this.props.filters}
            languages={this.props.languages}
            tags={this.props.tags}
            currency={this.props.currency}
            onSave={this.props.onFiltersSubmitted}
          >
            <PrimaryButton
              colorScheme='black'
              size="sm"
              leftIcon={<Icon icon='filters' size={4} color="white" />}
              rightIcon={this.filtersCount > 0 && <Badge variant="white" size="md">{this.filtersCount}</Badge>}
              fontWeight={500}
            >
              { this.props.t('header.filters') }
            </PrimaryButton>
          </ExperiencesFiltersDrawer>

          { this.forMyTeamButton }
        </HStack>

        { this.filters }
      </VStack>
    );
  }

}

function mapStateToProps(state: RootState): StateProps {
  return {
    user: state.user
  };
}

export default connect<StateProps, void, OwnProps>(
  mapStateToProps,
  null
)(withTranslation('experiences')(ExperiencesFilters));