import { CollectionExperience } from '@jurnee/common/src/entities/CollectionExperience';

export function getLocalCollectionUpvotes(collectionId: CollectionExperience['collectionId']): number[] {
  return JSON.parse(localStorage.getItem(`upvotes-${collectionId}`) || '[]');
}

export function setLocalCollectionUpvotes(collectionId: CollectionExperience['collectionId'], upvotes: number[]): void {
  localStorage.setItem(`upvotes-${collectionId}`, JSON.stringify(upvotes));
}

export function isCollectionExperienceUpvoted({ collectionId, experienceId }: CollectionExperience): boolean {
  const upvotes = getLocalCollectionUpvotes(collectionId);

  return upvotes.includes(experienceId);
}