import { HStack, Text, VStack } from '@chakra-ui/react';
import { DEFAULT_UTC_TIMEZONE } from '@jurnee/common/src/entities/Address';
import { PropositionJSON } from '@jurnee/common/src/entities/Proposition';
import { PropositionsGroupJSON } from '@jurnee/common/src/entities/PropositionsGroup';
import { getCityAndCountry } from '@jurnee/common/src/utils/addresses';
import { formatDate } from '@jurnee/common/src/utils/dates';
import { useTranslation } from 'react-i18next';
import { Status } from './Status';

interface Props {
  propositionsGroup: PropositionsGroupJSON;
  propositions: PropositionJSON[];
  selected: boolean;
  onClick(): void;
}

export function PropositionsGroupCard({ propositionsGroup, propositions, selected, onClick }: Props) {
  const { t } = useTranslation(['common', 'requests']);

  const from = propositionsGroup.from ? formatDate(
    new Date(propositionsGroup.from),
    {
      displayTime: true,
      displayTimeZone: true,
      timeZone: propositionsGroup.address?.timezone ?? DEFAULT_UTC_TIMEZONE
    }
  ) : t('requests:noEventDate');

  const selectedProps = { boxShadow: 'inset 3px 0 black' };

  return (
    <VStack
      w="100%"
      bgColor="white"
      padding={5}
      boxSizing="border-box"
      cursor="pointer"
      {...selected ? selectedProps : null}
      _notLast={{ borderBottom:'1px solid', borderColor:'gray.200' }}
      onClick={onClick}
    >
      <HStack w="100%" justifyContent="space-between">
        <Text>{from}</Text>

        <Status propositionsGroup={propositionsGroup} propositions={propositions} />
      </HStack>

      <HStack w="100%" justifyContent="space-between">
        <Text>
          { propositionsGroup.address ? getCityAndCountry(propositionsGroup.address) : '-' }
        </Text>

        <Text>
          { propositionsGroup.participants ? `${propositionsGroup.participants} ${t('common:people')}`: '-' }
        </Text>
      </HStack>

      {
        propositionsGroup.content &&
          <Text w="100%" color="gray.400" noOfLines={2}>
            { propositionsGroup.content }
          </Text>
      }
    </VStack>
  );
}