import { HStack, Text, VStack } from '@chakra-ui/react';
import { QuestionJSON } from '@jurnee/common/src/entities/Survey';
import { QuestionAnswerJSON } from '@jurnee/common/src/entities/SurveySubmission';
import { AnswersMultiple } from './AnswersMultiple';
import { AnswersText } from './AnswersText';
import { NoAnswers } from './NoAnswers';

interface Props {
  question: QuestionJSON;
  questionAnswers: QuestionAnswerJSON[];
}

function Answers({ question, questionAnswers }: Props) {
  if (questionAnswers.length === 0 && question.type === 'TEXT') {
    return <NoAnswers />;
  }

  if (question.type === 'TEXT') {
    return <AnswersText questionAnswers={questionAnswers} />;
  }

  return <AnswersMultiple
    questionAnswers={questionAnswers}
    questionOptions={question.questionOptions}
  />;
}

export function QuestionAnswers(props: Props) {
  return (
    <VStack w="100%" spacing={2} alignItems="flex-start">
      <HStack w="100%" justifyContent="space-between">
        <Text fontWeight={700} lineHeight="16px">
          { props.question.label }
        </Text>

        <Text lineHeight="16px">{ props.questionAnswers.length }</Text>
      </HStack>

      <Answers {...props} />
    </VStack>
  );
}