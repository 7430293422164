import { Heading, VStack } from '@chakra-ui/react';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { groupByDay } from '@jurnee/common/src/utils/bookings';
import { getMonthAndYear } from '@jurnee/common/src/utils/dates';
import BookingsCalendarDay from './Day';

interface Props {
  time: number;
  bookings: BookingJSON[];
  relationships: BookingRelationshipsJSON;
}

export default function BookingsCalendarMonth(props: Props) {
  const bookingsByDays = groupByDay(props.bookings);
  const days = Object.keys(bookingsByDays).map(Number).sort();

  return (
    <VStack w="100%" spacing={3} alignItems="flex-start">
      <Heading size="md" lineHeight="32px">{getMonthAndYear(new Date(props.time))}</Heading>

      <VStack
        w="100%"
        border="1px solid"
        borderColor="blue.50"
        borderRadius={4}
        bgColor="white"
        spacing={0}
        overflow="hidden"
      >
        { days.map(time => <BookingsCalendarDay key={time} time={time} bookings={bookingsByDays[time]} relationships={props.relationships} />) }
      </VStack>
    </VStack>
  );

}