import { TeamBudgetBreakdownJSON } from '@jurnee/common/src/entities/TeamBudgetBreakdown';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../state';
import { teamBudgetBreakdownsAdapter } from './teamBudgetBreakdowns.adapter';

export const teamBudgetBreakdownsSelectors = teamBudgetBreakdownsAdapter.getSelectors(
  (state: RootState) => state.teamBudgetBreakdowns
);

const getTeamBudgetBreakdownsByIdsSelector = createSelector(
  [state => teamBudgetBreakdownsSelectors.selectAll(state), (_, ids) => ids],
  (teamBudgetBreakdowns: TeamBudgetBreakdownJSON[], ids: number[]) => {
    return teamBudgetBreakdowns.filter(({ teamId })=> ids.find(id => id === teamId));
  }
);

export function getTeamBudgetBreakdownsByIds(ids: number[]) {
  return (state: RootState) => getTeamBudgetBreakdownsByIdsSelector(state, ids);
}