import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, HStack, VStack, useToast } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { BudgetCreateBody } from '@jurnee/common/src/dtos/budgets';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { BudgetOrganizerJSON } from '@jurnee/common/src/entities/BudgetOrganizer';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { cloneElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/store';
import { trackEvent } from 'src/store/analytics/analytics.thunks';
import { createBudget, updateBudget } from 'src/store/budgets/budgets.thunks';
import { getEmployeesByBudgetId, getEmployeesFetchStatusSelector, getEmployeesSelector } from 'src/store/employees/employees.selectors';
import { getUserBudgetBreakdownsFetchStatusSelector } from 'src/store/userBudgetBreakdowns/userBudgetBreakdowns.selectors';
import { getUserBudgetBreakdownsByBudgetId } from 'src/store/userBudgetBreakdowns/userBudgetBreakdowns.thunks';
import { PrimaryButton, SecondaryButton } from '../../components/buttons';
import { AmountInput } from './AmountInput';
import { DefinitionTypeSelect } from './DefinitionTypeSelect';
import { FrequencySelect } from './FrequencySelect';
import { NameInput } from './NameInput';
import { OrganizersList } from './OrganizersList';
import { UsersList } from './UsersList';
import { UsersSearchSelect } from './UsersSearchSelect';
import { VisibilitySelect } from './VisibilitySelect';

interface Props {
  budget?: BudgetJSON;
  children: React.ReactElement;
}

export function BudgetDrawer(props: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation(['settings', 'common']);

  const employees = useSelector(getEmployeesSelector);
  const employeesFetchStatus = useSelector(getEmployeesFetchStatusSelector);
  const userBudgetBreakdownsFetchStatus = useSelector(getUserBudgetBreakdownsFetchStatusSelector);

  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [amount, setAmount] = useState(props.budget?.amount || 0);
  const [definitionType, setDefinitionType] = useState(props.budget?.definitionType || 'GLOBAL');
  const [frequency, setFrequency] = useState(props.budget?.frequency || 'MONTHLY');
  const [name, setName] = useState(props.budget?.name || '');
  const [visibility, setVisibility] = useState(props.budget?.visibility || 'ALL_ORGANIZERS');

  const [budgetsOrganizers, setBudgetsOrganizers] = useState(getDefaultOrganizerIds());
  const [userIds, setUserIds] = useState(getDefaultUserIds());

  const organizerIds = useMemo(() => budgetsOrganizers.map(({ userId }) => userId), [budgetsOrganizers]);
  const isLoading = employeesFetchStatus !== 'FETCHED' || (props.budget && userBudgetBreakdownsFetchStatus !== 'FETCHED');

  const budgetBody: BudgetCreateBody = {
    amount,
    definitionType,
    frequency,
    name,
    budgetsOrganizers,
    userIds,
    visibility
  };

  function onClose() {
    setIsOpen(false);
    setIsSaving(false);
  }

  function onOpen(event: React.MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    if (props.budget) {
      dispatch(getUserBudgetBreakdownsByBudgetId({
        budgetId: props.budget.id
      }));
    }

    setIsOpen(true);

    dispatch(trackEvent({
      name: 'opened_budgets_drawer'
    }));
  }

  function getDefaultOrganizerIds() {
    if (!props.budget) {
      return [];
    }

    return props.budget.budgetsOrganizers.map(({ userId, isOwner }) => ({ userId, isOwner }));
  }

  function getDefaultUserIds() {
    if (!props.budget) {
      return [];
    }

    return useSelector(getEmployeesByBudgetId(props.budget.id)).map(user => user.id);
  }

  async function update() {
    setIsSaving(true);

    try {
      const { definitionType, ...data } = budgetBody;

      await dispatch(updateBudget({
        id: props.budget.id,
        data
      })).unwrap();

      onClose();
      return toast(getSuccessToast(t('budgets.drawer.toasts.update.success')));
    } catch(error) {
      setIsSaving(false);
      return toast(getErrorToast(t('budgets.drawer.toasts.update.error'), error));
    }
  }

  async function create() {
    setIsSaving(true);

    try {
      await dispatch(createBudget({
        data: budgetBody
      })).unwrap();

      onClose();
      return toast(getSuccessToast(t('budgets.drawer.toasts.create.success')));
    } catch(error) {
      setIsSaving(false);
      return toast(getErrorToast(t('budgets.drawer.toasts.create.error'), error));
    }
  }

  function onSave() {
    return props.budget ? update() : create();
  }

  function onAddUser({ value: { id } }: { value: UserDetails }) {
    if (definitionType === 'INDIVIDUAL') {
      return setUserIds([...userIds, id]);
    }

    setBudgetsOrganizers([...budgetsOrganizers, { userId: id, isOwner: false }]);
  }

  function onRemoveUser(user: UserDetails) {
    if (definitionType === 'INDIVIDUAL') {
      return setUserIds(userIds.filter(userId => userId !== user.id));
    }

    setBudgetsOrganizers(budgetsOrganizers.filter(({ userId }) => userId !== user.id));
  }

  function onBudgetOrganizerChange(data: BudgetOrganizerJSON) {
    setBudgetsOrganizers(
      budgetsOrganizers.map(budgetOrganizer => {
        if (budgetOrganizer.userId === data.userId) {
          return data;
        }

        return budgetOrganizer;
      })
    );
  }

  return (
    <>
      { cloneElement(props.children, { onClick: onOpen }) }

      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            { props.budget ? t('budgets.drawer.updateTitle') : t('budgets.drawer.createTitle') }
          </DrawerHeader>

          <DrawerBody>
            { isLoading ?
              <Loader /> :
              (
                <VStack spacing={5}>
                  <NameInput
                    defaultValue={name}
                    onChange={({ target }) => setName(target.value)}
                  />

                  <DefinitionTypeSelect
                    defaultValue={definitionType}
                    onChange={({ target }) => setDefinitionType(target.value as BudgetJSON['definitionType'])}
                    isDisabled={!!props.budget}
                  />

                  <AmountInput
                    defaultValue={amount}
                    definitionType={definitionType}
                    onChange={({ target }) => setAmount(Number(target.value))}
                  />

                  <FrequencySelect
                    defaultValue={frequency}
                    onChange={({ target }) => setFrequency(target.value as BudgetJSON['frequency'])}
                  />

                  {
                    definitionType === 'GLOBAL' &&
                      <VisibilitySelect
                        defaultValue={visibility}
                        onChange={setVisibility}
                      />
                  }

                  <FormControl>
                    <FormLabel>{t(`budgets.drawer.form.users.${definitionType}.label`)}</FormLabel>

                    <VStack w="100%" spacing="10px">
                      <UsersSearchSelect
                        budget={props.budget}
                        definitionType={definitionType}
                        users={employees}
                        userIds={definitionType === 'INDIVIDUAL' ? userIds : organizerIds}
                        onAddUser={onAddUser}
                      />

                      {
                        definitionType === 'INDIVIDUAL' ? (
                          <UsersList
                            budget={props.budget}
                            users={employees}
                            userIds={userIds}
                            onRemoveUser={onRemoveUser}
                          />
                        ) : (
                          <OrganizersList
                            budget={props.budget}
                            users={employees}
                            budgetOrganizers={budgetsOrganizers}
                            onRemove={onRemoveUser}
                            onBudgetOrganizerChange={onBudgetOrganizerChange}
                          />
                        )
                      }
                    </VStack>
                  </FormControl>
                </VStack>
              )
            }
          </DrawerBody>

          <DrawerFooter>
            <HStack justifyContent="space-between" w="100%">
              <SecondaryButton size="sm" colorScheme="pink" onClick={onClose}>
                { t('common:buttons.close') }
              </SecondaryButton>

              <PrimaryButton size="sm" colorScheme="teal" isLoading={isSaving} onClick={onSave}>
                { t('common:buttons.save') }
              </PrimaryButton>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}