import { Box, Heading, VStack } from '@chakra-ui/react';
import { EmptyState } from '@jurnee/common/src/components/EmptyState';
import { BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { PropositionJSON } from '@jurnee/common/src/entities/Proposition';
import { PropositionsGroupJSON } from '@jurnee/common/src/entities/PropositionsGroup';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getPropositionsByPropositionsGroupId } from 'src/utils/booking';
import { PropositionCard } from './PropositionCard';
import { StepsCard } from './StepsCard';

interface Props {
  propositionsGroup: PropositionsGroupJSON;
  relationships: Pick<BookingRelationshipsJSON, 'propositions' | 'products' | 'experiences'>;
  onDecline(proposition: PropositionJSON, reason: string): Promise<void>;
  onSelect(proposition: PropositionJSON): void;
}

export function Propositions({ propositionsGroup, relationships, onDecline, onSelect }: Props) {
  const { t } = useTranslation('requests');

  const propositions = useMemo(
    () => sortAlphabeticallyBy(getPropositionsByPropositionsGroupId(relationships.propositions, propositionsGroup.id), 'name'),
    [propositionsGroup, relationships]
  );

  return (
    <VStack w="100%" alignItems="stretch" spacing={3}>
      <Heading size="md">{t('propositions.heading')}</Heading>

      {
        propositionsGroup.status === 'DRAFT' ? (
          <VStack w="100%" spacing={4}>
            <StepsCard propositionsGroup={propositionsGroup} />

            <Box w="100%" bg="white" borderRadius={4} border="1px solid" borderColor="gray.200" p={16}>
              <EmptyState
                icon="listSearch"
                heading={t('emptyStates.waitingProposal.heading')}
                description={t('emptyStates.waitingProposal.description')}
              />
            </Box>
          </VStack>
        ) : (
          propositions.length === 0 ? (
            <Box w="100%" bg="white" borderRadius={4} border="1px solid" borderColor="blue.50" p={16}>
              <EmptyState
                icon="listSearch"
                heading={t('emptyStates.noPropositions.heading')}
                description={t('emptyStates.noPropositions.description')}
              />
            </Box>
          ) : (
            <VStack w="100%" spacing={4}>
              {propositions.map(proposition => {
                return <PropositionCard
                  key={proposition.id}
                  bookingId={propositionsGroup.bookingId}
                  proposition={proposition}
                  products={relationships.products}
                  experiences={relationships.experiences}
                  status={propositionsGroup.status}
                  onDecline={onDecline}
                  onSelect={onSelect}
                />;
              })}
            </VStack>
          )
        )
      }
    </VStack>
  );
}