import { Experience, ExperienceStats } from '@jurnee/common/src/entities/Experience';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'src/api/experiences';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';

export const getExperienceStats = createAsyncThunk<ExperienceStats, Experience['id'], { state: RootState }>('EXPERIENCE_STATS_GET', async (id, thunkAPI) => {
  try {
    return api.getExperienceStats(id);
  } catch (err) {
    thunkAPI.dispatch(showToast({ status: 'error', description: 'An error occurred while retrieving the experience stats' }));
  }
});