import { HStack, Input } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { QuestionUpsertBody } from '@jurnee/common/src/dtos/surveys';
import { useTranslation } from 'react-i18next';

interface Props {
  questionOption: QuestionUpsertBody['questionOptions'][0];
  index: number;
  onChange(index: number, value: string): void;
  onDelete(index: number): void;
}

export function QuestionOptionForm(props: Props) {
  const { t } = useTranslation('surveys', { keyPrefix: 'drawers.survey.form.question.questionOption' });

  return (
    <HStack w="100%" spacing={2}>
      <Icon
        icon="squareRoundedX"
        size={5}
        color="red.500"
        cursor="pointer"
        _hover={{ color: 'red.700' }}
        onClick={() => props.onDelete(props.index)}
      />

      <Input
        size="sm"
        placeholder={t('placeholder')}
        value={props.questionOption.value || ''}
        onChange={({ target }) => props.onChange(props.index, target.value)}
      />
    </HStack>
  );
}