import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { EntityJSON } from '@jurnee/common/src/entities/Entity';
import { ProviderSetupIntent } from '@jurnee/common/src/entities/ProviderEntity';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import PaymentForm from 'src/components/Payment/PaymentForm';
import StripeWrapper from 'src/components/Payment/StripeWrapper';
import { ProcessNumberInput } from 'src/components/ProcessNumberInput';
import { CreditCardCommitPayload } from 'src/store/bookingDetails/bookingDetails.thunks';

interface OwnProps extends WithTranslation {
  bookingInvoice: BookingInvoiceJSON;
  entity: EntityJSON;
  processNumber: BookingJSON['processNumber'];
  onSetupIntentCreated(data: CreditCardCommitPayload['data']): void;
  disabled: boolean;
}

type State = {
  data: {
    processNumber: BookingJSON['processNumber'];
  };
  isSubmitting: boolean;
};

class CreditCardCard extends React.Component<OwnProps> {

  state: State = {
    data: {
      processNumber: this.props.processNumber
    },
    isSubmitting: false
  };

  onSetupIntentCreated = (setupIntentId: ProviderSetupIntent['id']) => {
    this.props.onSetupIntentCreated({
      setupIntentId,
      processNumber: this.state.data.processNumber
    });
  };

  onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [target.name]: target.value
      }
    });
  };

  render() {
    return (
      <>
        <ProcessNumberInput
          defaultValue={this.props.processNumber}
          onChange={this.onChange}
        />
        <StripeWrapper>
          <PaymentForm
            entity={this.props.entity}
            onSetupIntentCreated={this.onSetupIntentCreated}
            bookingInvoice={this.props.bookingInvoice}
            disabled={this.props.disabled}
          />
        </StripeWrapper>
      </>
    );
  }

}

export default withTranslation('booking')(CreditCardCard);