import { api } from '@jurnee/common/src/browser/Api';
import { BookingInvoiceJSON, BookingsInvoicesSearchParams } from '@jurnee/common/src/entities/BookingInvoice';
import { List } from '@jurnee/common/src/serializers';
import { getBookingsInvoicesParams } from 'src/utils/bookingInvoice';

export default {
  getBookingsInvoices(params: BookingsInvoicesSearchParams): Promise<List<BookingInvoiceJSON, never>> {
    const searchParams = getBookingsInvoicesParams(params);

    return api.get(`bookings-invoices?${searchParams.toString()}`);
  },
};