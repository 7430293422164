import { Select } from '@chakra-ui/react';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  bookings: BookingJSON[];
  onChange(bookingId: number): void;
}

export function BookingSelect({ bookings, onChange }: Props) {
  const { t } = useTranslation('tasks', { keyPrefix: 'filters.booking' });

  const options = useMemo(() => [
    <option key={0} value={0}>{t('all')}</option>,
    ...sortAlphabeticallyBy(bookings, 'name').map(({ id, name }) => <option key={id} value={id}>{name}</option>)
  ], [bookings]);

  return (
    <Select size="sm" onChange={e => onChange(Number(e.target.value))}>
      { options }
    </Select>
  );
}