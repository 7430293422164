import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { Expand } from '@jurnee/common/src/serializers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createPropositionsGroup, PropositionsGroupCreateParams } from 'src/api/propositionsGroups';
import { RootState } from '../state';

export const createPropositionsGroupThunk = createAsyncThunk<Expand<BookingJSON, BookingRelationshipsJSON>, PropositionsGroupCreateParams, { state: RootState }>('PROPOSITIONS_GROUPS_CREATE', async (params, thunkAPI) => {
  try {
    const booking = await createPropositionsGroup(params);
    return booking;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.message);
  }
});