import { Text } from '@chakra-ui/react';
import { TaskJSON } from '@jurnee/common/src/entities/Task';
import { formatDate, getCurrentTimeZone } from '@jurnee/common/src/utils/dates';

interface Props {
  task: TaskJSON;
}

function getColor(task: TaskJSON) {
  if (!task.dueAt) {
    return;
  }

  if (['DONE', 'CANCELLED'].includes(task.status)) {
    return;
  }

  if (new Date(task.dueAt) > new Date()) {
    return;
  }

  return 'red.500';
}

export function TaskDueDate({ task }: Props) {
  const timeZone = getCurrentTimeZone();
  const color = getColor(task);

  return (
    <Text color={color}>
      { task.dueAt ? formatDate(new Date(task.dueAt), { timeZone }) : '-' }
    </Text>
  );
}