import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, VStack } from '@chakra-ui/react';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { cloneElement, useState } from 'react';
import { useAppDispatch } from 'src/store';
import { trackEvent } from 'src/store/analytics/analytics.thunks';
import { BudgetBreakdown } from '../../components/Budget/BudgetBreakdown';
import { BudgetSpendingStatement } from './BudgetSpendingStatement';

interface Props {
  budget: BudgetJSON;
  children: React.ReactElement;
}

export function BudgetSpendingStatementDrawer(props: Props) {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  function onOpen() {
    setIsOpen(true);
    dispatch(trackEvent({ name: 'opened_budget_spending_statement' }));
  }

  function onClose() {
    setIsOpen(false);
  }

  return (
    <>
      {cloneElement(props.children, { onClick: onOpen })}

      <Drawer size="md" isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{props.budget.name}</DrawerHeader>
          <DrawerBody as={VStack} spacing={4} w="100%">
            <Box bg="white" borderRadius={4} border="1px solid" borderColor="blue.50" p={4} w="100%">
              <BudgetBreakdown budget={props.budget} />
            </Box>
            <BudgetSpendingStatement budgetId={props.budget.id} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}