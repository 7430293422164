import { Box, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { TeamJSON } from '@jurnee/common/src/entities/Team';
import { getDefaultTabIndex } from '@jurnee/common/src/utils/tabs';
import { isAdmin } from '@jurnee/common/src/utils/user';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import router from 'src/router';
import { getApprovalProcesses } from 'src/store/approvalProcesses/approvalProcesses.thunks';
import { RootState } from 'src/store/state';
import { teamsSelectors } from 'src/store/teams/teams.selectors';
import { getEmployees } from '../../../store/employees/employees.thunks';
import { getTeams } from '../../../store/teams/teams.thunks';
import { RouteProps } from '../../Route';
import Employees from './Employees';
import Teams from './Teams';
import UserProvisioning from './UserProvisioning';

interface StateProps {
  approvalProcesses: RootState['approvalProcesses'];
  employees: RootState['employees'];
  teams: TeamJSON[];
  user: RootState['user'];
}

interface DispatchProps {
  getApprovalProcesses(): void;
  getEmployees(): void;
  getTeams(): void;
}

type Props = StateProps & DispatchProps & WithTranslation;

class CompanyPeople extends React.Component<Props> {

  componentDidMount() {
    if (!isAdmin(this.props.user)) {
      return;
    }

    this.props.getEmployees();
    this.props.getApprovalProcesses();
    this.props.getTeams();
  }

  get tabs() {
    return [{
      label: `${this.props.t('tabs.members')} (${this.props.employees.list.length})`,
      key: 'members'
    }, {
      label: `${this.props.t('tabs.teams')} (${this.props.teams.length})`,
      key: 'teams'
    }, {
      label: this.props.t('tabs.userProvisioning'),
      key: 'user-provisioning'
    }];
  }

  get tabsList() {
    return (
      <TabList>
        {this.tabs.map(({ key, label }) => (<Tab key={key}>{label}</Tab>))}
      </TabList>
    );
  }

  get header() {
    return (
      <Box bg="white" w="100%">
        <Box p={8}>
          <Heading>
            { this.props.t('header') }
          </Heading>
        </Box>

        { this.tabsList }
      </Box>
    );
  }

  render() {
    if (!isAdmin(this.props.user)) {
      return <Navigate to='/' />;
    }

    return (
      <main>
        <Tabs w="100%" defaultIndex={getDefaultTabIndex(this.tabs, router.state.location.search)}>
          {this.header}
          <TabPanels>
            <TabPanel>
              <Employees />
            </TabPanel>
            <TabPanel>
              <Teams />
            </TabPanel>
            <TabPanel>
              <UserProvisioning />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </main>
    );
  }

}

function mapStateToProps(state: RootState): StateProps {
  return {
    approvalProcesses: state.approvalProcesses,
    employees: state.employees,
    teams: teamsSelectors.selectAll(state),
    user: state.user,
  };
}

const mapDispatchToProps: DispatchProps = {
  getApprovalProcesses,
  getEmployees,
  getTeams
};

export default connect<StateProps, DispatchProps, RouteProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(['people', 'teams', 'common'])(CompanyPeople));