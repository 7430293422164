import { StatusBadge } from '@jurnee/common/src/components/StatusBadge';
import { PropositionJSON } from '@jurnee/common/src/entities/Proposition';
import { PropositionsGroupJSON } from '@jurnee/common/src/entities/PropositionsGroup';
import { useTranslation } from 'react-i18next';

interface Props {
  propositionsGroup: PropositionsGroupJSON;
  propositions: PropositionJSON[];
}

export function Status({ propositionsGroup, propositions }: Props) {
  const { t } = useTranslation('requests');

  function getStatus() {
    if (propositionsGroup.status === 'CLOSED') {
      if (propositions.some(({ selectedAt }) => !!selectedAt)) {
        return 'SELECTED';
      }

      return 'CLOSED';
    }

    if (propositionsGroup.status === 'OPEN') {
      if (propositions.length > 0 && propositions.every(({ declinedAt }) => !!declinedAt)) {
        return 'WAITING_PROPOSALS';
      }

      return 'READY';
    }

    return 'WAITING_PROPOSALS';
  }

  function getProps() {
    const status = getStatus();

    switch(status) {
    case 'SELECTED': return { label: t('status.SELECTED'), color: 'teal' };
    case 'CLOSED': return { label: t('status.CLOSED'), color: 'gray' };
    case 'READY': return { label: t('status.READY'), color: 'red' };
    default: return { label: t('status.WAITING_PROPOSALS'), color: 'blue' };
    }
  }

  return <StatusBadge {...getProps()} />;
}