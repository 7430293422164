export type FetchStatus = 'INITIAL' | 'FETCHING' | 'FETCHED';

export type AuthStatus = 'INITIAL' | 'AUTHENTICATING' | 'AUTHENTICATED' | 'UNVERIFIED';

export const FETCH_STATUS: Record<FetchStatus, FetchStatus> = {
  INITIAL: 'INITIAL',
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED'
};

export const AUTH_STATUS: Record<AuthStatus, AuthStatus> = {
  INITIAL: 'INITIAL',
  AUTHENTICATING: 'AUTHENTICATING',
  AUTHENTICATED: 'AUTHENTICATED',
  UNVERIFIED: 'UNVERIFIED'
};

export interface RootStateList<T> {
  list: T[];
  error: boolean;
  status: FetchStatus;
}

export interface RootStateData<T> {
  data: T;
  error: boolean;
  status: FetchStatus;
}