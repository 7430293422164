import {
  ForgotPasswordBody,
  ForgotPasswordResponse,
  LoginBody,
  LoginResponseJSON,
  ResendVerificationEmailBody,
  ResendVerificationEmailResponse,
  ResetPasswordBody,
  ResetPasswordResponse,
  SsoProviderName,
  VerifyBody,
  VerifyEmailBody,
  VerifyEmailResponse,
  VerifyResponse
} from '@jurnee/common/src/dtos/auth';
import { createAsyncThunk } from '@reduxjs/toolkit';
import router from 'src/router';
import api from '../../api/auth';
import { showToast } from '../toasts/toasts.thunks';

export const setToken = createAsyncThunk<null, string, unknown>('AUTH_SET_TOKEN', (args, thunkAPI) => {
  try {
    localStorage.setItem('token', args);
    const url = new URL(window.location.href);

    window.location.href = url.searchParams.has('redirect') ? url.searchParams.get('redirect') : '/';
  } catch (error) {
    thunkAPI.dispatch(showToast({ title: error.message, status: 'error' }));
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

export const login = createAsyncThunk<LoginResponseJSON, LoginBody, unknown>('AUTH_LOGIN', async (args, thunkAPI) => {
  try {
    const data = await api.login(args);

    return data;
  } catch (error) {
    thunkAPI.dispatch(showToast({ title: error.message, status: 'error' }));
    return thunkAPI.rejectWithValue({ error: error.message });
  }
});

const getSsoToken = async (ssoProviderName: SsoProviderName) => {
  if (ssoProviderName === 'google') {
    return api.getGoogleToken();
  }

  if (ssoProviderName === 'microsoft') {
    return api.getMsToken();
  }

  throw new Error('Unknown SSO provider');
};

export const loginSso = createAsyncThunk<LoginResponseJSON, SsoProviderName, unknown>('AUTH_LOGIN_SSO', async (ssoProviderName, thunkAPI) => {
  try {
    const token = await getSsoToken(ssoProviderName);
    const data = await api.loginSSO({ ssoProviderName, token });

    return data;
  } catch (error) {
    thunkAPI.dispatch(showToast({ title: 'An error occurred', status: 'error' }));
    return thunkAPI.rejectWithValue({ error: error.error });
  }
});

export const sendResetPasswordEmail = createAsyncThunk<ForgotPasswordResponse, ForgotPasswordBody, unknown>('SEND_RESET_PASSWORD_EMAIL', async (args, thunkAPI) => {
  try {
    const response = await api.forgotPassword(args);

    return response;
  } catch (error) {
    thunkAPI.dispatch(showToast({ title: error.message, status: 'error' }));
    return thunkAPI.rejectWithValue({ success: false });
  }
});

export const resetPassword = createAsyncThunk<ResetPasswordResponse, ResetPasswordBody & { passwordConfirmation: string }, unknown>('RESET_PASSWORD', async (args, thunkAPI) => {
  try {
    const { token, password, passwordConfirmation } = args;

    if (password !== passwordConfirmation) {
      thunkAPI.dispatch(showToast({ title: 'Password confirmation does not match', status: 'error' }));
      return { success: false };
    }

    const response = await api.resetPassword({ token, password });

    router.navigate('/login');

    thunkAPI.dispatch(showToast({ title: 'Your password has been successfully changed', status: 'success' }));
    return response;
  } catch (error) {
    thunkAPI.dispatch(showToast({ title: error.message, status: 'error' }));
    return thunkAPI.rejectWithValue({ success: false });
  }
});

export const verifyEmail = createAsyncThunk<VerifyEmailResponse, VerifyEmailBody, unknown>('VERIFY_EMAIL', async (args, thunkAPI) => {
  try {
    const { token } = args;
    const response = await api.verifyEmail({ token });

    router.navigate('/');

    thunkAPI.dispatch(showToast({ title: 'Your account has been successfully verified', status: 'success' }));
    return response;
  } catch (error) {
    router.navigate('/login');

    thunkAPI.dispatch(showToast({ title: error.message, status: 'error' }));
    return thunkAPI.rejectWithValue({ success: false });
  }
});


export const resendVerificationEmail = createAsyncThunk<ResendVerificationEmailResponse, ResendVerificationEmailBody, unknown>('RESEND_VERIFICATION_EMAIL', async (args, thunkAPI) => {
  try {
    const { email } = args;
    const response = await api.resendVerificationEmail({ email });

    return response;
  } catch (error) {
    thunkAPI.dispatch(showToast({ title: error.message, status: 'error' }));
    return thunkAPI.rejectWithValue({ success: false });
  }
});

export const verifyUser = createAsyncThunk<VerifyResponse, VerifyBody, unknown>('VERIFY_USER', async (args, thunkAPI) => {
  try {
    const { token } = args;
    const response = await api.verify({ token });

    return response;
  } catch (error) {
    thunkAPI.dispatch(showToast({ title: error.message, status: 'error' }));
    return thunkAPI.rejectWithValue({ success: false });
  }
});