import { HStack, Text, VStack } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { Trans } from 'react-i18next';

export function GenericExperienceCard() {
  return (
    <HStack border="1px solid" borderColor="yellow.500" borderRadius={8} bg="white" overflow="hidden" spacing={0}>
      <VStack bg="yellow.500" h="100%" minW="60px" justifyContent="center">
        <Icon icon="bulb" size={7} color="white"/>
      </VStack>
      <Text px={4} py={3}>
        <Trans i18nKey="experience:genericConcept" components={{ b: <b/> }} />
      </Text>
    </HStack>
  );
}