import { api } from '@jurnee/common/src/browser/Api';
import { CollectionCreateBody, CollectionExperienceAddBody, CollectionExperienceVoteBody, CollectionUpdateBody } from '@jurnee/common/src/dtos/collections';
import { Collection, CollectionJSON } from '@jurnee/common/src/entities/Collection';
import { CollectionExperience, CollectionExperienceJSON } from '@jurnee/common/src/entities/CollectionExperience';
import { List } from '@jurnee/common/src/serializers';

export default {
  addToCollection(collectionId: Collection['id'], body: CollectionExperienceAddBody): Promise<CollectionExperienceJSON> {
    return api.post(`collections/${collectionId}/experiences`, body);
  },
  removeFromCollection(collectionId: CollectionExperience['collectionId'], experienceId: CollectionExperience['experienceId']): Promise<CollectionExperienceJSON> {
    return api.delete(`collections/${collectionId}/experiences/${experienceId}`);
  },
  createCollection(body: CollectionCreateBody): Promise<CollectionJSON> {
    return api.post('collections', body);
  },
  getCollections(): Promise<List<CollectionJSON, never>> {
    return api.get('collections');
  },
  updateCollection(collectionId: Collection['id'], body: CollectionUpdateBody): Promise<CollectionJSON> {
    return api.put(`collections/${collectionId}`, body);
  },
  deleteCollection(collectionId: Collection['id']): Promise<CollectionJSON> {
    return api.delete(`collections/${collectionId}`);
  },
  getCollection(collectionId: Collection['id']): Promise<CollectionJSON> {
    return api.get(`collections/${collectionId}`);
  },
  upvoteExperience({ collectionId, experienceId }: CollectionExperienceVoteBody): Promise<CollectionExperienceJSON> {
    return api.put(`collections/${collectionId}/experiences/${experienceId}/upvote`);
  },
  downvoteExperience({ collectionId, experienceId }: CollectionExperienceVoteBody): Promise<CollectionExperienceJSON> {
    return api.put(`collections/${collectionId}/experiences/${experienceId}/downvote`);
  },
  incrementViews(collectionId: Collection['id']): Promise<void> {
    return api.put(`collections/${collectionId}/views`);
  }
};