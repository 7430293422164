import { VStack, useToast } from '@chakra-ui/react';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { getMainOrganizerId, getOrganizerIds } from '@jurnee/common/src/utils/bookings';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AddOrganizersModal from 'src/modals/AddOrganizersModal';
import { useAppDispatch } from 'src/store';
import { removeBookingOrganizer } from 'src/store/bookingDetails/bookingDetails.thunks';
import { getEmployeesByIds, getEmployeesSelector } from 'src/store/employees/employees.selectors';
import { OrganizersTable } from './OrganizersTable';

interface Props {
  booking: BookingJSON;
}

export function Organizers({ booking }: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation(['booking']);

  const organizers = useSelector(getEmployeesByIds(getOrganizerIds(booking)));
  const users = useSelector(getEmployeesSelector);

  const mainOrganizerId = useMemo(() => getMainOrganizerId(booking.bookingsOrganizers), [booking.bookingsOrganizers]);

  function removeOrganizer(organizerId: UserDetails['id']) {
    return dispatch(
      removeBookingOrganizer({
        bookingId: booking.id,
        organizerId
      })
    );
  }

  async function onRemove(organizer: UserDetails) {
    try {
      await removeOrganizer(organizer.id).unwrap();
      toast(getSuccessToast(t('organizers.toasts.removeOrganizer.success')));
    } catch(error) {
      toast(getErrorToast(t('organizers.toasts.removeOrganizer.error'), error.message));
    }
  }

  return (
    <VStack w="100%" spacing={5}>
      <AddOrganizersModal booking={booking} organizers={organizers} users={users} />

      <OrganizersTable organizers={organizers} mainOrganizerId={mainOrganizerId} onRemove={onRemove} />
    </VStack>
  );
}