import { chakra, FormControl, FormLabel, Heading, Textarea } from '@chakra-ui/react';
import { ItemReviewCreateBody } from '@jurnee/common/src/dtos/reviews';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import * as React from 'react';
import StarsRating from 'src/components/StarsRating';
import ItemReviewContainer from './ItemReviewContainer';

interface OwnProps {
  onNext(data: Omit<ItemReviewCreateBody, 'reviewId'>): void;
}

interface State {
  rating: number;
  comment: string;
}

export default class BookingReview extends React.Component<OwnProps> {

  state: State = {
    rating: 0,
    comment: null
  };

  handleNext = () => {
    this.props.onNext(this.state);
  };

  handleRating = (rating: number) => {
    this.setState({ ...this.state, rating });
  };

  handleComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ ...this.state, comment: isEmpty(e.target.value) ? null : e.target.value });
  };

  get commentPlaceholder() {
    return 'Please feel free to share more feedback :)\nDo you feel closer to your team?\nWould you like to do another event soon?';
  }

  render() {
    return (
      <ItemReviewContainer buttonLabel="Submit" spacing={5} onNext={this.handleNext} isButtonDisabled={this.state.rating === 0}>
        <Heading size="md">How do you feel about this team event being organized?</Heading>

        <FormControl id="rating">
          <FormLabel>Rating <chakra.span fontWeight="medium" color="gray.400">(required)</chakra.span></FormLabel>
          <StarsRating lowRatingLabel="Unhappy" highRatingLabel="Happy" onClick={this.handleRating} />
        </FormControl>

        <FormControl id="comment">
          <FormLabel>Comment <chakra.span fontWeight="medium" color="gray.400">(optional)</chakra.span></FormLabel>
          <Textarea size="sm" h="110px" resize="none" placeholder={this.commentPlaceholder} _placeholder={{ color: 'gray.400' }} onChange={this.handleComment} />
        </FormControl>
      </ItemReviewContainer>
    );
  }

}