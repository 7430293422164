import { Badge, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, VStack, useToast } from '@chakra-ui/react';
import { CommentCard } from '@jurnee/common/src/components/comments/CommentCard';
import { CommentForm, CommentFormData } from '@jurnee/common/src/components/comments/CommentForm';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { PropositionJSON } from '@jurnee/common/src/entities/Proposition';
import { PropositionCommentJSON } from '@jurnee/common/src/entities/PropositionComment';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPropositionCommentAttachmentUrl, getPropositionComments, uploadPropositionCommentAttachment } from 'src/api/propositions';
import { PrimaryButton } from 'src/components/buttons';
import { SkeletonComment } from 'src/components/Skeleton/SkeletonComment';
import { useAppDispatch } from 'src/store';
import { createPropositionCommentThunk } from 'src/store/propositionComments/propositionComments.thunks';

interface Props {
  bookingId: BookingJSON['id'];
  proposition: PropositionJSON;
  count: number;
}

export function PropositionCommentsDrawer({ bookingId, proposition, count }: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation(['requests', 'common']);

  const [isOpen, setIsOpen] = useState(false);

  const [comments, setComments] = useState<PropositionCommentJSON[]>([]);
  const [users, setUsers] = useState<UserJSON[]>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const propositionParams = {
    bookingId,
    propositionsGroupId: proposition.propositionsGroupId,
    propositionId: proposition.id
  };

  async function fetchComments() {
    const { list, relationships: { users } } = await getPropositionComments(propositionParams);
    setComments(list);
    setUsers(users);
    setIsLoading(false);
  }

  function onOpen() {
    setIsOpen(true);
    fetchComments();
  }

  function onClose() {
    setIsOpen(false);
    setIsSaving(false);
  }

  async function onSubmit({ content, files }: CommentFormData) {
    setIsSaving(true);

    try {
      const { data, relationships } = await dispatch(createPropositionCommentThunk({
        ...propositionParams,
        data: { content }
      })).unwrap();

      const attachments = await Promise.all(files.map(file => {
        return uploadPropositionCommentAttachment({
          ...propositionParams,
          commentId: data.id
        }, file);
      }));

      setComments([{ ...data, attachments }, ...comments]);
      const userIds = relationships.users.map(user => user.id);
      setUsers([...users.filter(user => !userIds.includes(user.id)), ...relationships.users]);
    } catch(err) {
      toast(getErrorToast(t('toasts.createComment.error')));
    }

    setIsSaving(false);
  }

  async function onDownload(comment: PropositionCommentJSON, attachmentId: number) {
    try {
      const { url } = await getPropositionCommentAttachmentUrl({
        ...propositionParams,
        commentId: comment.id,
        attachmentId
      });

      window.open(url, '_blank');
    } catch(error) {
      toast(getErrorToast(t('toasts.downloadFile.error'), error.message));
    }
  }

  return (
    <>
      <PrimaryButton
        colorScheme="black"
        size="xs"
        fontWeight={500}
        flexShrink={0}
        rightIcon={count > 0 && <Badge variant="white" size="sm">{count}</Badge>}
        onClick={onOpen}
      >
        { t(`comments.${count > 0 ? 'comments' : 'addComment'}`) }
      </PrimaryButton>

      <Drawer size="md" isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            { t('drawers.propositionComments.title') }
          </DrawerHeader>

          <DrawerBody>
            <VStack w="100%" spacing={4}>
              <CommentForm isUploading={isSaving} onSubmit={onSubmit} hasFileInput />

              {
                isLoading ? (
                  Array(count || 1).fill(0).map((_, i) => <SkeletonComment key={i} />)
                ) : (
                  comments.map(comment => {
                    const user = comment.userType === 'CUSTOMER' && users.find(user => user.id === comment.userId);

                    return <CommentCard
                      key={comment.id}
                      comment={comment}
                      user={user}
                      attachments={comment.attachments}
                      onDownload={attachmentId => onDownload(comment, attachmentId)}
                    />;
                  })
                )
              }
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}