import { AUTH_STATUS, AuthStatus, FETCH_STATUS, FetchStatus, RootStateData, RootStateList } from '@jurnee/common/src/browser/State';
import { PlaceResultDTO } from '@jurnee/common/src/dtos/places';
import { ApprovalProcessJSON } from '@jurnee/common/src/entities/ApprovalProcess';
import { ApprovalRequestJSON, ApprovalRequestRelationshipsJSON } from '@jurnee/common/src/entities/ApprovalRequest';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { BookingParticipantJSON } from '@jurnee/common/src/entities/BookingParticipant';
import { BookingReviewJSON } from '@jurnee/common/src/entities/BookingReview';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { BudgetBreakdownJSON } from '@jurnee/common/src/entities/BudgetBreakdown';
import { CollectionJSON } from '@jurnee/common/src/entities/Collection';
import { CompanyJSON } from '@jurnee/common/src/entities/Company';
import { CompanyRecommendedPartnerJSON } from '@jurnee/common/src/entities/CompanyRecommendedPartner';
import { CurrencyExchangeRates } from '@jurnee/common/src/entities/Currency';
import { EntityJSON } from '@jurnee/common/src/entities/Entity';
import { ExperienceJSON, ExperienceRatingJSON, ExperienceStats } from '@jurnee/common/src/entities/Experience';
import { ExperienceReviewJSON } from '@jurnee/common/src/entities/ExperienceReview';
import { ExternalCostJSON } from '@jurnee/common/src/entities/ExternalCost';
import { GeolocationJSON } from '@jurnee/common/src/entities/Geolocation';
import { Language } from '@jurnee/common/src/entities/Language';
import { Location } from '@jurnee/common/src/entities/Location';
import { NetPromoterScoreJSON } from '@jurnee/common/src/entities/NetPromoterScore';
import { PartnerReviewJSON } from '@jurnee/common/src/entities/PartnerReview';
import { ProviderDiscountJSON } from '@jurnee/common/src/entities/ProviderDiscount';
import { ReviewJSON } from '@jurnee/common/src/entities/Review';
import { TagJSON } from '@jurnee/common/src/entities/Tag';
import { TeamJSON } from '@jurnee/common/src/entities/Team';
import { TeamBudgetBreakdownJSON } from '@jurnee/common/src/entities/TeamBudgetBreakdown';
import { UserDetails, UserJSON, UserStatsJSON } from '@jurnee/common/src/entities/User';
import { UserBudgetBreakdownJSON } from '@jurnee/common/src/entities/UserBudgetBreakdown';
import { EntityId, EntityState } from '@reduxjs/toolkit';
import { budgetBreakdownsAdapter } from './budgetBreakdowns/budgetBreakdowns.adapter';
import { budgetsAdapter } from './budgets/budgets.adapter';
import { companyRecommendedPartnersAdapter } from './companyRecommendedPartners/companyRecommendedPartners.adapter';
import { experiencesRatingsAdapter } from './experiencesRatings/experiencesRatings.adapter';
import { teamBudgetBreakdownsAdapter } from './teamBudgetBreakdowns/teamBudgetBreakdowns.adapter';
import { teamsAdapter } from './teams/teams.adapter';
import { userBudgetBreakdownsAdapter } from './userBudgetBreakdowns/userBudgetBreakdowns.adapter';

const token = localStorage.getItem('token');

export type RootStateEntity<T, Id extends EntityId> = EntityState<T, Id> & {
  status: FetchStatus;
}

export interface RootState {
  approvalProcesses: RootStateList<ApprovalProcessJSON>;
  approvalRequests: RootStateList<ApprovalRequestJSON> & {
    relationships: ApprovalRequestRelationshipsJSON;
  };
  auth: {
    status: AuthStatus;
    token: string;
  };
  bootstrap: {
    status: FetchStatus;
  };
  bookingDetails: RootStateData<BookingJSON> & {
    relationships: BookingRelationshipsJSON;
  };
  bookingParticipants: RootStateList<BookingParticipantJSON>;
  bookings: RootStateList<BookingJSON> & {
    relationships: BookingRelationshipsJSON;
    past: RootStateList<BookingJSON> & {
      relationships: BookingRelationshipsJSON;
    }
  };
  bookingsInvoices: RootStateList<BookingInvoiceJSON>;
  budgets: RootStateEntity<BudgetJSON, BudgetJSON['id']>;
  budgetBreakdowns: RootStateEntity<BudgetBreakdownJSON, BudgetJSON['id']>;
  collections: RootStateList<CollectionJSON>;
  collectionDetails: RootStateData<CollectionJSON> & { updating: boolean };
  company: RootStateData<CompanyJSON> & {
    tags: RootStateList<TagJSON>;
  };
  companyRecommendedPartners: EntityState<CompanyRecommendedPartnerJSON, CompanyRecommendedPartnerJSON['partnerId']>;
  currencies: RootStateData<CurrencyExchangeRates>;
  employees: RootStateList<UserDetails>;
  entities: RootStateList<EntityJSON>;
  experienceDetails: RootStateData<ExperienceJSON>;
  experienceDetailsStats: RootStateData<ExperienceStats>;
  experiences: RootStateList<ExperienceJSON>;
  experiencesRatings: EntityState<ExperienceRatingJSON, ExperienceRatingJSON['experienceId']>;
  externalCosts: RootStateList<ExternalCostJSON>;
  geolocation: RootStateData<GeolocationJSON>;
  languages: RootStateList<Language>;
  locations: RootStateList<Location>;
  places: RootStateList<PlaceResultDTO>;
  providerDiscounts: RootStateList<ProviderDiscountJSON>;
  reviewDetails: RootStateData<ReviewJSON> & {
    booking: RootStateData<BookingJSON>;
    experienceReview: RootStateData<ExperienceReviewJSON>;
    partnerReview: RootStateData<PartnerReviewJSON>;
    bookingReview: RootStateData<BookingReviewJSON>;
    nps: RootStateData<NetPromoterScoreJSON>;
  };
  signup: RootStateData<UserDetails>;
  tags: RootStateList<TagJSON>;
  teamBudgetBreakdowns: RootStateEntity<TeamBudgetBreakdownJSON, TeamJSON['id']>;
  teams: RootStateEntity<TeamJSON, TeamJSON['id']>;
  user: UserDetails;
  userStats: RootStateData<UserStatsJSON>;
  userFavoriteExperiences: RootStateList<ExperienceJSON>;
  userBudgetBreakdowns: RootStateEntity<UserBudgetBreakdownJSON, UserJSON['id']>;
}

export const initialState: RootState = {
  approvalProcesses: {
    list: [],
    error: false,
    status: FETCH_STATUS.INITIAL
  },
  approvalRequests: {
    list: [],
    relationships: null,
    error: false,
    status: FETCH_STATUS.INITIAL
  },
  auth: {
    status: token ? AUTH_STATUS.AUTHENTICATED : AUTH_STATUS.INITIAL,
    token: token
  },
  bootstrap: {
    status: FETCH_STATUS.INITIAL
  },
  bookingDetails: {
    data: null,
    relationships: null,
    error: false,
    status: FETCH_STATUS.INITIAL
  },
  bookingParticipants: {
    list: [],
    error: false,
    status: FETCH_STATUS.INITIAL
  },
  bookings: {
    list: [],
    relationships: null,
    error: false,
    status: FETCH_STATUS.INITIAL,
    past: {
      list: [],
      relationships: null,
      error: false,
      status: FETCH_STATUS.INITIAL,
    }
  },
  bookingsInvoices: {
    status: FETCH_STATUS.INITIAL,
    error: false,
    list: []
  },
  budgets: budgetsAdapter.getInitialState({
    status: FETCH_STATUS.INITIAL
  }),
  budgetBreakdowns: budgetBreakdownsAdapter.getInitialState({
    status: FETCH_STATUS.INITIAL
  }),
  collections: {
    list: [],
    error: false,
    status: FETCH_STATUS.INITIAL
  },
  collectionDetails: {
    data: null,
    error: false,
    status: FETCH_STATUS.INITIAL,
    updating: false,
  },
  company: {
    data: null,
    error: false,
    status: FETCH_STATUS.INITIAL,
    tags: {
      list: [],
      error: false,
      status: FETCH_STATUS.INITIAL
    }
  },
  companyRecommendedPartners: companyRecommendedPartnersAdapter.getInitialState(),
  currencies: {
    data: null,
    error: false,
    status: FETCH_STATUS.INITIAL
  },
  employees: {
    list: [],
    error: false,
    status: FETCH_STATUS.INITIAL
  },
  entities: {
    list: [],
    error: false,
    status: FETCH_STATUS.INITIAL,
  },
  experienceDetails: {
    data: null,
    error: false,
    status: FETCH_STATUS.INITIAL
  },
  experienceDetailsStats: {
    data: null,
    error: false,
    status: FETCH_STATUS.INITIAL
  },
  experiences: {
    list: [],
    error: false,
    status: FETCH_STATUS.INITIAL
  },
  experiencesRatings: experiencesRatingsAdapter.getInitialState(),
  externalCosts: {
    list: [],
    error: false,
    status: FETCH_STATUS.INITIAL
  },
  geolocation: {
    data: null,
    error: false,
    status: FETCH_STATUS.INITIAL
  },
  languages: {
    list: [],
    error: false,
    status: FETCH_STATUS.INITIAL
  },
  locations: {
    list: [],
    error: false,
    status: FETCH_STATUS.INITIAL
  },
  places: {
    list: [],
    error: false,
    status: FETCH_STATUS.INITIAL
  },
  providerDiscounts: {
    list: [],
    error: false,
    status: FETCH_STATUS.INITIAL
  },
  reviewDetails: {
    data: null,
    error: false,
    status: FETCH_STATUS.INITIAL,
    booking: {
      data: null,
      error: false,
      status: FETCH_STATUS.INITIAL
    },
    experienceReview: {
      data: null,
      error: false,
      status: FETCH_STATUS.INITIAL
    },
    partnerReview: {
      data: null,
      error: false,
      status: FETCH_STATUS.INITIAL
    },
    bookingReview: {
      data: null,
      error: false,
      status: FETCH_STATUS.INITIAL
    },
    nps: {
      data: null,
      error: false,
      status: FETCH_STATUS.INITIAL
    },
  },
  signup: {
    data: null,
    error: false,
    status: FETCH_STATUS.INITIAL
  },
  tags: {
    list: [],
    error: false,
    status: FETCH_STATUS.INITIAL
  },
  teamBudgetBreakdowns: teamBudgetBreakdownsAdapter.getInitialState({
    status: FETCH_STATUS.INITIAL
  }),
  teams: teamsAdapter.getInitialState({
    status: FETCH_STATUS.INITIAL
  }),
  user: null,
  userStats: {
    data: null,
    error: false,
    status: FETCH_STATUS.INITIAL
  },
  userFavoriteExperiences: {
    list: [],
    error: false,
    status: FETCH_STATUS.INITIAL
  },
  userBudgetBreakdowns: userBudgetBreakdownsAdapter.getInitialState({
    status: FETCH_STATUS.INITIAL
  }),
};