import { QuoteJSON } from '@jurnee/common/src/entities/Quote';
import { withTranslation } from 'react-i18next';
import BaseStatusBadge, { StatusBadgeProps } from './index';

type Props = {
  status: QuoteJSON['status'];
}

class StatusBadge extends BaseStatusBadge<StatusBadgeProps & Props> {

  get label() {
    switch (this.props.status) {
    case 'CANCELED': return this.props.t('rejected');
    case 'SIGNED': return this.props.t('signed');
    case 'INITIAL':
    default:
      return this.props.t('pending');
    }
  }

  get variant() {
    switch (this.props.status) {
    case 'CANCELED':
      return 'pink';
    case 'SIGNED':
      return 'teal';
    case 'INITIAL':
    default:
      return 'blue';
    }
  }

}

export const QuoteStatus = withTranslation('common', { keyPrefix: 'status' })(StatusBadge);