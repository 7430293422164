import { api } from '@jurnee/common/src/browser/Api';
import { ApprovalResponseUpdateBody } from '@jurnee/common/src/dtos/approvalResponse';
import { ApprovalRequestJSON, ApprovalRequestRelationshipsJSON } from '@jurnee/common/src/entities/ApprovalRequest';
import { ApprovalResponseJSON } from '@jurnee/common/src/entities/ApprovalResponse';
import { List } from '@jurnee/common/src/serializers';

export default {
  getApprovalRequests(): Promise<List<ApprovalRequestJSON, ApprovalRequestRelationshipsJSON>> {
    return api.get('approval-requests');
  },
  updateApprovalResponse(approvalRequestId: ApprovalRequestJSON['id'], id: ApprovalResponseJSON['id'], data: ApprovalResponseUpdateBody): Promise<ApprovalResponseJSON> {
    return api.put(`approval-requests/${approvalRequestId}/approval-responses/${id}`, data);
  },
};