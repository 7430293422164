import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { deleteBookingParticipantThunk, getBookingParticipantsThunk, importBookingParticipantsThunk, inviteBookingParticipantsThunk, updateBookingParticipantThunk } from './bookingParticipants.thunks';

const slice = createSlice({
  name: 'bookingParticipants',
  initialState: initialState.bookingParticipants,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getBookingParticipantsThunk.pending, () => {
      return { list: [], error: null, status: FETCH_STATUS.FETCHING };
    });

    builder.addCase(getBookingParticipantsThunk.fulfilled, (_state, action) => {
      return { list: action.payload.list, error: null, status: FETCH_STATUS.FETCHED };
    });

    builder.addCase(getBookingParticipantsThunk.rejected, () => {
      return { list: [], error: true, status: FETCH_STATUS.INITIAL };
    });

    builder.addCase(deleteBookingParticipantThunk.fulfilled, (state, action) => {
      return {
        ...state,
        list: state.list.filter(({ id }) => id !== action.payload.id)
      };
    });

    builder.addCase(updateBookingParticipantThunk.fulfilled, (state, action) => {
      return {
        ...state,
        list: state.list.map(bookingParticipant => {
          if (bookingParticipant.id === action.payload.id) {
            return action.payload;
          }

          return bookingParticipant;
        })
      };
    });

    builder.addCase(importBookingParticipantsThunk.fulfilled, (state, { payload: { list } }) => {
      const bookingParticipants = state.list.map(bookingParticipant => {
        const updatedBookingParticipant = list.find(item => bookingParticipant.id === item.id);
        return updatedBookingParticipant || bookingParticipant;
      });

      const bookingParticipantsToAdd = list.filter(({ id }) => !state.list.find(bookingParticipant => bookingParticipant.id === id));

      return {
        ...state,
        list: [...bookingParticipants, ...bookingParticipantsToAdd]
      };
    });

    builder.addCase(inviteBookingParticipantsThunk.fulfilled, (state, { payload: { list } }) => {
      return {
        ...state,
        list: state.list.map(bookingParticipant => {
          const updatedBookingParticipant = list.find(item => bookingParticipant.id === item.id);
          return updatedBookingParticipant || bookingParticipant;
        })
      };
    });

  }
});

export default slice.reducer;