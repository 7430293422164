{
  "filters": {
    "name": {
      "placeholder": "Search a name or email"
    }
  },
  "table": {
    "head": {
      "name": "Name",
      "email": "Email",
      "budget": "Budget",
      "status": "Status"
    },
    "body": {
      "noBudget": "No budget"
    }
  },
  "emptyState": {
    "heading": "No participants imported",
    "description": "You will be able to follow the registration status of participants here once you have invited them."
  },
  "dotMenu": {
    "edit": "Edit participant",
    "remove": "Remove participant",
    "removeConfirmation": {
      "title": "Remove participant",
      "message": "Are you sure you want to remove the participant associated to the email <strong>{{email}}</strong>?"
    }
  },
  "buttons": {
    "invite": "Send invitations",
    "import": "Import participants",
    "register": "Register",
    "decline": "Decline",
    "changeMind": "I change my mind ->"
  },
  "sendInvitations": {
    "button": "Send invitations",
    "shareALink": "Share a link",
    "sendByEmails": "Send by emails",
    "sendBySlack": "Send by Slack"
  },
  "accepted": "You are registered",
  "declined": "Invitation declined",
  "participants": "Participants",
  "registrationCloses": "Registration closes {{endDate}}",
  "registrationClosed": "Registration closed on {{endDate}}",
  "toats": {
    "errors": {
      "update": "An error occurred while updating your participation",
      "init": "An error occurred while retrieving your invitation"
    }
  },
  "status": {
    "all": "All status",
    "ACCEPTED": "Accepted",
    "ATTENDED": "Attended",
    "DECLINED": "Declined",
    "INITIAL": "Not sent",
    "INVITED": "Awaiting"
  },
  "toasts": {
    "removeParticipant": {
      "success": "Participant removed",
      "error": "An error occurred while removing participant"
    },
    "create": {
      "success": "Registration created",
      "error": "An error occurred while creating registration"
    },
    "update": {
      "success": "Registration updated",
      "error": "An error occurred while updating registration"
    }
  },
  "drawer": {
    "title": "Edit participant",
    "form": {
      "status": {
        "label": "Status"
      },
      "comment": {
        "label": "Comment"
      }
    },
    "toasts": {
      "error": "An error occurred while updating the participant",
      "success": "Participant has been successfully updated"
    }
  },
  "importModal": {
    "title": "Import participants",
    "description": "First import your participants list here for your event and you will then be able to send them invitations so they can register for it.",
    "textArea": {
      "placeholder": "Enter email addresses separated by comma, space or newline"
    },
    "toasts": {
      "error": "An error occurred while importing participants",
      "success": "Participants have been successfully imported"
    }
  },
  "inviteModal": {
    "title": {
      "EMAIL": "Send invitations by emails",
      "SLACK": "Send invitations by Slack"
    },
    "description": "The invitations will be sent to:",
    "descriptionNextInvites": "It appears that some of your participants have already been invited while others have not. Which participants would like to send an invitation to:",
    "checkboxes": {
      "INITIAL": "Not sent ({{count}} participants)",
      "INVITED": "Awaiting answer ({{count}} participants)"
    },
    "button": "Send {{count}} invitations",
    "toasts": {
      "error": "An error occurred while inviting participants",
      "success": "Participants have been successfully invited"
    }
  },
  "form": {
    "endDate": {
      "label": "RSVP end date",
      "placeholder": "Select a date"
    },
    "eventDate": {
      "label": "Event date",
      "placeholder": "Select a date"
    },
    "eventStartTime": {
      "label": "Event start time",
      "placeholder": "Select a time"
    },
    "address": {
      "label": "Address",
      "placeholder": "Search an address"
    },
    "comment": {
      "label": "Comment",
      "placeholder": "Add any useful information"
    },
    "image": {
      "label": "Change"
    }
  },
  "budgetChart": {
    "committedBudget": "<strong>{{count}} people</strong> have accepted for a <strong><amount /></strong> budget.",
    "potentialBudget": "The potential budget is <strong><amount /></strong> if all <strong>{{count}} people</strong> accept."
  }
}