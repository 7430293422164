import { MultiSelect } from '@jurnee/common/src/components/Select';
import { CatalogType } from '@jurnee/common/src/entities/Company';
import * as React from 'react';

interface Props {
  onKeyDown?(e: React.KeyboardEvent): void;
  onChange(data: { label: string, value: CatalogType }[]): void;
  catalogs: { value: CatalogType, label: string }[];
  selectedCatalogs: CatalogType[];
}

export default class CatalogSelector extends React.PureComponent<Props> {

  get options() {
    return this.props.catalogs.map(({ value, label }) => {
      return { value, label };
    });
  }

  render() {
    return (
      <MultiSelect<CatalogType>
        onKeyDown={this.props.onKeyDown}
        options={this.options}
        placeholder={'Select catalog sections'}
        closeMenuOnSelect={false}
        openMenuOnFocus={true}
        maxMenuHeight={200}
        onChange={this.props.onChange}
        value={this.options.filter(option => this.props.selectedCatalogs.some(catalogType => option.value === catalogType))}
        isClearable={false}
      />
    );
  }

}