import { api } from '@jurnee/common/src/browser/Api';
import { Company } from '@jurnee/common/src/entities/Company';
import { TagJSON } from '@jurnee/common/src/entities/Tag';
import { List } from '@jurnee/common/src/serializers';
import { URLSearchParams } from 'url';

export default {
  getTags(params: URLSearchParams): Promise<List<TagJSON, never>> {
    return api.get(`tags?${params.toString()}`);
  },
  getCompanyTags(companyId: Company['id'], params: URLSearchParams): Promise<List<TagJSON, never>> {
    return api.get(`companies/${companyId}/tags?${params.toString()}`);
  },
};