import * as React from 'react';
import { RouteComponentProps } from './Route';
import { useParams, useMatch, useLocation, useNavigate } from 'react-router-dom';

export default function PublicRoute(props: RouteComponentProps) {
  const { path } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const match = useMatch(path);
  const params = useParams();

  return React.createElement(props.component, { location, navigate, match, params });
}