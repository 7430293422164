import { api } from '@jurnee/common/src/browser/Api';
import { PropositionsGroupCreateBody } from '@jurnee/common/src/dtos/propositionsGroups';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { Expand } from '@jurnee/common/src/serializers';

export interface PropositionsGroupCreateParams {
  bookingId: BookingJSON['id'];
  data: PropositionsGroupCreateBody;
}

export function createPropositionsGroup({ bookingId, data }: PropositionsGroupCreateParams): Promise<Expand<BookingJSON, BookingRelationshipsJSON>> {
  return api.post(`bookings/${bookingId}/propositions-groups`, data);
}