import { Box, Flex, useToast } from '@chakra-ui/react';
import { api } from '@jurnee/common/src/browser/Api';
import { BookingParticipantJSON } from '@jurnee/common/src/entities/BookingParticipant';
import { RegistrationJSON } from '@jurnee/common/src/entities/Registration';
import { getCdnImageUrl, setMobileViewport } from '@jurnee/common/src/utils/core';
import { getRegistrationImagePath } from '@jurnee/common/src/utils/registrations';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Params, useParams, useSearchParams } from 'react-router-dom';
import { getBookingParticipant, getRegistration } from 'src/api/registrations';
import { RegistrationForm } from './RegistrationForm';

type ParamsKeys = 'cuid' | 'registrationId';

export default function Registration() {
  const { t } = useTranslation('registration');
  const toast = useToast();
  const { cuid, registrationId } = useParams<Params<ParamsKeys>>();
  const [searchParams] = useSearchParams();

  const [registration, setRegistration] = useState<RegistrationJSON>(null);
  const [bookingParticipant, setBookingParticipant] = useState<BookingParticipantJSON>(null);

  async function fetchRegistration() {
    try {
      const registration = await getRegistration({
        bookingId: cuid,
        registrationId: Number(registrationId)
      });

      setRegistration(registration);
    } catch(err) {
      toast(getErrorToast(t('toats.errors.init')));
    }
  }

  async function fetchBookingParticipant(bookingParticipantId: BookingParticipantJSON['id']) {
    const bookingParticipant = await getBookingParticipant({
      bookingId: cuid,
      bookingParticipantId
    });

    setBookingParticipant(bookingParticipant);
  }

  useEffect(() => {
    setMobileViewport();

    try {
      fetchRegistration();
      const token = searchParams.get('token');

      if (token) {
        api.setToken(token);
        const bookingParticipantId = Number(searchParams.get('bookingParticipantId'));
        fetchBookingParticipant(bookingParticipantId);
      }
    } catch (err) {
      toast(getErrorToast(t('toats.errors.init')));
    }
  }, [cuid]);

  if (!registration) {
    return null;
  }

  const imagePath = getRegistrationImagePath(registration);

  const bgImg = `url('${getCdnImageUrl(imagePath)}')`;

  return (
    <Box bg="gray.800" bgImg={bgImg} bgSize="cover" bgPos="center center">
      <Flex w="100%" justifyContent="center" alignItems="center" h="100%" minH="100vh" p={8} backdropFilter="blur(40px)" bgColor="rgb(0, 0, 0, 0.25)">
        <Flex w="100%" maxW={900} bg="white" alignItems="stretch" borderRadius={8} boxShadow="0 0 15px rgba(0, 0, 0, 0.15)">
          <Box w="50%" bg={`black ${bgImg} center/contain no-repeat`} display={['none', 'block']} borderRadius="8px 0 0 8px" />
          <RegistrationForm registration={registration} bookingParticipant={bookingParticipant}/>
        </Flex>
      </Flex>
    </Box>
  );
}