/* eslint-disable @typescript-eslint/no-explicit-any */

window.analytics = [] as any;

// A list of the methods in Analytics.js to stub.
(analytics as any).methods = [
  'trackSubmit',
  'trackClick',
  'trackLink',
  'trackForm',
  'pageview',
  'identify',
  'reset',
  'group',
  'track',
  'ready',
  'alias',
  'debug',
  'page',
  'once',
  'off',
  'on',
  'addSourceMiddleware',
  'addIntegrationMiddleware',
  'setAnonymousId',
  'addDestinationMiddleware'
];

// Define a factory to create stubs. These are placeholders
// for methods in Analytics.js so that you never have to wait
// for it to load to actually record data. The `method` is
// stored as the first argument, so we can replay the data.
(analytics as any).factory = function (method: string) {
  return function (...args: any[]) {
    const list = Array.prototype.slice.call(args);
    list.unshift(method);
    (analytics as any).push(list);
    return analytics;
  };
};

// For each of our methods, generate a queueing stub.
for (let i = 0; i < (analytics as any).methods.length; i++) {
  const key = (analytics as any).methods[i];
  (analytics as any)[key] = (analytics as any).factory(key);
}

// Define a method to load Analytics.js from our CDN,
// and that will be sure to only ever load it once.
analytics.load = function (key: string, options = {}) {
  // Create an async script element based on your key.
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';

  const first = document.getElementsByTagName('script')[0];
  first.parentNode.insertBefore(script, first);
  (analytics as any)._loadOptions = options;
};

(analytics as any)._writeKey = process.env.SEGMENT_KEY;

// Add a version to keep track of what's in the wild.
(analytics as any).SNIPPET_VERSION = '4.13.2';

if (process.env.APP_ENV === 'production') {
  window.analytics.load(process.env.SEGMENT_KEY);

  // window.analytics.page('Dashboard', 'Page', {
  //   path: location.pathname,
  //   search: location.search,
  //   referrer: document.referrer,
  // });
}