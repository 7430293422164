import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  budgets: BudgetJSON[];
  defaultValue: BudgetJSON['id'];
  onChange(e: ChangeEvent<HTMLSelectElement>): void;
}

export function IndividualBudgetSelect({ budgets, defaultValue, onChange }: Props) {
  const { t } = useTranslation('employees', { keyPrefix: 'drawer.form.individualBudget' });

  const options = useMemo(
    () => sortAlphabeticallyBy(budgets, 'name').map(({ id, name }) => (
      <option key={id} value={id}>{name}</option>
    )),
    [budgets]
  );

  return (
    <FormControl id="budgetId">
      <FormLabel>{t('label')}</FormLabel>

      <Select size="sm" onChange={onChange} defaultValue={defaultValue}>
        <option value={0}>{t('noIndividualBudget')}</option>
        { options }
      </Select>
    </FormControl>
  );
}