import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { getUserTeams } from '../teams/teams.thunks';
import { teamBudgetBreakdownsAdapter } from './teamBudgetBreakdowns.adapter';

const slice = createSlice({
  name: 'teamBudgetBreakdowns',
  initialState: initialState.teamBudgetBreakdowns,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getUserTeams.pending, (state) => {
      state.status = FETCH_STATUS.FETCHING;
    });

    builder.addCase(getUserTeams.fulfilled, (state, { payload: { relationships } }) => {
      teamBudgetBreakdownsAdapter.setMany(state, relationships.teamBudgetBreakdowns);
      state.status = FETCH_STATUS.FETCHED;
    });

    builder.addCase(getUserTeams.rejected, (state) => {
      state.status = FETCH_STATUS.INITIAL;
    });

  }
});

export default slice.reducer;