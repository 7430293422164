import { HStack, Text } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { PropositionJSON } from '@jurnee/common/src/entities/Proposition';
import { formatDate, getCurrentTimeZone } from '@jurnee/common/src/utils/dates';
import { Trans } from 'react-i18next';

interface Props {
  expiredAt: PropositionJSON['expiredAt'];
}

export function ExpirationDate({ expiredAt }: Props) {
  const expiredDate = new Date(expiredAt);
  const date = formatDate(expiredDate, { timeZone: getCurrentTimeZone() });

  const isExpired = new Date() >= expiredDate;
  const bgColor = isExpired ? 'red.50' : 'yellow.50';
  const iconColor = isExpired ? 'red.500' : 'orange.500';
  const type = isExpired ? 'expired' : 'validUntil';

  return (
    <HStack w="100%" spacing={2} p={4} borderRadius={4} bgColor={bgColor}>
      <Icon icon="warning" color={iconColor} size={5} />
      <Text><Trans i18nKey={`requests:propositions.${type}`} values={{ date }} /></Text>
    </HStack>
  );
}