import { Box, HStack, Input } from '@chakra-ui/react';
import AddressSearch, { CitySearchParams } from '@jurnee/common/src/components/AddressSearch';
import { Icon } from '@jurnee/common/src/components/Icon';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface OwnProps extends WithTranslation {
  query: string;
  defaultCityValue: string;
  placeholder: string;
  hasCitySearch: boolean;
  onSubmit(data: SearchBarParams): void;
}

interface State {
  cityParams: CitySearchParams;
  query: string;
}

export interface SearchBarParams {
  cityParams: CitySearchParams;
  query: string;
}

class SearchBar extends React.Component<OwnProps> {

  state: State = {
    cityParams: undefined,
    query: this.props.query,
  };

  onInputClear = () => {
    if (!isEmpty(this.state.query)) {
      this.props.onSubmit({ ...this.state, query: '' });

      this.setState({ ...this.state, query: '' });
    }
  };

  onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, query: event.target.value });
  };

  onCityChange = (cityParams: CitySearchParams) => {
    this.props.onSubmit({ ...this.state, cityParams });

    this.setState({ ...this.state, cityParams });
  };

  onSubmit = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !isEmpty(this.state.query)) {
      this.props.onSubmit(this.state);
    }
  };

  render() {
    return (
      <HStack spacing={5}>
        <HStack
          w="265px"
          h="32px"
          spacing={3}
          py={2}
          px={3}
          bgColor="white"
          border="1px solid"
          borderColor="gray.200"
          borderRadius={4}
          _hover={{ borderColor:'gray.300' }}
        >
          <Icon
            color="gray.500"
            icon="search"
            size={4}
          />
          <Input
            fontSize={14}
            fontWeight={500}
            onChange={this.onInputChange}
            onKeyDown={this.onSubmit}
            placeholder={this.props.placeholder}
            _placeholder={{ color: 'gray.500' }}
            variant="unstyled"
            value={this.state.query}
          />
          <Icon
            color="gray.200"
            icon="circleCloseLine"
            size={4}
            onClick={this.onInputClear}
            cursor="pointer"
            _hover={{ color: 'gray.500' }}
            _active={{ color: 'gray.700' }}
          />
        </HStack>

        {
          this.props.hasCitySearch &&
            <Box w="265px" h="32px">
              <AddressSearch
                onChange={this.onCityChange}
                defaultValue={this.props.defaultCityValue}
                variant="searchBar"
                types='(cities)'
              />
            </Box>
        }
      </HStack>
    );
  }

}

export default withTranslation('experiences')(SearchBar);