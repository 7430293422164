import { HStack, Text, VStack } from '@chakra-ui/react';
import { Currency } from '@jurnee/common/src/entities/Currency';
import { formatPrice } from '@jurnee/common/src/utils/prices';
import * as React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RootState } from 'src/store/state';
import PriceInUserCurrency from './PriceInUserCurrency';

interface OwnProps {
  subtotal: number;
  totalDiscount: number;
  totalTax: number;
  total: number;
  currency: Currency;
  showCurrencyWarning: boolean;
}

interface StateProps {
  user: RootState['user'];
}

type Props = OwnProps & StateProps & WithTranslation;

class BookingPriceDetailsCard extends React.PureComponent<Props> {

  get subtotal() {
    return (
      <HStack w="100%" color="gray.400" justifyContent="space-between">
        <Text fontSize="sm" lineHeight="12px">{this.props.t('priceDetails.subtotal')}</Text>
        <Text fontSize="sm" lineHeight="12px">
          <PriceInUserCurrency value={this.props.subtotal} currency={this.props.currency}/>
        </Text>
      </HStack>
    );
  }

  get totalDiscount() {
    if (this.props.totalDiscount <= 0) {
      return null;
    }

    {return (
      <HStack w="100%" color="gray.400" justifyContent="space-between">
        <Text fontSize="sm" lineHeight="12px">{this.props.t('priceDetails.discount')}</Text>
        <Text fontSize="sm" lineHeight="12px">
          <PriceInUserCurrency value={-this.props.totalDiscount} currency={this.props.currency} />
        </Text>
      </HStack>
    );}
  }

  get totalTax() {
    return (
      <HStack w="100%" color="gray.400" justifyContent="space-between">
        <Text fontSize="sm" lineHeight="12px">{this.props.t('priceDetails.vat')}</Text>
        <Text fontSize="sm" lineHeight="12px">
          <PriceInUserCurrency value={this.props.totalTax} currency={this.props.currency} />
        </Text>
      </HStack>
    );
  }

  get total() {
    return (
      <HStack w="100%" justifyContent="space-between">
        <Text lineHeight="18px" fontSize="xl" fontWeight={700}>{this.props.t('priceDetails.total')}</Text>
        <Text lineHeight="18px" fontSize="xl" fontWeight={700}>
          <PriceInUserCurrency value={this.props.total} currency={this.props.currency} />
        </Text>
      </HStack>
    );
  }

  get totalBilled() {
    const { currency, user, total, showCurrencyWarning } = this.props;

    if (!showCurrencyWarning || currency === user.currency.id) {
      return null;
    }

    const { rate } = user.currency.targetExchangeRates.find(({ sourceId }) => sourceId === currency);

    return (
      <Text fontSize={14} color="orange.400" bg="orange.50" p={4} borderRadius={4} w="100%" textAlign="center">
        <Trans
          i18nKey={`booking:priceDetails.currencyWarning`}
          values={{ price: formatPrice(total, currency), rate: rate.toFixed(3) }}
        />
      </Text>
    );
  }

  render() {
    return (
      <VStack w="100%" spacing={5}>
        {this.subtotal}
        {this.totalDiscount}
        {this.totalTax}
        {this.total}
        {this.totalBilled}
      </VStack>
    );
  }

}


function mapStateToProps(state: RootState): StateProps {
  return {
    user: state.user
  };
}

export default connect<StateProps, void, void, RootState>(
  mapStateToProps,
  null
)(withTranslation('booking')(BookingPriceDetailsCard));