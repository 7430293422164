import { VStack } from '@chakra-ui/react';
import { ExploreCard } from 'src/components/ExploreCard';
import { Header } from './Header';

export function Home() {
  return (
    <main>
      <VStack h="100%" alignItems="center" justifyContent="center" spacing={7}>
        <Header />
        <ExploreCard maxW={712} />
      </VStack>
    </main>
  );
}