import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { addProviderDiscount, getProviderDiscounts } from './providerDiscounts.thunks';

const slice = createSlice({
  name: 'providerDiscounts',
  initialState: initialState.providerDiscounts,
  reducers: { },
  extraReducers: (builder) => {

    builder.addCase(getProviderDiscounts.pending, (state) => {
      return { ...state, list: [], error: false, status: FETCH_STATUS.FETCHING };
    });

    builder.addCase(getProviderDiscounts.fulfilled, (state, action) => {
      return { ...state, list: action.payload, error: false, status: FETCH_STATUS.FETCHED };
    });

    builder.addCase(getProviderDiscounts.rejected, (state) => {
      return { ...state, list: [], error: true, status: FETCH_STATUS.INITIAL };
    });

    builder.addCase(addProviderDiscount.fulfilled, (state, action) => {
      const list = state.list.map(providerDiscount => {
        if (providerDiscount.entityId === action.payload.entityId) {
          return action.payload;
        }

        return providerDiscount;
      });

      return { ...state, list };
    });

  }
});

export default slice.reducer;