import { Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { EmptyState } from '@jurnee/common/src/components/EmptyState';
import { BookingParticipantJSON } from '@jurnee/common/src/entities/BookingParticipant';
import { getParticipantLabel } from '@jurnee/common/src/utils/participants';
import { useTranslation } from 'react-i18next';
import { ParticipantBudget } from './ParticipantBudget';
import { ParticipantDotMenu } from './ParticipantDotMenu';
import { ParticipantStatus } from './ParticipantStatus';

export interface Props {
  bookingParticipants: BookingParticipantJSON[];
  onRemove(bookingParticipant: BookingParticipantJSON): void;
  onChange(): void;
  shouldDisplayBudget: boolean;
}

function Header({ shouldDisplayBudget }: Pick<Props, 'shouldDisplayBudget'>) {
  const { t } = useTranslation('registration', { keyPrefix: 'table' });

  return (
    <Thead>
      <Tr>
        <Th w="40%">{t('head.name')}</Th>
        <Th w="40%">{t('head.email')}</Th>
        { shouldDisplayBudget && <Th w="20%">{t('head.budget')}</Th> }
        <Th>{t('head.status')}</Th>
        <Th w={8}></Th>
      </Tr>
    </Thead>
  );
}

function Body({ bookingParticipants, onRemove, onChange, shouldDisplayBudget }: Props) {
  const { t } = useTranslation('registration');

  if (bookingParticipants.length === 0) {
    return (
      <Tbody>
        <Tr>
          <Td p={16} colSpan={5}>
            <EmptyState
              icon="listSearch"
              heading={t('emptyState.heading')}
              description={t('emptyState.description')}
            />
          </Td>
        </Tr>
      </Tbody>
    );
  }

  return (
    <Tbody>
      {
        bookingParticipants.map(bookingParticipant => {
          return (
            <Tr key={bookingParticipant.id}>
              <Td maxW={1}><Text isTruncated>{getParticipantLabel(bookingParticipant.participant)}</Text></Td>
              <Td maxW={1}><Text isTruncated>{bookingParticipant.participant.email}</Text></Td>
              { shouldDisplayBudget && <Td><ParticipantBudget email={bookingParticipant.participant.email} /></Td> }
              <Td><ParticipantStatus status={bookingParticipant.status} /></Td>
              <Td pl={0} pr={3}><ParticipantDotMenu bookingParticipant={bookingParticipant} onRemove={onRemove} onChange={onChange} /></Td>
            </Tr>
          );
        })
      }
    </Tbody>
  );
}

export default function BookingParticipantsTable(props: Props) {
  return (
    <TableContainer w="100%">
      <Table>
        <Header shouldDisplayBudget={props.shouldDisplayBudget} />
        <Body {...props} />
      </Table>
    </TableContainer>
  );
}