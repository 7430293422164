import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { createApprovalProcess, deleteApprovalProcess, getApprovalProcesses, updateApprovalProcess } from './approvalProcesses.thunks';

const slice = createSlice({
  name: 'approvalProcesses',
  initialState: initialState.approvalProcesses,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getApprovalProcesses.pending, () => {
      return { list: [], error: null, status: FETCH_STATUS.FETCHING };
    });

    builder.addCase(getApprovalProcesses.fulfilled, (_state, action) => {
      return { list: action.payload.list, error: null, status: FETCH_STATUS.FETCHED };
    });

    builder.addCase(getApprovalProcesses.rejected, () => {
      return { list: [], error: true, status: FETCH_STATUS.INITIAL };
    });

    builder.addCase(createApprovalProcess.fulfilled, (state, action) => {
      return { ...state, list: [...state.list, action.payload] };
    });

    builder.addCase(updateApprovalProcess.fulfilled, (state, { payload }) => {
      return { ...state, list: state.list.map((approvalProcess) => approvalProcess.id === payload.id ? payload : approvalProcess) };
    });

    builder.addCase(deleteApprovalProcess.fulfilled, (state, action) => {
      return { ...state, list: state.list.filter(({ id }) => id !== action.payload.id) };
    });

  }
});

export default slice.reducer;