import { UserDetails, UserJSON, UserUpdate } from '@jurnee/common/src/entities/User';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/user';
import { RootState } from '../state';

export interface UserUpdatePayload {
  id: UserJSON['id'];
  data: UserUpdate;
}

export const updateUser = createAsyncThunk<UserDetails, UserUpdatePayload, { state: RootState }>('USER_UPDATE', async ({ id, data }: UserUpdatePayload, thunkAPI) => {
  try {
    const updatedUser = await api.update(id, data);

    return updatedUser;
  } catch (err) {
    return thunkAPI.rejectWithValue({ message: err.message });
  }
});