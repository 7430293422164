import { Select } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  value: string;
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
}

export function StatusSelect({ value, onChange }: Props) {
  const { t } = useTranslation('registration', { keyPrefix: 'status' });

  return (
    <Select size="sm" minW="185px" maxW="215px" onChange={onChange} value={value}>
      <option value=''>{t('all')}</option>
      {
        ['ACCEPTED', 'ATTENDED', 'INVITED', 'DECLINED', 'INITIAL'].map((status, i) => {
          return <option key={i} value={status}>{t(status)}</option>;
        })
      }
    </Select>
  );
}