import { TaskJSON } from '@jurnee/common/src/entities/Task';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createTask, deleteTask, TaskCreateParams, TaskParams, TaskUpdateParams, updateTask } from '../../api/tasks';
import { RootState } from '../state';
import { getUserStatsThunk } from '../userStats/userStats.thunks';

export const createTaskThunk = createAsyncThunk<TaskJSON, TaskCreateParams, { state: RootState }>('TASKS_CREATE', async (params, thunkAPI) => {
  try {
    const task = await createTask(params);

    thunkAPI.dispatch(getUserStatsThunk());

    return task;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const updateTaskThunk = createAsyncThunk<TaskJSON, TaskUpdateParams & { prevStatus: TaskJSON['status'] }, { state: RootState }>('TASKS_UPDATE', async ({ prevStatus, ...params }, thunkAPI) => {
  try {
    const task = await updateTask(params);

    thunkAPI.dispatch(getUserStatsThunk());

    return task;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const deleteTaskThunk = createAsyncThunk<TaskJSON, TaskParams, { state: RootState }>('TASKS_DELETE', async (params, thunkAPI) => {
  try {
    const task = await deleteTask(params);

    thunkAPI.dispatch(getUserStatsThunk());

    return task;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});