import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { budgetsAdapter } from './budgets.adapter';
import { createBudget, deleteBudget, getBudgets, getUserBudgets, updateBudget } from './budgets.thunks';

const slice = createSlice({
  name: 'budgets',
  initialState: initialState.budgets,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getBudgets.pending, (state) => {
      state.status = FETCH_STATUS.FETCHING;
    });

    builder.addCase(getBudgets.fulfilled, (state, { payload: { list } }) => {
      budgetsAdapter.setMany(state, list);
      state.status = FETCH_STATUS.FETCHED;
    });

    builder.addCase(getBudgets.rejected, (state) => {
      state.status = FETCH_STATUS.INITIAL;
    });

    builder.addCase(getUserBudgets.fulfilled, (state, { payload: { list } }) => {
      budgetsAdapter.setMany(state, list);
    });

    builder.addCase(createBudget.fulfilled, (state, { payload: { data } }) => {
      budgetsAdapter.addOne(state, data);
    });

    builder.addCase(updateBudget.fulfilled, (state, { payload: { data } }) => {
      budgetsAdapter.updateOne(state, { id: data.id, changes: data });
    });

    builder.addCase(deleteBudget.fulfilled, (state, { payload: { id } }) => {
      budgetsAdapter.removeOne(state, id);
    });

  }
});

export default slice.reducer;