import { Select } from '@chakra-ui/react';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

export interface Props {
  value: BudgetJSON['definitionType'] | '';
  onChange(e: ChangeEvent<HTMLSelectElement>): void;
}

export function DefinitionTypeSelect(props: Props) {
  const { t } = useTranslation('settings', { keyPrefix: 'budgets.filters.definitionTypes' });

  return (
    <Select size="sm" minW="185px" maxW="215px" onChange={props.onChange} value={props.value}>
      <option value="">{t('all')}</option>
      {['GLOBAL', 'INDIVIDUAL'].map(type => (
        <option key={type} value={type}>{t(type)}</option>
      ))}
    </Select>
  );
}