import { VStack } from '@chakra-ui/react';
import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { Loader } from '@jurnee/common/src/components/Loader';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import EmptyState from 'src/components/EmptyState';
import { PrimaryButton } from 'src/components/buttons';
import EditApprovalProcessDrawer from 'src/drawers/EditApprovalProcessDrawer';
import { getApprovalProcesses } from 'src/store/approvalProcesses/approvalProcesses.thunks';
import { RootState } from 'src/store/state';
import ApprovalProcessesTable from './ApprovalProcessesTable';

interface StateProps {
  approvalProcesses: RootState['approvalProcesses'];
  company: RootState['company'];
}

interface DispatchProps {
  getApprovalProcesses(): void;
}

type Props = StateProps & DispatchProps & WithTranslation;

class ApprovalSettings extends React.Component<Props> {

  componentDidMount(): void {
    if (this.props.approvalProcesses.status !== FETCH_STATUS.FETCHING) {
      this.props.getApprovalProcesses();
    }
  }

  get emptyState() {
    return (
      <EmptyState
        imagePath="/assets/illustrations/approval.svg"
        heading={this.props.t('emptyState.heading')}
        description={this.props.t('emptyState.description')}
      >
        <EditApprovalProcessDrawer>
          <PrimaryButton size="sm">{this.props.t('drawer.newApprovalProcess')}</PrimaryButton>
        </EditApprovalProcessDrawer>
      </EmptyState>
    );
  }

  get table() {
    return (
      <VStack w="100%" spacing={5}>
        <EditApprovalProcessDrawer>
          <PrimaryButton size="sm" alignSelf="flex-end">
            { this.props.t('drawer.newApprovalProcess') }
          </PrimaryButton>
        </EditApprovalProcessDrawer>

        <ApprovalProcessesTable approvalProcesses={this.props.approvalProcesses.list} />
      </VStack>
    );
  }

  render() {
    if (this.props.approvalProcesses.status !== FETCH_STATUS.FETCHED) {
      return <Loader/>;
    }

    return this.props.approvalProcesses.list.length > 0 ? this.table : this.emptyState;
  }

}

function mapStateToProps(state: RootState): StateProps {
  return {
    approvalProcesses: state.approvalProcesses,
    company: state.company
  };
}

const mapDispatchToProps: DispatchProps = {
  getApprovalProcesses,
};

export default connect<StateProps, DispatchProps, void, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('settings', { keyPrefix: 'approval' })(ApprovalSettings));