import { HStack, Text, VStack } from '@chakra-ui/react';
import { PropositionsGroupJSON } from '@jurnee/common/src/entities/PropositionsGroup';
import { useTranslation } from 'react-i18next';
import { getStepsProgress, Stepper } from 'src/components/Booking/Stepper';

interface Props {
  propositionsGroup: PropositionsGroupJSON;
}

export function StepsCard({ propositionsGroup }: Props) {
  const { t } = useTranslation(['booking', 'requests']);

  const progress = getStepsProgress(propositionsGroup);
  const current = Object.entries(progress).reduce((out, [key, value]) => value > 0 ? key : out, 'RECEIVED');
  const step = Object.entries(progress).findIndex(([key]) => key === current);

  return (
    <VStack w="100%" bg="white" borderRadius={4} border="1px solid" borderColor="gray.200" p={4} spacing={2}>
      <HStack justifyContent="space-between" w="100%">
        <Text lineHeight="16px">{t(`processing.${current}`)}</Text>
        <Text lineHeight="16px">{t('requests:propositions.stepOf', { step, total: Object.entries(progress).length })}</Text>
      </HStack>

      <Stepper progress={progress} />
    </VStack>
  );
}