import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  UseToastOptions,
  VStack
} from '@chakra-ui/react';
import { CountrySelector } from '@jurnee/common/src/components/CountrySelector';
import { EntityUpdateBody } from '@jurnee/common/src/dtos/entities';
import { EntityJSON } from '@jurnee/common/src/entities/Entity';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import { PayloadAction } from '@reduxjs/toolkit';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getEntityUpdateBody, isEmptyMandatoryField, taxIdNumberTypes } from 'src/utils/entities';
import { PrimaryButton, SecondaryButton } from '../components/buttons';
import { TrackEventOptions, trackEvent } from '../store/analytics/analytics.thunks';
import { EntityUpdatePayload, updateEntity } from '../store/entities/entities.thunks';
import { showToast } from '../store/toasts/toasts.thunks';

interface OwnProps extends WithTranslation {
  entity: EntityJSON;
  children: React.ReactElement;
}

interface DispatchProps {
  showToast(payload: UseToastOptions): void;
  trackEvent(opts: TrackEventOptions): void;
  updateEntity(payload: EntityUpdatePayload): PayloadAction<EntityJSON & { error?: string }>;
}

type Props = OwnProps & DispatchProps;

interface State {
  isOpen: boolean;
  isSaving: boolean;
  isSubmitted: boolean;
  body: EntityUpdateBody;
}

class EditEntityDrawer extends React.PureComponent<Props, State> {

  state: State = {
    isOpen: false,
    isSaving: false,
    isSubmitted: false,
    body: getEntityUpdateBody(this.props.entity)
  };

  onSave = async () => {
    this.setState({ ...this.state, isSubmitted: true });

    if (isEmptyMandatoryField(this.state.body)) {
      return;
    }

    this.setState({ ...this.state, isSaving: true });

    await this.props.updateEntity({
      body: this.state.body,
      id: this.props.entity.id,
      onSuccess: () => {
        this.props.showToast({ title: this.props.t('toasts.update.success'), status: 'success' });
        this.setState({ ...this.state, isSubmitted: false, isSaving: false, isOpen: false });
      },
      onError: (err: Error) => {
        this.props.showToast({ title: err.message || this.props.t('toasts.update.error'), status: 'error' });
        this.setState({ ...this.state, isSubmitted: false, isSaving: false });
      }
    });
  };

  onClose = () => {
    this.setState({ isOpen: false });
  };

  onOpen = () => {
    this.setState({ ...this.state, isOpen: true });

    this.props.trackEvent({
      name: 'opened_edit_entity_drawer'
    });
  };

  onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      body: {
        ...this.state.body,
        [target.name]: target.value || null
      }
    });
  };

  onTaxIdNumberChange = ({ target }: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    this.setState({
      ...this.state,
      body: {
        ...this.state.body,
        taxIdNumber: {
          ...this.state.body.taxIdNumber,
          [target.name]: target.value || null
        }
      }
    });
  };

  onCountryChange = (data: { label: string, value: string }) => {
    this.setState({
      ...this.state,
      body: {
        ...this.state.body,
        country: data.label,
        countryCode: data.value
      }
    });
  };

  get taxIdNumberOptions() {
    return taxIdNumberTypes.map(({ value, label }) => <option key={value} value={value}>{label}</option>);
  }

  get form() {
    const { body } = this.state;

    return (
      <VStack spacing={4}>
        <FormControl id="name" isInvalid={this.state.isSubmitted && isEmpty(body.name)} isRequired>
          <FormLabel>{this.props.t('form.name.label')}</FormLabel>
          <Input
            size="sm"
            autoFocus
            placeholder={this.props.t('form.name.placeholder')}
            onChange={this.onChange}
            name="name"
            defaultValue={body.name}
          />
          <FormErrorMessage>{this.props.t('form.name.error')}</FormErrorMessage>
        </FormControl>

        <FormControl id="address" isInvalid={this.state.isSubmitted && isEmpty(body.address)} isRequired>
          <FormLabel>{this.props.t('form.address.label')}</FormLabel>
          <Input
            size="sm"
            placeholder={this.props.t('form.address.placeholder')}
            onChange={this.onChange}
            name="address"
            defaultValue={body.address}
          />
          <FormErrorMessage>{this.props.t('form.address.error')}</FormErrorMessage>
        </FormControl>

        <FormControl id="additionalInformation">
          <FormLabel>{this.props.t('form.additionalInformation.label')}</FormLabel>
          <Input
            size="sm"
            placeholder={this.props.t('form.additionalInformation.placeholder')}
            onChange={this.onChange}
            name="additionalInformation"
            defaultValue={body.additionalInformation}
          />
        </FormControl>

        <FormControl id="postalCodeAndCity" isInvalid={this.state.isSubmitted && (isEmpty(body.postalCode) || isEmpty(body.city))} isRequired>
          <HStack spacing={8}>
            <FormControl id="postalCodeAndCity" isInvalid={this.state.isSubmitted && isEmpty(body.postalCode)} isRequired>
              <FormLabel>{this.props.t('form.postalCode.label')}</FormLabel>
              <Input
                size="sm"
                placeholder={this.props.t('form.postalCode.placeholder')}
                onChange={this.onChange}
                name="postalCode"
                defaultValue={body.postalCode}
              />
            </FormControl>

            <FormControl id="city" isInvalid={this.state.isSubmitted && isEmpty(body.city)} isRequired>
              <FormLabel>{this.props.t('form.city.label')}</FormLabel>
              <Input
                size="sm"
                placeholder={this.props.t('form.city.placeholder')}
                onChange={this.onChange}
                name="city"
                defaultValue={body.city}
              />
            </FormControl>
          </HStack>
          <FormErrorMessage>{this.props.t('form.postalCode.error')}</FormErrorMessage>
        </FormControl>

        <FormControl id="state">
          <FormLabel>{this.props.t('form.state.label')}</FormLabel>
          <Input
            size="sm"
            placeholder={this.props.t('form.state.placeholder')}
            onChange={this.onChange}
            name="state"
            defaultValue={body.state}
          />
        </FormControl>

        <FormControl id="country" isInvalid={this.state.isSubmitted && (isEmpty(body.country) || isEmpty(body.countryCode))} isRequired>
          <FormLabel>{this.props.t('form.country.label')}</FormLabel>
          <Box className="react-select-small">
            <CountrySelector onChange={this.onCountryChange} value={body.countryCode} />
          </Box>
          <FormErrorMessage>{this.props.t('form.country.error')}</FormErrorMessage>
        </FormControl>

        <FormControl id="taxIdNumber" isInvalid={this.state.isSubmitted && (isEmpty(body.taxIdNumber.type) || isEmpty(body.taxIdNumber.value))} isRequired>
          <FormLabel>{this.props.t('form.taxIdNumber.label')}</FormLabel>
          <HStack spacing={4}>
            <Select
              size="sm"
              bg="white"
              w="200px"
              defaultValue={body.taxIdNumber.type}
              name="type"
              onChange={this.onTaxIdNumberChange}
            >
              {this.taxIdNumberOptions}
            </Select>
            <Input
              size="sm"
              bg="white"
              name="value"
              onChange={this.onTaxIdNumberChange}
              defaultValue={body.taxIdNumber.value}
              placeholder={this.props.t('form.taxIdNumber.placeholder')}
            />
          </HStack>
          <FormErrorMessage>{this.props.t('form.taxIdNumber.error')}</FormErrorMessage>
        </FormControl>
      </VStack>
    );
  }

  render() {
    return (
      <>
        {React.cloneElement(this.props.children, { onClick: this.onOpen })}
        <Drawer isOpen={this.state.isOpen} onClose={this.onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>{this.props.t('billing:entities.drawer.header')}</DrawerHeader>
            <DrawerBody>
              {this.form}
            </DrawerBody>
            <DrawerFooter>
              <HStack justifyContent="space-between" w="100%">
                <SecondaryButton size="sm" colorScheme="pink" onClick={this.onClose}>
                  {this.props.t('common:buttons.close')}
                </SecondaryButton>
                <PrimaryButton size="sm" colorScheme="teal" isLoading={this.state.isSaving} onClick={this.onSave}>
                  {this.props.t('common:buttons.save')}
                </PrimaryButton>
              </HStack>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </>
    );
  }
}

const mapDispatchToProps: DispatchProps = {
  showToast,
  trackEvent,
  updateEntity: updateEntity as unknown as DispatchProps['updateEntity'],
};

export default connect<null, DispatchProps>(
  null,
  mapDispatchToProps
)(withTranslation('entity')(EditEntityDrawer));