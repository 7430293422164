import { Box } from '@chakra-ui/react';
import { SearchSelect } from '@jurnee/common/src/components/Select';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  users: UserDetails[];
  userIds: number[];
  placeholder?: string;
  onAddUser(data: { value: UserDetails }): void;
}

function getOptions({ users, userIds }: Pick<Props, 'users' | 'userIds'>) {
  const filteredUsers = users.filter(user => userIds.every(userId => userId !== user.id));
  const options = filteredUsers.map(user => ({ value: user, label: getUserLabel(user) }));

  return sortAlphabeticallyBy(options, 'label');
}

export function UsersSearchSelect({ onAddUser, placeholder, ...props }: Props) {
  const { t } = useTranslation('common', { keyPrefix: 'fields.usersSearch' });

  const options = useMemo(() => getOptions(props), [props]);

  return (
    <Box w="100%" className="react-select-small">
      <SearchSelect<UserDetails>
        options={options}
        closeMenuOnSelect={false}
        menuPlacement="top"
        openMenuOnFocus={true}
        maxMenuHeight={200}
        placeholder={placeholder ?? t('placeholder')}
        value={null}
        onChange={onAddUser}
      />
    </Box>
  );
}