import { PlaceDetailsDTO, PlaceResultDTO } from '@jurnee/common/src/dtos/places';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';

export function getPlaceLabel({ name, address }: Partial<PlaceDetailsDTO>) {
  return `${name} · ${address.city}, ${address.postalCode}`;
}

export function filterDuplicatePlaces(places: PlaceResultDTO[], experiences: ExperienceJSON[]) {
  const providerIds = experiences.flatMap(({ partner }) => {
    return partner ? partner.partnersProvidersInformation.map(({ providerId }) => providerId) : [];
  });

  return places.filter(({ id }) => !providerIds.includes(id));
}