import { Box, FormControl, FormLabel } from '@chakra-ui/react';
import { PlaceAddressDTO, PlaceDetailsDTO } from '@jurnee/common/src/dtos/places';
import { useTranslation } from 'react-i18next';
import PlaceSearch from 'src/components/PlaceSearch';
import { getPlaceLabel } from 'src/utils/places';

interface Props {
  sessionToken: string;
  cityAddress: PlaceAddressDTO;
  partnerPlace: Partial<PlaceDetailsDTO>;
  onChange(partnerPlace: PlaceDetailsDTO): void;
}

export function ProviderSelector(props: Props) {
  const { t } = useTranslation('common', { keyPrefix: 'fields.provider' });

  return (
    <FormControl id="partnerPlace" isRequired>
      <FormLabel>{t('label')}</FormLabel>

      <Box className="react-select-small">
        <PlaceSearch
          itemType="SPECIFIC_PARTNER"
          sessiontoken={props.sessionToken}
          latitude={props.cityAddress?.latitude}
          longitude={props.cityAddress?.longitude}
          placeholder={t('placeholder')}
          onChange={({ details }) => props.onChange(details)}
          isDisabled={!props.cityAddress}
          currentValue={props.partnerPlace ? { label: getPlaceLabel(props.partnerPlace), value: props.partnerPlace.id } : null}
        />
      </Box>
    </FormControl>
  );
}