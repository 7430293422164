import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  onDelete(): void
}

export function DeleteButton(props: Props) {
  const { t } = useTranslation('common', { keyPrefix: 'buttons' });

  return (
    <Text
      color="red.500"
      cursor="pointer"
      _hover={{ color: 'red.700' }}
      onClick={props.onDelete}
    >
      { t('delete') }
    </Text>
  );
}