import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultValue: string;
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
}

export function StatusSelect({ defaultValue, onChange }: Props) {
  const { t } = useTranslation('tasks');

  const statuses = [
    'TODO',
    'IN_PROGRESS',
    'DONE',
    'CANCELED'
  ];

  return (
    <FormControl id="status">
      <FormLabel>{t('drawer.form.status.label')}</FormLabel>

      <Select size="sm" defaultValue={defaultValue} onChange={onChange}>
        { statuses.map(status => <option key={status} value={status}>{t(`status.${status}`)}</option>) }
      </Select>
    </FormControl>
  );
}