import { Link, Text } from '@chakra-ui/react';
import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { Loader } from '@jurnee/common/src/components/Loader';
import * as React from 'react';
import { Trans } from 'react-i18next';
import EntitiesTable from 'src/components/EntitiesTable';
import { RootState } from 'src/store/state';

interface Props {
  entities: RootState['entities'];
}

export default class Entities extends React.Component<Props> {

  get footer() {
    return (
      <Text textAlign="center" p={8} whiteSpace="pre-line" color="gray.400">
        <Trans
          i18nKey="billing:entities.footer"
          components={{
            intercom: <Link color="black" id="intercom-launcher" />,
            mailto: <Link color="black" href="mailto:hello@jurnee.io" />
          }}
        />
      </Text>
    );
  }

  render() {
    if (this.props.entities.status !== FETCH_STATUS.FETCHED) {
      return <Loader h={400} />;
    }

    return (
      <>
        <EntitiesTable entities={this.props.entities.list} />
        {this.footer}
      </>
    );
  }

}