import { Heading, Text, VStack } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { useTranslation } from 'react-i18next';
import { canvasStyles, fireConfetti } from 'src/utils/confetti';

export function Success() {
  const { t } = useTranslation('surveys', { keyPrefix: 'submission.success' });

  function onInit({ confetti }: { confetti: confetti.CreateTypes | null }) {
    confetti ? fireConfetti(confetti, { y: 0.5 }) : null;
  }

  return (
    <VStack w="100%" spacing={5} alignItems="center">
      <Icon icon="squareRoundedCheck" color="teal.400" size={10}/>
      <Heading>{t('title')}</Heading>
      <Text textAlign="center" maxW={380}>{t('text')}</Text>
      <ReactCanvasConfetti onInit={onInit} style={canvasStyles} />
    </VStack>
  );
}