import { Box, Center, FormControl, FormLabel, HStack, Textarea, VStack, useToast } from '@chakra-ui/react';
import AddressSearch, { CitySearchParams } from '@jurnee/common/src/components/AddressSearch';
import { DatePicker } from '@jurnee/common/src/components/DatePicker';
import { TimeSlotSelector } from '@jurnee/common/src/components/TimeSlotSelector';
import { RegistrationUpsertBody } from '@jurnee/common/src/dtos/registrations';
import { DEFAULT_UTC_TIMEZONE } from '@jurnee/common/src/entities/Address';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { RegistrationJSON } from '@jurnee/common/src/entities/Registration';
import { formatAddress, getAddressFromPlaceAddressDTO } from '@jurnee/common/src/utils/addresses';
import { getCdnImageUrl } from '@jurnee/common/src/utils/core';
import { convertToTimezone, formatTimeShort, getCurrentTimeZone } from '@jurnee/common/src/utils/dates';
import { getRegistrationImagePath } from '@jurnee/common/src/utils/registrations';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateRegistration, uploadRegistrationImage } from 'src/api/registrations';
import { SecondaryButton } from 'src/components/buttons';
import { ImageUploadInput } from 'src/components/ImageUploadInput';
import { useAppDispatch } from 'src/store';
import { createRegistrationThunk } from 'src/store/registrations/registrations.thunks';
import { setHoursFromTime } from 'src/utils/date';

interface Props {
  booking: BookingJSON;
  registration: RegistrationJSON;
  onSave(registration: RegistrationJSON): void
}

function getDefaultEndDate(registration: RegistrationJSON) {
  if (!registration?.endDate) {
    return null;
  }

  return convertToTimezone(registration.endDate, registration.address.timezone, getCurrentTimeZone());
}

function getDefaultEventDate(registration: RegistrationJSON) {
  if (!registration) {
    return null;
  }

  return convertToTimezone(registration.eventDate, registration.address.timezone, getCurrentTimeZone());
}

function getDefaultStartTime(registration: RegistrationJSON) {
  if (!registration) {
    return null;
  }

  const eventDate = convertToTimezone(registration.eventDate, registration.address.timezone, getCurrentTimeZone());

  return formatTimeShort(eventDate);
}

function getEndDateUTC(date: Date) {
  if (!date) {
    return null;
  }

  return convertToTimezone(date, getCurrentTimeZone(), DEFAULT_UTC_TIMEZONE);
}

function getEventDateUTC(date: Date, time: string) {
  const eventDate = setHoursFromTime(date, time);

  return convertToTimezone(eventDate, getCurrentTimeZone(), DEFAULT_UTC_TIMEZONE);
}

export default function RegistrationForm(props: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['registration', 'common']);
  const toast = useToast();

  const [address, setAddress] = useState<RegistrationUpsertBody['address']>(null);
  const [comment, setComment] = useState<RegistrationUpsertBody['comment']>(null);
  const [endDate, setEndDate] = useState<RegistrationUpsertBody['endDate']>(null);
  const [eventDate, setEventDate] = useState<RegistrationUpsertBody['eventDate']>(null);
  const [startTime, setStartTime] = useState<string>('');
  const [image, setImage] = useState<File>(null);

  const [isSaving, setIsSaving] = useState(false);

  const isSaveDisabled = [address, eventDate, startTime].includes(null);

  useEffect(() => {
    if (props.registration) {
      const { id, additionalInformation, description, ...address } = props.registration.address;

      setAddress(address);
      setComment(props.registration.comment);
      setEndDate(getDefaultEndDate(props.registration));
      setEventDate(getDefaultEventDate(props.registration));
      setStartTime(getDefaultStartTime(props.registration));
    }
  }, [props.registration]);

  async function uploadImage(registration: RegistrationJSON) {
    return uploadRegistrationImage(
      {
        bookingId: registration.booking.id,
        registrationId: registration.id
      },
      image
    );
  }

  async function update(data: RegistrationUpsertBody) {
    try {
      const registration = await updateRegistration(
        {
          bookingId: props.registration.booking.id,
          registrationId: props.registration.id
        },
        data
      );

      if (image) {
        await uploadImage(registration);
      }

      toast(getSuccessToast(t('toasts.update.success')));

      return registration;
    } catch(error) {
      toast(getErrorToast(t('toasts.update.error')));
    }
  }

  async function create(data: RegistrationUpsertBody) {
    try {
      const registration = await dispatch(
        createRegistrationThunk({ bookingId: props.booking.id, data })
      ).unwrap();

      if (image) {
        await uploadImage(registration);
      }

      toast(getSuccessToast(t('toasts.create.success')));

      return registration;
    } catch(error) {
      toast(getErrorToast(t('toasts.create.error')));
    }
  }

  function getRegistrationBody(): RegistrationUpsertBody {
    return {
      address,
      comment,
      endDate: getEndDateUTC(endDate),
      eventDate: getEventDateUTC(eventDate, startTime)
    };
  }

  async function onSave() {
    setIsSaving(true);

    const data = getRegistrationBody();
    const registration = props.registration ? await update(data) : await create(data);

    props.onSave(registration);

    setIsSaving(false);
  }

  function handleAddressChange({ placeAddress }: CitySearchParams) {
    setAddress(getAddressFromPlaceAddressDTO(placeAddress));
  }

  function handleImageChange(image: File) {
    if (image) {
      setImage(image);
    }
  }

  const imageUrl = useMemo(() => image ? URL.createObjectURL(image) : null, [image]);

  function getImagePath() {
    if (image) {
      return imageUrl;
    }

    const path = getRegistrationImagePath(props.registration);

    return getCdnImageUrl(path);
  }

  function onChangeEndDate(date: Date) {
    setEndDate(setHoursFromTime(date, '23:59'));
  }

  return (
    <VStack w="100%" spacing={5} border="1px solid" borderColor="gray.200" borderRadius={4} bgColor="white" p={5}>
      <HStack w="100%" display="flex" alignItems="stretch" spacing={5}>
        <Center
          w="50%"
          bg={`linear-gradient(rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.30)), url('${getImagePath()}');`}
          bgPosition="center"
          bgSize="cover"
          borderRadius={4}
        >
          <ImageUploadInput
            size="sm"
            colorScheme="white"
            label={t('form.image.label')}
            imageMaxWidth={1200}
            onImageChange={handleImageChange}
          />
        </Center>

        <VStack w="50%" spacing={4}>
          <FormControl id="endDate">
            <FormLabel>{t('form.endDate.label')}</FormLabel>
            <DatePicker
              popperPlacement="top"
              dateFormat="dd MMM yyyy"
              selected={endDate}
              minDate={new Date()}
              placeholderText={t('form.endDate.placeholder')}
              onChange={onChangeEndDate}
              inputProps={{ size: 'sm' }}
            />
          </FormControl>

          <FormControl id="eventDate" isRequired>
            <FormLabel>{t('form.eventDate.label')}</FormLabel>
            <DatePicker
              popperPlacement="top"
              dateFormat="dd MMM yyyy"
              selected={eventDate}
              minDate={new Date()}
              placeholderText={t('form.eventDate.placeholder')}
              onChange={setEventDate}
              inputProps={{ size: 'sm' }}
            />
          </FormControl>

          <FormControl id="eventStartTime" isRequired>
            <FormLabel>{t('form.eventStartTime.label')}</FormLabel>
            <TimeSlotSelector
              date={eventDate || new Date()}
              value={startTime}
              duration={30}
              onPick={({ from }) => setStartTime(from)}
              placeholder={t('form.eventStartTime.placeholder')}
              size="sm"
            />
          </FormControl>
        </VStack>
      </HStack>

      <FormControl id="address" isRequired>
        <FormLabel>{t('form.address.label')}</FormLabel>

        <Box className="react-select-small">
          <AddressSearch
            defaultValue={props.registration ? formatAddress(props.registration.address) : null}
            placeholder={t('form.address.placeholder')}
            onChange={handleAddressChange}
            types='locality|administrative_area_level_3|route'
          />
        </Box>
      </FormControl>

      <FormControl id="comment">
        <FormLabel>{t('form.comment.label')}</FormLabel>
        <Textarea
          onChange={({ target }) => setComment(target.value)}
          defaultValue={comment}
          placeholder={t('form.comment.placeholder')}
          height="120px"
          resize="none"
          borderRadius={4}
        />
      </FormControl>

      <SecondaryButton size="sm" colorScheme="teal" alignSelf="flex-end" onClick={onSave} isDisabled={isSaveDisabled} isLoading={isSaving}>
        { t('buttons.save', { ns: 'common' }) }
      </SecondaryButton>
    </VStack>
  );
}