import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, HStack, VStack, useToast } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { QuestionJSON, SurveyJSON } from '@jurnee/common/src/entities/Survey';
import { QuestionAnswerJSON, SurveySubmissionJSON } from '@jurnee/common/src/entities/SurveySubmission';
import { generateSurveyAnswersCsv, getQuestionsAnswers, getSurveyAnswersCsvFilename } from '@jurnee/common/src/utils/surveys';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { cloneElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSurveySubmissions } from 'src/api/surveys';
import { useAppDispatch } from 'src/store';
import { trackEvent } from 'src/store/analytics/analytics.thunks';
import { PrimaryButton, SecondaryButton } from '../../components/buttons';
import { QuestionAnswers } from './QuestionAnswers';

interface Props {
  survey: SurveyJSON;
  children?: React.ReactElement;
}

export function SurveyAnswersDrawer(props: Props) {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { t } = useTranslation(['surveys', 'common']);

  const [isOpen, setIsOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [areSubmissionsLoading, setAreSubmissionsLoading] = useState(true);

  const [surveySubmissions, setSurveySubmissions] = useState<SurveySubmissionJSON[]>([]);

  const questionsAnswers = useMemo(
    () => getQuestionsAnswers(surveySubmissions) as Record<QuestionJSON['id'], QuestionAnswerJSON[]>,
    [surveySubmissions]
  );

  async function fetchSurveySubmissions() {
    setAreSubmissionsLoading(true);

    try {
      const { list } = await getSurveySubmissions({
        bookingId: props.survey.bookingId,
        surveyId: props.survey.id
      });

      setSurveySubmissions(list);
    } catch(error) {
      toast(getErrorToast(t('drawers.surveyAnswers.toasts.fetchSurveySubmissions.error')));
    }

    setAreSubmissionsLoading(false);
  }

  function onClose() {
    setIsOpen(false);
    setIsExporting(false);
  }

  function onOpen(event?: React.MouseEvent) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    setIsOpen(true);

    fetchSurveySubmissions();

    dispatch(trackEvent({
      name: 'opened_survey_answers_drawer'
    }));
  }

  async function handleExport() {
    setIsExporting(true);

    try {
      const csvData = generateSurveyAnswersCsv(surveySubmissions, props.survey.questions);
      const filename = getSurveyAnswersCsvFilename(props.survey.name);

      const link = document.createElement('a');
      link.href = URL.createObjectURL(new Blob([csvData], { type: 'text/csv' }));
      link.setAttribute('download', filename);
      link.click();
    } catch(error) {
      toast(getErrorToast(t('drawers.surveyAnswers.toasts.export.error'), error.message));
    }

    setIsExporting(false);
  }

  return (
    <>
      { cloneElement(props.children, { onClick: onOpen }) }

      <Drawer size="md" isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            { props.survey.name }
          </DrawerHeader>

          <DrawerBody>
            {
              areSubmissionsLoading ? (
                <Loader />
              ) : (
                <VStack w="100%" spacing={5}>
                  {
                    props.survey.questions.map(question =>
                      <QuestionAnswers
                        key={question.id}
                        question={question}
                        questionAnswers={questionsAnswers[question.id] || []}
                      />
                    )
                  }
                </VStack>
              )
            }
          </DrawerBody>

          <DrawerFooter>
            <HStack justifyContent="space-between" w="100%">
              <SecondaryButton size="sm" colorScheme="pink" onClick={onClose}>
                { t('common:buttons.close') }
              </SecondaryButton>

              <PrimaryButton size="sm" isLoading={isExporting} isDisabled={surveySubmissions.length === 0} onClick={handleExport}>
                { t('common:buttons.export') }
              </PrimaryButton>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}