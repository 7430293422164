import { UseToastOptions } from '@chakra-ui/react';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { sortByDate } from '@jurnee/common/src/utils/arrays';
import { getBookingInvoiceDateFromStatus, getInvoicesTotal } from '@jurnee/common/src/utils/bookingInvoices';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import bookingApi from 'src/api/bookings';
import BillingDocumentLine from 'src/components/BillingDocumentLine';
import { showToast } from 'src/store/toasts/toasts.thunks';

interface OwnProps {
  bookingsInvoices: BookingInvoiceJSON[];
}

interface DispatchProps {
  showToast(payload: UseToastOptions): void;
}

type Props = OwnProps & DispatchProps & WithTranslation;

class Invoices extends React.Component<Props> {

  onClickInvoice = async (bookingInvoice: BookingInvoiceJSON) => {
    try {
      const payload = await bookingApi.getBookingInvoiceFileUrl(bookingInvoice.bookingId, bookingInvoice.providerInvoiceId);

      window.open(payload.providerInvoiceFileUrl, '_blank');
    } catch(e) {
      this.props.showToast({ title: this.props.t('document.toasts.error'), status: 'error' });
    }
  };

  render() {
    const bookingsInvoices = sortByDate(this.props.bookingsInvoices.filter(({ status }) => ['DRAFT', 'OPEN', 'PAID'].includes(status)), 'finalizedAt', 'desc');

    if (bookingsInvoices.length === 0) {
      return null;
    }

    return bookingsInvoices.map(bookingInvoice => {
      const total = getInvoicesTotal([bookingInvoice]);
      const date = getBookingInvoiceDateFromStatus(bookingInvoice);
      const onClick = ['OPEN', 'PAID'].includes(bookingInvoice.status) ? () => this.onClickInvoice(bookingInvoice) : null;

      return <BillingDocumentLine
        key={bookingInvoice.providerInvoiceId}
        type='invoice'
        total={total}
        currency={bookingInvoice.currency}
        status={bookingInvoice.status}
        date={date}
        linkLabel={this.props.t('common:buttons.download')}
        onClick={onClick}
      />;
    });
  }

}

const mapDispatchToProps: DispatchProps = {
  showToast
};

export default connect<null, DispatchProps>(
  null,
  mapDispatchToProps
)(withTranslation('billing')(Invoices));