import { Input } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  label: string
  onChange(e: ChangeEvent<HTMLInputElement>): void;
}

export function LabelInput(props: Props) {
  const { t } = useTranslation('surveys', { keyPrefix: 'drawers.survey.form.question.label' });

  return (
    <Input
      size="sm"
      placeholder={t('placeholder')}
      value={props.label || ''}
      onChange={props.onChange}
    />
  );
}