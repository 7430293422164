import { Badge, HStack, Text } from '@chakra-ui/react';

interface Props {
  label: string;
  count?: number;
}

export function TabLabel({ label, count }: Props) {
  if (!count || count < 0) {
    return label;
  }

  return (
    <HStack spacing={2}>
      <Text>{label}</Text>
      <Badge variant="pink" size="md">{count}</Badge>
    </HStack>
  );
}