import { Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  type: 'BUDGET' | 'TEAM';
  headcount: number;
}

export function PeopleHeadcount({ type, headcount }: Props) {
  const { t } = useTranslation('bookings', { keyPrefix: 'budgetsOverview' });

  const label = t(type === 'BUDGET' ? 'organizers' : 'people');

  return (
    <VStack spacing={2} alignItems="flex-end">
      <Text lineHeight="14px" fontWeight={700}>{label}</Text>

      <Text lineHeight="14px" color="gray.400">{headcount}</Text>
    </VStack>
  );
}