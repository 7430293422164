import { FormControl, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import { cloneElement, useState } from 'react';
import InputCopy from 'src/components/InputCopy';
import { useAppDispatch } from 'src/store';
import { trackEvent } from 'src/store/analytics/analytics.thunks';

interface Props {
  link: string;
  title: string;
  formLabel?: string;
  description?: string
  eventName: string;
  children: React.ReactElement;
}

export function ShareLinkModal(props: Props) {
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState(false);

  function onClose() {
    setIsOpen(false);
  }

  function onOpen() {
    setIsOpen(true);

    dispatch(trackEvent({
      name: `opened_${props.eventName}_drawer`
    }));
  }

  return (
    <>
      { cloneElement(props.children, { onClick: onOpen }) }

      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent borderRadius={8} overflow="hidden">
          <ModalHeader>
            { props.title }
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody>
            { props.description && <Text color="gray.400" mb={2}>{props.description}</Text> }
            <FormControl id="link">
              { props.formLabel && <FormLabel>{props.formLabel}</FormLabel> }
              <InputCopy value={props.link} isLink={true} />
            </FormControl>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}