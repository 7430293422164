import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import * as React from 'react';

export default class InvitationButton extends React.PureComponent<IconButtonProps> {

  render() {
    return (
      <IconButton
        borderRadius={8}
        onClick={this.props.onClick}
        background="purple.50"
        _hover={{ background: 'purple.100' }}
        size="sm"
        icon={<Icon icon="sendMail" size={5} color="purple.500" />}
        {...this.props}
      />
    );
  }

}