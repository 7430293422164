import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { withTranslation } from 'react-i18next';
import BaseStatusBadge, { StatusBadgeProps } from './index';

type Props = {
  status: BookingInvoiceJSON['status'];
}

class StatusBadge extends BaseStatusBadge<StatusBadgeProps & Props> {

  get label() {
    switch (this.props.status) {
    case 'DRAFT': return this.props.t('pending');
    case 'OPEN': return this.props.t('open');
    case 'PAID': return this.props.t('paid');
    default:
      return this.props.t('pending');
    }
  }

  get variant() {
    switch (this.props.status) {
    case 'PAID':
      return 'teal';
    case 'OPEN':
      return 'pink';
    case 'DRAFT':
      return 'blue';
    default:
      return 'white';
    }
  }

}

export const BookingInvoiceStatus = withTranslation('common', { keyPrefix: 'status' })(StatusBadge);