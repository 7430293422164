import { getLanguages } from '@jurnee/common/src/api/languages';
import { LanguagesList } from '@jurnee/common/src/entities/Language';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';

export const getLanguagesThunk = createAsyncThunk<LanguagesList, null, { state: RootState }>('LANGUAGES_FETCH', async (args, thunkAPI) => {
  try {
    const languages = await getLanguages();

    return languages;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while retrieving languages`, status: 'error' }));
    return thunkAPI.rejectWithValue(e.message);
  }
});
