import { HStack, Text } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { useTranslation } from 'react-i18next';

export function BudgetStatus({ outBudgetCount }: { outBudgetCount: number }) {
  const { t } = useTranslation('booking', { keyPrefix: 'budget.budgetIndividualCard' });

  if (outBudgetCount > 0) {
    return (
      <HStack spacing={2}>
        <Icon icon="warning" color="red.500" size={4} />

        <Text color="gray.400" lineHeight="14px">
          { t('outOfBudget', { count: outBudgetCount }) }
        </Text>
      </HStack>
    );
  }

  return (
    <HStack spacing={2}>
      <Icon icon="circleCheckFill" color="teal.400" size={4} />

      <Text color="gray.400" lineHeight="14px">
        { t('withinBudget') }
      </Text>
    </HStack>
  );
}