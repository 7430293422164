import { VStack, useDisclosure, useToast } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { SurveyJSON } from '@jurnee/common/src/entities/Survey';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteSurvey, getSurveys } from 'src/api/surveys';
import { PrimaryButton } from 'src/components/buttons';
import { SurveyDrawer } from 'src/drawers/SurveyDrawer';
import { SurveysTable } from './SurveysTable';

interface Props {
  booking: BookingJSON;
}

export function Surveys({ booking }: Props) {
  const toast = useToast();
  const { t } = useTranslation('surveys');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [surveys, setSurveys] = useState<SurveyJSON[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchSurveys() {
    const { list } = await getSurveys({ bookingId: booking.id });

    setSurveys(list);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchSurveys();
  }, []);

  async function onDelete(survey: SurveyJSON) {
    try {
      const deletedSurvey = await deleteSurvey({ bookingId: booking.id, surveyId: survey.id });

      setSurveys(surveys.filter(survey => survey.id !== deletedSurvey.id));

      toast(getSuccessToast(t('toasts.deleteSurvey.success')));
    } catch(error) {
      toast(getErrorToast(t('toasts.deleteSurvey.error'), error.message));
    }
  }

  function onUpdate(updatedSurvey: SurveyJSON) {
    setSurveys(surveys.map(survey => survey.id === updatedSurvey.id ? updatedSurvey : survey));
  }

  if (isLoading) {
    return <Loader h={400} />;
  }

  return (
    <VStack w="100%" spacing={5}>
      <SurveyDrawer
        key={new Date().getTime()}
        bookingId={booking.id}
        isOpen={isOpen}
        onClose={onClose}
        onCreate={survey => setSurveys([...surveys, survey])}
      >
        <PrimaryButton size="sm" colorScheme="black" alignSelf="flex-end" onClick={onOpen}>
          { t('createSurvey') }
        </PrimaryButton>
      </SurveyDrawer>

      <SurveysTable
        surveys={surveys}
        bookingCuid={booking.cuid}
        onDelete={onDelete}
        onUpdate={onUpdate}
      />
    </VStack>
  );
}