import { UseToastOptions } from '@chakra-ui/react';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { QuoteJSON } from '@jurnee/common/src/entities/Quote';
import { sortByDate } from '@jurnee/common/src/utils/arrays';
import { getInvoicesTotal } from '@jurnee/common/src/utils/bookingInvoices';
import { getQuoteDateFromStatus } from '@jurnee/common/src/utils/quotes';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import api from 'src/api/bookings';
import BillingDocumentLine from 'src/components/BillingDocumentLine';
import { QuoteCancelPayload, QuoteDownloadPayload, cancelQuote, downloadQuote } from 'src/store/quotes/quotes.thunks';
import { showToast } from 'src/store/toasts/toasts.thunks';

interface OwnProps {
  quotes: QuoteJSON[];
  bookingsInvoices: BookingInvoiceJSON[];
}

interface DispatchProps {
  showToast(payload: UseToastOptions): void;
  downloadQuote(payload: QuoteDownloadPayload): void;
  cancelQuote(payload: QuoteCancelPayload): void;
}

type Props = OwnProps & DispatchProps & WithTranslation;

class Quotes extends React.Component<Props> {

  downloadQuote(quote: QuoteJSON) {
    this.props.downloadQuote({
      bookingId: quote.bookingId,
      quoteId: quote.id,
      onError: () => {
        this.props.showToast({ title: this.props.t('billing:document.toasts.error'), status: 'error' });
      }
    });
  }

  cancelQuote(quote: QuoteJSON) {
    this.props.cancelQuote({
      bookingId: quote.bookingId,
      quoteId: quote.id,
      onSuccess: () => {
        this.props.showToast({ title: this.props.t('quotes.toasts.delete.success'), status: 'success' });
      },
      onError: () => {
        this.props.showToast({ title: this.props.t('quotes.toasts.delete.error'), status: 'error' });
      }
    });
  }

  async openQuote(quote: QuoteJSON) {
    try {
      const { url } = await api.getQuoteLink(quote.bookingId, quote.id);

      if (url) {
        window.open(url, '_blank');
      } else {
        throw new Error('URL not found');
      }
    } catch (err) {
      this.props.showToast({ title: this.props.t('quotes.toasts.open.error'), status: 'error' });
    }
  }

  onClick(quote: QuoteJSON) {
    if (quote.status === 'INITIAL' && quote.providerMemberId) {
      this.openQuote(quote);
    } else {
      this.downloadQuote(quote);
    }
  }

  render() {
    const quotes = sortByDate(this.props.quotes.filter(({ status }) => status !== 'CANCELED'), 'createdAt', 'desc');

    if (quotes.length === 0) {
      return null;
    }

    return quotes.map(quote => {
      const bookingInvoice = this.props.bookingsInvoices.find(({ providerInvoiceId }) => providerInvoiceId === quote.providerInvoiceId);

      const total = getInvoicesTotal([bookingInvoice]);
      const sublabel = this.props.t(`quotes.${quote.status}.sublabel`, { userLabel: getUserLabel(quote.recipient) });
      const date = getQuoteDateFromStatus(quote);

      const linkLabel = this.props.t(`common:buttons.${quote.status === 'INITIAL' ? 'view' : 'download'}`);
      const onClick = quote.status === 'CANCELED' ? null : () => this.onClick(quote);

      const deleteLinkLabel = this.props.t('common:buttons.delete');
      const onDelete = quote.status !== 'INITIAL' ? null : () => this.cancelQuote(quote);

      return <BillingDocumentLine
        key={quote.id}
        type='quote'
        total={total}
        currency={bookingInvoice.currency}
        status={quote.status}
        date={date}
        sublabel={sublabel}
        linkLabel={linkLabel}
        deleteLinkLabel={deleteLinkLabel}
        onClick={onClick}
        onDelete={onDelete}
      />;
    });
  }

}

const mapDispatchToProps: DispatchProps = {
  cancelQuote,
  downloadQuote,
  showToast,
};

export default connect<null, DispatchProps>(
  null,
  mapDispatchToProps
)(withTranslation('booking')(Quotes));