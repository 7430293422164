import { Badge, BadgeProps } from '@chakra-ui/react';
import * as React from 'react';
import { WithTranslation } from 'react-i18next';

interface Props extends BadgeProps {
  status: string;
}

export type StatusBadgeProps = Props & WithTranslation;

export default abstract class BaseStatusBadge<T extends StatusBadgeProps> extends React.PureComponent<T> {

  abstract get label(): string;
  abstract get variant(): string;

  render() {
    const { mt, boxShadow } = this.props;

    return (
      <Badge boxShadow={boxShadow} mt={mt} variant={this.variant}>
        {this.label}
      </Badge>
    );
  }

}