import { Link, Td, Text, Tr, useDisclosure } from '@chakra-ui/react';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { TaskJSON } from '@jurnee/common/src/entities/Task';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import { useTranslation } from 'react-i18next';
import { Link as ReactLink, useLocation } from 'react-router-dom';
import { TaskDotMenu } from 'src/components/TaskDotMenu';
import { TaskDueDate } from 'src/components/TaskDueDate';
import { TaskStatus } from 'src/components/TaskStatus';
import { TaskDrawer } from 'src/drawers/TaskDrawer';

interface Props {
  booking: BookingJSON;
  task: TaskJSON;
  organizers: UserJSON[];
  onDelete(task: TaskJSON): void;
  onUpdate(task: TaskJSON): void;
}

function getAssigneeLabel(task: TaskJSON, users: UserJSON[]) {
  const { t } = useTranslation('common');

  if (task.assigneeType === 'JURNEE') {
    return t('jurneeTeam');
  }

  const user = users.find(user => user.id === task.assigneeId);

  return user ? getUserLabel(user) : '-';
}

export function TaskRow({ booking, task, organizers, onDelete, onUpdate }: Props) {
  const location = useLocation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Tr cursor="pointer" onClick={onOpen}>
      <Td>
        <Link color="blue.400" to={`/bookings/${booking.id}?tab=collaboration`} as={ReactLink} state={{ prevPath: location.pathname }}>
          { booking.name }
        </Link>
      </Td>
      <Td>{task.name}</Td>
      <Td maxW={1}><Text isTruncated>{getAssigneeLabel(task, organizers)}</Text></Td>
      <Td><TaskDueDate task={task} /></Td>
      <Td><TaskStatus status={task.status} /></Td>
      <Td pl={0} pr={3} textAlign="right">
        <TaskDotMenu task={task} onDelete={onDelete} />
      </Td>
      <Td hidden>
        <TaskDrawer
          key={task.id}
          booking={booking}
          task={task}
          organizers={organizers}
          isOpen={isOpen}
          onClose={onClose}
          onUpsert={onUpdate}
        />
      </Td>
    </Tr>
  );
}