import { Heading, VStack } from '@chakra-ui/react';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { groupByMonth } from '@jurnee/common/src/utils/bookings';
import { useTranslation } from 'react-i18next';
import EmptyState from 'src/components/EmptyState';
import { BookingsTabs as Tabs } from 'src/utils/booking';
import BookingsCalendarMonth from './Month';

interface Props {
  bookings: BookingJSON[];
  relationships: BookingRelationshipsJSON;
  tab: Tabs;
}

export default function BookingsCalendar(props: Props) {
  const { t } = useTranslation('bookings');
  const groupedBookings = groupByMonth(props.bookings);
  const months = Object.keys(groupedBookings).map(Number).sort();

  if (months.length === 0) {
    return (
      <VStack w="100%" spacing={3} alignItems="flex-start">
        <Heading size="md" lineHeight="32px">{t(`list.${props.tab}`)}</Heading>

        <EmptyState
          imagePath="/assets/illustrations/calendar_checked.svg"
          description={t(`emptyState.calendar.description`)}
          heading={t(`emptyState.calendar.heading`)}
        />
      </VStack>
    );
  }

  return (
    <VStack w="100%" spacing={3} alignItems="flex-start">
      { months.map(time => <BookingsCalendarMonth key={time} time={time} bookings={groupedBookings[time]} relationships={props.relationships} />) }
    </VStack>
  );

}