import { api } from '@jurnee/common/src/browser/Api';
import { BudgetJSON, BudgetRelationshipsJSON } from '@jurnee/common/src/entities/Budget';
import { Experience, ExperienceJSON, ExperienceRelationshipsJSON } from '@jurnee/common/src/entities/Experience';
import { TaskJSON, TaskRelationshipsJSON } from '@jurnee/common/src/entities/Task';
import { TeamJSON, TeamRelationshipsJSON } from '@jurnee/common/src/entities/Team';
import { UserDetails, UserStatsJSON, UserUpdate } from '@jurnee/common/src/entities/User';
import { List } from '@jurnee/common/src/serializers';

export default {
  getUser(userId: UserDetails['id']): Promise<UserDetails> {
    return api.get(`users/${userId}`);
  },
  update(userId: UserDetails['id'], data: UserUpdate): Promise<UserDetails> {
    return api.put(`users/${userId}`, data);
  },
  getFavoriteExperiences(userId: UserDetails['id']): Promise<List<ExperienceJSON, ExperienceRelationshipsJSON>> {
    return api.get(`users/${userId}/favorite-experiences`);
  },
  addToFavoriteExperiences(userId: UserDetails['id'], experienceId: Experience['id']) {
    return api.post(`users/${userId}/favorite-experiences/${experienceId}`);
  },
  removeFromFavoriteExperiences(userId: UserDetails['id'], experienceId: Experience['id']) {
    return api.delete(`users/${userId}/favorite-experiences/${experienceId}`);
  },
  getTeams(userId: UserDetails['id']): Promise<List<TeamJSON, TeamRelationshipsJSON>> {
    return api.get(`users/${userId}/teams`);
  },
  getBudgets(userId: UserDetails['id']): Promise<List<BudgetJSON, BudgetRelationshipsJSON>> {
    return api.get(`users/${userId}/budgets`);
  },
  getUserStats(id: UserDetails['id']): Promise<UserStatsJSON> {
    return api.get(`/users/${id}/stats`);
  },
  getUserTasks(id: UserDetails['id']): Promise<List<TaskJSON, TaskRelationshipsJSON>> {
    return api.get(`users/${id}/tasks`);
  },
  getUserIntercomHash(id: UserDetails['id']): Promise<{ userHash: string }> {
    return api.get(`users/${id}/intercom`);
  }
};