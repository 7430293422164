import { useToast } from '@chakra-ui/react';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import { RootState } from 'src/store/state';
import api from '../api/user';

interface Props {
  user: RootState['user'];
  company: RootState['company'];
}

function Help({ company, user }: Props): JSX.Element {
  const { boot } = useIntercom();
  const toast = useToast();
  const { t } = useTranslation('navbar');

  const [userHash, setUserHash] = useState(null);

  async function fetchUserHash() {
    try {
      const { userHash } = await api.getUserIntercomHash(user.id);
      setUserHash(userHash);
    } catch(e) {
      toast(getErrorToast(t('toasts.fetchUserHash.error')));
    }
  }

  useEffect(() => {
    fetchUserHash();
  }, []);

  if (!userHash) {
    return;
  }

  boot({
    alignment: 'left',
    horizontalPadding: 20,
    verticalPadding: 75,
    name: `${user.firstName} ${user.lastName}`,
    hideDefaultLauncher: true,
    userId: user.id.toString(),
    userHash,
    email: user.email,
    company: {
      companyId: company.data.id.toString(),
      name: company.data.name
    },
    customLauncherSelector: '#intercom-launcher'
  });

  return null;
}


function mapStateToProps(state: RootState): Props {
  return {
    user: state.user,
    company: state.company
  };
}

export default connect<Props, void>(mapStateToProps)(Help);