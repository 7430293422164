import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { BudgetBreakdownJSON } from '@jurnee/common/src/entities/BudgetBreakdown';
import { createAsyncThunk } from '@reduxjs/toolkit';
import budgetsApi from '../../api/budgets';
import { RootState } from '../state';

export const getBudgetBreakdownByBookingIdAndBudgetId = createAsyncThunk<BudgetBreakdownJSON, { bookingId: BookingJSON['id'], budgetId: BudgetJSON['id'] }, { state: RootState }>('GET_BOOKING_BUDGET_BREAKDOWN', async (args, thunkAPI) => {
  try {
    const budgetBreakdown = await budgetsApi.getBudgetBreakdownByBookingIdAndBudgetId(args.bookingId, args.budgetId);
    return budgetBreakdown;
  } catch (e) {
    return thunkAPI.rejectWithValue({ message: e.message });
  }
});