import { chakra, FormControl, FormLabel, Heading, HStack, Input, Switch, Text } from '@chakra-ui/react';
import { ReviewCreateBody } from '@jurnee/common/src/dtos/reviews';
import { isEmpty } from '@jurnee/common/src/utils/strings';
import * as React from 'react';
import ItemReviewContainer from './ItemReviewContainer';

interface OwnProps {
  onNext(data: ReviewCreateBody): void;
}

interface State {
  authorName: string;
  isPublic: boolean;
}

export default class ReviewAuthor extends React.Component<OwnProps> {

  state: State = {
    authorName: null,
    isPublic: true
  };

  handleNext = () => {
    this.props.onNext(this.state);
  };

  handleAuthorName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, authorName: isEmpty(e.target.value) ? null : e.target.value });
  };

  handleIsPublic = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, isPublic: e.target.checked });
  };

  render() {
    return (
      <ItemReviewContainer buttonLabel="Next" spacing={5} onNext={this.handleNext}>
        <Heading size="md">What's your name?</Heading>

        <FormControl id="authorName">
          <FormLabel>Full name <chakra.span fontWeight="medium" color="gray.400">(optional)</chakra.span></FormLabel>
          <Input size="sm" autoFocus placeholder="Name" name="authorName" onChange={this.handleAuthorName}/>
        </FormControl>

        <Text fontSize="sm" fontStyle="italic" color="gray.400">
            Your name will be visible to the event organizer as well as in the public reviews of the activity if you agree.
        </Text>

        <FormControl id="isPublic">
          <HStack>
            <Switch size="sm" id='isPublic' onChange={this.handleIsPublic} defaultChecked={this.state.isPublic} />
            <Text fontWeight={700}>I agree that my review is publicly visible</Text>
          </HStack>
        </FormControl>
      </ItemReviewContainer>
    );
  }

}