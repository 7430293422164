import { HStack, Image, StackProps, Text, VStack } from '@chakra-ui/react';
import { StatusBadge } from '@jurnee/common/src/components/StatusBadge';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/buttons';

interface Props {
  name: string;
  isLoading: boolean;
  isConnected: boolean;
  onConnect(): void;
}

export function IntegrationCard({ name, isLoading, isConnected, onConnect, ...inheritedProps }: Props & StackProps) {
  const { t } = useTranslation(['settings', 'common']);

  return (
    <VStack spacing={4} border="1px solid" borderColor="gray.200" borderRadius={4} bgColor="white" p={5} {...inheritedProps}>
      <HStack w="100%" justifyContent="space-between">
        <HStack spacing={3}>
          <Image src={`/assets/images/${name}.svg`} w={6} h={6} />

          <Text fontSize={16} fontWeight={700}>
            { t(`integrations.${name}.label`) }
          </Text>
        </HStack>

        {
          isConnected ? (
            <StatusBadge
              label={t('integrations.connected')}
              color='teal'
            />
          ) : (
            <PrimaryButton size="xs" colorScheme="black" onClick={onConnect} isLoading={isLoading}>
              { t('common:buttons.connect') }
            </PrimaryButton>
          )
        }
      </HStack>

      <Text color="gray.400">
        { t(`integrations.${name}.description`) }
      </Text>
    </VStack>
  );
}