import { VStack } from '@chakra-ui/react';
import { QuestionUpsertBody } from '@jurnee/common/src/dtos/surveys';
import { getTmpId } from '@jurnee/common/src/utils/strings';
import { AddOptionButton } from './AddOptionButton';
import { QuestionOptionForm } from './QuestionOptionForm';

interface Props {
  type: QuestionUpsertBody['type'];
  questionOptions: QuestionUpsertBody['questionOptions'];
  onChange(questionOptions: QuestionUpsertBody['questionOptions']): void;
}

export function QuestionOptions(props: Props) {
  function onAdd() {
    const newQuestionOptions = [...props.questionOptions, { id: getTmpId(), value: '' }];
    props.onChange(newQuestionOptions);
  }

  function onDelete(index: number) {
    const newQuestionOptions = props.questionOptions.filter((_, i) => i !== index);
    props.onChange(newQuestionOptions);
  }

  function onChange(index: number, value: string) {
    const newQuestionOptions = props.questionOptions.map((questionOption, i) => {
      if (i === index) {
        return { ...questionOption, value };
      }

      return questionOption;
    });

    props.onChange(newQuestionOptions);
  }

  return (
    <VStack spacing={2} w="100%" pl={2}>
      {
        props.questionOptions.map((questionOption, i) =>
          <QuestionOptionForm
            key={questionOption.id}
            questionOption={questionOption}
            index={i}
            onChange={onChange}
            onDelete={onDelete}
          />
        )
      }

      <AddOptionButton onAdd={onAdd} />
    </VStack>
  );
}