import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { getBookingsInvoicesToPay } from '@jurnee/common/src/utils/bookingInvoices';
import { RootState } from '../state';

export function getBookingFetchStatusSelector(state: RootState) {
  return state.bookingDetails.status;
}

function getIsBookingDetailsFetched(state: RootState) {
  return state.bookingDetails.status === FETCH_STATUS.FETCHED;
}

export function getBookingDetailsSelector(state: RootState) {
  return getIsBookingDetailsFetched(state) ? state.bookingDetails.data : null;
}

export function getBookingDetailsRelationshipsSelector(state: RootState) {
  return getIsBookingDetailsFetched(state) ? state.bookingDetails.relationships : null;
}

export function getBookingDetailsEntitySelector(state: RootState) {
  if (!getIsBookingDetailsFetched(state)) {
    return null;
  }

  return state.entities.list.find(({ id }) => id === state.bookingDetails.data.entityId);
}

export function getBookingDetailsInvoiceToPay(state: RootState) {
  if (!getIsBookingDetailsFetched(state)) {
    return null;
  }

  const { bookingDetails: { relationships: { bookingsInvoices, quotes } } } = state;

  return getBookingsInvoicesToPay(bookingsInvoices, quotes).pop() as BookingInvoiceJSON;
}

export function getBookingDetailsPropositionsGroup(state: RootState) {
  if (!getIsBookingDetailsFetched(state)) {
    return null;
  }

  return state.bookingDetails.relationships.propositionsGroups[0];
}