import {
  Center,
  Circle,
  HStack,
  Spacer,
  Text
} from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import * as React from 'react';

interface OwnProps {
  disableFirstRankedBadge: boolean;
  index: number;
  upvotes: number;
}

export default class CollectionExperienceRank extends React.PureComponent<OwnProps> {

  get isFirstRanked() {
    return !this.props.disableFirstRankedBadge && this.props.index === 0 && this.props.upvotes > 0;
  }

  get badgeColor() {
    return this.isFirstRanked ? 'black' : 'gray.200';
  }

  get textColor() {
    return this.isFirstRanked ? 'white' : 'gray.400';
  }

  get crownIcon() {
    if(!this.isFirstRanked) {
      return null;
    }

    return (
      <Circle position="absolute" top="-12px" right="-12px" size="24px" bgColor="orange.300">
        <Icon icon='crown' color="white" size={4} />
      </Circle>
    );
  }

  render() {
    return (
      <HStack position="relative" spacing={0}>
        <HStack spacing={0} w="110px" borderRadius={8}>
          <Center borderStartRadius={8} h="48px" w="40px" bgColor={this.badgeColor}>
            <Text color={this.textColor} fontSize={15} fontWeight={700}>
            #{this.props.index + 1}
            </Text>
          </Center>
          <Center borderEndRadius={8} h="48px" w="70px" bgColor="white" border="1px solid" borderColor={this.badgeColor}>
            <Spacer />
            <HStack spacing={1} paddingEnd={1}>
              <Text fontSize={24} fontWeight={700} mr="-6px">
                {this.props.upvotes}
              </Text>
              <Icon icon="upvote" color="teal.300" />
            </HStack>
          </Center>
        </HStack>
        {this.crownIcon}
      </HStack>
    );
  }

}