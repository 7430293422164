import { QuoteJSON } from '../entities/Quote';

export function areAllQuotesSigned(quotes: Pick<QuoteJSON, 'status'>[]): boolean {
  const eligibleQuotes = quotes.filter(({ status }) => status !== 'CANCELED');

  if (eligibleQuotes.length === 0) {
    return false;
  }

  return eligibleQuotes.every(({ status }) => {
    return status === 'SIGNED';
  });
}

export function getQuoteDateFromStatus(quote: QuoteJSON) {
  switch(quote.status) {
  case 'SIGNED':
    return new Date(quote.acceptedAt as string);
  case 'CANCELED':
    return new Date(quote.canceledAt as string);
  default:
    return new Date(quote.createdAt);
  }
}

export function hasInitialStatus(quote: QuoteJSON): boolean {
  return quote.status === 'INITIAL';
}