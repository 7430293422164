import { Box, HStack, Heading, Tab, TabList } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { TabItem } from '.';

interface Props {
  tabs: TabItem[];
}

export function ApprovalRequestsHeader({ tabs }: Props) {
  const { t } = useTranslation('approvalRequests', { keyPrefix: 'header' });

  return (
    <Box bg="white" w="100%">
      <HStack justifyContent="space-between" p={8} borderColor="blue.50">
        <Heading w="100%">{t('title')}</Heading>
      </HStack>

      <TabList>
        { tabs.map(({ key, label }) => <Tab key={key}>{label}</Tab>) }
      </TabList>
    </Box>
  );
}