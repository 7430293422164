import { FormControl, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { Booking } from '@jurnee/common/src/entities/Booking';
import * as React from 'react';
import { connect } from 'react-redux';
import InputCopy from 'src/components/InputCopy';
import { PrimaryButton, SecondaryButton } from '../components/buttons';
import { TrackEventOptions, trackEvent } from '../store/analytics/analytics.thunks';

interface OwnProps {
  bookingCuid: Booking['cuid'];
}

interface State {
  isOpen: boolean;
}

interface DispatchProps {
  trackEvent(opts: TrackEventOptions): void;
}

class ShareReviewFormModal extends React.PureComponent<OwnProps & DispatchProps, State> {

  state: State = {
    isOpen: false
  };

  onClose = () => {
    this.setState({ isOpen: false });
  };

  onOpen = () => {
    this.setState({ isOpen: true });

    this.props.trackEvent({
      name: 'opened_share_review_link_modal'
    });
  };

  get link() {
    return `${window.location.origin}/bookings/${this.props.bookingCuid}/reviews`;
  }

  get header() {
    return (
      <ModalHeader>
        Send to my team
        <ModalCloseButton />
      </ModalHeader>
    );
  }

  get body() {
    return (
      <ModalBody textAlign="center">
        <FormControl id="link">
          <FormLabel>Send a link</FormLabel>
          <InputCopy value={this.link} isLink={true} />
        </FormControl>
      </ModalBody>
    );
  }

  get footer() {
    return (
      <ModalFooter>
        <SecondaryButton colorScheme="pink" onClick={this.onClose} size="sm">
          Close
        </SecondaryButton>
      </ModalFooter>
    );
  }

  render() {
    return (
      <>
        <Modal isOpen={this.state.isOpen} onClose={this.onClose} size="md">
          <ModalOverlay />
          <ModalContent>
            {this.header}
            {this.body}
            {this.footer}
          </ModalContent>
        </Modal>
        <PrimaryButton size="sm" onClick={this.onOpen}>
          Share review form
        </PrimaryButton>
      </>
    );
  }

}

const mapDispatchToProps: DispatchProps = {
  trackEvent
};

export default connect<null, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps
)(ShareReviewFormModal);