import { UseToastOptions } from '@chakra-ui/react';
import { getPlacesAutoComplete } from '@jurnee/common/src/api/places';
import { Option, SearchSelect } from '@jurnee/common/src/components/Select';
import { PlaceDTO } from '@jurnee/common/src/dtos/places';
import { BookingItemTypeJSON } from '@jurnee/common/src/entities/BookingItemType';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { InputActionMeta } from 'react-select';
import { showToast } from 'src/store/toasts/toasts.thunks';
import { getPlaceLabel } from 'src/utils/places';

interface OwnProps {
  sessiontoken: string;
  latitude: number;
  longitude: number;
  placeholder: string;
  currentValue: { label: string, value: string };
  isDisabled: boolean;
  itemType?: BookingItemTypeJSON['key'];
  onChange(place: PlaceDTO): void;
}

interface DispatchProps {
  showToast(payload: UseToastOptions): void;
}

interface State {
  places: PlaceDTO[];
}

type Props = OwnProps & WithTranslation & DispatchProps;

class PlaceSearch extends React.PureComponent<Props, State> {

  state: State = {
    places: [],
  };

  inputTimout: NodeJS.Timeout = null;

  onInputChange = (input: string, { prevInputValue }: InputActionMeta) => {
    this.setState({ ...this.state, places: [] });

    if (!input || input === prevInputValue) {
      return;
    }

    clearTimeout(this.inputTimout);

    this.inputTimout = setTimeout(async () => {
      try {
        const places = await getPlacesAutoComplete({
          input,
          isDetailed: true,
          types: this.autocompleteTypes,
          sessiontoken: this.props.sessiontoken,
          location: {
            lat: this.props.latitude,
            long: this.props.longitude,
            radius: 50000
          }
        });

        this.setState({ ...this.state, places: places.list });
      } catch(err) {
        const title = this.props.t('placeSearch.error');
        this.props.showToast({ title, status: 'error' });
      }
    }, 500);
  };

  onChange = async (data: { label: string, value: string }) => {
    try {
      const place = this.state.places.find(({ id }) => id === data.value);
      this.props.onChange(place);
    } catch(err) {
      const title = this.props.t('placeSearch.error');
      this.props.showToast({ title, status: 'error' });
    }
  };

  get autocompleteTypes() {
    switch(this.props.itemType) {
    case 'BAR':
      return 'bar|cafe|night_club';
    case 'RESTAURANT':
      return 'restaurant';
    default:
      return 'establishment';
    }
  }

  get options() {
    return this.state.places.map(({ details }) => {
      return {
        label: getPlaceLabel(details),
        value: details.id,
        img: details.photos.length > 0 ? details.photos[0] : null
      };
    });
  }

  render() {
    return <SearchSelect<PlaceDTO['id']>
      options={this.options}
      value={this.props.currentValue}
      openMenuOnFocus={false}
      maxMenuHeight={200}
      placeholder={this.props.placeholder}
      onInputChange={this.onInputChange}
      onChange={this.onChange}
      noOptionsMessage={() => null}
      filterOption={() => true}
      components={{ Option, IndicatorsContainer: () => null }}
      isDisabled={this.props.isDisabled}
    />;
  }

}

const mapDispatchToProps: DispatchProps = {
  showToast,
};

export default connect<null, DispatchProps>(
  null,
  mapDispatchToProps
)(withTranslation('experiences')(PlaceSearch));