import './store/analytics/analytics';

import { ChakraProvider } from '@chakra-ui/react';
import theme from '@jurnee/common/src/theme';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import store from './store';

const container = document.getElementById('app-container');

const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  </Provider>
);