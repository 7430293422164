import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import * as React from 'react';

export default class FavoriteButton extends React.PureComponent<IconButtonProps> {

  render() {
    return (
      <IconButton
        borderRadius={4}
        onClick={this.props.onClick}
        bg="white"
        _hover={{ background: 'whiteAlpha.800' }}
        _active={{ background: 'white' }}
        size="sm"
        icon={<Icon icon={this.props.isActive ? 'heartFill' : 'heartLine'} size={5} color={this.props.isActive ? 'pink.500' : 'black'} />}
        { ...this.props }
      />
    );
  }

}