import { ButtonProps, Input, LinkProps } from '@chakra-ui/react';
import { resizeImage } from '@jurnee/common/src/utils/images';
import { useRef } from 'react';
import { SecondaryButton } from 'src/components/buttons';

interface Props {
  label: string;
  imageMaxWidth: number;
  onImageChange(image: File): void;
}

export function ImageUploadInput({ label, imageMaxWidth, onImageChange, ...inheritedProps }: Props & ButtonProps & LinkProps) {
  const imageInput = useRef(null);

  function handleClick() {
    imageInput.current.click();
  }

  async function onChange({ target }: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const [image] = target.files;

    if (!image) {
      return null;
    }

    const resizedImage = await resizeImage(image, imageMaxWidth);

    onImageChange(resizedImage);
  }

  return (
    <>
      <SecondaryButton {...inheritedProps} onClick={handleClick}>{label}</SecondaryButton>
      <Input type="file" accept="image/png, image/jpeg, image/jpg" onChange={onChange} ref={imageInput} hidden />
    </>
  );
}