import { CurrencyExchangeRates } from '../entities/Currency';
import ExternalCostEntity from '../entities/ExternalCost';
import { getConversionRate } from './currencies';
import { DateRange, isInDateRange } from './dates';

export function getExternalCostsTotal(externalCosts: Pick<ExternalCostEntity, 'amount' | 'currency' | 'paymentDate'>[], currencies: CurrencyExchangeRates, targetId: string, range?: DateRange) {
  let total = 0;

  for (const { amount, currency, paymentDate } of externalCosts) {
    if (range && !isInDateRange(paymentDate, range)) {
      continue;
    }

    total += amount * getConversionRate(currency, targetId, currencies);
  }

  return parseFloat(total.toFixed(2));
}