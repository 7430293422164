import { api } from '@jurnee/common/src/browser/Api';
import { BudgetUpdateBody } from '@jurnee/common/src/dtos/budgets';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BudgetJSON, BudgetRelationshipsJSON } from '@jurnee/common/src/entities/Budget';
import { BudgetBreakdownJSON } from '@jurnee/common/src/entities/BudgetBreakdown';
import { BudgetSpendingStatementItemJSON } from '@jurnee/common/src/entities/BudgetSpendingStatement';
import { UserBudgetBreakdownJSON } from '@jurnee/common/src/entities/UserBudgetBreakdown';
import { YearlyBudgetJSON } from '@jurnee/common/src/entities/YearlyBudget';
import { Expand, List } from '@jurnee/common/src/serializers';

export default {
  getBudgets(): Promise<List<BudgetJSON, BudgetRelationshipsJSON>> {
    return api.get('budgets');
  },
  createBudget(data: BudgetUpdateBody): Promise<Expand<BudgetJSON, BudgetRelationshipsJSON>> {
    return api.post('budgets', data);
  },
  getYearlyBudget(): Promise<YearlyBudgetJSON> {
    return api.get('budgets/yearly');
  },
  updateBudget(data: BudgetUpdateBody, id: BudgetJSON['id']): Promise<Expand<BudgetJSON, BudgetRelationshipsJSON>> {
    return api.put(`budgets/${id}`, data);
  },
  deleteBudget(id: BudgetJSON['id']): Promise<BudgetJSON> {
    return api.delete(`budgets/${id}`);
  },
  getUserBudgetBreakdownsByBudgetId(id: BudgetJSON['id']): Promise<List<UserBudgetBreakdownJSON, never>> {
    return api.get(`budgets/${id}/user-budget-breakdowns`);
  },
  getBudgetSpendingStatement(id: BudgetJSON['id']): Promise<List<BudgetSpendingStatementItemJSON, never>> {
    return api.get(`budgets/${id}/spending-statement`);
  },
  getUserBudgetBreakdownsByBookingId(bookingId: BookingJSON['id']): Promise<List<UserBudgetBreakdownJSON, never>> {
    return api.get(`bookings/${bookingId}/user-budget-breakdowns`);
  },
  getBudgetBreakdownByBookingIdAndBudgetId(bookingId: BookingJSON['id'], budgetId: BudgetJSON['id']): Promise<BudgetBreakdownJSON> {
    return api.get(`bookings/${bookingId}/budgets/${budgetId}/budget-breakdown`);
  }
};