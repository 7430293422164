import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { getBooking } from '../bookingDetails/bookingDetails.thunks';
import { createBudget, deleteBudget, getBudgets, getUserBudgets, updateBudget } from '../budgets/budgets.thunks';
import { initialState } from '../state';
import { getBudgetBreakdownByBookingIdAndBudgetId } from './budgetBreakdown.thunks';
import { budgetBreakdownsAdapter } from './budgetBreakdowns.adapter';

const slice = createSlice({
  name: 'budgetBreakdowns',
  initialState: initialState.budgetBreakdowns,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getBudgets.pending, (state) => {
      state.status = FETCH_STATUS.FETCHING;
    });

    builder.addCase(getBudgets.fulfilled, (state, { payload: { relationships: { budgetBreakdowns } } }) => {
      budgetBreakdownsAdapter.setMany(state, budgetBreakdowns);
      state.status = FETCH_STATUS.FETCHED;
    });

    builder.addCase(getBudgets.rejected, (state) => {
      state.status = FETCH_STATUS.INITIAL;
    });

    builder.addCase(getUserBudgets.fulfilled, (state, { payload: { relationships: { budgetBreakdowns } } }) => {
      budgetBreakdownsAdapter.setMany(state, budgetBreakdowns);
    });

    builder.addCase(createBudget.fulfilled, (state, { payload: { relationships: { budgetBreakdowns } } }) => {
      budgetBreakdownsAdapter.addOne(state, budgetBreakdowns[0]);
    });

    builder.addCase(updateBudget.fulfilled, (state, { payload: { relationships: { budgetBreakdowns } } }) => {
      const changes = budgetBreakdowns[0];
      budgetBreakdownsAdapter.updateOne(state, { id: changes.budgetId, changes });
    });

    builder.addCase(deleteBudget.fulfilled, (state, { payload: { id } }) => {
      budgetBreakdownsAdapter.removeOne(state, id);
    });

    builder.addCase(getBooking.fulfilled, (state, { payload: { relationships: { budgetBreakdowns } } }) => {
      budgetBreakdownsAdapter.setMany(state, budgetBreakdowns);
    });

    builder.addCase(getBudgetBreakdownByBookingIdAndBudgetId.fulfilled, (state, { payload }) => {
      budgetBreakdownsAdapter.setOne(state, payload);
    });

  }
});

export default slice.reducer;