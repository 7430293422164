import { ItemReviewCreateBody, NetPromoterScoreCreateBody, ReviewCreateBody } from '@jurnee/common/src/dtos/reviews';
import { Booking, BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BookingReviewJSON } from '@jurnee/common/src/entities/BookingReview';
import { ExperienceReviewJSON } from '@jurnee/common/src/entities/ExperienceReview';
import { NetPromoterScoreJSON } from '@jurnee/common/src/entities/NetPromoterScore';
import { PartnerReviewJSON } from '@jurnee/common/src/entities/PartnerReview';
import { ReviewJSON } from '@jurnee/common/src/entities/Review';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'src/api/bookings';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';

export const getBookingByCuid = createAsyncThunk<BookingJSON, Booking['cuid'], { state: RootState }>('BOOKING_GET_BY_CUID', async (cuid, thunkAPI) => {
  try {
    const booking = await api.getBookingByCuid(cuid);

    return booking;
  } catch (e) {
    thunkAPI.dispatch(showToast({ status: 'error', description: 'An error occurred while retrieving the booking' }));
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const createReview = createAsyncThunk<ReviewJSON, { bookingCuid: Booking['cuid'] } & ReviewCreateBody, { state: RootState }>('REVIEW_CREATE', async (data, thunkAPI) => {
  try {
    const { bookingCuid, ...rest } = data;

    const review = await api.createReview(bookingCuid, rest);

    return review;
  } catch (e) {
    thunkAPI.dispatch(showToast({ status: 'error', description: 'An error occurred while creating the review' }));
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const createExperienceReview = createAsyncThunk<ExperienceReviewJSON, { bookingCuid: Booking['cuid'] } & ItemReviewCreateBody, { state: RootState }>('EXPERIENCE_REVIEW_CREATE', async (data, thunkAPI) => {
  try {
    const { bookingCuid, ...rest } = data;

    const experienceReview = await api.createExperienceReview(bookingCuid, rest);

    return experienceReview;
  } catch (e) {
    thunkAPI.dispatch(showToast({ status: 'error', description: 'An error occurred while creating the experience review' }));
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const createPartnerReview = createAsyncThunk<PartnerReviewJSON, { bookingCuid: Booking['cuid'] } & ItemReviewCreateBody, { state: RootState }>('PARTNER_REVIEW_CREATE', async (data, thunkAPI) => {
  try {
    const { bookingCuid, ...rest } = data;

    const partnerReview = await api.createPartnerReview(bookingCuid, rest);

    return partnerReview;
  } catch (e) {
    thunkAPI.dispatch(showToast({ status: 'error', description: 'An error occurred while creating the partner review' }));
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const createBookingReview = createAsyncThunk<BookingReviewJSON, { bookingCuid: Booking['cuid'] } & ItemReviewCreateBody, { state: RootState }>('BOOKING_REVIEW_CREATE', async (data, thunkAPI) => {
  try {
    const { bookingCuid, ...rest } = data;

    const bookingReview = await api.createBookingReview(bookingCuid, rest);

    return bookingReview;
  } catch (e) {
    thunkAPI.dispatch(showToast({ status: 'error', description: 'An error occurred while creating the booking review' }));
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const createNps = createAsyncThunk<NetPromoterScoreJSON, { bookingCuid: Booking['cuid'] } & NetPromoterScoreCreateBody, { state: RootState }>('NPS_CREATE', async (data, thunkAPI) => {
  try {
    const { bookingCuid, ...rest } = data;

    const nps = await api.createNps(bookingCuid, rest);

    return nps;
  } catch (e) {
    thunkAPI.dispatch(showToast({ status: 'error', description: 'An error occurred while creating the nps' }));
    return thunkAPI.rejectWithValue(e.message);
  }
});