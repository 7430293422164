import {
  Box,
  Grid,
  GridItem,
  Heading,
  VStack
} from '@chakra-ui/react';
import { Collection } from '@jurnee/common/src/entities/Collection';
import { PLACEHOLDER_PATH } from '@jurnee/common/src/utils/bookings';
import { getCdnImageUrl } from '@jurnee/common/src/utils/core';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface OwnProps {
  key: string;
  collection: Collection;
}

export default class CollectionCard extends React.PureComponent<OwnProps> {

  get collectionPath() {
    return `/collections/${this.props.collection.id}`;
  }

  get collectionImagesPaths() {
    if (this.props.collection.collectionsExperiences.length === 0) {
      return [PLACEHOLDER_PATH];
    }

    return this.props.collection.collectionsExperiences
      .slice(0, 4)
      .map(({ experience }) => {
        return experience.experiencesImages[0].image.path;
      });
  }

  get gridItems() {
    return this.collectionImagesPaths.map((path, idx) => {
      const rowSpan = this.collectionImagesPaths.length === 3 && idx === 0 ? 4 : 2;

      return (
        <GridItem
          rowSpan={rowSpan}
          bg={`url('${getCdnImageUrl(path)}');`}
          bgSize="cover"
          key={idx}
        />
      );
    });
  }

  get imagesGrid() {
    return (
      <Grid
        h='100%'
        templateRows='repeat(2, 2)'
        templateColumns={`repeat(${this.gridItems.length === 1 ? '1': '2'}, 1fr)`}
        gap="2px"
        bgColor="white"
        bgSize="cover"
      >
        {this.gridItems}
      </Grid>
    );
  }

  render() {
    return (
      <Link to={this.collectionPath}>
        <Box
          w="100%"
          style={{ aspectRatio: '1.75' }}
          alignItems="flex-start"
          justifyContent="flex-end"
          borderRadius={8}
          bg="gray.300"
          overflow="hidden"
        >
          {this.imagesGrid}
        </Box>
        <VStack paddingTop={3} spacing={1} alignItems="flex-start">
          <Heading color="gray.800" maxW="100%" size="md" noOfLines={1}>
            {this.props.collection.title}
          </Heading>
        </VStack>
      </Link>
    );
  }

}