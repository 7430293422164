import { MultiSelect } from '@jurnee/common/src/components/Select';
import { TagJSON } from '@jurnee/common/src/entities/Tag';
import * as React from 'react';

interface Props {
  onKeyDown?(e: React.KeyboardEvent): void;
  onChange(data: { label: string, value: number }[]): void;
  tags: TagJSON[];
  selectedTagIds: number[];
  isClearable?: boolean;
  placeholder?: string;
}

export default class TagSelector extends React.PureComponent<Props> {

  get options() {
    return this.props.tags.map(tag => {
      return {
        value: tag.id,
        label: tag.name
      };
    });
  }

  render() {
    return (
      <MultiSelect<TagJSON['id']>
        onKeyDown={this.props.onKeyDown}
        options={this.options}
        placeholder={this.props.placeholder || 'Select preferences'}
        closeMenuOnSelect={false}
        openMenuOnFocus={true}
        maxMenuHeight={200}
        onChange={this.props.onChange}
        value={this.options.filter(option => this.props.selectedTagIds.some(tagId => option.value === tagId))}
        isClearable={this.props.isClearable}
      />
    );
  }

}