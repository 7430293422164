import { api } from '@jurnee/common/src/browser/Api';
import { logger } from '@jurnee/common/src/browser/Logger';
import { CompanyJSON } from '@jurnee/common/src/entities/Company';
import { GeolocationJSON } from '@jurnee/common/src/entities/Geolocation';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { DashboardJwt, parseJwt } from '@jurnee/common/src/utils/auth';
import { isAdmin, isOrganizer } from '@jurnee/common/src/utils/user';
import { createAsyncThunk } from '@reduxjs/toolkit';
import companyApi from '../../api/company';
import { getGeolocation } from '../../api/geolocation';
import userApi from '../../api/user';
import { logout } from '../auth/auth.store';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';
import { getUserStatsThunk } from '../userStats/userStats.thunks';

interface BootstrapPayload {
  user: UserDetails;
  company: CompanyJSON;
  geolocation: GeolocationJSON;
}

export const bootstrap = createAsyncThunk<BootstrapPayload, void, { state: RootState }>('BOOTSTRAP', async (arg, thunkAPI) => {
  try {
    const state = thunkAPI.getState();

    const { userId, companyId } = parseJwt<DashboardJwt>(state.auth.token);

    const [company, user, geolocation] = await Promise.all([
      state.company.data ? Promise.resolve(state.company.data) : companyApi.getCompany(companyId),
      state.user ? Promise.resolve(state.user) : userApi.getUser(userId),
      state.geolocation.data ? Promise.resolve(state.geolocation.data) : getGeolocation(),
    ]);

    logger.setContext({ userId, companyId });

    thunkAPI.dispatch(getUserStatsThunk(userId));

    api.addEventListener('unauthorized', () => {
      thunkAPI.dispatch(logout());
    });

    window.analytics.identify(user.id.toString(), {
      createdAt: user.createdAt,
      isAdmin: isAdmin(user),
      isOrganizer: isOrganizer(user),
      firstName: user.firstName,
      lastName: user.lastName,
      avatar: user.imageUrl,
      company: {
        id: company.id,
        name: company.name
      },
      email: user.email
    });

    window.analytics.group(company.id.toString(), {
      name: company.name
    });

    window.analytics.track('initialized_dashboard');

    logger.info('Bootstraping successed');

    return { company, user, geolocation };
  } catch (error) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while bootstraping the application`, status: 'error' }));
    logger.error('Bootstraping failed', { error: error.message });
    thunkAPI.dispatch(logout());
    return thunkAPI.rejectWithValue({ user: null, company: null });
  }
});