import { Text, TextProps } from '@chakra-ui/react';
import { Currency } from '@jurnee/common/src/entities/Currency';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { getCheapestPackageUnitPrice, getMinPricePerUnit, isCustomRequestProduct } from '@jurnee/common/src/utils/products';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import PriceInUserCurrency from '../PriceInUserCurrency';

interface OwnProps extends TextProps {
  experience: ExperienceJSON;
  currency: Currency;
  participants?: number;
}

type Props = OwnProps & WithTranslation;

class ExperiencePriceLabel extends React.PureComponent<Props> {

  get products() {
    return this.props.experience.products.filter(product => product.currency === this.props.currency && !isCustomRequestProduct(product));
  }

  get unitPrice() {
    if (this.props.participants) {
      return getCheapestPackageUnitPrice(this.products, this.props.participants);
    }

    return Math.min(...this.products.map(getMinPricePerUnit));
  }

  render() {
    if (this.products.length === 0) {
      return null;
    }

    return (
      <Text fontSize={14} fontWeight={700} lineHeight="16px" noOfLines={1}>
        <PriceInUserCurrency value={this.unitPrice} currency={this.props.currency} /> {this.props.t('experience:price.perPers')}
      </Text>
    );
  }

}

export default withTranslation('experience')(ExperiencePriceLabel);