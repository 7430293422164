import { api } from '@jurnee/common/src/browser/Api';
import { EmployeeUpdateBody, InviteEmployeesBody } from '@jurnee/common/src/dtos/users';
import { Company } from '@jurnee/common/src/entities/Company';
import { User, UserDetails } from '@jurnee/common/src/entities/User';

export default {
  getEmployees(companyId: number): Promise<UserDetails[]> {
    return api.get(`companies/${companyId}/employees`);
  },
  deleteEmployee(companyId: Company['id'], userId: User['id']): Promise<void> {
    return api.delete(`companies/${companyId}/employees/${userId}`);
  },
  inviteEmployees(companyId: Company['id'], body: InviteEmployeesBody): Promise<number> {
    return api.post(`companies/${companyId}/employees/invite`, body);
  },
  sendInvitation(companyId: Company['id'], userId: User['id'], email: User['email']): Promise<number> {
    return api.post(`companies/${companyId}/employees/${userId}/send-invitation`, { email });
  },
  updateEmployee(companyId: Company['id'], userId: User['id'], body: EmployeeUpdateBody): Promise<UserDetails> {
    return api.put(`companies/${companyId}/employees/${userId}`, body);
  }
};