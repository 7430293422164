import { HStack, VStack } from '@chakra-ui/react';
import SkeletonText from './SkeletonText';

export function SkeletonComment() {
  return (
    <VStack
      w="100%"
      spacing={4}
      alignItems="flex-start"
      p={4}
      bgColor="white"
      borderRadius={4}
      border="1px solid"
      borderColor="gray.200"
    >
      <HStack spacing={2}>
        <SkeletonText w="22px" h="22px" pt="2px" skeletonHeight="22px" />
        <SkeletonText w="100px" h="14px" pt="2px" skeletonHeight="10px" />
      </HStack>

      <VStack spacing={1} alignItems="flex-start">
        <SkeletonText w="280px" h="14px" pt="2px" skeletonHeight="10px" />
        <SkeletonText w="240px" h="14px" pt="2px" skeletonHeight="10px" />
      </VStack>
    </VStack>
  );
}