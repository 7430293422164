import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  isInvalid: boolean;
  onChange(description: string): void
}

export function ServiceDescriptionInput(props: Props) {
  const { t } = useTranslation('booking', { keyPrefix: 'modals.payViaJurnee.description' });

  return (
    <FormControl id="description" isInvalid={props.isInvalid} isRequired>
      <FormLabel>{t('label')}</FormLabel>

      <Input
        size="sm"
        placeholder={t('placeholder')}
        onChange={e => props.onChange(e.target.value.trim())}
      />
    </FormControl>
  );
}