import { Request } from 'express';
import { Schema, ValidatorResult } from 'jsonschema';

function getRandomId(): string {
  return Math.random().toString(36).substr(2, 9).toUpperCase();
}

export function getRandomRequestId(): string {
  return `REQ-${getRandomId()}`;
}

export function getRandomSessionId(): string {
  return `SES-${getRandomId()}`;
}

export function getRandomInstanceId(): string {
  return `INS-${getRandomId()}`;
}

export function getRequestIp(req: Request): string {
  return (req.headers['do-connecting-ip'] || req.ip) as string;
}

export function getCdnImageUrl(path: string): string {
  return `${process.env.CDN_URL}/${path}`;
}

export function handleValidatorError(error: ValidatorResult) {
  const firstError = error.errors[0];

  return {
    name: firstError.property,
    argument: firstError.argument,
    message: (firstError.schema as Schema).description || firstError.message
  };
}

export function getDomainFromWebsite(website: string) {
  try {
    const url = new URL(website);

    return url.hostname.replace('www.', '');
  } catch (err) {
    return null;
  }
}

export function setMobileViewport() {
  const meta = document.createElement('meta');

  meta.setAttribute('name', 'viewport');
  meta.setAttribute('content', 'width=device-width, initial-scale=1');

  document.head.appendChild(meta);
}

export function getEntityKey<T extends { id: number, updatedAt: string }>({ id, updatedAt }: Pick<T, 'id' | 'updatedAt'>) {
  return `${id}-${new Date(updatedAt).getTime()}`;
}

export type Changes<T> = Partial<Record<keyof T, { before: string, after: string }>>

export function getChanges<T>(a: T, b: T, opts?: { excluded: (keyof T)[] }) {
  const diff: Changes<T> = {};

  const keys = new Set([
    ...Object.keys(a as Record<string, unknown>) as (keyof T)[],
    ...Object.keys(b as Record<string, unknown>) as (keyof T)[]
  ].filter(key => !opts?.excluded || !opts.excluded.includes(key)));

  for (const key of keys) {
    const before = (a[key] ?? '').toString();
    const after = (b[key] ?? '').toString();

    if (before !== after) {
      diff[key] = { before, after };
    }
  }

  if (Object.keys(diff).length === 0) {
    return null;
  }

  return diff;
}