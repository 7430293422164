import { Box, Center, Image, Input, Stack } from '@chakra-ui/react';
import { AUTH_STATUS } from '@jurnee/common/src/browser/State';
import { ForgotPasswordBody, ResetPasswordBody } from '@jurnee/common/src/dtos/auth';
import * as React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import LogoedCenteredBox from '../components/LogoedCenteredBox';
import { PrimaryButton } from '../components/buttons';
import { resetPassword, sendResetPasswordEmail } from '../store/auth/auth.thunks';
import { RootState } from '../store/state';
import { RouteProps } from './Route';

interface StateProps {
  auth: RootState['auth'];
}

interface DispatchProps {
  sendResetPasswordEmail(payload: ForgotPasswordBody): any;
  resetPassword(payload: ResetPasswordBody & { passwordConfirmation: string }): void;
}

type Props = RouteProps & StateProps & DispatchProps;

interface State {
  credentials: {
    email: string;
    password: string;
    passwordConfirmation: string;
  },
  forgotPasswordHandled: boolean;
  token: string;
}

class ResetPassword extends React.Component<Props> {

  state: State = {
    credentials: {
      email: '',
      password: '',
      passwordConfirmation: ''
    },
    forgotPasswordHandled: false,
    token: null
  };

  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const token = searchParams.get('token');

    if (token) {
      this.setState({ ...this.state, token: token });
    }
  }

  setCredential = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      credentials: {
        ...this.state.credentials,
        [event.target.name]: event.target.value
      }
    });
  };

  onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (this.state.token) {
        this.handleResetPassword();
      } else {
        this.handleForgotPassword();
      }
    }
  };

  handleForgotPassword = async () => {
    const response = await this.props.sendResetPasswordEmail({ email: this.state.credentials.email });

    if (response.payload.success) {
      this.setState({ ...this.state, forgotPasswordHandled: true });
    }
  };

  handleResetPassword = async () => {
    await this.props.resetPassword({
      token: this.state.token,
      password: this.state.credentials.password,
      passwordConfirmation: this.state.credentials.passwordConfirmation
    });
  };

  renderForm() {
    if(this.state.token) {
      return (
        <Stack borderRadius={8} border="1px solid" borderColor="blue.50" p={8} w="100%" justify="center" spacing={5} bg="white">
          <Input size="md" onKeyDown={this.onKeyDown} placeholder="Password" type="password" name="password" onChange={this.setCredential} />
          <Input size="md" onKeyDown={this.onKeyDown} placeholder="Confirm password" type="password" name="passwordConfirmation" onChange={this.setCredential} />
          <PrimaryButton size="md" onClick={this.handleResetPassword}>Change my password</PrimaryButton>
        </Stack>
      );
    }

    return (
      <Stack borderRadius={8} border="1px solid" borderColor="blue.50" p={8} w="100%" justify="center" spacing={5} bg="white">
        <Input size="md" onKeyDown={this.onKeyDown} placeholder="Email" name="email" onChange={this.setCredential} />
        <PrimaryButton size="md" onClick={this.handleForgotPassword}>Reset password</PrimaryButton>
      </Stack>
    );
  }

  render() {
    if (this.props.auth.status === AUTH_STATUS.AUTHENTICATED) {
      return <Navigate to="/" />;
    }

    if (this.state.forgotPasswordHandled) {
      return (
        <LogoedCenteredBox title='Please check your inbox'>
          <Box w="100%" textAlign="center">
            If your account exists, we will send you an email to reset your password! 📮
          </Box>
        </LogoedCenteredBox>
      );
    }

    return (
      <Center h="100%" w="400px" margin="auto">
        <Stack w="100%">
          <Image margin="30px auto" htmlWidth="200px" src="/assets/images/logo.svg"/>
          {this.renderForm()}
        </Stack>
      </Center>
    );
  }
}

function mapStateToProps(state: RootState): StateProps {
  return {
    auth: state.auth
  };
}

const mapDispatchToProps: DispatchProps = {
  sendResetPasswordEmail,
  resetPassword
};

export default connect<StateProps, DispatchProps, Record<string, never>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);