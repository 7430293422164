import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultValue: string;
  isDisabled: boolean;
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
}

export function PrioritySelect({ defaultValue, isDisabled, onChange }: Props) {
  const { t } = useTranslation('tasks');

  const priorities = [
    'URGENT',
    'HIGH',
    'MEDIUM',
    'LOW'
  ];

  return (
    <FormControl id="priority">
      <FormLabel>{t('drawer.form.priority.label')}</FormLabel>

      <Select size="sm" defaultValue={defaultValue} onChange={onChange} isDisabled={isDisabled}>
        { priorities.map(priority => <option key={priority} value={priority}>{t(`priority.${priority}`)}</option>) }
      </Select>
    </FormControl>
  );
}