import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export function NoAnswers() {
  const { t } = useTranslation('surveys', { keyPrefix: 'drawers.surveyAnswers' });

  return (
    <Box
      w="100%"
      bgColor="white"
      border="1px solid"
      borderColor="gray.200"
      borderRadius={4}
      p={4}
      textAlign="center"
    >
      <Text color="gray.400">
        { t('noAnswers') }
      </Text>
    </Box>
  );
}