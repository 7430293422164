import { Box } from '@chakra-ui/react';
import { SearchSelect } from '@jurnee/common/src/components/Select';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  budget: BudgetJSON;
  definitionType: BudgetJSON['definitionType'];
  users: UserDetails[];
  userIds: number[];
  onAddUser({ value: { id } }: { value: UserDetails }): void;
}

function getOptions({ users, userIds, definitionType, budget, existingBudgetLabel }: Omit<Props, 'onAddUser'> & { existingBudgetLabel: string }) {
  const filteredUsers = users.filter(user => userIds.every(userId => userId !== user.id));

  const options = filteredUsers.map(user => {
    const isDisabled = definitionType === 'INDIVIDUAL' ? user.budgetId && (user.budgetId !== budget?.id) : false;
    const sublabel = isDisabled ? existingBudgetLabel : null;

    return {
      value: user,
      label: getUserLabel(user),
      sublabel,
      isDisabled
    };
  });

  return sortAlphabeticallyBy(options, 'label');
}

export function UsersSearchSelect({ onAddUser, ...props }: Props) {
  const { t } = useTranslation('settings', { keyPrefix: `budgets.drawer.form.users.${props.definitionType}` });

  const options = useMemo(() => getOptions({
    ...props,
    existingBudgetLabel: t('existingBudget')
  }), [props]);

  return (
    <Box w="100%" className="react-select-small">
      <SearchSelect<UserDetails>
        options={options}
        closeMenuOnSelect={false}
        menuPlacement="top"
        openMenuOnFocus={true}
        maxMenuHeight={200}
        placeholder={t('placeholder')}
        value={null}
        onChange={onAddUser}
      />
    </Box>
  );
}