import { CollectionCreateBody, CollectionExperienceAddBody } from '@jurnee/common/src/dtos/collections';
import { Collection, CollectionJSON } from '@jurnee/common/src/entities/Collection';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/collections';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';

export const createCollection = createAsyncThunk<CollectionJSON, CollectionCreateBody, { state: RootState }>('COLLECTIONS_CREATE', async (data, thunkAPI) => {
  try {
    const collection = await api.createCollection(data);

    return collection;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while creating the list`, status: 'error' }));
    return thunkAPI.rejectWithValue({ error: e.message });
  }
});

export const getCollections = createAsyncThunk<CollectionJSON[], void, { state: RootState }>('COLLECTIONS_GET', async (data, thunkAPI) => {
  try {
    const { list } = await api.getCollections();

    return list;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while retrieving your lists`, status: 'error' }));
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const addToCollection = createAsyncThunk<void, CollectionExperienceAddBody & { collectionId: Collection['id'] }, { state: RootState }>('COLLECTIONS_ADD', async (data, thunkAPI) => {
  try {
    const { collectionId, experienceId } = data;

    await api.addToCollection(collectionId, { experienceId });
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while adding the experience`, status: 'error' }));
    return thunkAPI.rejectWithValue(e.message);
  }
});