import { CurrencyExchangeRates } from '@jurnee/common/src/entities/Currency';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrencies } from 'src/api/currencies';
import { RootState } from '../state';

export const getCurrenciesThunk = createAsyncThunk<CurrencyExchangeRates, null, { state: RootState }>('CURRENCIES_FETCH', async (_, thunkAPI) => {
  try {
    const currencies = await getCurrencies();
    return currencies;
  } catch (e) {
    return thunkAPI.rejectWithValue(e.message);
  }
});