import { HStack, Heading, VStack } from '@chakra-ui/react';
import { BackButton } from '@jurnee/common/src/components/buttons/BackButton';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { ExperiencesSearchParams } from '@jurnee/common/src/entities/Experience';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ExperiencesTabs } from './ExperiencesTabs';

interface Props {
  filters: ExperiencesSearchParams;
  booking: BookingJSON;
}

export function ExperiencesHeader(props: Props) {
  const { t } = useTranslation('experiences');

  return (
    <VStack w="100%" spacing={0}>
      <HStack w="100%" bg="white" spacing={4} px={8} py={7}>
        <Link to={props.booking ? `/bookings/${props.booking.id}` : '/experiences'}>
          <BackButton />
        </Link>

        <Heading>{t('header.title')}</Heading>
      </HStack>

      <ExperiencesTabs {...props} />
    </VStack>
  );
}