import { ProviderDiscountAddBody } from '@jurnee/common/src/dtos/entities';
import { Entity } from '@jurnee/common/src/entities/Entity';
import { ProviderDiscountJSON } from '@jurnee/common/src/entities/ProviderDiscount';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/company';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';

export type ProviderDiscountAddPayload = {
  entityId: Entity['id'];
  body: ProviderDiscountAddBody
}

export const getProviderDiscounts = createAsyncThunk<ProviderDiscountJSON[], void, { state: RootState }>('PROVIDER_DISCOUNTS_GET', async (_data, thunkAPI) => {
  try {
    const { company } = thunkAPI.getState();
    const { list } = await api.getProviderDiscounts(company.data.id);

    return list;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while retrieving your discounts`, status: 'error' }));

    return thunkAPI.rejectWithValue(e.message);
  }
});

export const addProviderDiscount = createAsyncThunk<ProviderDiscountJSON, ProviderDiscountAddPayload, { state: RootState }>('PROVIDER_DISCOUNT_ADD', async (data, thunkAPI) => {
  try {
    const { company } = thunkAPI.getState();
    const providerDiscount = await api.addProviderDiscount(company.data.id, data.entityId, data.body);

    thunkAPI.dispatch(showToast({ title: `Discount code has been successfully applied`, status: 'success' }));

    return providerDiscount;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: e.message || `An error occurred while creating discount`, status: 'error' }));

    return thunkAPI.rejectWithValue(e.message);
  }
});