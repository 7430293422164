import { api } from '@jurnee/common/src/browser/Api';
import { Company } from '@jurnee/common/src/entities/Company';
import { Experience, ExperienceJSON, ExperienceRelationshipsJSON, ExperienceStats } from '@jurnee/common/src/entities/Experience';
import { Expand, List } from '@jurnee/common/src/serializers';

export default {
  getExperiences(companyId: Company['id'], params: URLSearchParams): Promise<List<ExperienceJSON, ExperienceRelationshipsJSON>> {
    return api.get(`companies/${companyId}/experiences?${params.toString()}`);
  },
  getExperience(companyId: Company['id'], experienceId: ExperienceJSON['id']): Promise<Expand<ExperienceJSON, ExperienceRelationshipsJSON>> {
    return api.get(`companies/${companyId}/experiences/${experienceId}`);
  },
  getExperienceStats(id: Experience['id']): Promise<ExperienceStats> {
    return api.get(`experiences/${id}/stats`);
  }
};