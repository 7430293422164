import { VStack } from '@chakra-ui/react';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { CUSTOM_CATEGORY_TYPES, CustomCategoryType, EXPERIENCES_CATEGORIES } from 'src/utils/experiences';
import { CustomCategoryCard } from './CustomCategoryCard';

interface Props {
  booking: BookingJSON;
}

export function CustomCards({ booking }: Props) {
  const categories = EXPERIENCES_CATEGORIES.filter(({ type }) =>
    CUSTOM_CATEGORY_TYPES.includes(type as CustomCategoryType)
  );

  const colorSchemes = ['blue', 'pink', 'teal'];

  return (
    <VStack display="flex" w="100%" spacing={5}>
      {
        categories.map((category, i) =>
          <CustomCategoryCard
            key={category.type}
            category={category}
            colorScheme={colorSchemes[i % colorSchemes.length]}
            booking={booking}
          />
        )
      }
    </VStack>
  );
}