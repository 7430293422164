import { HStack, Text, VStack } from '@chakra-ui/react';
import { BookingItemJSON } from '@jurnee/common/src/entities/BookingItem';
import { getItemTimezone } from '@jurnee/common/src/utils/bookingsItems';
import { formatDate } from '@jurnee/common/src/utils/dates';
import { useTranslation } from 'react-i18next';

interface Props {
  item: BookingItemJSON;
}

export function BookingItemLine(props: Props) {
  const { t } = useTranslation('booking');

  const date = formatDate(
    new Date(props.item.from),
    {
      displayTime: true,
      displayTimeZone: true,
      timeZone: getItemTimezone(props.item)
    }
  );

  return (
    <VStack w="100%" alignItems="flex-start" py={2} pl={3} spacing={1} borderLeft="4px solid" borderColor="gray.200">
      <Text fontSize={14} noOfLines={1} fontWeight={700}>{props.item.description}</Text>

      <HStack w="100%" justifyContent="space-between">
        <Text fontSize={14} lineHeight="16px" noOfLines={1} color="gray.400">{date}</Text>
        <Text
          fontSize={14}
          lineHeight="16px"
          noOfLines={1}
          textAlign="center"
          color="gray.400"
        >
          { t('participants', { count: props.item.participants }) }
        </Text>
      </HStack>
    </VStack>
  );
}