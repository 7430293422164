import CurrencyEntity, { CurrencyExchangeRates } from '../entities/Currency';

export function getConversionRate(sourceId: string, targetId: string, currencies: CurrencyExchangeRates) {
  if (sourceId === targetId) {
    return 1;
  }

  return currencies[sourceId][targetId];
}

export function getCurrencyExchangeRates(currencies: CurrencyEntity[]) {
  return currencies.reduce<CurrencyExchangeRates>((currencies, { id, sourceExchangeRates }) => {
    currencies[id] ||= {};

    for (const { targetId, rate } of sourceExchangeRates) {
      if (targetId === id) {
        continue;
      }

      currencies[id][targetId] = rate;
    }

    return currencies;
  }, {});
}