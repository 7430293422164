import { PlaceResultDTO } from '@jurnee/common/src/dtos/places';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/places';
import { RootState } from '../state';

export interface PlacesTextSearchPayload {
  params: URLSearchParams;
  onError(error: unknown): void;
  onSuccess(places: { list: PlaceResultDTO[], total: number }): void;
}

export const searchPlaces = createAsyncThunk<{ list: PlaceResultDTO[], total: number }, PlacesTextSearchPayload, { state: RootState }>('PLACE_SEARCH', async ({ params, onSuccess, onError }, thunkAPI) => {
  try {
    const places = await api.searchPlaces(params);
    onSuccess(places);
    return places;
  } catch (err) {
    onError(err);
    return thunkAPI.rejectWithValue(err.message);
  }
});