import { FormControl, FormLabel, Textarea } from '@chakra-ui/react';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface OwnProps extends WithTranslation {
  isRequired: boolean;
  onChange(e: React.ChangeEvent<HTMLTextAreaElement>): void;
}

class CommentTextArea extends React.Component<OwnProps> {

  render() {
    return (
      <FormControl id='comment' isRequired={this.props.isRequired}>
        <FormLabel>{this.props.t(`form.steps.event.comment.${this.props.isRequired ? 'labelRequired' : 'label'}`)}</FormLabel>
        <Textarea
          onChange={this.props.onChange}
          placeholder={this.props.t(`form.steps.event.comment.${this.props.isRequired ? 'placeholderRequired' : 'placeholder'}`)}
          height="100px"
          resize="none"
        />
      </FormControl>
    );
  }

}

export default withTranslation('experience')(CommentTextArea);