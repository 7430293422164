import { createStandaloneToast, UseToastOptions } from '@chakra-ui/toast';
import theme from '@jurnee/common/src/theme';
import { createAsyncThunk } from '@reduxjs/toolkit';

const { toast } = createStandaloneToast({ theme });

export const showToast = createAsyncThunk('TOASTS_SHOW', async (options: UseToastOptions) => {
  toast({
    title: options.title,
    description: options.description,
    position: 'bottom-right',
    variant: 'left-accent',
    status: options.status,
    duration: options.duration,
    isClosable: true
  });
});
