import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../state';
import { getLocations } from './locations.thunks';

const slice = createSlice({
  name: 'locations',
  initialState: initialState.locations,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getLocations.pending, () => {
      return { list: [], error: null, status: FETCH_STATUS.FETCHING };
    });

    builder.addCase(getLocations.fulfilled, (state, { payload: { list }}) => {
      return { list, error: null, status: FETCH_STATUS.FETCHED };
    });

    builder.addCase(getLocations.rejected, () => {
      return { list: [], error: true, status: FETCH_STATUS.INITIAL };
    });

  }
});

export default slice.reducer;