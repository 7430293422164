import { HStack, Text } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { PropositionJSON } from '@jurnee/common/src/entities/Proposition';
import { PropositionsGroupJSON } from '@jurnee/common/src/entities/PropositionsGroup';
import { ConfirmModal } from '@jurnee/common/src/modals/ConfirmModal';
import { DeclineModal } from '@jurnee/common/src/modals/DeclineModal';
import { formatDate, getCurrentTimeZone } from '@jurnee/common/src/utils/dates';
import { isSelectedOrDeclined } from '@jurnee/common/src/utils/propositions';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/buttons';

interface Props {
  proposition: PropositionJSON;
  status: PropositionsGroupJSON['status'];
  onDecline(reason: string): Promise<void>;
  onSelect(): void;
}

export function Actions({ proposition, status, onDecline, onSelect }: Props) {
  const { t } = useTranslation(['requests', 'common']);

  if (proposition.selectedAt) {
    const date = formatDate(
      new Date(proposition.selectedAt),
      {
        displayTime: true,
        displayTimeZone: true,
        timeZone: getCurrentTimeZone()
      }
    );

    return (
      <HStack spacing={1}>
        <Icon icon="squareRoundedCheck" color="teal.400" size={4} />
        <Text color="teal.400" noOfLines={1}>
          { t('propositions.selectedAt', { date }) }
        </Text>
      </HStack>
    );
  }

  if (proposition.declinedAt) {
    const date = formatDate(
      new Date(proposition.declinedAt),
      {
        displayTime: true,
        displayTimeZone: true,
        timeZone: getCurrentTimeZone()
      }
    );

    return (
      <HStack spacing={1}>
        <Icon icon="squareRoundedX" color="red.500" size={4} />
        <Text color="red.500" noOfLines={1}>
          { t('propositions.declinedAt', { date }) }
        </Text>
      </HStack>
    );
  }

  if (status === 'CLOSED') {
    return null;
  }

  if (isSelectedOrDeclined(proposition)) {
    return null;
  }

  return (
    <HStack spacing={4}>
      <DeclineModal
        title={t('modals.declineProposition.title')}
        message={t('modals.declineProposition.message')}
        reasonLabel={t('modals.declineProposition.reason.label')}
        reasonPlaceholder={t('modals.declineProposition.reason.placeholder')}
        button={t('modals.declineProposition.button')}
        isReasonRequired={true}
        onDecline={onDecline}
      >
        <PrimaryButton
          w="110px"
          colorScheme="pink"
          size="xs"
          fontWeight={500}
          flexShrink={0}
        >
          { t('common:buttons.decline') }
        </PrimaryButton>
      </DeclineModal>

      <ConfirmModal
        title={t('modals.selectProposition.title')}
        message={t('modals.selectProposition.message')}
        buttonLabel={t('modals.selectProposition.buttonLabel')}
        onConfirm={onSelect}
        buttonColor="teal"
      >
        <PrimaryButton
          w="110px"
          colorScheme="teal"
          size="xs"
          fontWeight={500}
          flexShrink={0}
        >
          { t('common:buttons.select') }
        </PrimaryButton>
      </ConfirmModal>
    </HStack>
  );
}