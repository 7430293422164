import { Box, FormControl, FormLabel, HStack, Select, Tooltip } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultValue: string;
  isDisabled: boolean;
  onChange(e: ChangeEvent<HTMLSelectElement>): void;
}

export function RoleSelect({ defaultValue, isDisabled, onChange }: Props) {
  const { t } = useTranslation('employees');

  const roles = [{
    key: 'ADMIN',
    value: 'ADMIN'
  }, {
    key: 'ORGANIZER',
    value: 'ORGANIZER'
  }, {
    key: 'PARTICIPANT',
    value: ''
  }];

  return (
    <FormControl id="roleKey">
      <HStack justifyContent="space-between">
        <FormLabel>{t('drawer.form.role.label')}</FormLabel>

        <Tooltip label={t('role.tooltip')} minW={360} size="md">
          <Box>
            <Icon icon="help" size={5} color="gray.400" position="absolute" top={0} right={0} />
          </Box>
        </Tooltip>
      </HStack>

      <Select size="sm" onChange={onChange} defaultValue={defaultValue || ''} isDisabled={isDisabled}>
        { roles.map(({ key, value }) => <option key={key} value={value}>{t(`role.${key}`)}</option>) }
      </Select>
    </FormControl>
  );
}