import { Box, Divider, HStack, Image, Input, Link, Stack } from '@chakra-ui/react';
import { AUTH_STATUS } from '@jurnee/common/src/browser/State';
import { LoginBody, ResendVerificationEmailBody, SsoProviderName } from '@jurnee/common/src/dtos/auth';
import * as React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import MicrosoftButton from 'src/components/buttons/MicrosoftButton';
import { SsoButton } from 'src/components/buttons/SsoButton';
import LogoedCenteredBox from '../components/LogoedCenteredBox';
import { GoogleButton, PrimaryButton, SecondaryButton } from '../components/buttons';
import { logout } from '../store/auth/auth.store';
import { login, loginSso, resendVerificationEmail, setToken } from '../store/auth/auth.thunks';
import { RootState } from '../store/state';
import { RouteProps } from './Route';

interface StateProps {
  auth: RootState['auth'];
}

interface DispatchProps {
  login(payload: LoginBody): void;
  logout(): void;
  loginSso(ssoProviderName: SsoProviderName): void;
  setToken(token: string): void;
  resendVerificationEmail(payload: ResendVerificationEmailBody): void;
}

type Props = RouteProps & StateProps & DispatchProps;

interface State {
  credentials: LoginBody;
  resendVerificationEmailHandled: boolean;
}

class Login extends React.Component<Props, State> {

  state: State = {
    credentials: {
      email: '',
      password: ''
    },
    resendVerificationEmailHandled: false
  };

  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);

    if (searchParams.has('connectAs')) {
      this.props.logout();
      this.props.setToken(searchParams.get('connectAs'));
    }
  }

  setValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      credentials: {
        ...this.state.credentials,
        [event.target.name]: event.target.value
      }
    });
  };

  onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.submit();
    }
  };

  submit = () => {
    this.props.login(this.state.credentials);
  };

  loginSsoGoogle = () => {
    this.props.loginSso('google');
  };

  loginSsoMicrosoft = () => {
    this.props.loginSso('microsoft');
  };

  resendVerificationEmail = () => {
    this.props.resendVerificationEmail({ email: this.state.credentials.email });

    this.setState({ ...this.state, resendVerificationEmailHandled: true });
  };

  renderGoogleButton() {
    return <GoogleButton labelprefix='Sign in with' onClick={this.loginSsoGoogle} />;
  }

  renderSsoButton() {
    return (
      <Link href='/login/sso'>
        <SsoButton />
      </Link>
    );
  }

  renderMicrosoftButton() {
    return <MicrosoftButton labelprefix='Sign in with' onClick={this.loginSsoMicrosoft} />;
  }

  get redirect() {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.has('redirect')) {
      return searchParams.get('redirect');
    }

    return '/';
  }

  renderEmailVerificationRequest() {
    return (
      <LogoedCenteredBox title='Verify your account'>
        <Box w="100%" textAlign="center">
          It seems that your account is still not verified 😞
        </Box>
        <Box w="100%" textAlign="center">
          If you did not receive the verification email, please click on the button below:
        </Box>
        <PrimaryButton size="md" onClick={this.resendVerificationEmail}>Resend verification email</PrimaryButton>
      </LogoedCenteredBox>
    );
  }

  render() {
    if (this.props.auth.status === AUTH_STATUS.UNVERIFIED) {
      if (this.state.resendVerificationEmailHandled) {
        return (
          <LogoedCenteredBox title='Please check your inbox'>
            <Box w="100%" textAlign="center">
              We just sent you an email in order to verify your email address! 📮
            </Box>
          </LogoedCenteredBox>
        );
      } else {
        return this.renderEmailVerificationRequest();
      }
    }

    if (this.props.auth.status === AUTH_STATUS.AUTHENTICATED) {
      return <Navigate to={this.redirect} />;
    }

    return (
      <Stack w="100%" h="100%" alignItems="center" justifyContent="center" spacing={5}>
        <Image mx="auto" w={200} src="/assets/images/logo.svg"/>

        <Stack borderRadius={8} border="1px solid" borderColor="blue.50" p={5} w={400} justify="center" spacing={5} bg="white">
          <Input size="md" onKeyDown={this.onKeyDown} placeholder="Email" name="email" onChange={this.setValue} />
          <Input size="md" onKeyDown={this.onKeyDown} placeholder="Password" type="password" name="password" onChange={this.setValue} />

          <PrimaryButton size="md" isLoading={this.props.auth.status === AUTH_STATUS.AUTHENTICATING} onClick={this.submit}>Login</PrimaryButton>
          <Divider />
          { this.renderGoogleButton() }
          { this.renderMicrosoftButton() }
          { this.renderSsoButton() }
          <SecondaryButton size="md" as="a" href="/signup">Create an account</SecondaryButton>
        </Stack>
        <HStack justifyContent="center">
          <Link color="blue.200" href="/reset-password">Forgot password?</Link>
        </HStack>
      </Stack>
    );
  }
}

function mapStateToProps(state: RootState): StateProps {
  return {
    auth: state.auth
  };
}

const mapDispatchToProps: DispatchProps = {
  setToken,
  logout,
  loginSso,
  login,
  resendVerificationEmail
};

export default connect<StateProps, DispatchProps, Record<string, never>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Login);