import { Center, Heading, Image, Stack } from '@chakra-ui/react';
import * as React from 'react';

interface Props {
  children: React.ReactNode;
  title: string;
}

export default class Component extends React.PureComponent<Props> {

  render() {
    return (
      <Center h='100%' w='400px' margin='auto'>
        <Stack w='100%'>
          <Image margin='30px auto' htmlWidth='200px' src='/assets/images/logo.svg' />

          <Stack spacing={5} bg="white" p={8} border="1px solid" borderColor="gray.200" borderRadius={8}>
            <Heading size="md" textAlign="center">{this.props.title}</Heading>
            {this.props.children}
          </Stack>
        </Stack>
      </Center>
    );
  }

}