import { Experience, ExperienceJSON, ExperienceRelationshipsJSON } from '@jurnee/common/src/entities/Experience';
import { Expand } from '@jurnee/common/src/serializers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'src/api/experiences';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';

export const getExperience = createAsyncThunk<Expand<ExperienceJSON, ExperienceRelationshipsJSON>, Experience['id'], { state: RootState }>('EXPERIENCE_GET', async (id, thunkAPI) => {
  try {
    const { company } = thunkAPI.getState();
    return api.getExperience(company.data.id, id);
  } catch (err) {
    thunkAPI.dispatch(showToast({ status: 'error', description: 'An error occurred while retrieving the experience' }));
  }
});