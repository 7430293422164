import { ApprovalRequestCreateBody } from '@jurnee/common/src/dtos/approvalRequests';
import { ApprovalResponseUpdateBody } from '@jurnee/common/src/dtos/approvalResponse';
import { ApprovalRequestJSON, ApprovalRequestRelationshipsJSON } from '@jurnee/common/src/entities/ApprovalRequest';
import { ApprovalResponseJSON } from '@jurnee/common/src/entities/ApprovalResponse';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { List } from '@jurnee/common/src/serializers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/approvalRequests';
import bookingsApi from '../../api/bookings';
import { getBookings } from '../bookings/bookings.thunks';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';
import { getUserStatsThunk } from '../userStats/userStats.thunks';

export interface ApprovalResponseUpdatePayload {
  approvalResponse: ApprovalResponseJSON;
  data: ApprovalResponseUpdateBody;
}

export interface ApprovalRequestCreatePayload {
  id: BookingJSON['id'];
  data: ApprovalRequestCreateBody;
}

export const getApprovalRequests = createAsyncThunk<List<ApprovalRequestJSON, ApprovalRequestRelationshipsJSON>, null, { state: RootState }>('APPROVAL_REQUESTS_GET', async (_args, thunkAPI) => {
  try {
    const approvalRequests = await api.getApprovalRequests();
    return approvalRequests;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while retrieving your approval requests`, status: 'error' }));
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const createApprovalRequest = createAsyncThunk<ApprovalRequestJSON, ApprovalRequestCreatePayload, { state: RootState }>('APPROVAL_REQUESTS_CREATE', async ({ id, data }, thunkAPI) => {
  try {
    const approvalRequest = await bookingsApi.createApprovalRequest(id, data);
    const searchParams = new URLSearchParams({ after: Date.now().toString() });

    thunkAPI.dispatch(getBookings({ scope: 'user', searchParams }));

    thunkAPI.dispatch(getUserStatsThunk());

    return approvalRequest;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.message);
  }
});

export const updateApprovalResponse = createAsyncThunk<ApprovalResponseJSON, ApprovalResponseUpdatePayload, { state: RootState }>('APPROVAL_RESPONSES_UPDATE', async ({ approvalResponse, data }, thunkAPI) => {
  try {
    const { id, approvalRequestId } = approvalResponse;
    const updatedApprovalResponse = await api.updateApprovalResponse(approvalRequestId, id, data);

    const searchParams = new URLSearchParams({ after: Date.now().toString() });
    thunkAPI.dispatch(getBookings({ scope: 'user', searchParams }));

    thunkAPI.dispatch(getUserStatsThunk());

    return updatedApprovalResponse;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.message);
  }
});