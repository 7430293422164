import { Checkbox, FormControl, FormLabel, HStack, Text } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { QuestionProps } from './QuestionProps';

export function QuestionCheckbox({ question, questionAnswers, onChange }: QuestionProps) {
  function onCheckboxChange({ target }: ChangeEvent<HTMLInputElement>) {
    const optionId = Number(target.value);
    const filteredAnswers = questionAnswers.filter(({ questionOptionId }) => questionOptionId !== optionId);

    if (!target.checked) {
      return onChange(filteredAnswers, question);
    }

    onChange([
      ...filteredAnswers,
      {
        questionId: question.id,
        questionOptionId: optionId,
        value: null
      }
    ], question);
  }

  return (
    <FormControl isRequired={question.required}>
      <FormLabel>{question.label}</FormLabel>

      {question.questionOptions.map(({ id, value }) => (
        <HStack key={id} spacing={2} pb={2}>
          <Checkbox
            value={id}
            onChange={onCheckboxChange}
            isChecked={!!questionAnswers.find(({ questionOptionId }) => questionOptionId === id)}
          />
          <Text color="gray.400">{value}</Text>
        </HStack>
      ))}
    </FormControl>
  );
}