import { Textarea } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultValue: string;
  isDisabled: boolean;
  onChange(value: string): void;
}

export function DescriptionTextArea(props: Props) {
  const { t } = useTranslation('tasks', { keyPrefix: 'drawer.form.description' });

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [description, setDescription] = useState(props.defaultValue);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [textAreaRef, description]);

  function onChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const value = e.target.value;

    setDescription(value);
    props.onChange(value);
  }

  return (
    <Textarea
      name="description"
      ref={textAreaRef}
      rows={1}
      pt={2}
      pb={5}
      px={5}
      fontSize={14}
      variant="unstyled"
      borderRadius="none"
      defaultValue={props.defaultValue}
      placeholder={t('placeholder')}
      resize="none"
      onChange={onChange}
      isDisabled={props.isDisabled}
      _disabled={{ color: 'black' }}
    />
  );
}