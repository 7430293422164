import { Select, SelectProps } from '@chakra-ui/react';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { TeamJSON } from '@jurnee/common/src/entities/Team';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import { useMemo } from 'react';

interface Props {
  budgets: BudgetJSON[];
  teams: TeamJSON[];
  defaultValue: DefaultBudgetTeamValue;
  onChange(data: DefaultBudgetTeamValue): void;
}

type BudgetTeamOption = {
  type: 'BUDGET' | 'TEAM';
  label: string;
  value: string;
  id: number;
};

export type DefaultBudgetTeamValue = Pick<BudgetTeamOption, 'type' | 'id'>;

function getBudgetTeamOptions({ budgets, teams }: Pick<Props, 'budgets' | 'teams'>) {
  const options: BudgetTeamOption[] = [];

  for (const { id, name } of budgets) {
    options.push({ type: 'BUDGET', label: name, value: `BUDGET_${id}`, id });
  }

  for (const { id, name } of teams) {
    options.push({ type: 'TEAM', label: name, value: `TEAM_${id}`, id });
  }

  return sortAlphabeticallyBy(options, 'label');
}

export function BudgetTeamSelect({ budgets, teams, defaultValue, onChange, ...inheritedProps }: Props & Omit<SelectProps, 'onChange' | 'defaultValue'>) {
  const options = useMemo(() => getBudgetTeamOptions({ budgets, teams }), [budgets, teams]);

  const defaultOptionValue = useMemo(
    () => defaultValue ? options.find(option =>
      option.type === defaultValue.type &&
        option.id === defaultValue.id
    ).value : null
    , [options]
  );

  function handleChange({ target }: React.ChangeEvent<HTMLSelectElement>) {
    const { type, id } = options.find(option => option.value === target.value);

    onChange({ type, id });
  }

  return (
    <Select
      bg="white"
      fontSize={14}
      variant="unstyled"
      defaultValue={defaultOptionValue}
      onChange={handleChange}
      {...inheritedProps}
    >
      {
        options.map(({ label, value }) =>
          <option key={value} value={value} label={label} />
        )
      }
    </Select>
  );
}