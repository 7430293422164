import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import * as React from 'react';

interface Props {
  children: React.ReactNode;
}

export default class StripeWrapper extends React.PureComponent<Props> {

  private stripe: Promise<Stripe> = loadStripe(process.env.STRIPE_PUBLIC_KEY);

  get options() {
    return {
      fonts: [
        {
          src: `url('/assets/fonts/inter/regular.woff2') format(woff)`,
          family: 'Inter'
        }
      ]
    };
  }

  render() {
    return (
      <Elements stripe={this.stripe} options={this.options}>
        {this.props.children}
      </Elements>
    );
  }

}