import { ApprovalRequestJSON } from '@jurnee/common/src/entities/ApprovalRequest';
import { ApprovalResponseJSON } from '@jurnee/common/src/entities/ApprovalResponse';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { formatDate, getCurrentTimeZone } from '@jurnee/common/src/utils/dates';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import ApprovalStatus from 'src/components/ApprovalStatus';
import { ApprovalResponseStatus } from 'src/components/Status/ApprovalResponseStatus';

interface OwnProps {
  approvalRequest: ApprovalRequestJSON;
  users: UserJSON[];
}

type Props = OwnProps & WithTranslation;

class ApprovalResponses extends React.Component<Props> {

  getDate(approvalResponse: ApprovalResponseJSON) {
    switch(approvalResponse.status) {
    case 'APPROVED':
      return new Date(approvalResponse.approvedAt);
    case 'CANCELED':
      return new Date(approvalResponse.canceledAt);
    case 'REJECTED':
      return new Date(approvalResponse.rejectedAt);
    default:
      return new Date(approvalResponse.createdAt);
    }
  }

  render() {
    return this.props.approvalRequest.approvalResponses.map((approvalResponse, i) => {
      const approver = this.props.users.find(({ id }) => approvalResponse.userId === id);
      const date = this.getDate(approvalResponse as ApprovalResponseJSON);

      const formattedDate = formatDate(date, {
        displayTime: true,
        displayTimeZone: true,
        timeZone: getCurrentTimeZone()
      });

      return <ApprovalStatus
        key={i}
        user={approver}
        status={<ApprovalResponseStatus status={approvalResponse.status} />}
        comment={approvalResponse.comment}
        date={this.props.t(`approvalResponses.${approvalResponse.status}.dateLabel`, { date: formattedDate })}
      />;
    });
  }

}

export default withTranslation('booking')(ApprovalResponses);