import { VStack, useToast } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { BudgetSpendingStatementItemJSON } from '@jurnee/common/src/entities/BudgetSpendingStatement';
import { sortByDate } from '@jurnee/common/src/utils/arrays';
import { getErrorToast } from '@jurnee/common/src/utils/toasts';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from 'src/api/budgets';
import { BudgetSpendingStatementItem } from './BudgetSpendingStatementItem';

interface Props {
  budgetId: BudgetJSON['id'];
}

export function BudgetSpendingStatement({ budgetId }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const [pendingItems, setPendingItems] = useState<BudgetSpendingStatementItemJSON[]>([]);
  const [spentItems, setSpentItems] = useState<BudgetSpendingStatementItemJSON[]>([]);
  const toast = useToast();
  const { t } = useTranslation('budgets');

  async function fetchBudgetSpendingStatement() {
    try {
      const { list } = await api.getBudgetSpendingStatement(budgetId);
      const sortedList = sortByDate(list, 'date');
      const pending: BudgetSpendingStatementItemJSON[] = [];
      const spent: BudgetSpendingStatementItemJSON[] = [];

      for (const item of sortedList) {
        if (item.pending > 0) { pending.push(item); }
        if (item.spent > 0) { spent.push(item); }
      }

      setPendingItems(pending);
      setSpentItems(spent);
    } catch (error) {
      toast(getErrorToast(t('toasts.fetchSpendingStatement.error')));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchBudgetSpendingStatement();
  }, [budgetId]);

  if (isLoading) {
    return <Loader/>;
  }

  return (
    <VStack spacing={4} alignItems="stretch" w="100%">
      { spentItems.map((item) => <BudgetSpendingStatementItem type="spent" key={item.id} item={item}/>) }
      { pendingItems.map((item) => <BudgetSpendingStatementItem type="pending" key={item.id} item={item} />) }
    </VStack>
  );
}