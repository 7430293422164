import { Heading, VStack } from '@chakra-ui/react';
import { NetPromoterScoreCreateBody } from '@jurnee/common/src/dtos/reviews';
import * as React from 'react';
import { PrimaryButton } from 'src/components/buttons';
import NpsRating from 'src/components/NpsRating';

interface OwnProps {
  onNext(data: NetPromoterScoreCreateBody): void;
}

interface State {
  rating: number;
}

export default class Nps extends React.Component<OwnProps> {

  state: State = {
    rating: 0
  };

  handleNext = () => {
    this.props.onNext(this.state);
  };

  handleRating = (rating: number) => {
    this.setState({ ...this.state, rating });
  };

  render() {
    return (
      <VStack w="100%" h="100%" justifyContent="space-between">
        <Heading size="md">How likely are you to recommend Jurnee to a friend or colleague?</Heading>

        <NpsRating onClick={this.handleRating} />

        <PrimaryButton size="md" w="100%" colorScheme="teal" onClick={this.handleNext} isDisabled={this.state.rating === 0}>Submit</PrimaryButton>
      </VStack>
    );
  }

}