import { ApprovalResponseJSON } from '@jurnee/common/src/entities/ApprovalResponse';
import { withTranslation } from 'react-i18next';
import BaseStatusBadge, { StatusBadgeProps } from './index';

type Props = {
  status: ApprovalResponseJSON['status'];
}

class StatusBadge extends BaseStatusBadge<StatusBadgeProps & Props> {

  get label() {
    switch (this.props.status) {
    case 'APPROVED': return this.props.t('approved');
    case 'CANCELED': return this.props.t('canceled');
    case 'REJECTED': return this.props.t('rejected');
    case 'INITIAL':
    default:
      return this.props.t('pending');
    }
  }

  get variant() {
    switch (this.props.status) {
    case 'CANCELED':
      return 'gray';
    case 'APPROVED':
      return 'teal';
    case 'REJECTED':
      return 'pink';
    case 'INITIAL':
    default:
      return 'blue';
    }
  }

}

export const ApprovalResponseStatus = withTranslation('common', { keyPrefix: 'status' })(StatusBadge);